import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
export default function DnAllerREF34E({ label, name, tooltip }: { label: string, name: NamePath ,tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner ce champ"
      },]}
    tooltip={{
      title: tooltip,
      icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
      color: '#0086CA',
    }}>
    <Select
      placeholder="Sélectionnez le Dn Aller"
      allowClear
      style={{ width: '150px' }}
    >
      <Option value="1">32</Option>
      <Option value="2">40</Option>
      <Option value="3">50</Option>
      <Option value="4">65</Option>
      <Option value="5">80</Option>
      <Option value="6">100</Option>
      <Option value="7">125</Option>
      <Option value="8">150</Option>
      <Option value="9">175</Option>
      <Option value="10">200</Option>
      <Option value="11">250</Option>
      <Option value="12">300</Option>
      <Option value="13">350</Option>
      <Option value="14">400</Option>
      <Option value="15">450</Option>
      <Option value="16">500</Option>
      <Option value="17">550</Option>
      <Option value="18">600</Option>
      <Option value="19">700</Option>
      <Option value="20">800</Option>
      <Option value="21">900</Option>
      <Option value="22">1000</Option>
    </Select>
  </Form.Item >
}