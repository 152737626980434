import { IHeliosUser } from '@/features/profile';
import usersServices from '@/services/user/userServices';
import { setHeliosUser } from '@/store/slices';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

export const useUpdateUserPhones = (
    phoneNumber: string,
    isToChangeMobNumber: boolean
) => {
    const dispatch = useDispatch();
    const {
        isPending,
        isResolved,
        isRejected,
        run: updateUserPhoneNumber,
    } = useAsync({
        deferFn: () =>
            usersServices.changPhonesNumber(phoneNumber, isToChangeMobNumber),
        onResolve: async (phonesNumbers: IHeliosUser) => {
            const user = await usersServices.getHeliosUser();
            dispatch(setHeliosUser(user));
        },
    });

    return { isPending, isResolved, isRejected, updateUserPhoneNumber };
};
