import React from 'react';

import { Header, Text } from '@/utils/constants';
import headerImgDs from '@assets/img/header-img-ds.png';
import headerImgMb from '@assets/img/header-img-mb.png';
import logo from '@assets/img/logo_cameo.png';
import { Col, Image, Row } from 'antd';

import './header.scss';

export const BaseHeader: React.FC = React.memo(() => {
    return (
        <Header className="header__base">
            <picture className="header__base__image">
                <source srcSet={headerImgDs} media="(min-width: 992px)" />
                <img src={headerImgMb} alt="header" />
            </picture>
            <Row className="header__base__meta">
                <Col className="header__base__logo">
                    <Image preview={false} width={'100%'} src={logo} />
                </Col>
                <Col>
                    <Text strong className="header__base__description">
                        Votre plateforme de suivi de projets CEE
                    </Text>
                </Col>
            </Row>
        </Header>
    );
});
