import { useCallback } from 'react';

import { COMPELETE_NEW_PASSWORD, DASHBOARD } from '@/routes';
import { NEW_PASSWORD_REQUIRED } from '@/utils';
import { CognitoUser } from '@aws-amplify/auth';
import { IHeliosUser } from '@features/profile';
import usersServices from '@services/user/userServices';
import { setCognitoUser } from '@store/slices';
import { Auth } from 'aws-amplify';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useConnexion = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCognitoConnexion = useCallback(async (param: [string, string]) => {
        const [username, password] = param;
        try {
            const cognitoUser: Promise<CognitoUser> = await Auth.signIn(
                username,
                password
            );
            const session = (await cognitoUser).getSignInUserSession();

            if (session && session.getIdToken().getJwtToken()) {
                try {
                    const heliosUser: IHeliosUser =
                        await usersServices.getHeliosUser();
                    if (heliosUser.email) {
                        return cognitoUser;
                    }
                    return Promise.reject();
                } catch (error) {
                    return Promise.reject(error);
                }
            }
            return Promise.resolve(cognitoUser);
        } catch (error) {
            return Promise.reject(error);
        }
    }, []);

    const {
        isPending,
        isResolved,
        isRejected,
        run: onConnexion,
    } = useAsync({
        deferFn: (param) => onCognitoConnexion(param as [string, string]),
        onResolve: (user: CognitoUser) => {
            if (user.challengeName === NEW_PASSWORD_REQUIRED) {
                dispatch(setCognitoUser(user));
                navigate(COMPELETE_NEW_PASSWORD);
            } else {
                navigate(DASHBOARD);
            }
        },
    });

    return { isPending, isResolved, isRejected, onConnexion };
};
