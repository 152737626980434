import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { TNewProjectForm } from './types';

const initialState = {
    formValues: {} as TNewProjectForm,
};

export const newProjectFormSlice = createSlice({
    name: 'newProjectFormSlice',
    initialState,
    reducers: {
        setNewProjectFormValues: (
            state,
            action: PayloadAction<TNewProjectForm>
        ) => {
            state.formValues = action.payload;
        },
    },
});

export const { setNewProjectFormValues } = newProjectFormSlice.actions;
export default newProjectFormSlice.reducer;
