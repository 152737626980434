import React, { useState } from 'react';

import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Checkbox, Form, Input, Row, Space } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const OthersWorks = () => {
    const [, setOthersWorkTypes] = useState<CheckboxValueType[]>();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        setOthersWorkTypes(checkedValues);
    };
    return (
        <Form.Item
            className="new-project__under-form-item"
            name={constant.OTHERS_WORK_TYPES}
        >
            <Checkbox.Group onChange={onChange} name="othersworks">
                <Space direction="vertical">
                    <div>
                        <Checkbox value="transportation">
                            <Text>Transports</Text>
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox value="agriculture">
                            <Text>Agriculture</Text>
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox value="networks">
                            <Text>Réseaux</Text>
                        </Checkbox>
                    </div>
                    <div>
                        <Row align="bottom">
                            <Checkbox value="otherTypes">
                                <Text>Autre</Text>
                            </Checkbox>
                            <Form.Item
                                className="form-item--same-line"
                                name={constant.OTHERS_WORK_TYPES_NAMES}
                            >
                                <Input className="input-text-sameline" />
                            </Form.Item>
                        </Row>
                    </div>
                </Space>
            </Checkbox.Group>
        </Form.Item>
    );
};
