import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, FormListFieldData, Typography } from 'antd';

import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';

const { Title } = Typography;

export const Barth1011S = ({ field }: { field: FormListFieldData }) => {
  /* Couleurs */
  const primary_color = '#002766';
  const border_color = '#76B8DE';
  const subtitle_color = '#5E5E5E';

  /* Infos FOST */
  const code_fost = 'BAR-TH-101';
  const libelle_fost = 'Chauffe-eau solaire individuel (France métropolitaine)';
  const denomination_fost = (
    <>Mise en place d’un chauffe-eau solaire individuel (CESI)</>
  );
  const lien_pdf =
    'https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-101.pdf';

  return (
    <>
      <Title level={4} style={{ color: primary_color, margin: 0 }}>
        {code_fost} : {libelle_fost}
      </Title>
      <Flex justify="space-between" align="top">
        <Typography.Text
          strong
          style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}
        >
          {denomination_fost}
        </Typography.Text>
        <Button
          style={{ color: primary_color }}
          size="small"
          href={lien_pdf}
          target="_blank"
        >
          <FilePdfOutlined />
          Fiche PDF
        </Button>
      </Flex>
      <Divider
        orientation="left"
        orientationMargin={0}
        style={{
          color: primary_color,
          borderColor: border_color,
          marginTop: '12px',
          marginBottom: '12px',
        }}
      ></Divider>

      {/* Code postal */}
      <ZoneClimatiqueS field={field} />
    </>
  );
};
