import { FormListFieldData } from 'antd';

import { Barth107SE1S } from './Formulaires simples/Barth107SE1S';
import { Barth1011S } from './Formulaires simples/Barth1011S';
import { Barth1071S } from './Formulaires simples/Barth1071S';
import { Barth1123S } from './Formulaires simples/Barth1123S';
import { Barth1133S } from './Formulaires simples/Barth1133S';
import { Barth1232S } from './Formulaires simples/Barth1232S';
import { Barth1255S } from './Formulaires simples/Barth1255S';
import { Barth1293S } from './Formulaires simples/Barth1293S';
import { Barth1435S } from './Formulaires simples/Barth1435S';
import { Barth1482S } from './Formulaires simples/Barth1482S';
import { Barth1501S } from './Formulaires simples/Barth1501S';
import { Barth1594S } from './Formulaires simples/Barth1594S';
import { Barth1661S } from './Formulaires simples/Barth1661S';
import { Barth1711S } from './Formulaires simples/Barth1711S';
import { Barth1721S } from './Formulaires simples/Barth1721S';
import { Bateq1274S } from './Formulaires simples/Bateq1274S';
import { Batth1022S } from './Formulaires simples/Batth1022S';
import { Batth1093S } from './Formulaires simples/Batth1093S';
import { Batth1122S } from './Formulaires simples/Batth1122S';
import { Batth1134S } from './Formulaires simples/Batth1134S';
import { Batth1165S } from './Formulaires simples/Batth1165S';
import { Batth1252S } from './Formulaires simples/Batth1252S';
import { Batth1262S } from './Formulaires simples/Batth1262S';
import { Batth1393S } from './Formulaires simples/Batth1393S';
import { Batth1402S } from './Formulaires simples/Batth1402S';
import { Batth1412S } from './Formulaires simples/Batth1412S';
import { Batth1582S } from './Formulaires simples/Batth1582S';
import { Indut1022S } from './Formulaires simples/Indut1022S';
import { Indut1041S } from './Formulaires simples/Indut1041S';
import { Indut1051S } from './Formulaires simples/Indut1051S';
import { Indut1174S } from './Formulaires simples/Indut1174S';
import { Indut1181S } from './Formulaires simples/Indut1181S';
import { Indut1301S } from './Formulaires simples/Indut1301S';
import { Resch1033S } from './Formulaires simples/Resch1033S';
import { Resch1043S } from './Formulaires simples/Resch1043S';
import { Resch1053S } from './Formulaires simples/Resch1053S';
import { Resch1064S } from './Formulaires simples/Resch1064S';
import { Resch1084S } from './Formulaires simples/Resch1084S';


export const FormSelector = ({
    fost,
    field,
}: {
    fost: string;
    field: FormListFieldData;
}) => {
    const formsList = [
        {
            key: 1,
            code: 'BAR-TH-101',
            libelle: 'Chauffe-eau solaire individuel (France métropolitaine)',
            form: <Barth1011S field={field} />,
        },
        {
            key: 2,
            code: 'BAR-TH-107',
            libelle: 'Chaudière collective à haute performance énergétique',
            form: <Barth1071S field={field} />,
        },
        {
            key: 3,
            code: 'BAR-TH-107-SE',
            libelle:
                'Chaudière collective haute performance énergétique avec contrat assurant la conduite de l’installation',
            form: <Barth107SE1S field={field} />,
        },
        {
            key: 4,
            code: 'BAR-TH-112',
            libelle: 'Appareil indépendant de chauffage au bois',
            form: <Barth1123S field={field} />,
        },
        {
            key: 5,
            code: 'BAR-TH-113',
            libelle: 'Chaudière biomasse individuelle',
            form: <Barth1133S field={field} />,
        },
        {
            key: 6,
            code: 'BAR-TH-123',
            libelle:
                'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
            form: <Barth1232S field={field} />,
        },
        {
            key: 7,
            code: 'BAR-TH-125',
            libelle:
                'Système de ventilation double flux autoréglable ou modulé à haute performance (France métropolitaine)',
            form: <Barth1255S field={field} />,
        },
        {
            key: 8,
            code: 'BAR-TH-129',
            libelle: 'Pompe à chaleur de type air/air',
            form: <Barth1293S field={field} />,
        },
        {
            key: 9,
            code: 'BAR-TH-143',
            libelle: 'Système solaire combiné (France métropolitaine)',
            form: <Barth1435S field={field} />,
        },
        {
            key: 10,
            code: 'BAR-TH-148',
            libelle: 'Chauffe-eau thermodynamique à accumulation',
            form: <Barth1482S field={field} />,
        },
        {
            key: 11,
            code: 'BAR-TH-150',
            libelle:
                'Pompe à chaleur collective à absorption de type air/eau ou eau/eau',
            form: <Barth1501S field={field} />,
        },
        {
            key: 12,
            code: 'BAR-TH-159',
            libelle:
                'Pompe à chaleur collective à absorption de type air/eau ou eau/eau',
            form: <Barth1594S field={field} />,
        },
        {
            key: 13,
            code: 'BAR-TH-166',
            libelle: 'Pompe à chaleur collective de type air/eau ou eau/eau',
            form: <Barth1661S field={field} />,
        },
        {
            key: 14,
            code: 'BAR-TH-171',
            libelle: 'Pompe à chaleur de type air/eau',
            form: <Barth1711S field={field} />,
        },
        {
            key: 15,
            code: 'BAR-TH-172',
            libelle: 'Pompe à chaleur de type eau/eau ou sol/eau',
            form: <Barth1721S field={field} />,
        },
        {
            key: 16,
            code: 'BAT-TH-102',
            libelle: 'Chaudière collective à haute performance énergétique',
            form: <Batth1022S field={field} />,
        },
        {
            key: 17,
            code: 'BAT-TH-109',
            libelle:
                'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
            form: <Batth1093S field={field} />,
        },
        {
            key: 18,
            code: 'BAT-TH-112',
            libelle:
                'Système de variation électronique de vitesse sur un moteur asynchrone',
            form: <Batth1122S field={field} />,
        },
        {
            key: 19,
            code: 'BAT-TH-113',
            libelle: 'Pompe à chaleur de type air/eau ou eau/eau',
            form: <Batth1134S field={field} />,
        },
        {
            key: 20,
            code: 'BAT-TH-125',
            libelle:
                'Ventilation mécanique simple flux à débit d’air constant ou modulé',
            form: <Batth1252S field={field} />,
        },
        {
            key: 21,
            code: 'BAT-TH-126',
            libelle:
                'Ventilation mécanique double flux avec échangeur à débit d’air constant ou modulé',
            form: <Batth1262S field={field} />,
        },
        {
            key: 22,
            code: 'BAT-TH-139',
            libelle:
                'Système de récupération de chaleur sur un groupe de production de froid',
            form: <Batth1393S field={field} />,
        },
        {
            key: 23,
            code: 'BAT-TH-140',
            libelle: 'Pompe à chaleur à absorption de type air/eau ou eau/eau',
            form: <Batth1402S field={field} />,
        },
        {
            key: 24,
            code: 'BAT-TH-141',
            libelle: 'Pompe à chaleur à moteur gaz de type air/eau',
            form: <Batth1412S field={field} />,
        },
        {
            key: 25,
            code: 'BAT-TH-158',
            libelle: 'Pompe à chaleur réversible de type air/air',
            form: <Batth1582S field={field} />,
        },
        {
            key: 26,
            code: 'BAT-EQ-127',
            libelle: 'Luminaire à modules LED',
            form: <Bateq1274S field={field} />,
        },
        {
            key: 27,
            code: 'IND-UT-102',
            libelle:
                'Système de variation électronique de vitesse sur un moteur asynchrone',
            form: <Indut1022S field={field} />,
        },
        {
            key: 28,
            code: 'IND-UT-104',
            libelle:
                'Économiseur sur les effluents gazeux d’une chaudière de production de vapeur',
            form: <Indut1041S field={field} />,
        },
        {
            key: 29,
            code: 'IND-UT-105',
            libelle: 'Brûleur micro-modulant sur chaudière industrielle',
            form: <Indut1051S field={field} />,
        },
        {
            key: 30,
            code: 'IND-UT-117',
            libelle:
                'Système de récupération de chaleur sur un groupe de production de froid',
            form: <Indut1174S field={field} />,
        },
        {
            key: 31,
            code: 'IND-UT-118',
            libelle:
                'Brûleur avec dispositif de récupération de chaleur sur four industriel',
            form: <Indut1181S field={field} />,
        },
        {
            key: 32,
            code: 'IND-UT-130',
            libelle:
                'Condenseur sur les effluents gazeux d’une chaudière de production de vapeur',
            form: <Indut1301S field={field} />,
        },
        {
            key: 33,
            code: 'RES-CH-103',
            libelle:
                'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment tertiaire',
            form: <Resch1033S field={field} />,
        },
        {
            key: 34,
            code: 'RES-CH-104',
            libelle:
                'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment résidentiel',
            form: <Resch1043S field={field} />,
        },
        {
            key: 35,
            code: 'RES-CH-105',
            libelle: 'Passage d’un réseau de chaleur en basse température',
            form: <Resch1053S field={field} />,
        },
        {
            key: 36,
            code: 'RES-CH-106',
            libelle:
                'Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur',
            form: <Resch1064S field={field} />,
        },
        {
            key: 37,
            code: 'BAT-TH-116',
            libelle:
                'Système de gestion technique du bâtiment pour le chauffage, l’eau chaude sanitaire, le refroidissement/climatisation, l’éclairage et les auxiliaires',
            form: <Batth1165S field={field} />,
        },
        {
            key: 38,
            code: 'RES-CH-108',
            libelle:
                'Récupération de chaleur fatale pour valorisation vers un réseau de chaleur ou vers un tiers (France métropolitaine)',
            form: <Resch1084S field={field} />,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            {formsList
                .filter((choix) => choix.code === fost)
                .map((choix) => (
                    <div key={choix.key}>{choix.form}</div>
                ))}
        </div>
    );
};
