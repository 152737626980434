import { Form, InputNumber, Alert, FormListFieldData, Radio, Typography, Divider, Collapse, Button, Card, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import ModeFonctionnementREF36A from '@/features/new-project/operations/forms/components/common/ModeFonctionnementREF36A';

const { Paragraph } = Typography

/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Indut1051 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Lien vers le PDF de la FOST */
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-105.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>Le brûleur micro-modulant est installé sur une chaudière de puissance thermique nominale
            <Typography.Text strong style={{ color: primary_color }}> inférieure à 20 MW.</Typography.Text>
        </Paragraph>
        <Paragraph>Le brûleur micro-modulant dispose d’une
            <Typography.Text strong style={{ color: primary_color }}> régulation </Typography.Text>de la teneur en oxygène dans les fumées.</Paragraph>
        <Paragraph>Le brûleur micro-modulant a une plage de modulation minimale :
            <Typography style={{ paddingLeft: '16px' }}>- de<Typography.Text strong style={{ color: primary_color }}> un à cinq (de 20% à 100%) </Typography.Text>
                par came électronique pour une chaudière de puissance utile nominale inférieure ou égale à 2 MW</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- de<Typography.Text strong style={{ color: primary_color }}> un à huit (de 12,5% à 100%) </Typography.Text>
                par came électronique pour une chaudière de puissance utile nominale supérieure à 2 MW</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- alimentée<Typography.Text strong style={{ color: primary_color }}> au gaz naturel ou au GPL</Typography.Text></Typography>
        </Paragraph>
        <Typography>La mise en place d’un économiseur sur une chaudière de secours n’est pas éligible à l’opération.</Typography>

    </>
    const facture = <>
        <Paragraph>La preuve de la réalisation de l'opération mentionne la
            <Typography.Text strong style={{ color: primary_color }}> mise en place sur une chaudière industrielle d’un brûleur micro-modulant et des plages de
                modulation par came électronique de ce brûleur et la mise en place d’une régulation de la teneur en oxygène dans les fumées.</Typography.Text></Paragraph>
        <Typography>A défaut, la preuve de réalisation de l'opération mentionne la mise en place d'un équipement avec ses marque et
            référence et elle est complétée par un document issu du fabricant indiquant que l'équipement de marque et référence
            installé est un brûleur micro-modulant avec la précision des plages de modulation par came électronique de ce
            brûleur et qu’il comporte une régulation de la teneur en oxygène dans les fumées.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>IND-UT-105 : Brûleur micro-modulant sur chaudière industrielle</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’un brûleur micro-modulant sur une chaudière
                    industrielle de puissance thermique nominale inférieure à 20 MW.
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Batiment industriel  */}
            <Form.Item
                name={[field.name, "BatimentIndustrie"]}
                label="L'opération est-elle réalisée dans le secteur de l'industrie ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                        form.setFieldValue(['items', field.name, "Bruleur1A5"], undefined)
                        form.setFieldValue(['items', field.name, "Bruleur1A8"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Bat industrie non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentIndustrie"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée dans le secteur de l'industrie."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Industrie OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentIndustrie"]) === "Oui" ? (
                        <>
                            {/* Puissance chaudière */}
                            <Form.Item
                                name={[field.name, "REF-26"]}
                                label="Puissance utile nominale de la chaudière (kW) :"
                                tooltip={{
                                    title: <>Puissance définie par l’article R.224-20 du code de l’environnement comme « la puissance thermique maximale fixée et
                                        garantie par le constructeur comme pouvant être délivrée au fluide caloporteur en marche continue ». La mise en place d’un
                                        brûleur micro-modulant sur une chaudière de secours n’est pas éligible à cette opération</>,
                                    icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                                    color: '#0086CA',
                                }}
                            >
                                <InputNumber
                                    addonAfter="kW"
                                    min={0}
                                    decimalSeparator=','
                                    onChange={
                                        () => {
                                            form.setFieldValue(['items', field.name, "Bruleur1A5"], undefined)
                                            form.setFieldValue(['items', field.name, "Bruleur1A8"], undefined)
                                        }}
                                />
                            </Form.Item>
                            {/* Alerte si Puissance > 20000 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-26"]) >= 20000 ? (
                                        <>
                                            <Alert
                                                message="L'opération n'est éligible que pour une chaudière de puissance nomimale inférieure à 20 MW."
                                                type="error"
                                                showIcon
                                            />
                                            {
                                                () => {
                                                    form.setFieldValue(['items', field.name, "Bruleur1A5"], undefined)
                                                    form.setFieldValue(['items', field.name, "Bruleur1A8"], undefined)
                                                    form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                                }}
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Eligibilité si Puissance < 2000 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-26"]) < 2000 && getFieldValue(['items', field.name, "REF-26"]) > 0 ? (
                                        <>
                                            {/* Eligibilité Brûleur 1 à 5 */}
                                            <Form.Item
                                                name={[field.name, "Bruleur1A5"]}
                                                label="Le brûleur micro-modulant a-t-il une plage de modulation minimale de un à cinq (de 20% à 100%) par came électronique ?"
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte si Bruleur 1 à 5 non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Bruleur1A5"]) === "Non" ? (
                                                        <><Alert
                                                            message="Pour une chaudière de puissance utile nominale inférieure ou égale à 2 MW, cette opération n'est éligible que si
                                                                le brûleur micro-modulant a une plage de modulation minimale de un à cinq (de 20% à 100%) par came électronique."
                                                            type="error"
                                                            showIcon
                                                        />
                                                            {() => {form.setFieldValue(['items', field.name, "REF-36-A"], undefined)}}
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Eligibilité si Puissance >= 2000 && < 20000 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-26"]) < 20000 && getFieldValue(['items', field.name, "REF-26"]) >= 2000 ? (
                                        <>
                                            {/* Eligibilité Brûleur 1 à 8 */}
                                            <Form.Item
                                                name={[field.name, "Bruleur1A8"]}
                                                label="Le brûleur micro-modulant a-t-il une plage de modulation minimale de un à huit (de 12,5% à 100%) par came électronique ?"
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte si Bruleur 1 à 8 non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Bruleur1A8"]) === "Non" ? (
                                                        <><Alert
                                                            message="Pour une chaudière de puissance utile nominale supérieure à 2 MW et inférieure à 20 MW, cette opération n'est éligible que si
                                                                le brûleur micro-modulant a une plage de modulation minimale de un à huit (de 12,5% à 100%) par came électronique."
                                                            type="error"
                                                            showIcon
                                                        />
                                                        {() => {form.setFieldValue(['items', field.name, "REF-36-A"], undefined)}}
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Mode de fonctionnement */}
                            <ModeFonctionnementREF36A
                                name={[field.name, "REF-36-A"]}
                                label="Mode de fonctionnement du site : "
                            />

                        </>) : null
                }
            </Form.Item >

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
