import { STR_VALUE } from '@/features/new-project/constants';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray } from '@/utils';

interface WorkType {
    name?: string;
    label?: string;
    children?: any[];
    [key: string]: any;
}

export const createHeatingAnswers = (item: any, values: TNewProjectForm) => {
    const heating: typeof item = {};
    heating.name = item.name;
    heating.label = item.label;

    if (values.heatingWork && values.heatingWork.length > 0) {
        const children = item.children;
        heating.children = [];
        let worktype = {} as WorkType;

        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            if (values.heatingWork.includes(element.name)) {
                worktype = {};
                worktype.name = element.name;
                worktype.label = element.label;
                worktype.children = [];
                heating.children.push(worktype);

                const elementChildren = element.children;
                for (let i = 0; i < elementChildren.length; i++) {
                    const child = elementChildren[i];

                    const asnwers = getObjectFromArray(
                        elementChildren,
                        'name',
                        child.name
                    );

                    if (values[asnwers.name]) {
                        asnwers.label = `${asnwers.label.replace(
                            STR_VALUE,
                            values[asnwers.name]
                        )}`;
                        worktype.children.push(asnwers);
                    }
                }
            }
        }
    }
    return heating;
};
