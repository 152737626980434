import React, { useEffect, useState } from 'react';

import { Sidebar, Text } from '@/utils/constants';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import logo from '@assets/img/logo_cameo.png';
import { Image, Row } from 'antd';
import { Link } from 'react-router-dom';

import { SideBarMenu } from './SideBarMenu';
import './styles.scss';

export const SideBar: React.FC = React.memo(() => {
    const [collapsed, setCollapsed] = useState(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        let style = getComputedStyle(
            document.querySelector('body') as HTMLElement
        );
        setWidth(parseInt(style.width));
        window.addEventListener('resize', () => {
            let style = getComputedStyle(
                document.querySelector('body') as HTMLElement
            );
            setWidth(parseInt(style.width));
        });
    }, []);

    const onCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };
    return (
        <Sidebar
            breakpoint="xl"
            theme={'light'}
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            className="sidebar"
            collapsedWidth={0}
            width={width > 600 ? '220px' : '300px'}
            trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        >
            <Row className="sidebar__header">
                <Image preview={false} className="sidebar__logo" src={logo} />
            </Row>
            <Row justify={'center'}>
                <Text strong className="sidebar__title">
                    Menu
                </Text>
            </Row>
            <SideBarMenu />
            <Row justify="center" align="middle" className="sidebar__faq">
                <Link to="/faq">
                    <QuestionCircleOutlined style={{ marginRight: '5px' }} />
                    FAQ
                </Link>
            </Row>
        </Sidebar>
    );
});
