import { Form, Alert, FormListFieldData, InputNumber, Typography, Divider, Flex, Button, Radio } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import TypeLogementREF3 from '../refs/TypeLogementREF3';
import SurfaceHabitableREF14F from '../refs/SurfaceHabitableREF14F';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import SurfaceHabitableREF14E from '../refs/SurfaceHabitableREF14E';
import EfficaciteEnergetiqueREF140B from '../refs/EfficaciteEnergetiqueREF140B';
import TypeCoupDePouceIREF159Z from '../refs/TypeCoupDePouceIREF159Z';
import MenageModesteREF175 from '../refs/MenageModesteREF175';

const { Title } = Typography

export const Barth1594S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAR-TH-159"
    const libelle_fost = "Pompe à chaleur hybride individuelle"
    const denomination_fost = "Mise en place d’une pompe à chaleur air/eau individuelle comportant un dispositif d’appoint utilisant un combustible liquide ou gazeux et une régulation qui les pilote"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-159%20vA50-4%20%C3%A0%20compter%20du%2001-04-2023.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Type de logement */}
            <TypeLogementREF3
                label="Type de logement :"
                name={[field.name, 'REF-3']}
                onChange={() => {
                    form.setFieldValue(['items', field.name, "SurfaceChauffee"], undefined)
                    form.setFieldValue(['items', field.name, "IREF-14-E"], undefined)
                    form.setFieldValue(['items', field.name, "REF-14-F"], undefined)
                }}
            />

            {/* Surface chauffée appartement */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "1" ? (
                        <>
                            <Form.Item
                                name={[field.name, "SurfaceChauffee"]}
                                label="Surface chauffée par la PAC (m²) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="m²"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 35 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "0" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 60 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 35) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "1" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 60) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "2" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "3" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "4" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) <= 130 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "5" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 130) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "6" }]) }
                                        else {
                                            form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                                        }
                                    }}
                                />
                            </Form.Item>

                            <SurfaceHabitableREF14F
                                label=""
                                name={[field.name, 'REF-14-F']}
                                disabled={true}
                                hidden
                            />
                        </>
                    ) : null
                }
            </Form.Item >

            {/* Surface chauffée maison */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "0" ? (
                        <>
                            <Form.Item
                                name={[field.name, "SurfaceChauffee"]}
                                label="Surface chauffée par la PAC (m²) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="m²"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "0" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "1" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "2" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) <= 130 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "3" }]) }
                                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 130) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "4" }]) }
                                        else {
                                            form.setFields([{ name: ["items", field.name, "REF-14-E"], value: undefined }])
                                        }
                                    }}
                                />
                            </Form.Item>

                            <SurfaceHabitableREF14E
                                label=""
                                name={[field.name, 'REF-14-E']}
                                disabled={true}
                                hidden
                            />

                        </>
                    ) : null
                }
            </Form.Item >


            {/* Etas variable continue */}
            < Form.Item
                label="Efficacité énergétique saisonnière :"
                name={[field.name, 'Etas']}
                rules={
                    [
                        { required: true, message: "Veuillez renseigner ce champ" },
                    ]}
                tooltip={{
                    title: "L'efficacité énergétique saisonnière est déterminée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }
                }
            >
                <InputNumber
                    min={0}
                    controls={false}
                    decimalSeparator=','
                    style={{ width: '100px' }}
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Etas"]) >= 111 && form.getFieldValue(['items', field.name, "Etas"]) < 120) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 120 && form.getFieldValue(['items', field.name, "Etas"]) < 130) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 130 && form.getFieldValue(['items', field.name, "Etas"]) < 140) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "2" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 140 && form.getFieldValue(['items', field.name, "Etas"]) < 150) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "3" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 150 && form.getFieldValue(['items', field.name, "Etas"]) < 160) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "4" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 160) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: "5" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "REF-140-B"], value: undefined }]) }
                    }}
                />
            </Form.Item >

            {/* ETAS */}
            <EfficaciteEnergetiqueREF140B
                label=""
                name={[field.name, 'REF-140-B']}
                disabled={true}
                hidden
            />

            {/* Alerte si ETAS < 111 */}
            < Form.Item noStyle shouldUpdate >
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Etas"]) < 111 ? (
                        <Alert
                            message="Cette opération n'est éligible que si l'efficacité énergétique saisonnière est supérieure ou égale à 111%."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item >

            {/* Coup de pouce */}
            <Form.Item
                name={[field.name, "EligibleCoupDePouce"]}
                label="La nouvelle PAC vient-elle en remplacement d'une chaudière au gaz, au fioul ou au charbon ?"
                colon={false}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                  ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "IREF-159-Z"], undefined)
                    form.setFieldValue(['items', field.name, "REF-175"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Energie remplacée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "EligibleCoupDePouce"]) === "Oui" ? (
                        <>
                            <Typography style={{ marginBottom: 10 }}><Typography.Text strong>L'opération est éligible au bonus Coup de Pouce, renseignez les informations suivantes pour connaître le montant bonifié :</Typography.Text></Typography>
                            <TypeCoupDePouceIREF159Z
                                name={[field.name, "IREF-159-Z"]}
                                label="Énergie remplacée"
                            />
                            <MenageModesteREF175
                                name={[field.name, "REF-175"]}
                                label="Ménage modeste :"
                            />
                        </>
                    ) : null
                }
            </Form.Item>
        </>
    );
};
