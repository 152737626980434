import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function PuissanceChaudiereREF24D({ label, name, onChange, disabled, hidden }: { label: string, name: NamePath, onChange?: () => void, disabled: boolean, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={[{
                required: true,
                message: "Veuillez renseigner la puissance"
            },]}
        >
        <Radio.Group buttonStyle='solid'         
            style={{ width: '250px' }}
            onChange={onChange}
            disabled={disabled}
        >
            <Radio.Button value="0">P &lt; 400 kW</Radio.Button>
            <Radio.Button value="1">P ≥ 400 kW</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
