import React, { useState } from 'react';

import { NumericInput } from '@/components';
import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Checkbox, Form, Input, Row, Space } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const Regulation = () => {
    const [regulationTypes, setRegulationTypes] =
        useState<CheckboxValueType[]>();
    const [, setusesConcerned] = useState<CheckboxValueType[]>();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        setRegulationTypes(checkedValues);
    };
    const onChangeUsesConcerned = (checkedValues: CheckboxValueType[]) => {
        setusesConcerned(checkedValues);
    };

    return (
        <Form.Item
            className="new-project__under-form-item"
            name={constant.REGULATION_TYPE}
        >
            <Checkbox.Group onChange={onChange}>
                <Space direction="vertical">
                    <div>
                        <Checkbox value={constant.REGULATION_GTB}>
                            <Text>Gestion technique du bâtiment (GTB) :</Text>
                        </Checkbox>
                        {regulationTypes?.includes(constant.REGULATION_GTB) && (
                            <div className="work-type-answer">
                                <Text>
                                    <ul style={{ listStyle: 'square' }}>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>Surface gérée :</Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.REGULATION_MANAGED_SURFACE
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>
                                                        m<sup>2</sup>
                                                    </Text>
                                                </Row>
                                            </li>
                                            <li style={{ paddingLeft: 0 }}>
                                                <Text>Usages concernés: </Text>
                                                <Form.Item
                                                    className="form-item--same-line"
                                                    name={
                                                        constant.REGULATION_USES_CONCERNED
                                                    }
                                                >
                                                    <Checkbox.Group
                                                        onChange={
                                                            onChangeUsesConcerned
                                                        }
                                                    >
                                                        <Space
                                                            direction="vertical"
                                                            size="small"
                                                        >
                                                            <div className="form-answer-third-level">
                                                                <Checkbox
                                                                    value={
                                                                        constant.REGULATION_USES_CONCERNED_HEATING
                                                                    }
                                                                >
                                                                    <Text>
                                                                        chauffage
                                                                    </Text>
                                                                </Checkbox>
                                                            </div>
                                                            <div className="form-answer-third-level">
                                                                <Checkbox
                                                                    value={
                                                                        constant.REGULATION_USES_CONCERNED_CLIM
                                                                    }
                                                                >
                                                                    <Text>
                                                                        refroidissement/clim
                                                                    </Text>
                                                                </Checkbox>
                                                            </div>
                                                            <div className="form-answer-third-level">
                                                                <Checkbox
                                                                    value={
                                                                        constant.REGULATION_USES_CONCERNED_ESC
                                                                    }
                                                                >
                                                                    <Text>
                                                                        ECS
                                                                    </Text>
                                                                </Checkbox>
                                                            </div>
                                                            <div className="form-answer-third-level">
                                                                <Checkbox
                                                                    value={
                                                                        constant.REGULATION_USES_CONCERNED_ECLAIRAGE
                                                                    }
                                                                >
                                                                    <Text>
                                                                        éclairage
                                                                    </Text>
                                                                </Checkbox>
                                                            </div>
                                                            <div className="form-answer-third-level">
                                                                <Row align="bottom">
                                                                    <Checkbox
                                                                        value={
                                                                            constant.OTHERS_REGULATION_GBT
                                                                        }
                                                                    >
                                                                        <Text>
                                                                            Autre
                                                                        </Text>
                                                                    </Checkbox>
                                                                    <Form.Item
                                                                        className="form-item--same-line"
                                                                        name={
                                                                            constant.OTHERS_REGULATION_GBT_NAMES
                                                                        }
                                                                    >
                                                                        <Input className="input-text-sameline" />
                                                                    </Form.Item>
                                                                </Row>
                                                            </div>
                                                        </Space>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </li>
                                        </Space>
                                    </ul>
                                </Text>
                            </div>
                        )}
                    </div>
                    <div>
                        <Checkbox value={constant.REGULATION_VARIATOR_MOTORS}>
                            <Text>
                                Variateur de vitesse sur moteur ou système
                                moto-régulé :
                            </Text>
                        </Checkbox>
                        {regulationTypes?.includes(
                            constant.REGULATION_VARIATOR_MOTORS
                        ) && (
                            <div className="work-type-answer">
                                <Text>
                                    <ul style={{ listStyle: 'square' }}>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        Puissance du moteur :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.REGULATION_ENGINE_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                        </Space>
                                    </ul>
                                </Text>
                            </div>
                        )}
                    </div>
                    <div>
                        <Checkbox value={constant.REGULATION_GROUP_PRODUCTION}>
                            <Text>
                                Régulation sur groupe de production de froid
                                (haute ou basse pression flottante) :
                            </Text>
                        </Checkbox>
                        {regulationTypes?.includes(
                            constant.REGULATION_GROUP_PRODUCTION
                        ) && (
                            <div className="work-type-answer">
                                <Text>
                                    <ul style={{ listStyle: 'square' }}>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        Puissance du groupe
                                                        froid :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.REGULATION_COLD_GROUP_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                        </Space>
                                    </ul>
                                </Text>
                            </div>
                        )}
                    </div>
                </Space>
            </Checkbox.Group>
        </Form.Item>
    );
};
