import { FormListFieldData, Typography, Divider, Flex, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import DnAllerREF34E from '../refs/DnAllerREF34E';
import LongueurCanalisationREF46 from '../refs/LongueurCanalisationREF46';
import DureeAnnuelleUtilisationReseauREF130 from '../refs/DureeAnnuelleUtilisationReseauREF130';

const { Title } = Typography

export const Resch1053S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Infos FOST */
    const code_fost = "RES-CH-105"
    const libelle_fost = "Passage d’un réseau de chaleur en basse température"
    const denomination_fost = <>Passage d’une utilisation d’un réseau de chaleur en haute pression
        (eau surchauffée), ou d’une partie d’un réseau de chaleur haute pression, à une utilisation du
        réseau de chaleur en basse pression et basse température (eau chaude inférieure à 120°C)</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-105%20vA36-3%20%C3%A0%20compter%20du%2012-10-2020.pdf"


    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* DN Aller */}
            <DnAllerREF34E
                name={[field.name, "REF-34-E"]}
                label="Diamètre initial DN du réseau :"
                tooltip="Le diamètre nominal (DN) correspond à la désignation de dimension commune à tous les 
                        éléments d'une même tuyauterie autre que ceux désignés par leur diamètre extérieur ou intérieur. 
                        C'est un nombre entier utilisé aux fins de référence. Il correspond au diamètre nominal de la 
                        canalisation du réseau de chaleur avant son passage en basse température."
            />

            {/* Longueur canalisation */}
            <LongueurCanalisationREF46
                name={[field.name, "REF-46"]}
                label="Longueur de canalisation concernée (m) :"
            />

            {/* Durée d'utilisation du réseau */}
            <DureeAnnuelleUtilisationReseauREF130
                name={[field.name, "REF-130"]}
                label="Durée annuelle d’utilisation de la portion du réseau de chaleur concernée :"
                tooltip="La durée annuelle d’utilisation du réseau de chaleur est celle de l’année 
                        calendaire précédant la date d’achèvement de l’opération."
            />

        </>
    );
};
