import { FormListFieldData, Typography, Flex, Divider, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '../refs/PuissanceREF26';
import SecteurIREF22AX from '../refs/SecteurIREF22AX';
import TypeLampeIREF30F from '../refs/TypeLampeIREF30F';
import FluxLumineuxIREF168B from '../refs/FluxLumineuxIREF168B';


const { Title } = Typography

export const Bateq1274S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-EQ-127"
    const libelle_fost = "Luminaire à modules LED"
    const denomination_fost = <>Mise en place d’un luminaire à modules LED</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-EQ-127%20vA40-4%20%C3%A0%20compter%20du%2001-04-2022.pdf"


    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/*Secteur*/}
            < SecteurIREF22AX
                label="Secteur :"
                name={[field.name, 'IREF-22-AX']}
            />

            {/* Type lampe */}
            <TypeLampeIREF30F
                label="Type de lampe :"
                name={[field.name, 'IREF-30-F']}
            />

            {/* Flux lumineux */}
            <FluxLumineuxIREF168B
                label="Efficacité lumineuse :"
                name={[field.name, 'IREF-168-B']}
                tooltip="L’efficacité lumineuse est égale au flux lumineux total sortant du luminaire divisé par sa puissance totale, y compris les
                                auxiliaires d'alimentation"
            />

            {/* Puissance */}
            <PuissanceREF26
                label="Puissance totale des luminaires à modules LED installés (en watt) :"
                name={[field.name, 'REF-26']}
                unite="W"
            />
        </>
    );
};
