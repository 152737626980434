import React from 'react';

import { RootState } from '@/store/store';
import { Text, Title } from '@/utils/constants';
import { NOT_SPECIFIED } from '@/utils/constants';
import {
    currencyFormatter,
    dateFormatter,
    emptyStringFormatter,
    numberFormatter,
} from '@/utils/helpers';
import { Card, Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

export const GenericInformations: React.FC = () => {
    const { details, currentDossierFromList } = useSelector(
        (state: RootState) => state.dossierReducer
    );

    return (
        <Card>
            <Space size="small" direction="vertical" style={{ width: '100%' }}>
                <Title level={4} style={{ marginTop: 0 }}>
                    Informations générales
                </Title>
                <Row align="top" gutter={[0, 20]}>
                    <Col md={10} xs={24}>
                        <Space size="middle" direction="vertical">
                            <Space size="small" direction="vertical">
                                <Text>Volume</Text>
                                <Text strong>
                                    {`${numberFormatter(
                                        details.volumeDossier
                                    )} kWhc`}
                                </Text>
                            </Space>
                            {details.benificiaryPrime > 0 && (
                                <>
                                    <Space size="small" direction="vertical">
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <Text>Prime</Text>
                                            <Text strong>
                                                {currencyFormatter(
                                                    details.benificiaryPrime,
                                                    true
                                                )}
                                            </Text>
                                        </Space>
                                    </Space>

                                    <Space size="small" direction="vertical">
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <Text>En remise sur devis</Text>
                                            <Text strong>
                                                {details.isQuotation
                                                    ? 'Oui'
                                                    : 'Non'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </>
                            )}
                        </Space>
                    </Col>
                    <Col md={10} xs={24}>
                        <Space size="middle" direction="vertical">
                            <Space size="small" direction="vertical">
                                <Text>Création du Dossier</Text>
                                <Text strong>
                                    {dateFormatter(
                                        currentDossierFromList?.createdAt as Date
                                    )}
                                </Text>
                            </Space>
                            <Space direction="vertical" size="small">
                                <Text>Numéro du Dossier</Text>
                                <Text strong>
                                    #{currentDossierFromList?.id}
                                </Text>
                            </Space>
                            <Space size="small" direction="vertical">
                                <Text>Adresse des travaux</Text>
                                <Text strong>{`${
                                    details?.addressWork &&
                                    emptyStringFormatter(details.addressWork)
                                        ? details.addressWork
                                        : NOT_SPECIFIED
                                }`}</Text>
                            </Space>
                        </Space>
                    </Col>
                    <Col md={4} xs={24}>
                        <Space size="middle" direction="vertical">
                            <Space size="small" direction="vertical">
                                <Text>Bâtiment</Text>
                                <Text strong>
                                    {details?.buildingWork
                                        ? details.buildingWork
                                        : NOT_SPECIFIED}
                                </Text>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </Card>
    );
};
