import BaseAPI from '@/apis/base';

class RegistrationRequestsServices {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }

    async sendRegistrationRequest(registration: any) {
        return await this.axios
            .post('/registration-requests/create', registration)
            .then((response) => response.data);
    }
}

const registrationRequestsServices = new RegistrationRequestsServices();
export default registrationRequestsServices;
