import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function TypeCoupDePouceIREF159Z({ label, name, disabled, hidden }: { label: string, name: NamePath, disabled?: boolean, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner l'énergie remplacée"
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">Gaz</Radio.Button>
            <Radio.Button value="1">Fioul</Radio.Button>
            <Radio.Button value="2">Charbon</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
