import { useState } from 'react';

import { useCustomFormValidations } from '@/hooks/antdform/useCustomFormValidations';
import { FicheRevision } from '@/hooks/new-project/types';
import { useGetFicheRevision } from '@/hooks/new-project/useGetFicheRevision';
import { dateFormatter } from '@/utils';
import { convertCalenderFieldToTimeStamp } from '@/utils/helpers';
import { Button, Card, Flex, FormListFieldData } from 'antd';

import { useWatch } from 'antd/es/form/Form';

import { ESTIMATE_SIGNATURE_DATE } from '../constants';
import { ProjectConcerns } from '../project-concerns/ProjectConcerns';
import { ProjectConcernsDataType } from '../types';
import { createComponentName } from '../utils';
import { CreateForm } from './forms/components/CreateForm';
import './styles.scss';

export const SimulateProject = ({
    field,
    isSimulated,
    onFicheCancelled
}: {
    field: FormListFieldData;
    isSimulated: boolean;
    onFicheCancelled: () => void;
}) => {
    const [formName, setFormName] = useState<string | undefined>();

    const quotationDateFiled = useWatch(ESTIMATE_SIGNATURE_DATE);
    const { getFicheRevision } = useGetFicheRevision();
    const { requeiredFieldAction } = useCustomFormValidations();

    const handleFiche = async (item: ProjectConcernsDataType) => {
        if (!quotationDateFiled) {
            requeiredFieldAction(
                ESTIMATE_SIGNATURE_DATE,
                'Afin de pouvoir choisir une fiche, veuillez compléter ce champ',
                true
            );

            return false;
        }
        const { value, children } = item;
        if (!children) {
            const ficheRevision: FicheRevision | void | null =
                await getFicheRevision(
                    value.toLocaleString(),
                    convertCalenderFieldToTimeStamp(quotationDateFiled)
                );
            if (ficheRevision) {
                const { decreeVersion } = ficheRevision;
                const existFormName = `${createComponentName(
                    value.toLocaleString()
                )}${decreeVersion}`;
                setFormName(existFormName);
                return true;
            }
            setFormName('');
            return false;
        }
    };

    const handelOnBack = () => {
        setFormName('');
    };

    const handleFicheCancelled = () => {
        setFormName('');
        onFicheCancelled();
    };

    return (
        <Flex vertical gap="small" align="flex-start">
            {!formName && (
                <ProjectConcerns
                    field={field}
                    onFicheSelected={(item) => handleFiche(item)}
                    onBack={handelOnBack}
                />
            )}

            {formName ? (
                <>
                    {!isSimulated && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ alignSelf: 'center' }}
                            onClick={handleFicheCancelled}
                        >
                            Changer de fiche CEE
                        </Button>
                    )}
                    <Card style={{ width: '100%', maxWidth: '100%' }}>
                        <CreateForm
                            name={formName}
                            estimationQuataionDate={dateFormatter(
                                quotationDateFiled
                            )}
                            field={field}
                        />
                    </Card>
                </>
            ) : null}
        </Flex>
    );
};
