import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { IAppInitialState } from './types';

const initialState: IAppInitialState = {
    isAppLoading: false,
    isAppError: false,
};

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        setIsAppLoading: (state, action: PayloadAction<boolean>) => {
            state.isAppError = false;
            state.isAppLoading = action.payload;
        },
        setIsAppError: (state, action: PayloadAction<boolean>) => {
            state.isAppLoading = false;
            state.isAppError = action.payload;
        },
        resetIsAppLoading: (state) => {
            state = initialState;
        },
    },
});

export const { setIsAppLoading, setIsAppError, resetIsAppLoading } =
    appSlice.actions;
export default appSlice.reducer;
