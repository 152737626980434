import React from 'react';

import { Col, Row } from 'antd';

import { BaseFooter } from './footer/Footer';
import { BaseHeader } from './header/Header';
import './styles.scss';

export const BaseLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <>
            <BaseHeader />
            <Row justify="center" align="middle" className="layout__connexion">
                <Col xl={8} xs={21} lg={16}>
                    {children}
                </Col>
            </Row>
            <BaseFooter />
        </>
    );
};
