import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import ProductionAnnuelleREF101 from '@/features/new-project/operations/forms/components/common/ProductionAnnuelleREF101';

const { Paragraph } = Typography

export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};

const { Title } = Typography

export const Indut1174 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "IND-UT-117"
    const libelle_fost = "Système de récupération de chaleur sur un groupe de production de froid"
    const denomination_fost = <>Mise en place d’un système de récupération de chaleur sur un groupe de
        production de froid afin de chauffer ou préchauffer un fluide caloporteur (e.g. de l’eau), sur site</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-117%20v%20A35-4%20%C3%A0%20compter%20du%2001-10-2020.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>La fiche s’applique au groupe de production de froid par
            <Typography.Text strong style={{ color: primary_color }}> compression mécanique </Typography.Text>
            utilisant un fluide frigorigène, circulant en circuit fermé, dont la
            <Typography.Text strong style={{ color: primary_color }}> température d’évaporation est inférieure ou égale à 18°C.</Typography.Text>
        </Paragraph>
        <Paragraph>La mise en place du système de récupération de chaleur fait l’objet d’une
            <Typography.Text strong style={{ color: primary_color }}> étude préalable de dimensionnement </Typography.Text>
            établie, datée et signée par un professionnel ou un bureau d’étude. Elle vise à évaluer les économies d’énergie
            attendues, via la chaleur récupérée, au regard des installations de production de froid et des besoins de chaleur mais
            également à démontrer la bonne adaptation entre les besoins de froid et la production de froid puis entre le système
            de récupération de chaleur et les besoins de chaud en présentant les calculs et leurs hypothèses.
        </Paragraph>
        <Typography>
            L’étude de dimensionnement définit une période représentative des besoins de chaleur et des besoins de froid qui
            ne peut pas être inférieure à 24h et qui considère les usages, a minima, sur les deux dernières années, les arrêts de
            saisonnalité ainsi que de la concomitance des besoins tertiaires de froid et des besoins de chaleur. Il en est déduit
            sur cette base une durée annuelle.
        </Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne la
            <Typography.Text strong style={{ color: primary_color }}> mise en place d’un système de récupération de chaleur </Typography.Text>
            sur un groupe de production de froid et la
            <Typography.Text strong style={{ color: primary_color }}> puissance </Typography.Text>
            du système de récupération de chaleur en kW thermique.
        </Paragraph>
        <Typography>
            À défaut, la preuve de réalisation de l'opération mentionne la mise en place, sur un groupe de production de froid,
            d'un équipement avec ses marque et référence et elle est complétée par un document issu du fabricant indiquant que
            l'équipement de marque et référence installé est un système de récupération de chaleur et mentionnant sa puissance
            en kW thermique.
        </Typography>
    </>
    const justificatifs = <>
        <Typography>Le document justificatif spécifique à l’opération est
            <Typography.Text strong style={{ color: primary_color }}> l’étude de dimensionnement </Typography.Text>
            préalable à la mise en place du système de récupération de chaleur répondant aux attendus ci-dessus.
        </Typography>
    </>
    const dimensionnement = <>
        <Paragraph>L'étude de dimensionnement comporte les éléments suivants :
            <Paragraph>
                <Typography.Text strong style={{ color: primary_color }}>a) l’identification de l’opération :</Typography.Text>
                <Paragraph style={{ paddingLeft: '16px' }}>i. la raison sociale et l’adresse du bénéficiaire</Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>ii. l’adresse du chantier si différente de l’adresse du bénéficiaire</Paragraph>
            </Paragraph>
            <Paragraph>
                <Typography.Text strong style={{ color: primary_color }}>b) la description des caractéristiques techniques des équipements suivants :</Typography.Text>
                <Paragraph style={{ paddingLeft: '16px' }}>i. des installations de production de froid : marques, références, usage(s) du froid, puissances frigorifique
                    (évaporateurs) et électrique (compresseurs) installées, température d’évaporation du fluide frigorigène,
                    durées de fonctionnement annuel des compresseurs, sur la période représentative</Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>ii. des systèmes de récupération de chaleur : équipements (condenseur, désurchauffeur et/ou
                    refroidissement d’huile), pompes ou ventilateurs de distribution, longueur du circuit de distribution,
                    éventuels stockages, etc. accompagné d’un schéma simplifié de l’installation, marques et références des
                    systèmes, usage(s) de la chaleur, puissance, température, durées annuelles d’utilisation de la chaleur sur
                    la période représentative</Paragraph>
            </Paragraph>
            <Paragraph>
                La description des équipements précisera les équipements existants avant l’étude de dimensionnement et ceux
                qui sont mis en place dans le cadre de l’opération.
            </Paragraph>
            <Paragraph>
                <Typography.Text strong style={{ color: primary_color }}>c) la justification et le dimensionnement de l’opération :</Typography.Text>
                <Paragraph style={{ paddingLeft: '16px' }}>i. la justification de l’usage et de la puissance installée du système de production de froid au regard des
                    besoins en froid ainsi que de la durée moyenne de fonctionnement annuel des compresseurs frigorifiques,
                    sur la période représentative dans le cas de compresseurs existants et sur la durée moyenne prévisionnelle
                    dans le cas de compresseurs neufs</Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>ii. la nature des besoins de chaleur à couvrir pour les usages : procédés, eau chaude sanitaire ou chauffage
                    des locaux</Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>iii. pour chacun des besoins de chaleur à couvrir :
                    <Typography style={{ paddingLeft: '16px' }}>- la puissance thermique à couvrir</Typography>
                    <Typography style={{ paddingLeft: '16px' }}>- la température demandée</Typography>
                    <Paragraph style={{ paddingLeft: '16px' }}>- la durée annuelle du besoin de chaleur sur la base de la période représentative</Paragraph>
                    <Paragraph style={{ paddingLeft: '16px' }}>Ainsi, pour les besoins de chaleur, l’étude précise :
                        <Typography style={{ paddingLeft: '16px' }}>- la somme des puissances thermiques unitaires à couvrir</Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- la moyenne pondérée des durées annuelles des besoins unitaires affectée des
                            puissances respectives (c’est-à-dire (d<sub>1</sub>*P<sub>1</sub> +...+ d<sub>n</sub>*P<sub>n</sub>)/(P<sub>1</sub>+...+P<sub>n</sub>)).</Typography>
                    </Paragraph>
                </Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>iv. la simultanéité des besoins en froid et des besoins de chaleur sur la période représentative</Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>v. la justification du bon dimensionnement du système de récupération de chaleur au regard des besoins de
                    chaleur à couvrir et de la simultanéité avec les besoins en froid, qui précise en particulier :
                    <Paragraph style={{ paddingLeft: '16px' }}>- la puissance maximale de réjection de l’installation de production de froid</Paragraph>
                    <Paragraph style={{ paddingLeft: '16px' }}>- P<sub>déjà récupérée</sub> en kW (thermique) qui est la puissance thermique déjà récupérée par un ou plusieurs
                        systèmes de récupération de chaleur sur le groupe de production de froid concerné par l’opération</Paragraph>
                    <Paragraph style={{ paddingLeft: '16px' }}>- la puissance thermique du système de récupération de chaleur</Paragraph>
                    <Paragraph style={{ paddingLeft: '16px' }}>- P<sub>récupérée</sub> en kW (thermique) qui est le minimum entre la puissance thermique du système de récupération
                        de chaleur et la somme des puissances thermiques à couvrir</Paragraph>
                    <Paragraph style={{ paddingLeft: '16px' }}>P<sub>compresseur(s)</sub> en kW (électrique) qui est la somme des puissances électriques nominales indiquées sur les
                        plaques du ou des compresseur(s) raccordé(s) au système de récupération de chaleur ou à défaut celles
                        indiquées sur un document issu du fabricant.</Paragraph>
                </Paragraph>
                <Paragraph style={{ paddingLeft: '16px' }}>vi. une évaluation des économies d’énergie attendues, sur une période annuelle</Paragraph>
            </Paragraph>
        </Paragraph>

    </>
    const incompatibilites = <>
        <Paragraph>- Est exclu de l’opération tout système de récupération de chaleur sur un groupe de production de froid afin de
            <Typography.Text strong style={{ color: primary_color }}> chauffer ou préchauffer de l’air.</Typography.Text>
        </Paragraph>
        <Paragraph>- Cette opération n’est pas cumulable avec les opérations relevant de la fiche standardisée
            <Typography.Text strong style={{ color: primary_color }}> IND-BA-112 si le groupe de production
            de froid est connecté à la tour aéroréfrigérante.</Typography.Text>
        </Paragraph>
        <Typography>- La mise en place d’un système de récupération de chaleur sur un groupe de production de froid de
            <Typography.Text strong style={{ color: primary_color }}> secours ou sur une pompe à chaleur </Typography.Text>
            n’est pas éligible à cette opération.
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: "Contenu de l'étude de dimensionnement" },
        { key: '4', label: 'Pièces justificatives' },
        { key: '5', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{dimensionnement}</>,
        4: <>{justificatifs}</>,
        5: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    /* onChange Calcul de Pretenue */
    const onPChange = () => {
        if (form.getFieldValue(['items', field.name, "Pcompresseur"]) > 0 &&
            form.getFieldValue(['items', field.name, "Precuperee"]) > 0 &&
            form.getFieldValue(['items', field.name, "Pdejarecuperee"]) >= 0) {
            if (form.getFieldValue(['items', field.name, "Precuperee"]) <= ((2 * form.getFieldValue(['items', field.name, "Pcompresseur"])) - form.getFieldValue(['items', field.name, "Pdejarecuperee"]))) { form.setFields([{ name: ["items", field.name, "REF-26"], value: form.getFieldValue(['items', field.name, "Precuperee"]) }]) }
            else { form.setFields([{ name: ["items", field.name, "REF-26"], value: ((2 * form.getFieldValue(['items', field.name, "Pcompresseur"])) - form.getFieldValue(['items', field.name, "Pdejarecuperee"])) }]) }
        }
        else { form.setFields([{ name: ["items", field.name, "REF-26"], value: undefined }]) }
    }

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Compression Mecanique et Temp évap */}
            <Form.Item
                name={[field.name, "CompressionMecaEtTempEvap"]}
                label="Le groupe de production de froid fonctionne-t-il par compression mécanique et la température d’évaporation du fluide frigorigène
                                    est-elle inférieure ou égale à 18°C"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "REF-101"], undefined)
                    form.setFieldValue(['items', field.name, "Pcompresseur"], undefined)
                    form.setFieldValue(['items', field.name, "Precuperee"], undefined)
                    form.setFieldValue(['items', field.name, "Pdejarecuperee"], undefined)
                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si CompressionMecaEtTempEvap non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "CompressionMecaEtTempEvap"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle s'applique au groupe de production de froid par compression mécanique utilisant un fluide frigorigène,
                                            circulant en circuit fermé, dont la température d’évaporation est inférieure ou égale à 18°C."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire CompressionMecaEtTempEvap OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "CompressionMecaEtTempEvap"]) === "Oui" ? (
                        <>
                            {/* Production annuelle */}
                            <ProductionAnnuelleREF101
                                name={[field.name, "REF-101"]}
                                label="Durée annuelle d’utilisation de la chaleur récupérée (heures) :"
                                unite='heures'
                                tooltip="Données de l’étude de dimensionnement préalable."
                            />
                            {/* Puissance compresseur */}
                            <Form.Item
                                name={[field.name, "Pcompresseur"]}
                                label="Puissance électrique du ou des compresseur(s) frigorifique(s) (kW) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: <>Données de l’étude de dimensionnement préalable. Aucune autre information que la puissance
                                        nominale plaquée ou à défaut celle fournie sur justificatif du fabricant n’est utilisable dans la
                                        fiche CEE (par exemple, les valeurs d’intensité ne sont pas applicables).</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
                            </Form.Item>
                            {/* Puissance récupérée */}
                            <Form.Item
                                name={[field.name, "Precuperee"]}
                                label="Puissance thermique récupérée (kW) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: "Données de l’étude de dimensionnement préalable.",
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
                            </Form.Item>
                            {/* Puissance déjà récupérée */}
                            <Form.Item
                                name={[field.name, "Pdejarecuperee"]}
                                label="Puissance thermique déjà récupérée sur le groupe de production de froid (kW) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: <>Données de l’étude de dimensionnement préalable.</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pcompresseur"]) > 0 &&
                                        getFieldValue(['items', field.name, "Precuperee"]) > 0 &&
                                        getFieldValue(['items', field.name, "Pdejarecuperee"]) >= 0 ? (
                                        <>
                                            {/* Puissance retenue */}
                                            <Form.Item style={{ margin: '0px' }}
                                                name={[field.name, "REF-26"]}
                                                label="Puissance récupérée retenue (kW) :"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner les champs précédents" },
                                                ]}
                                                tooltip={{
                                                    title: <>La puissance récupérée est limitée à (2 x P<sub>compresseur</sub>) - P<sub>déjà récupérée</sub></>,
                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                    color: tooltip_color,
                                                }}
                                                extra="Calculée automatiquement"
                                            >
                                                <InputNumber min={0} disabled addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Note de dimensionnement */}
            <Form.Item
                name={[field.name, "NoteDimensionnement"]}
                label="L'étude de dimensionnement a-t-elle été réalisée ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si NoteDimensionnement non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Non" ? (
                        <Alert
                            message="La note de dimensionnement devra être réalisée et remise au bénéficiaire à l'achèvement des travaux."
                            type="info"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Message si NoteDimensionnement oui */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Oui" ? (
                        <Alert
                            message="Vous pouvez déposer la note de dimensionnement dans les pièces jointes du projet, à la fin du formulaire."
                            type="info"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>



            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
