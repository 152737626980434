import { Form, Alert, FormListFieldData, Radio, Typography, Divider, Flex, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import NbAppartementsREF131 from '@/features/new-project/operations/forms/components/common/NbAppartementsREF131';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1232 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    // Lien vers le PDF de la FOST
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-123%20vA54-2%20%C3%A0%20compter%20du%2001-01-2024.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>- Le dispositif est équipé d’une <Typography.Text strong style={{ color: primary_color }}>fonction basée sur l’apprentissage adaptatif de l’arrêt et du démarrage optimisé du système de chauffage</Typography.Text>, au sens de la norme
            NF EN 12098-1 « Performance énergétique des bâtiments - Régulation pour les systèmes de chauffage - Partie 1 : Equipement de régulation pour les systèmes de chauffage
            à eau chaude », qui recalcule les paramètres utilisés pour déterminer l'heure d'arrêt et de mise en marche, en se basant sur la température ambiante mesurée, l’inertie
            du bâtiment, les paramètres d’occupation ou la météorologie locale.</Paragraph>
        <Typography>- Le dispositif intègre une <Typography.Text strong style={{ color: primary_color }}>fonction « descente de température (réduit de nuit) »
            et une fonction « commutateur été/hiver » </Typography.Text>au sens de la norme NF EN 12098-1.</Typography>

    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography>- la mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative au sens de la norme NF EN 12098-1</Typography>
        </Paragraph>

        <Typography>À défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence et elle est accompagnée
            d’un document issu du fabricant indiquant que l’équipement de marque et référence mis en place est un optimiseur de relance comprenant une fonction
            auto-adaptative au sens de la norme NF EN 12098-1.</Typography>
    </>
    const incompatibilites = <>
        <Typography>La présente opération n’est pas cumulable avec les opérations relevant de la fiche <Typography.Text strong style={{ color: primary_color }}>BAR-TH-118 « Système de régulation par programmation d’intermittence ».</Typography.Text>
             </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
        3: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };


    return (
        <>
            <Title level={3} style={{ color: primary_color }}>BAR-TH-123 : Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative
                    sur un circuit de chauffage collectif à combustible existant.
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Appartements plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Appartements2Ans"]}
                label="L'opération est réalisée sur des appartements existants depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                style={{ marginBottom: '10px' }}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "Chauffage2Ans"], undefined)
                        form.setFieldValue(['items', field.name, "Commutateur"], undefined)
                        form.setFieldValue(['items', field.name, "Descente"], undefined)
                        form.setFieldValue(['items', field.name, "REF-131"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Apparts + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur des appartements existants depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Appartements OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Oui" ? (
                        <>
                            {/* Système de chauffage existant plus de 2 ans */}
                            <Form.Item
                                name={[field.name, "Chauffage2Ans"]}
                                label="L'équipement de régulation est-il installé sur un système de chauffage collectif à combustible existant depuis plus de 2 ans ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                style={{ marginBottom: '10px' }}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "Commutateur"], undefined)
                                        form.setFieldValue(['items', field.name, "Descente"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-131"], undefined)
                                    }
                                }>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte Chauffage + de 2 ans non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Chauffage2Ans"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si elle est réalisée sur un système de chauffage collectif à combustible existant depuis plus de 2 ans."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Suite formulaire si Chauffage OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Chauffage2Ans"]) === "Oui" ? (
                                        <>
                                            {/* Présence d'un commutateur */}
                                            <Form.Item
                                                name={[field.name, "Commutateur"]}
                                                label="Le dispositif intègre-t-il une fonction commutateur été/hiver au sens de la norme NF EN 12098-1 ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {

                                                        form.setFieldValue(['items', field.name, "REF-131"], undefined)
                                                    }
                                                }>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte pas de commutateur */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Commutateur"]) === "Non" ? (
                                                        <Alert
                                                            message="Le dispositif n'est éligible que s'il intègre une fonction commutateur été/hiver au sens de la norme NF EN 12098-1."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* Présence d'une fonction descente */}
                                            <Form.Item
                                                name={[field.name, "Descente"]}
                                                label="Le dispositif intègre-t-il une fonction descente de température (réduit de nuit) au sens de la norme NF EN 12098-1 ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {

                                                        form.setFieldValue(['items', field.name, "REF-131"], undefined)
                                                    }
                                                }>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte pas de fonction descente */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Descente"]) === "Non" ? (
                                                        <Alert
                                                            message="Le dispositif n'est éligible que s'il intègre une fonction descente de température (réduit de nuit) au sens de la norme NF EN 12098-1."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Nb appartements si éligibilité OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Commutateur"]) === "Oui" && getFieldValue(['items', field.name, "Descente"]) === "Oui" ? (
                                                        <NbAppartementsREF131
                                                            label="Nombre d'appartements :"
                                                            name={[field.name, 'REF-131']}
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item>
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
