import { Text } from '@/utils';
import { Checkbox, Form, Space } from 'antd';

import { SECONDE_USE_OF_BUILDING } from '../../constants';
import useOfBuildingTypes from '../../data/useOfBuildingTypes.json';

export const SecondUseOfBuilding = () => {
    return (
        <Form.Item
            label={
                <Text className="field-description" strong>
                    Usage(s) secondaire(s) du ou des bâtiment concernés par les
                    travaux (si nécessaire) :
                </Text>
            }
            rules={[{ required: false }]}
            name={SECONDE_USE_OF_BUILDING}
        >
            <Checkbox.Group>
                <Space direction="vertical" size="middle">
                    {useOfBuildingTypes.map((useOfBuildingType) => (
                        <Checkbox
                            key={useOfBuildingType.key}
                            value={useOfBuildingType.value}
                            id={`${useOfBuildingType.value}_option`}
                        >
                            {useOfBuildingType.label}
                        </Checkbox>
                    ))}
                </Space>
            </Checkbox.Group>
        </Form.Item>
    );
};
