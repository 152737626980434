import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card, Select, Switch } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import SecteurREF22C from '@/features/new-project/operations/forms/components/common/SecteurREF22C';
import ClasseEnergetiqueREF78I from '@/features/new-project/operations/forms/components/common/ClasseEnergetiqueREF78I';

const { Title, Paragraph } = Typography
const { Option } = Select

export const Batth1165 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-116"
    const libelle_fost = "Système de gestion technique du bâtiment pour le chauffage, l’eau chaude sanitaire, le refroidissement/climatisation, l’éclairage et les auxiliaires"
    const denomination_fost = "Mise en place d’un système de gestion technique du bâtiment pour un usage chauffage et, le cas échéant, eau chaude sanitaire, refroidissement/climatisation, éclairage et auxiliaires"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-116%20vA59-5%20%C3%A0%20compter%20du%2001-01-2024.pdf"



    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>
            La présente fiche concerne soit l’achat d’un système neuf de gestion technique du bâtiment, soit l’amélioration d’un système existant de gestion
            technique du bâtiment. Dans le cas de l’amélioration d’un système existant de gestion technique du bâtiment, le système existant avant l’opération
            est au plus de classe C au sens de la norme NF EN ISO 52120-1 : 2022.
        </Paragraph>
        <Typography>
            Le système de gestion technique du bâtiment acquis ou amélioré assure, par un système d’automatisation centralisé, les fonctions de régulation de
            classe B ou A au sens de la norme susmentionnée pour l’usage chauffage et, le cas échéant, les usages eau chaude sanitaire, refroidissement/climatisation,
            éclairage ou auxiliaires. Dans le cas de l’outre-mer, l’usage principal à considérer est l’usage refroidissement/climatisation, et le cas échéant, eau chaude
            sanitaire, éclairage et auxiliaires.
        </Typography>

    </>
    const facture = <>
        <Paragraph>
            La preuve de la réalisation de l’opération mentionne la mise en place d’un système de gestion technique du bâtiment assurant,
            par un système d’automatisation centralisé, les fonctions de régulation de classe B ou A au sens de la norme NF EN ISO 52120-1 : 2022.
        </Paragraph>
        <Typography>
            A défaut, la preuve de la réalisation mentionne la mise en place d’un système avec ses marque et référence et elle est accompagnée d’un
            document issu du professionnel réalisant l’opération.
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire plus de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "xxx"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            <Form.Item
                                name={[field.name, "AchatOuAmelioration"]}
                                label="L'opération concerne :"
                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                            >
                                <Select style={{ maxWidth: '320px' }} >
                                    <Option value="Achat">L’achat d’un système neuf de GTB</Option>
                                    <Option value="Amelioration">L’amélioration d’un système existant de GTB</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "AchatOuAmelioration"]) === "Amelioration" ? (
                                        <>
                                            <Form.Item
                                                name={[field.name, "ClasseC"]}
                                                label="Le système existant avant l’opération est au plus de classe C :"
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Alerte si Classe C non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "ClasseC"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si le système existant est au plus de classe C."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Suite si Achat ou Classe C Oui  */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "AchatOuAmelioration"]) === "Achat" || getFieldValue(['items', field.name, "ClasseC"]) === "Oui" ? (
                                        <>
                                            {/* Secteur */}
                                            <SecteurREF22C
                                                label="Secteur d'activité :"
                                                name={[field.name, 'REF-22-C']}
                                            />

                                            {/* Classe énergétique */}
                                            <ClasseEnergetiqueREF78I
                                                name={[field.name, "REF-78-I"]}
                                                label="Classe du système de GTB installé"
                                                tooltip="Selon la norme NF EN ISO 52120-1 : 2022"
                                            />

                                            {/* DOM-TOM */}
                                            <Form.Item
                                                name={[field.name, "DomTom"]}
                                                label="Le site des travaux est-il dans les DOM-TOM ?"
                                                colon={false}
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={() => {
                                                    form.setFieldValue(['items', field.name, "REF-10"], '')
                                                    form.setFieldValue(['items', field.name, "IREF-2-A"], '')
                                                    form.setFieldValue(['items', field.name, "IREF-2-B"], '')
                                                    form.setFieldValue(['items', field.name, "IREF-2-C"], '')
                                                    form.setFieldValue(['items', field.name, "IREF-2-D"], '')
                                                    if (form.getFieldValue(['items', field.name, "DomTom"]) === "Oui") {
                                                        form.setFieldValue(['items', field.name, "UsageRefroidissement"], true)
                                                        form.setFieldValue(['items', field.name, "IREF-3-C"], '1')
                                                        form.setFieldValue(['items', field.name, "UsageChauffage"], undefined)
                                                    }
                                                    if (form.getFieldValue(['items', field.name, "DomTom"]) === "Non") {
                                                        form.setFieldValue(['items', field.name, "UsageChauffage"], true)
                                                        form.setFieldValue(['items', field.name, "IREF-3-A"], '0')
                                                        form.setFieldValue(['items', field.name, "UsageRefroidissement"], undefined)
                                                        form.setFieldValue(['items', field.name, "IREF-3-C"], '')
                                                    }
                                                }}>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Usages et surfaces associées */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "DomTom"]) ? (
                                                        <>
                                                            <Typography.Text strong>Usages gérés par le système de GTB :</Typography.Text>
                                                            {/* Chauffage si DomTom Non*/}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DomTom"]) === "Non" ? (
                                                                        <Form.Item
                                                                            name={[field.name, "UsageChauffage"]}
                                                                            valuePropName='checked'
                                                                            initialValue={true}
                                                                            label="Usage chauffage"
                                                                            style={{ marginBottom: 0 }}
                                                                        >
                                                                            <Switch size='small' defaultChecked={true} disabled={true} onChange={() => {
                                                                                if (form.getFieldValue(['items', field.name, "UsageChauffage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: "0" }]) }
                                                                                else {
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: '' }])
                                                                                    form.setFields([{ name: ["items", field.name, "REF-10"], value: '' }])
                                                                                }
                                                                            }}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Chauffage si DomTom Oui*/}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DomTom"]) === "Oui" ? (
                                                                        <Form.Item
                                                                            name={[field.name, "UsageChauffage"]}
                                                                            valuePropName='checked'
                                                                            label="Usage chauffage"
                                                                            style={{ marginBottom: 0 }}
                                                                        >
                                                                            <Switch size='small' onChange={() => {
                                                                                if (form.getFieldValue(['items', field.name, "UsageChauffage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: "0" }]) }
                                                                                else {
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: '' }])
                                                                                    form.setFields([{ name: ["items", field.name, "REF-10"], value: '' }])
                                                                                }
                                                                            }}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null
                                                                }
                                                            </Form.Item>


                                                            {/* Surface chauffage */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "UsageChauffage"]) ? (
                                                                        <SurfaceREF10
                                                                            name={[field.name, "REF-10"]}
                                                                            label="Surface gérée (m²)"
                                                                            style={{ marginLeft: 16 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* ECS */}
                                                            <Form.Item
                                                                name={[field.name, "UsageECS"]}
                                                                valuePropName='checked'
                                                                label="Usage eau chaude sanitaire"
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <Switch size='small' onChange={() => {
                                                                    if (form.getFieldValue(['items', field.name, "UsageECS"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-B"], value: "2" }]) }
                                                                    else {
                                                                        form.setFields([{ name: ["items", field.name, "IREF-3-B"], value: '' }])
                                                                        form.setFields([{ name: ["items", field.name, "IREF-2-A"], value: '' }])
                                                                    }
                                                                }}
                                                                />
                                                                {/* Usage eau chaude sanitaire</Checkbox> */}
                                                            </Form.Item>
                                                            {/* Surface ECS */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "UsageECS"]) ? (
                                                                        <SurfaceREF10
                                                                            name={[field.name, "IREF-2-A"]}
                                                                            label="Surface gérée (m²)"
                                                                            style={{ marginLeft: 16 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Refroidissement-Climatisation */}
                                                            {/* Refroidissement si DomTom Non*/}
                                                            < Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DomTom"]) === "Non" ? (
                                                                        <Form.Item
                                                                            name={[field.name, "UsageRefroidissement"]}
                                                                            valuePropName='checked'
                                                                            label='Usage refroidissement-climatisation'
                                                                            style={{ marginBottom: 0 }}
                                                                        >
                                                                            <Switch size='small' onChange={() => {
                                                                                if (form.getFieldValue(['items', field.name, "UsageRefroidissement"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: "1" }]) }
                                                                                else {
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: '' }])
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-2-B"], value: '' }])
                                                                                }
                                                                            }}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Refroidissement si DomTom Oui*/}
                                                            < Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DomTom"]) === "Oui" ? (
                                                                        <Form.Item
                                                                            name={[field.name, "UsageRefroidissement"]}
                                                                            valuePropName='checked'
                                                                            initialValue={true}
                                                                            label='Usage refroidissement-climatisation'
                                                                            style={{ marginBottom: 0 }}
                                                                        >
                                                                            <Switch size='small' defaultChecked={true} disabled={true} onChange={() => {
                                                                                if (form.getFieldValue(['items', field.name, "UsageRefroidissement"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: "1" }]) }
                                                                                else {
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: '' }])
                                                                                    form.setFields([{ name: ["items", field.name, "IREF-2-B"], value: '' }])
                                                                                }
                                                                            }}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Surface Refroidissement */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "UsageRefroidissement"]) ? (
                                                                        <SurfaceREF10
                                                                            name={[field.name, "IREF-2-B"]}
                                                                            label="Surface gérée (m²)"
                                                                            style={{ marginLeft: 16 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Eclairage */}
                                                            <Form.Item
                                                                name={[field.name, "UsageEclairage"]}
                                                                label="Usage éclairage"
                                                                valuePropName='checked'
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <Switch size='small' onChange={() => {
                                                                    if (form.getFieldValue(['items', field.name, "UsageEclairage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-D"], value: "3" }]) }
                                                                    else {
                                                                        form.setFields([{ name: ["items", field.name, "IREF-3-D"], value: '' }])
                                                                        form.setFields([{ name: ["items", field.name, "IREF-2-C"], value: '' }])
                                                                    }
                                                                }}
                                                                />
                                                            </Form.Item>
                                                            {/* Surface Eclairage */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "UsageEclairage"]) ? (
                                                                        <SurfaceREF10
                                                                            name={[field.name, "IREF-2-C"]}
                                                                            label="Surface gérée (m²)"
                                                                            style={{ marginLeft: 16 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Auxiliaires */}
                                                            <Form.Item
                                                                name={[field.name, "UsageAuxiliaires"]}
                                                                label="Usage auxiliaires"
                                                                valuePropName='checked'
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <Switch size='small' onChange={() => {
                                                                    if (form.getFieldValue(['items', field.name, "UsageAuxiliaires"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-E"], value: "4" }]) }
                                                                    else {
                                                                        form.setFields([{ name: ["items", field.name, "IREF-3-E"], value: '' }])
                                                                        form.setFields([{ name: ["items", field.name, "IREF-2-D"], value: '' }])
                                                                    }
                                                                }}
                                                                />
                                                            </Form.Item>
                                                            {/* Surface Auxiliaires */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "UsageAuxiliaires"]) ? (
                                                                        <SurfaceREF10
                                                                            name={[field.name, "IREF-2-D"]}
                                                                            label="Surface gérée (m²)"
                                                                            style={{ marginLeft: 16 }}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item >

                                            {/* Références d'usages masquées qui doivent toutes être envoyées (soit avec la valeur de l'usage soit "" transformé en n/a) */}

                                            {/* Ref usage Chauffage */}
                                            <Form.Item hidden name={[field.name, "IREF-3-A"]} initialValue='' />
                                            {/* Ref usage ECS */}
                                            <Form.Item hidden name={[field.name, "IREF-3-B"]} initialValue='' />
                                            {/*Ref usage Refroidissement-Climatisation */}
                                            <Form.Item hidden name={[field.name, "IREF-3-C"]} initialValue='' />
                                            {/* Ref usage Eclairage */}
                                            <Form.Item hidden name={[field.name, "IREF-3-D"]} initialValue='' />
                                            {/* Ref usage Auxiliaires */}
                                            <Form.Item hidden name={[field.name, "IREF-3-E"]} initialValue='' />
                                        </>
                                    ) : null
                                }
                            </Form.Item>



                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>

    );
};
