import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';


const { Option } = Select;
export default function DureeAnnuelleUtilisationReseauREF130({ label, name, tooltip }: { label: string, name: NamePath, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
    return <Form.Item
        name={name}
        label={label}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner ce champ"
            },]}
        tooltip={{
            title: <>{tooltip}</>,
            icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
            color: '#0086CA',
        }}>
        <Select
            placeholder="Sélectionnez la durée"
            allowClear
            style={{ width: '150px' }}
        >
            <Option value="0">12 mois</Option>
            <Option value="1">11 mois</Option>
            <Option value="2">10 mois</Option>
            <Option value="3">9 mois</Option>
            <Option value="4">8 mois</Option>
            <Option value="5">7 mois</Option>
            <Option value="6">6 mois</Option>
        </Select>
    </Form.Item >
}