import { FormListFieldData, Typography, Divider, Flex, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import DnAllerREF34F from '../refs/DnAllerREF34F';
import LongueurCanalisationREF46 from '../refs/LongueurCanalisationREF46';
import DureeAnnuelleUtilisationReseauREF130 from '../refs/DureeAnnuelleUtilisationReseauREF130';
import NatureFluideREF121C from '../refs/NatureFluideREF121C';
import TypeCanalisationREF33C from '../refs/TypeCanalisationREF33C';

const { Title } = Typography
export const Resch1064S = ({ field }: { field: FormListFieldData }) => {
    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    /* Infos FOST */
    const code_fost = "RES-CH-106"
    const libelle_fost = "Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur"
    const denomination_fost = <>Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur enterré ou en caniveau dans tout ou partie du réseau primaire</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/documents/RES-CH-106%20vA60-4%20%C3%A0%20compter%20du%2001-03-2024.pdf"
    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>
            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />
            {/* DN Aller */}
            <DnAllerREF34F
                name={[field.name, "REF-34-F"]}
                label="Diamètre initial DN du réseau :"
                tooltip="Le diamètre nominal (DN) correspond à la désignation de dimension commune à tous les 
                        éléments d'une même tuyauterie autre que ceux désignés par leur diamètre extérieur ou intérieur."
            />
            {/* Longueur canalisation */}
            <LongueurCanalisationREF46
                name={[field.name, "REF-46"]}
                label="Longueur de canalisation concernée (m) :"
            />
            {/* Durée d'utilisation du réseau */}
            <DureeAnnuelleUtilisationReseauREF130
                name={[field.name, "REF-130"]}
                label="Durée annuelle d’utilisation de la portion du réseau de chaleur concernée :"
                tooltip="La durée annuelle d’utilisation du réseau de chaleur est celle de l’année calendaire précédant la date d’achèvement de l’opération."
            />
			{/* Type de canalisation */}
            <TypeCanalisationREF33C
                name={[field.name, "REF-33-C"]}
                label="Type de canalisation :"
                tooltip="Les canalisations respectant les exigences relatives à la classe d’isolation thermique 4 sont définies par la norme NF EN 12828. Les canalisations respectant les exigences relatives aux séries d’isolation thermique 1, 2 et 3 sont définies par la norme NF EN 253."
            />
			{/* Nature du Fluide */}
            <NatureFluideREF121C
                name={[field.name, "REF-121-C"]}
                label="Nature du fluide caloporteur :"
            />
        </>
    );
};