import React from 'react';

import { Col, Space, Spin } from 'antd';

import './spinner.scss';

export const Spinner: React.FC = () => {
    return (
        <Space direction="horizontal" className="spinner">
            <Col>
                <Spin size="large" />
            </Col>
        </Space>
    );
};
