import React from 'react';

import { Text, Title } from '@/utils';
import { Checkbox, Col, Divider, Form, Input, Radio, Row, Space } from 'antd';
import { PatternFormat } from 'react-number-format';

import applicantProfileTypes from '../new-project/data/applicantProfileTypes.json';
import buildingExistings from '../new-project/data/buildingExisting.json';
import useOfBuildingTypes from '../new-project/data/useOfBuildingTypes.json';
import { PrincipalUseOfBuilding } from '../new-project/general-description/work-descriptions/PrincipalUseOfBuilding';
import { SecondUseOfBuilding } from '../new-project/general-description/work-descriptions/SecondUseOfBuilding';

const { TextArea } = Input;

interface ProjectRequestInfoProps {
    projectFormType: string;
}

export const ProjectRequestInfo: React.FC<ProjectRequestInfoProps> = ({
    projectFormType,
}) => {
    return (
        <>
            <Title level={4}>1. Votre projet</Title>
            <Row>
                <Form.Item name={'project_name'} className="middle-input-width">
                    <Input />
                </Form.Item>
            </Row>
            <Row>
                <Space direction="vertical" size="small">
                    <>
                        {projectFormType !== 'SIMPLIFIED' && (
                            <>
                                <Form.Item
                                    label={
                                        <Text
                                            className="field-description"
                                            strong
                                        >
                                            Dans le cadre de ce projet, vous
                                            êtes :
                                        </Text>
                                    }
                                    name="applicantProfile"
                                >
                                    <Radio.Group>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            {applicantProfileTypes.map(
                                                (applicantProfileType) => (
                                                    <Radio
                                                        key={
                                                            applicantProfileType.key
                                                        }
                                                        value={
                                                            applicantProfileType.value
                                                        }
                                                        id={
                                                            applicantProfileType.value
                                                        }
                                                    >
                                                        {
                                                            applicantProfileType.label
                                                        }{' '}
                                                        {
                                                            applicantProfileType.description
                                                        }
                                                    </Radio>
                                                )
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <Text
                                            className="field-description"
                                            strong
                                        >
                                            SIRET Installateur
                                        </Text>
                                    }
                                    className="middle-input-width"
                                    name={'siret-installer'}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Text
                                            className="field-description"
                                            strong
                                        >
                                            SIRET Bénéficiaire
                                        </Text>
                                    }
                                    className="middle-input-width"
                                    name={'siret-beneficiary'}
                                >
                                    <Input />
                                </Form.Item>

                                <Space
                                    direction="vertical"
                                    className="middle-width-section"
                                >
                                    <Text className="field-description" strong>
                                        Coordonnées bénéficiaire :
                                    </Text>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Prénom
                                                    </Text>
                                                }
                                                className="middle-input-width"
                                                name="beneficiaryLastname"
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Nom
                                                    </Text>
                                                }
                                                name="beneficiaryName"
                                                className="middle-input-width"
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Fonction
                                                    </Text>
                                                }
                                                className="middle-input-width"
                                                name="beneficiaryFunction"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Email
                                                    </Text>
                                                }
                                                className="middle-input-width"
                                                name="beneficiaryEmail"
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Numéro mobile
                                                    </Text>
                                                }
                                                rules={[{ required: false }]}
                                                className="middle-input-width"
                                                name="beneficiaryMobileNumber"
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label={
                                                    <Text className="field-description">
                                                        Numéro fixe
                                                    </Text>
                                                }
                                                rules={[{ required: false }]}
                                                name="beneficiaryPhoneNumber"
                                                className="middle-input-width"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Space>
                            </>
                        )}
                    </>
                </Space>
            </Row>
            {projectFormType === 'GENERIC' ? (
                <>
                    <Row>
                        <Space direction="vertical" size="small">
                            <Form.Item
                                label={
                                    <Text className="field-description" strong>
                                        Usage principal du ou des bâtiments
                                        concernés par les travaux
                                    </Text>
                                }
                                name={'principaleUseOfBuilding'}
                            >
                                <Radio.Group>
                                    <Space direction="vertical" size="middle">
                                        {useOfBuildingTypes.map(
                                            (useOfBuildingType) => (
                                                <Radio
                                                    key={useOfBuildingType.key}
                                                    value={
                                                        useOfBuildingType.label
                                                    }
                                                    id={useOfBuildingType.value}
                                                >
                                                    {useOfBuildingType.label}
                                                </Radio>
                                            )
                                        )}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Space>
                    </Row>
                    <Row>
                        <Space direction="vertical" size="small">
                            <Form.Item
                                label={
                                    <Text className="field-description" strong>
                                        Usage(s) secondaire(s) du ou des
                                        bâtiment concernés par les travaux (si
                                        nécessaire) :
                                    </Text>
                                }
                                name={'secondeUseOfBuilding'}
                            >
                                <Checkbox.Group>
                                    <Space direction="vertical" size="middle">
                                        {useOfBuildingTypes.map(
                                            (useOfBuildingType) => (
                                                <Checkbox
                                                    key={useOfBuildingType.key}
                                                    value={
                                                        useOfBuildingType.label
                                                    }
                                                    id={`${useOfBuildingType.value}_option`}
                                                >
                                                    {useOfBuildingType.label}
                                                </Checkbox>
                                            )
                                        )}
                                    </Space>
                                </Checkbox.Group>
                            </Form.Item>
                        </Space>
                    </Row>
                </>
            ) : null}
            <Row>
                <Col>
                    {projectFormType !== 'SIMPLIFIED' && (
                        <>
                            <Space className="middle-width-section">
                                <Row gutter={16}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label={
                                                <Text
                                                    className="field-description"
                                                    strong
                                                >
                                                    Estimation date début
                                                    travaux
                                                </Text>
                                            }
                                            name={'estimate_work_start_date'}
                                            className="middle-input-width"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            label={
                                                <Text
                                                    className="field-description"
                                                    strong
                                                >
                                                    Estimation date fin travaux
                                                </Text>
                                            }
                                            className="middle-input-width"
                                            name={'estimate_work_end_date'}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Space>
                            <Form.Item
                                label={
                                    <Text className="field-description" strong>
                                        Estimation date signature devis
                                    </Text>
                                }
                                name={'estimate_signature_date'}
                                className="middle-input-width"
                            >
                                <Input />
                            </Form.Item>
                        </>
                    )}

                    <Divider />
                    <Title level={4}>2. Adresse des travaux</Title>

                    <Row gutter={16}>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="streetNumber"
                                label={
                                    <Text className="field-description" strong>
                                        N° de voie
                                    </Text>
                                }
                            >
                                <PatternFormat
                                    customInput={Input}
                                    format="#####"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="streetName"
                                label={
                                    <Text className="field-description" strong>
                                        Nom de la voie
                                    </Text>
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="zipCode"
                                label={
                                    <Text className="field-description" strong>
                                        Code postal
                                    </Text>
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="city"
                                label={
                                    <Text className="field-description" strong>
                                        Ville
                                    </Text>
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="building"
                                label={
                                    <Text className="field-description" strong>
                                        Bâtiment
                                    </Text>
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="cadastralPlot"
                                label={
                                    <Text className="field-description" strong>
                                        Parcelle cadastrale
                                    </Text>
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    {projectFormType === 'GENERIC' && (
                        <Form.Item
                            label={
                                <Text className="field-description" strong>
                                    Les bâtiments concernés ont-ils tous plus de
                                    2 ans ? Pour être éligible aux CEE, les
                                    bâtiments résidentiels et tertiaires doivent
                                    avoir plus de 2 ans.
                                </Text>
                            }
                            name={'building_existing'}
                        >
                            <Radio.Group>
                                <Space direction="vertical" size="small">
                                    {buildingExistings.map(
                                        (buildingExisting) => {
                                            return (
                                                <Radio
                                                    key={buildingExisting.key}
                                                    value={
                                                        buildingExisting.value
                                                    }
                                                    id={buildingExisting.value}
                                                >
                                                    {buildingExisting.label}
                                                </Radio>
                                            );
                                        }
                                    )}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    )}
                </Col>
                <Col></Col>
            </Row>
            {projectFormType !== 'SIMPLIFIED' && (
                <Form.Item
                    style={{ width: '100%' }}
                    label={
                        <Text className="field-description" strong>
                            Décrivez-nous votre projet en quelques lignes
                        </Text>
                    }
                    name={'project_description'}
                    rules={[{ required: false }]}
                >
                    <TextArea
                        style={{
                            height: 150,
                            resize: 'none',
                        }}
                        placeholder="Isolation des toits d'un entrepôt de 45 000 m&sup2; et changement des huisseries sur tout le site"
                    />
                </Form.Item>
            )}
        </>
    );
};
