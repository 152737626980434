import React from 'react';

import { Title } from '@/utils/constants';
import { Card } from 'antd';

import '../cards.scss';
import { TAuthenticationCard } from './types';

export const AuthenticationCard: React.FC<TAuthenticationCard> = ({
    children,
    title,
}) => {
    return (
        <Card className="card--authentication">
            <Title type="secondary">{title}</Title>
            {children}
        </Card>
    );
};
