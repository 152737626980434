import { useState, useEffect } from 'react';

const usePreserveWithDelay = (initialState: boolean, delay: number) => {
  const [isPreserve, setIsPreserve] = useState<boolean>(initialState);

  useEffect(() => {
    if (!isPreserve) {
      const timeoutId = setTimeout(() => {
        setIsPreserve(true);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isPreserve, delay]);

  return [isPreserve, setIsPreserve] as const;
};

export default usePreserveWithDelay;
