import { Form, Alert, FormListFieldData, Radio, Typography, InputNumber, Collapse, Card, Button, Divider, Flex } from 'antd';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22AH from '@/features/new-project/operations/forms/components/common/SecteurREF22AH';
import TypeInstallationREF81T from '@/features/new-project/operations/forms/components/common/TypeInstallationREF81T';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography
const { Title } = Typography


export const Batth1252 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-125"
    const libelle_fost = "Ventilation mécanique simple flux à débit d’air constant ou modulé"
    const denomination_fost = "Mise en place d’une ventilation mécanique simple flux à débit d’air constant ou modulée"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-125%20v%20A32-2%20%C3%A0%20compter%20du%2001-04-2020.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>Le système de ventilation mécanique simple flux modulée bénéficie d’un avis technique de la Commission chargée
            de formuler des Avis Techniques (CCFAT) en cours de validité à la date d’engagement de l’opération ou possède
            des caractéristiques de performance et de qualité équivalentes établies par un organisme implanté dans l’Espace
            économique européen et accrédité selon la norme NF EN ISO/CEI 17065 par le comité français d’accréditation
            (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans
            le cadre de la coordination européenne des organismes d’accréditation.
        </Paragraph>
        <Typography>Le caisson de ventilation a une puissance électrique absorbée
            <Typography.Text strong style={{ color: primary_color }}> inférieure
                ou égale à 0,3 W/(m3/h) </Typography.Text>au débit nominal.
        </Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography style={{ paddingLeft: '16px' }}>- la mise en place d’une ventilation mécanique simple flux à débit d’air constant ou modulée (proportionnelle ou
                à détection de présence)</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la puissance électrique absorbée du caisson de ventilation au débit nominal</Typography>
        </Paragraph>
        <Typography>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’équipements avec leurs marques et
            références et elle est accompagnée d’un ou plusieurs document(s) issu(s) du fabricant indiquant que les équipements
            installés constituent un système de ventilation mécanique simple flux à débit d’air constant ou modulée
            (proportionnelle ou à détection de présence). Un des documents précise la puissance électrique absorbée du caisson
            de ventilation au débit nominal.</Typography>
    </>

    const justificatifs = <>
        <Typography>Dans le cas d’une ventilation mécanique simple flux modulée (proportionnelle ou à détection de présence), le
            document justificatif spécifique à l’opération est l’avis technique de la Commission chargée de formuler des avis
            techniques (CCFAT), en cours de validité à la date d’engagement de l’opération ou les éléments de preuve
            équivalents.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Pièces justificatives' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };


    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>{denomination_fost}</Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire existant depuis plus de 2 ans */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2ans"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-22-AH"], undefined)
                        form.setFieldValue(['items', field.name, "REF-81-T"], undefined)
                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                        form.setFieldValue(['items', field.name, "PElecAbsorbee"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si bâtiment non tertiaire */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si bâtiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Oui" ? (
                        <>
                            {/*Secteur*/}
                            <SecteurREF22AH
                                label="Secteur :"
                                name={[field.name, 'REF-22-AH']}
                            />
                            {/* Type d'installation */}
                            <TypeInstallationREF81T
                                label="Type de ventilation :"
                                name={[field.name, 'REF-81-T']}
                                tooltip='L’installation d’une ventilation mécanique hygroréglable dans le secteur de l’hôtellerie, quelle que soit son type, est
                                assimilée à une ventilation mécanique simple flux modulée proportionnelle. Dans le cas d’une ventilation simple flux modulée
                                (proportionnelle ou à détection de présence), le système bénéficie d’un avis technique de la Commission chargée de formuler des
                                Avis Techniques (CCFAT), en cours de validité à la date d’engagement de l’opération ou équivalent.'
                            />
                            {/* Surface */}
                            <SurfaceREF10
                                label="Surface ventilée (m²) :"
                                name={[field.name, 'REF-10']}
                                tooltip="On entend par surface ventilée, la surface totale du bâtiment couverte par le système de ventilation mécanique simple flux
                                à débit d’air constant ou modulée"
                            />
                            {/* P élec absorbée */}
                            <Form.Item
                                name={[field.name, "PElecAbsorbee"]}
                                label="Puissance électrique absorbée du caisson de ventilation au débit nominal (W/(m³/h)) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber addonAfter="W/(m³/h)" decimalSeparator=',' />
                            </Form.Item>
                            {/* Alerte si P elec > 0,3 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "PElecAbsorbee"]) > 0.3 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si Le caisson de ventilation a une puissance électrique absorbée inférieure ou égale à 0,3 W/(m3/h) au débit nominal."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>


                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
