import { useEffect, useState } from 'react';

import { ContentNotFound } from '@/components';
import { Container } from '@/layouts';
import { useGetDossierById } from '@hooks/dossier/useGetDossierById';
import { useGetDossierDetails } from '@hooks/dossier/useGetDossierDetails';
import { DISSIERS_SLUGS } from '@routes/constants';
import { setCurrentDossierFromList } from '@store/slices/dossier/dossierSlice';
import { Col, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { IDossier } from '../dashboard';
import { GenericInformations } from './generic-informations/GenericInformations';
import { DossierHeader } from './header/DossierHeader';
import { Interlocutors } from './interlocutors/Interlocutors';
import { Partners } from './partners/Partners';
import { DossierStepDescription } from './step-description/DossierStepDescription';
import { DossierSteps } from './steps/DossierSteps';

const Dossier = () => {
    const { slug, dossierId } = useParams<string>();
    const [isExist, setIsExist] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { isPending, isRejected, getDossierDetails } = useGetDossierDetails();

    const { getDossierFromList } = useGetDossierById();

    useEffect(() => {
        if (
            dossierId !== undefined &&
            DISSIERS_SLUGS.includes(slug as string)
        ) {
            const currentDossierFromList = getDossierFromList(
                parseInt(dossierId as string)
            );
            if (currentDossierFromList) {
                setIsExist(true);
            }
            dispatch(
                setCurrentDossierFromList(currentDossierFromList as IDossier)
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossierId, slug, getDossierFromList]);

    useEffect(() => {
        getDossierDetails(parseInt(dossierId as string));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dossierId]);

    if (isRejected || !isExist) return <ContentNotFound />;

    return (
        <Container loading={isPending}>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex', width: '100%' }}
            >
                <DossierHeader />
                <DossierSteps />
                <Row gutter={[16, 16]}>
                    <Col sm={18}>
                        <GenericInformations />
                    </Col>
                    <Col sm={6} xs={24}>
                        <Interlocutors />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col sm={18}>
                        <Partners />
                    </Col>
                </Row>
                <DossierStepDescription />
            </Space>
        </Container>
    );
};

export default Dossier;
