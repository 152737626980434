import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function DureeContratREF43B({name, label}: {name: NamePath, label: string}) {
  return <Form.Item
            name={name}
            label={label}
            rules={[
                {
                required: true,
                message: "Veuillez renseigner la durée du contrat"
                },
            ]}
            >
            <Select
                placeholder="Sélectionnez la durée du contrat"
                allowClear
                style={{width: '150px'}}
            >
                <Option value="0">1 an</Option>
                <Option value="1">2 ans</Option>
                <Option value="2">3 ans</Option>
                <Option value="3">4 ans</Option>
                <Option value="4">5 ans</Option>
                <Option value="5">6 ans</Option>
                <Option value="6">7 ans</Option>
                <Option value="7">8 ans</Option>
                <Option value="8">9 ans</Option>
                <Option value="9">10 ans</Option>
            </Select>
        </Form.Item>
}