import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Flex, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SurfaceHabitableREF14E from '@/features/new-project/operations/forms/components/common/SurfaceHabitableREF14E';
import UsageREF15A from '@/features/new-project/operations/forms/components/common/UsageREF15A';
import EfficaciteEnergetiqueREF140H from '@/features/new-project/operations/forms/components/common/EfficaciteEnergetiqueIREF140H';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography


const { Title } = Typography

export const Barth1721 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    // Lien vers le PDF de la FOST
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-172%20vA55-1%20%C3%A0%20compter%20du%2001-01-2024_0.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>L’efficacité énergétique saisonnière pour le chauffage des locaux (ηs) de la PAC selon le règlement (EU) n° 813/2013 de la
            commission du 2 août 2013, déterminée selon l’application de la PAC installée, est supérieure ou égale à :
            <Typography style={{ paddingLeft: '16px' }}>- <Typography.Text strong style={{ color: primary_color }}>126% </Typography.Text>pour une application
                <Typography.Text strong style={{ color: primary_color }}> basse température </Typography.Text>au sens du règlement susmentionné</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- <Typography.Text strong style={{ color: primary_color }}>111% </Typography.Text>pour une application
                <Typography.Text strong style={{ color: primary_color }}> moyenne ou haute température</Typography.Text></Typography>
        </Paragraph>
        <Typography>La PAC est équipée d’un régulateur relevant de l’une des classes <Typography.Text strong style={{ color: primary_color }}>IV, V, VI, VII ou VIII </Typography.Text>
            telles que définies au paragraphe 6.1 de la communication de la Commission 2014/C 207/02.</Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography style={{ paddingLeft: '16px' }}>- la mise en place d’une pompe à chaleur air/eau</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- le type d’application choisi pour l’installation de la pompe à chaleur (basse température ; moyenne ou haute température)</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- l’efficacité énergétique saisonnière (ηs) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 déterminée selon l’application de la PAC installée</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- l’installation d’un régulateur ainsi que la classe de celui-ci</Typography>

        </Paragraph>
        <Typography>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence et elle est
            complétée par un document issu du fabricant ou d’un organisme établi dans l'Espace économique européen et accrédité selon la norme NF EN ISO/IEC 17065
            par le Comité français d'accréditation (COFRAC) ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans
            le cadre de European co-operation for Accreditation (EA), coordination européenne des organismes d'accréditation.</Typography>
        <Typography>Ce document indique que l’équipement de marque et référence mis en place est une pompe à chaleur de type air/air et précise le SCOP de l’équipement
            installé ainsi que sa puissance nominale. En cas de mention d'une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de
            fin de validité.</Typography>
    </>

    const justificatifs = <>
        <Typography>Les documents justificatifs spécifiques à l'opération sont :
            <Typography style={{ paddingLeft: '16px' }}>- la note de dimensionnement susmentionnée</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la décision de qualification ou de certification du professionnel délivrée selon les mêmes
                exigences que celles prévues à l’article 2 du décret susvisé</Typography>
        </Typography>
    </>

    const incompatibilites = <>
        <Paragraph>Ne donnent pas lieu à la délivrance de certificats d’économies d’énergie au titre de la présente fiche, les PAC associées à
            un autre système de chauffage et les PAC utilisées uniquement pour le chauffage de l'eau chaude sanitaire.</Paragraph>
        <Typography>La présente opération n’est pas cumulable avec les opérations relevant de la fiche
            <Typography.Text strong style={{ color: primary_color }}> BAR-TH-148 « Chauffe-eau thermodynamique à accumulation » </Typography.Text>
            si la PAC installée au titre de la présente fiche est utilisée pour le chauffage et l’eau chaude sanitaire.
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Pièces justificatives' },
        { key: '4', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
        4: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };


    return (
        <>
            <Title level={3} style={{ color: primary_color }}>BAR-TH-172 : Pompe à chaleur de type eau/eau ou sol/eau</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’une pompe à chaleur (PAC) de type eau/eau ou sol/eau.</Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Batiment plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Batiment2Ans"]}
                label="L'opération est réalisée sur un bâtiment existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-3"], undefined)
                        form.setFieldValue(['items', field.name, "REF-14-F"], undefined)
                        form.setFieldValue(['items', field.name, "REF-14-E"], undefined)
                        form.setFieldValue(['items', field.name, "REF-15-A"], undefined)
                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                        form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                        form.setFieldValue(['items', field.name, "IREF-140-H"], undefined)
                        form.setFieldValue(['items', field.name, "SurfaceChauffee"], undefined)
                        form.setFieldValue(['items', field.name, "Regulateur"], undefined)
                        form.setFieldValue(['items', field.name, "NoteDimensionnement"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Batiment + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Batiment2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Batiment2Ans"]) === "Oui" ? (
                        <>
                            {/* Type de logement */}
                            <Form.Item
                                name={[field.name, 'REF-3']}
                                label="Type de logement :"
                                rules={
                                    [{
                                        required: true,
                                        message: "Veuillez renseigner le type de logement"
                                    },]}
                                tooltip={{
                                    title: <>Cette opération n'est éligible que si elle est réalisée dans une maison individuelle.</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value="0">Maison individuelle</Radio.Button>
                                    <Radio.Button disabled value="1">Appartement</Radio.Button>
                                </Radio.Group>
                            </Form.Item >
                            {/* Alerte si Appartement */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-3"]) === "1" ? (
                                        <><Alert
                                            message="Cette opération n'est éligible que si elle est réalisée dans une maison individuelle."
                                            type="error"
                                            showIcon
                                        />

                                        </>

                                    ) : null
                                }
                            </Form.Item>

                            {/* Suite formulaire si Maison individuelle */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-3"]) === "0" ? (
                                        <>

                                            {/* Régulateur */}
                                            <Form.Item
                                                name={[field.name, "Regulateur"]}
                                                label="La PAC est-elle équipée d'un régulateur ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group
                                                    buttonStyle='solid'
                                                    onChange={
                                                        () => {
                                                            form.setFieldValue(['items', field.name, "REF-14-F"], undefined)
                                                            form.setFieldValue(['items', field.name, "REF-14-E"], undefined)
                                                            form.setFieldValue(['items', field.name, "REF-15-A"], undefined)
                                                            form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                            form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                                                            form.setFieldValue(['items', field.name, "IREF-140-H"], undefined)
                                                            form.setFieldValue(['items', field.name, "SurfaceChauffee"], undefined)
                                                        }}
                                                >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si Regulateur non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Regulateur"]) === "Non" ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si la PAC est équipée d'un régulateur relevant de l’une des classes IV, V, VI, VII
                                                ou VIII telles que définies au paragraphe 6.1 de la communication de la Commission 2014/C 207/02."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Suite formulaire si Regulateur OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Regulateur"]) === "Oui" ? (
                                                        <>
                                                            {/* Classe du régulateur */}
                                                            <Form.Item
                                                                name={[field.name, "ClasseRegulateur"]}
                                                                label="Classe du régulateur :"
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                                tooltip={{
                                                                    title: <>Pour être éligible aux CEE, le régulateur doit relever de l'une des classes IV, V, VI, VII ou VIII
                                                                        telles que définies au paragraphe 6.1 de la communication de la Commission 2014/C 207/02.</>,
                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                    color: tooltip_color,
                                                                }}
                                                            >
                                                                <Radio.Group buttonStyle='solid'>
                                                                    <Radio.Button value="IV">IV</Radio.Button>
                                                                    <Radio.Button value="V">V</Radio.Button>
                                                                    <Radio.Button value="VI">VI</Radio.Button>
                                                                    <Radio.Button value="VII">VII</Radio.Button>
                                                                    <Radio.Button value="VIII">VIII</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>

                                                            {/* Usage */}
                                                            <UsageREF15A
                                                                label="Usage de la pompe à chaleur :"
                                                                name={[field.name, 'REF-15-A']}
                                                            />

                                                            {/*Temperature*/}
                                                            <Form.Item
                                                                name={[field.name, "TemperaturePAC"]}
                                                                label="Quel est l'application de la PAC installée ?"
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                                tooltip={{
                                                                    title: <><Paragraph style={{ color: 'white' }}>Une application à basse température est une application dans laquelle un dispositif de chauffage des locaux
                                                                        par pompe à chaleur fournit sa puissance calorifique déclarée pour une température de sortie de l’échangeur thermique intérieur de 35 °C.</Paragraph>
                                                                        <Paragraph style={{ color: 'white' }}>Une application à moyenne ou haute température est une application dans laquelle un dispositif de chauffage des locaux par pompe à chaleur
                                                                            fournit sa puissance calorifique déclarée pour une température de sortie de l’échangeur thermique intérieur d’au moins 55 °C.</Paragraph></>,
                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                    color: tooltip_color,
                                                                }}
                                                            >
                                                                <Radio.Group buttonStyle='solid' onChange={
                                                                    () => {
                                                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                                        form.setFieldValue(['items', field.name, "REF-140-G"], undefined)
                                                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                                    }}>
                                                                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                                                                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>


                                                            {/* Surface chauffée */}
                                                            <Form.Item style={{ margin: "0px" }}>
                                                                <Space align="end">
                                                                    <Form.Item
                                                                        name={[field.name, "SurfaceChauffee"]}
                                                                        label="Surface chauffée par la PAC (m²) : "
                                                                        rules={[
                                                                            { required: true, message: "Veuillez renseigner ce champ" },
                                                                        ]}
                                                                    >
                                                                        <InputNumber
                                                                            addonAfter="m²"
                                                                            min='0'
                                                                            controls={false}
                                                                            style={{ width: '150px' }}
                                                                            onChange={() => {
                                                                                if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "0" }]) }
                                                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "1" }]) }
                                                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "2" }]) }
                                                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 130 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "3" }]) }
                                                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 130) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "4" }]) }
                                                                                else {
                                                                                    form.setFields([{ name: ["items", field.name, "REF-14-E"], value: undefined }])
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item>
                                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                                    </Form.Item>
                                                                    <SurfaceHabitableREF14E
                                                                        label=""
                                                                        name={[field.name, 'REF-14-E']}
                                                                        disabled={true}
                                                                    />
                                                                </Space>
                                                            </Form.Item>



                                                            {/* ETAS variable continue */}
                                                            <Form.Item style={{ margin: "0px" }}>
                                                                <Space align="end" size="middle">
                                                                    <Form.Item
                                                                        label="Efficacité énergétique saisonnière de la PAC :"
                                                                        name={[field.name, 'Etas']}
                                                                        rules={[
                                                                            { required: true, message: "Veuillez renseigner ce champ" },
                                                                        ]}
                                                                        tooltip={{
                                                                            title: "L’efficacité énergétique saisonnière (ηs) est déterminée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 déterminée et selon l’application de la PAC installée.",
                                                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                            color: tooltip_color,
                                                                        }}
                                                                    >
                                                                        <InputNumber
                                                                            min={0}
                                                                            controls={false}
                                                                            style={{ width: '150px' }}
                                                                            addonAfter='%'
                                                                            onChange={() => {
                                                                                if (getFieldValue(['items', field.name, "Etas"]) >= 111 && getFieldValue(['items', field.name, "Etas"]) < 140) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: "0" }]) }
                                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 140 && getFieldValue(['items', field.name, "Etas"]) < 170) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: "1" }]) }
                                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 170 && getFieldValue(['items', field.name, "Etas"]) < 200) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: "2" }]) }
                                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 200 && getFieldValue(['items', field.name, "Etas"]) < 230) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: "3" }]) }
                                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 230) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: "4" }]) }
                                                                                else if (getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "IREF-140-H"], value: undefined }]) }

                                                                            }}
                                                                        />
                                                                    </Form.Item>

                                                                    <Form.Item>
                                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                                    </Form.Item>
                                                                    {/* ETAS */}
                                                                    <EfficaciteEnergetiqueREF140H
                                                                        label=""
                                                                        name={[field.name, 'IREF-140-H']}
                                                                        disabled={true}
                                                                    />
                                                                </Space>
                                                                {/* Alerte si ETAS < 126 et basse température */}
                                                                <Form.Item noStyle shouldUpdate>
                                                                    {({ getFieldValue }) =>
                                                                        getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                                                                            <Alert
                                                                                message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                                                                                type="error"
                                                                                showIcon
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </Form.Item>

                                                                {/* Alerte si ETAS < 111 et moyenne/haute température */}
                                                                <Form.Item noStyle shouldUpdate>
                                                                    {({ getFieldValue }) =>
                                                                        getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                                                                            <Alert
                                                                                message="Pour une PAC moyenne ou haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                                                                                type="error"
                                                                                showIcon
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </Form.Item>

                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* Note de dimensionnement */}
                                            <Form.Item
                                                name={[field.name, "NoteDimensionnement"]}
                                                label="L'étude de dimensionnement a-t-elle été réalisée ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid'>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si NoteDimensionnement non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Non" ? (
                                                        <Alert
                                                            message="La note de dimensionnement devra être réalisée et remise au bénéficiaire à l'achèvement des travaux."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Message si NoteDimensionnement oui */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Oui" ? (
                                                        <Alert
                                                            message="Vous pouvez déposer la note de dimensionnement dans les pièces jointes du projet, à la fin du formulaire."
                                                            type="info"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>


            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
