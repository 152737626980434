import { Text } from '@/utils';
import { currencyFormatter } from '@utils/helpers';
import { Card, Space } from 'antd';

export const BenificiaryPrime = ({
    benificiaryPrime,
}: {
    benificiaryPrime: number;
}) => {
    return (
        <Card size="small">
            <Space direction="vertical" size="small">
                <Text className="dossier__primes-volume--type">
                    Prime bénéficiaire
                </Text>
                <Text className="dossier__primes-volume--currency">
                    {currencyFormatter(benificiaryPrime, true)}
                </Text>
            </Space>
        </Card>
    );
};
