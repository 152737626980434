import { FormListFieldData, Typography, Divider, Button, Flex, Radio, Form } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import TypeCoupDePouceIREF159Z from '../refs/TypeCoupDePouceIREF159Z';

const { Title } = Typography

export const Barth1435S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Infos FOST */
    const code_fost = "BAR-TH-143"
    const libelle_fost = "Système solaire combiné (France métropolitaine)"
    const denomination_fost = <>Mise en place d’un système solaire combiné (SSC) destiné au chauffage et à la production d’eau chaude sanitaire</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-143%20vA51-5%20%C3%A0%20compter%20du%2001-03-2023.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code postal */}
            <ZoneClimatiqueS field={field} />

            {/* Coup de pouce */}
            <Form.Item
                name={[field.name, "EligibleCoupDePouce"]}
                label="Le système solaire combiné installé vient-il en remplacement d'une chaudière au gaz, au fioul ou au charbon ?"
                colon={false}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "IREF-159-Z"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Energie remplacée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "EligibleCoupDePouce"]) === "Oui" ? (
                        <>
                            <Typography style={{ marginBottom: 10 }}><Typography.Text strong>L'opération est éligible au bonus Coup de Pouce, renseignez les informations suivantes pour connaître le montant bonifié :</Typography.Text></Typography>
                            <TypeCoupDePouceIREF159Z
                                name={[field.name, "IREF-159-Z"]}
                                label="Énergie remplacée"
                            />
                        </>
                    ) : null
                }
            </Form.Item>
        </>
    );
};
