import React, { useEffect } from 'react';

import { UploadFiles } from '../upload/UploadFile';
import { useSubmitNewProject } from '@/hooks/new-project/useSubmitNewProject';
import { Container } from '@/layouts';
import { setBenificiarySiret, setInstallerSiret } from '@/store/slices';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { RootState } from '@/store/store';
import { Text } from '@/utils';
import { Button, Card, Divider, Form, Row, Space, message } from 'antd';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { NEW_PROJECT_FILES } from '../constants';
import { GeneralDescription } from '../general-description/GeneralDescription';
import { WorkDetails } from '../operations/forms/components/other-operation/WorkDetails';
import { createJsonForm, updateFormValues } from '../utils';
import './styles.scss';

const GenericForm: React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const { isPending, submitAddNewProject, isResolved, isRejected, error } =
        useSubmitNewProject();

    const { installerSiret, beneficiarySiret } = useSelector(
        (state: RootState) => state.newProjectReducer
    );
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );

    const onFinish = () => {
        const formvalues: TNewProjectForm = form.getFieldsValue();
        const updateValues = updateFormValues(formvalues, {
            installerSiret,
            beneficiarySiret,
        });
        const data = createJsonForm(updateValues);
        data.accountSiret = heliosUser?.partner?.siret!;
        data.companyName = heliosUser?.partner?.companyName!;
        data.contactFullName = `${heliosUser?.firstName} ${heliosUser?.lastName}`;
        data.projectFormType = 'GENERIC';

        submitAddNewProject(data);
    };

    useEffect(() => {
        if (isRejected) {
            if (error && (error as AxiosError).request) {
                const responseData = JSON.parse(
                    (error as AxiosError).request.response
                );
                const errorMessage = responseData?.detail?.project_files?.[0];

                if (errorMessage && errorMessage.includes('fichier')) {
                    messageApi.error(errorMessage);
                } else {
                    messageApi.error(
                        `Un problème s'est produit, veuillez réessayer plus tard.`
                    );
                }
            } else {
                messageApi.error(
                    `Un problème s'est produit, veuillez réessayer plus tard.`
                );
            }
        }

        if (isResolved) {
            messageApi.success(`La demande a bien été prise en compte`);
            dispatch(setBenificiarySiret(''));
            dispatch(setInstallerSiret(''));
            form.resetFields();
        }
    }, [isRejected, isResolved, isPending, form, messageApi, error, dispatch]);

    return (
        <Container>
            <Card style={{ height: '100%' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={{
                        required: 'Ce champ est obligatoire',
                    }}
                    className="add-project-form"
                    name="projects-form"
                >
                    <Space direction="vertical" size="large">
                        <div>
                            <GeneralDescription isSimulator={false} />
                            <Divider />
                            <WorkDetails />
                            <Divider />
                            <UploadFiles />
                        </div>

                        <Row justify="center">
                            <Space
                                direction="vertical"
                                size="middle"
                                className="add-project-submit"
                            >
                                <Space size="large" wrap>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ alignSelf: 'center' }}
                                        loading={isPending}
                                    >
                                        Soumettre mon projet
                                    </Button>
                                </Space>
                                <Text>
                                    Après soumission, nous analysons votre
                                    projet et revenons vers vous sous 48h
                                </Text>
                            </Space>
                        </Row>
                    </Space>
                </Form>
            </Card>
            {contextHolder}
        </Container>
    );
};

export default GenericForm;
