import { FormListFieldData, Typography, Divider, Flex, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import SurfaceREF10 from '../refs/SurfaceREF10';
import SecteurREF22AA from '../refs/SecteurREF22AA';
import PuissanceChaudiereREF24E from '../refs/PuissanceChaudiereREF24E';

/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Batth1582S = ({ field }: { field: FormListFieldData }) => {


    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-158"
    const libelle_fost = "Pompe à chaleur réversible de type air/air"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) réversible de type air/air de puissances calorifique et frigorifique nominales inférieures ou égales à 1 MW"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-158%20vA42-2%20%C3%A0%20compter%20du%2031-01-2022.pdf"



    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/*Secteur*/}
            <SecteurREF22AA
                label="Secteur :"
                name={[field.name, 'REF-22-AA']}
            />

            {/* Puissance Chaudière */}
            <PuissanceChaudiereREF24E
                label="Puissance de la PAC :"
                name={[field.name, 'REF-24-E']}
            />

            {/* Surface */}
            <SurfaceREF10
                label="Surface totale chauffée par la PAC installée (m²) :"
                name={[field.name, 'REF-10']}
                tooltip='Surface totale chauffée par la PAC installée (m²)'
            />
        </>
    );
};
