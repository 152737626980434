import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appSlice from './slices/app/appSlice';
import dashboardSlice from './slices/dashboard/dashboardSlice';
import dossierSlice from './slices/dossier/dossierSlice';
import newProjectFormSlice from './slices/new-project/newProjectFormSlice';
import newProjectSlice from './slices/new-project/newProjectSlice';
import operationsSlice from './slices/new-project/operationsSlice';
import userSlice from './slices/users/congnito/userSlice';
import heliosUserSlice from './slices/users/helios/heliosUserSlice';

const reducer = combineReducers({
    appReducer: appSlice,
    congintoReducer: userSlice,
    heliosUserReducer: heliosUserSlice,
    dashboardReducer: dashboardSlice,
    dossierReducer: dossierSlice,
    newProjectReducer: newProjectSlice,
    newProjectFormReducer: newProjectFormSlice,
    operationsReducer: operationsSlice,
});

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
