import * as constant from '@/features/new-project/constants';
import { createSimpleAnswersOfElement } from '@/features/new-project/utils';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray } from '@/utils';

export const createRegulationAnswers = (item: any, values: TNewProjectForm) => {
    const newItem: typeof item = {};
    newItem.name = item.name;
    newItem.label = item.label;
    newItem.children = [];
    if (values.regulationType && values.regulationType.length > 0) {
        if (
            values.regulationType.includes(
                constant.REGULATION_VARIATOR_MOTORS
            ) ||
            values.regulationType.includes(constant.REGULATION_GROUP_PRODUCTION)
        ) {
            const { children } = item;
            for (let index = 0; index < values.regulationType.length; index++) {
                const element = values.regulationType[index];
                const question = getObjectFromArray(children, 'name', element);
                const { children: questionChild } = question;
                if (values[questionChild[0].name]) {
                    const answer = createSimpleAnswersOfElement(
                        question,
                        values
                    );

                    newItem.children.push(answer);
                }
            }
        }

        if (values.regulationType.includes(constant.REGULATION_GTB)) {
            const { children } = item;
            const {
                children: regulationGtbChildren,
                name,
                label,
            } = getObjectFromArray(children, 'name', constant.REGULATION_GTB);

            if (
                values[constant.REGULATION_MANAGED_SURFACE] ||
                values[constant.REGULATION_USES_CONCERNED]
            ) {
                const regulationGTB: any = {};
                regulationGTB.name = name;
                regulationGTB.label = label;

                if (values[constant.REGULATION_MANAGED_SURFACE]) {
                    const regulationManagedSurface: any = {};

                    regulationManagedSurface.name =
                        constant.REGULATION_MANAGED_SURFACE;
                    regulationManagedSurface.label =
                        regulationGtbChildren[0].label.replace(
                            constant.STR_VALUE,
                            values[constant.REGULATION_MANAGED_SURFACE]
                        );

                    newItem.children.push(regulationManagedSurface);
                }

                if (
                    values[constant.REGULATION_USES_CONCERNED] &&
                    values[constant.REGULATION_USES_CONCERNED].length > 0
                ) {
                    const {
                        children: usesConcernedValues,
                        name: usesConcernedName,
                        label: usesConcernedLabel,
                    } = getObjectFromArray(
                        regulationGtbChildren,
                        'name',
                        constant.REGULATION_USES_CONCERNED
                    );

                    const regulationUsesConcerned: any = {};
                    regulationUsesConcerned.name = usesConcernedName;
                    regulationUsesConcerned.label = usesConcernedLabel;
                    const regulationUsesValues =
                        values[constant.REGULATION_USES_CONCERNED];
                    regulationUsesConcerned.children = [];
                    for (let i = 0; i < regulationUsesValues.length; i++) {
                        const element = regulationUsesValues[i];

                        const answer = getObjectFromArray(
                            usesConcernedValues,
                            'name',
                            element
                        );

                        if (answer.name === constant.OTHERS_REGULATION_GBT) {
                            answer.label = answer.label.replace(
                                constant.STR_VALUE,
                                values[constant.OTHERS_REGULATION_GBT_NAMES]
                            );
                        }

                        regulationUsesConcerned.children.push(answer);
                    }

                    newItem.children.push(regulationUsesConcerned);
                }
            }
        }
    }

    return newItem;
};
