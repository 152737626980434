import { CheckOutlined } from "@ant-design/icons";
import { FormListFieldData, Row } from "antd";

interface OperationTitleProps {
  field: FormListFieldData;
  isDone: boolean;
}

const OperationTitle: React.FC<OperationTitleProps> = ({ field, isDone }) => {
  const operationNumber = field.name + 1;
  const suffix = operationNumber === 1 ? 'er' : 'éme';
  const title = `${operationNumber}${suffix} opération`;

  return (
      <Row>
          {title}

          {isDone && <CheckOutlined style={{ marginLeft: '8px' }} />}
      </Row>
  );
};

export default OperationTitle
