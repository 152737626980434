import { Text } from '@/utils';
import { Form, Radio, Space } from 'antd';

import { PRINCIPALE_USE_OF_BUILDING } from '../../constants';
import useOfBuildingTypes from '../../data/useOfBuildingTypes.json';

export const PrincipalUseOfBuilding = () => {
    return (
        <Form.Item
            label={
                <Text className="field-description" strong>
                    Usage principal du ou des bâtiments concernés par les
                    travaux
                </Text>
            }
            rules={[{ required: true }]}
            name={PRINCIPALE_USE_OF_BUILDING}
        >
            <Radio.Group>
                <Space direction="vertical" size="middle">
                    {useOfBuildingTypes.map((useOfBuildingType) => (
                        <Radio
                            key={useOfBuildingType.key}
                            value={useOfBuildingType.label}
                            id={useOfBuildingType.value}
                        >
                            {useOfBuildingType.label}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Form.Item>
    );
};
