import React from 'react';

import { Card, Empty } from 'antd';

import './style.scss';

export const ContentNotFound: React.FC = () => {
    return (
        <Card className="content-not-found__card">
            <Empty description="Une erreur s'est produit ou le contenue que vous cherchez n'existe pas" />
        </Card>
    );
};
