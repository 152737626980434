import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function SecteurIREF22AX({ label, name }: { label: string, name: NamePath }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner le secteur"
      },]}>
    <Select
      placeholder="Sélectionnez le secteur"
      style={{ width: '280px' }}
    >
      <Option value="0">Hôtellerie</Option>
      <Option value="1">Santé/ Entrepôts / Commerce ≥ 400 m²</Option>
      <Option value="2">Enseignement</Option>
      <Option value="3">Commerce &lt; 400 m²</Option>
      <Option value="4">Bureaux - restauration</Option>
      <Option value="5">Autres</Option>
    </Select>
  </Form.Item >
}