import { useCallback } from 'react';

import type { IDossier } from '@/features/dashboard/index';
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

export const useGetDossierById = () => {
    const { inprogressDossiers, archivedDossiers } = useSelector(
        (state: RootState) => state.dashboardReducer
    );

    const getDossierFromList = useCallback(
        (id: number) => {
            let dossiers: IDossier[] = [];
            dossiers = inprogressDossiers.concat(archivedDossiers);
            const found: IDossier | undefined = dossiers.find((dossier) => {
                return dossier.id === id;
            });

            return found;
        },
        [inprogressDossiers, archivedDossiers]
    );

    return { getDossierFromList };
};
