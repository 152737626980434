import { CONTACT_ID, NEW_PASSWORD_REQUIRED } from './cognito/constants';
import {
    BENEFICIARY,
    BUSINESS_PROVIDER,
    Footer,
    Header,
    INSTALLER,
    NOT_SPECIFIED,
    Sidebar,
    Text,
    Title,
} from './constants';
import {
    buildForm,
    currencyFormatter,
    dateFormatter,
    emptyStringFormatter,
    getObjectFromArray,
    numberFormatter,
    removeSpacesFromString,
} from './helpers';
import { inputValidation } from './validators';

const isProduction = process.env.NODE_ENV !== 'development';

export {
    CONTACT_ID,
    NEW_PASSWORD_REQUIRED,
    Title,
    Text,
    Header,
    Footer,
    Sidebar,
    NOT_SPECIFIED,
    BENEFICIARY,
    INSTALLER,
    BUSINESS_PROVIDER,
    isProduction,
    dateFormatter,
    currencyFormatter,
    numberFormatter,
    emptyStringFormatter,
    removeSpacesFromString,
    buildForm,
    getObjectFromArray,
    inputValidation,
};
