import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function SurfaceHabitableREF14F({ label, name, disabled, style, hidden }: { label: string, name: NamePath, disabled?: boolean, style?: React.CSSProperties, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        style={style}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner la surface habitable"
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">S &lt; 35</Radio.Button>
            <Radio.Button value="1">35 ≤ S &lt; 60</Radio.Button>
            <Radio.Button value="2">60 ≤ S &lt; 70</Radio.Button>
            <Radio.Button value="3">70 ≤ S &lt; 90</Radio.Button>
            <Radio.Button value="4">90 ≤ S &lt; 110</Radio.Button>
            <Radio.Button value="5">110 ≤ S ≤ 130</Radio.Button>
            <Radio.Button value="6">S &gt; 130</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
