import React from 'react';

import { Footer } from '@/utils/constants';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import './style.scss';

export const DashboardFooter: React.FC = () => {
    return (
        <Footer className="dashboard__footer">
            <Row justify="space-around" align="middle">
                <Col>
                    <Link to="/privacy-policy">
                        Politique de confidentialité
                    </Link>
                </Col>
                <Col>
                    <Link to="/contact">Contact</Link>
                </Col>
                <Col>
                    <Link to="https://www.cameo-energy.com/" target="blank">
                        cameo-energy.com
                    </Link>
                </Col>
            </Row>
        </Footer>
    );
};
