import { DateTimeFormatOptions } from './types';

export const dateFormatter = (
    date: Date | number,
    format: DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    },
    timeZoneName: string = 'fr-FR'
): string => {
    let dateToFormat = date;

    if (date instanceof Date) {
        dateToFormat = new Date(date);
    }

    return new Intl.DateTimeFormat(timeZoneName, format).format(dateToFormat);
};

export const currencyFormatter = (
    prime: number = 0,
    withCent: boolean = false
): string => {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        useGrouping: true,
        maximumFractionDigits: withCent ? undefined : 0,
    }).format(prime);
};

export const numberFormatter = (number: number, maximumFractionDigits?: number): string => {
    return new Intl.NumberFormat('fr-FR', { useGrouping: true, maximumFractionDigits }).format(number);
};

export const emptyStringFormatter = (str: string): string | null => {
    if (str.trim().length === 0) return null;

    return str;
};

export const removeSpacesFromString = (value: string) => {
    if (!value) return;
    return value.replaceAll(' ', '');
};

export const buildForm = (object: any) =>
    Object.keys(object).reduce((formData, key) => {
        formData.append(key, object[key]);
        return formData;
    }, new FormData());

export const getObjectFromArray = (
    arr: any[],
    attributName: string,
    value: any
) => {
    return arr.find((item) => item[attributName] === value);
};

export const convertCalenderFieldToTimeStamp = (calndateDate: any): number => {
    const { $y, $M, $D } = calndateDate;

    return new Date(`${$y}-${$M}-${$D}`).getTime();
};
