import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function TypeLogementREF3({ label, name, style, onChange }: { label: string, name: NamePath, style?: React.CSSProperties, onChange?: () => void }) {
    return <Form.Item
        name={name}
        label={label}
        style={style}
        
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le type de logement"
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            onChange={onChange}
        >
            <Radio.Button value="0">Maison individuelle</Radio.Button>
            <Radio.Button value="1">Appartement</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
