import { CognitoUser } from '@aws-amplify/auth';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ICognitoUser } from './types';

const initialState: ICognitoUser = {
    user: null,
    username: '',
};

export const userSlice = createSlice({
    name: 'cognitoUser',
    initialState,
    reducers: {
        setCognitoUser: (
            state: ICognitoUser,
            action: PayloadAction<CognitoUser>
        ) => {
            state.user = action.payload;
        },
        setUsername: (state: ICognitoUser, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        resetCognitoUser: (state) => {
            state = initialState;
        },
    },
});

export const { setCognitoUser, setUsername, resetCognitoUser } =
    userSlice.actions;
export default userSlice.reducer;
