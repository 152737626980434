import React from 'react';

import { PROJECT_STATES } from '@/features/new-project/constants';
import { NOT_SPECIFIED, Text, Title, dateFormatter } from '@/utils';
import {
    ProjectsRequestsStatusProps,
    useGetProjectsRequests,
} from '@hooks/new-project/useGetProjectsRequests';
import { Card, Empty } from 'antd';

import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { useNavigate } from 'react-router-dom';

export const RequestProjectsTable = () => {

  const navigate = useNavigate()

    const columns: ColumnsType<ProjectsRequestsStatusProps> = [
        {
            title: 'Nom du projet',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (projectName) => <Text>{projectName}</Text>,
        },
        {
            title: 'Adresse',
            dataIndex: 'projectAddress',
            key: 'projectAddress',
            render: (projectAddress) => {
                const {
                    streetNumber,
                    streetName,
                    city,
                    zipCode,
                    building,
                    cadastralPlot,
                } = projectAddress;

                return (
                    <>
                        <div>
                            <Text>{`${streetNumber || ''} ${streetName || ''} ${
                                zipCode || ''
                            } ${city || ''}`}</Text>
                        </div>
                        {building && (
                            <div>
                                <Text>
                                    Bâtiment : {`${building || NOT_SPECIFIED}`}
                                </Text>
                            </div>
                        )}
                        {cadastralPlot && (
                            <div>
                                <Text>
                                    Parcelle cadastrale :{' '}
                                    {`${cadastralPlot || NOT_SPECIFIED}`}
                                </Text>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Date de la demande',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => <Text>{dateFormatter(createdAt)}</Text>,
        },
        {
            title: 'Statut',
            dataIndex: 'state',
            key: 'state',
            render: (state) => <Text>{PROJECT_STATES[state]}</Text>,
        },
    ];
    const customizeRenderEmpty = {
        emptyText: (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Vous n'avez pas encore des demandes"
            />
        ),
    };
    const { data, isPending } = useGetProjectsRequests();

    return (
        <Card
            className="card__current-projects-table"
            loading={isPending}
            // style={{ height: '100%' }}
        >
            <Title level={3} style={{ marginTop: 0 }}>
                Vos demandes
            </Title>
            <Table
                onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        console.log(record.id)
                        navigate(`/demande-projet/${record.id}`)
                      },
                    };
                }}
                locale={customizeRenderEmpty}
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record.id}
            />
        </Card>
    );
};
