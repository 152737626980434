import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select
export default function SecteurREF22K({label, name}: {label: string, name: NamePath}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner le secteur"
                    },]}>
                <Select
                    placeholder="Sélectionnez le secteur"
                    style={{width: '200px'}}
                  >
                    <Option value="0">Bureau</Option>
                    <Option value="1">Enseignement</Option>
                    <Option value="2">Commerces</Option>
                    <Option value="3">Hôtellerie, restauration</Option>
                    <Option value="4">Santé</Option>
                    <Option value="5">Autres secteurs</Option>
                  </Select>
            </Form.Item >
}