import { useCallback } from 'react';

import { HAS_ACCOUNT } from '@/utils/constants';
import userServices from '@services/user/userServices';
import { Auth } from 'aws-amplify';
import { useAsync } from 'react-async';
import { useNavigate } from 'react-router-dom';

export const useCompletePassword = () => {
    const navigate = useNavigate();

    const onForgetPassword = useCallback(async (params: string[]) => {
        const [user, password] = params;
        await Auth.completeNewPassword(user, password);
    }, []);

    const {
        isPending,
        isResolved,
        isRejected,
        run: onCompletePassword,
    } = useAsync({
        deferFn: (params) => onForgetPassword(params),
        async onResolve() {
            const updateCognitoStatus =
                await userServices.updateCognitoAccountStatus(HAS_ACCOUNT);
            if (updateCognitoStatus.email) {
                navigate('/');
            }
        },
    });

    return { isPending, isResolved, isRejected, onCompletePassword };
};
