import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';


export default function ModeFonctionnementREF36A({label, name}: {label: string, name: NamePath}) {
    return < Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner ce champ"
                    },]} >
                <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="0">1x8h</Radio.Button>
                    <Radio.Button value="1">2x8h</Radio.Button>
                    <Radio.Button value="2">3x8h avec arrêt le week-end</Radio.Button>
                    <Radio.Button value="3">3x8h sans arrêt le week-end</Radio.Button>
                </Radio.Group>
            </Form.Item >
}
