import { useEffect } from 'react';

import { useGetUser } from '@/hooks';
import { useGetDossiersList } from '@/hooks/dashboard';
import { Container } from '@/layouts';
import { DashboardLayout } from '@/layouts/dashboard/DashboardLayout';
import {
    setArchivedDossiers,
    setHeliosUser,
    setInprogressDossiers,
    setIsAppError,
    setPrimes,
} from '@/store/slices';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

export const PrivateOutlet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isPending, data: user, isRejected } = useGetUser();
    const { isPending: isloading, data: dossiers } = useGetDossiersList();

    useEffect(() => {
        if (user) {
            dispatch(setHeliosUser(user));
        }

        if (dossiers) {
            dispatch(setInprogressDossiers(dossiers.inprogressDossiers));
            dispatch(setArchivedDossiers(dossiers.archivedDossiers));
            dispatch(setPrimes(dossiers.primes));
            dispatch(setIsAppError(false));
        }

        if (isRejected) {
            navigate('/');
        }
    }, [user, dossiers, dispatch, navigate, isRejected]);

    return (
        <DashboardLayout>
            <Container loading={isPending || isloading}>
                <Outlet />
            </Container>
        </DashboardLayout>
    );
};
