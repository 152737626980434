import { IDossier } from './types';

export const chartConfig = (chartData: number[]) => {
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: window.innerWidth < 576 ? 1 : 5,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Statut des dossiers',
            },
            tooltips: {
                display: false,
            },
        },
        scales: {
            y: {
                min: 0,
                max: Math.max(...chartData) + 1,
                ticks: {
                    precision: 0,
                },
            },
            x: {
                grid: {
                    drawBorder: false,
                    display: false,
                },
            },
        },
    };

    const labels = [
        'Avant-projet',
        'Travaux en cours',
        'Paiement'
    ];

    const data = {
        labels,
        datasets: [
            {
                data: chartData,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    // if (window.innerWidth < 576) {
    //     options.aspectRatio = 1
    // }

    return {
        options,
        data,
    };
};

export const countDossierByStep = (dossiers: IDossier[]): number[] => {
    let travaux = 0;
    let paiement = 0;
    let avantProjet = 0;

    for (let index = 0; index < dossiers.length; index++) {
        const element = dossiers[index];
        const { name } = element.stepOfDossier;

        switch (name) {
            case 'Avant-projet':
                avantProjet += 1;
                break;
            case 'Travaux en cours':
                travaux += 1;
                break;
            case 'Paiement':
                paiement += 1;
                break;
            default:
                break;
        }
    }

    return [
        avantProjet,
        travaux,
        paiement,
    ];
};
