import { Form, InputNumber } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function RREF142({ label, name, disabled, hidden }: {
    label: string, 
    name: NamePath, 
    disabled?: boolean
    hidden? : boolean
}) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={[
            { required: true, message: "Veuillez renseigner ce champ" },
        ]}
        extra="Le facteur R est calculé automatiquement."
        style={{margin: "0px"}}
    >
        <InputNumber
            disabled={disabled}
            style={{width: "150px"}}
        />
    </Form.Item>
}