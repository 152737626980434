import { useCallback } from 'react';

import newProjectServices from '@services/new-project/newProjectServices';
import { useAsync } from 'react-async';
import { ProjectRequestProps } from './project-request-types';




export const useGetProjectRequestById = (requestId: string) => {
  const fetchProjectRequestById = useCallback(async (): Promise<ProjectRequestProps> => {

      return await newProjectServices.getProjectRequestById(requestId);
  }, [requestId]);

  const { isPending, data } = useAsync({
      promiseFn: fetchProjectRequestById,
  });

  return { isPending, data };
};
