import { Form, Radio, Typography, Divider, Flex, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';


const { TextArea } = Input;


export const FormInscription = ({ onClick }: { onClick: () => void }) => {


    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Mise en forme*/
    const labelLayout = { span: 6 }
    const colLayout = { flex: 1 }
    const labelAlign = 'right'

    return (
        <>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    Afin de pouvoir traiter votre demande, nous avons besoin des informations complémentaires suivantes :
                </Typography.Text>
                <Button type='text' icon={<CloseOutlined />} onClick={onClick} />
            </Flex>

            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px', fontSize:'1.3em' }}>Votre projet</Divider>


            {/* Type clients */}
            <Form.Item
                name={['inscription', "TypeClient"]}
                label="Votre client est un :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner le type de clients" },
                ]}
            >
                <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="Particulier">Particulier</Radio.Button>
                    <Radio.Button value="Professionnel">Professionnel</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Adresse travaux*/}
            <Form.Item
                name={['inscription', "AdresseTravaux"]}
                label="Adresse des travaux :"
                rules={[
                    { required: true, message: "Veuillez renseigner l'adresse du site des travaux" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
            >
                <Input placeholder='Adresse du site des travaux' />
            </Form.Item>

            {/* Code Postal travaux*/}
            <Form.Item
                name={['inscription', "CodePostalTravaux"]}
                label="Code postal des travaux :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner le code postal du site des travaux" },
                    { pattern: /^[0-9]*$/, message: "Veuillez utiliser uniquement des chiffres" },
                    { pattern: /^.{5,5}$/, message: "Veuillez renseigner 5 chiffres" },
                ]}
            >
                <Input placeholder='Code postal du site des travaux' showCount maxLength={5} />
            </Form.Item>

            {/* Ville travaux*/}
            <Form.Item
                name={['inscription', "VilleTravaux"]}
                label="Ville des tavaux"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner la ville du site des travaux" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
            >
                <Input placeholder='Ville du site des travaux' />
            </Form.Item>

            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px', fontSize:'1.3em' }}>Votre entreprise</Divider>

            {/* Raison Sociale */}
            <Form.Item
                name={['inscription', "RaisonSociale"]}
                label="Raison Sociale :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner la raison sociale de votre entreprise" },
                    { pattern: /^.{1,200}$/, message: "Ce champ est limité à 200 caractères" },
                ]}
            >
                <Input placeholder='Raison sociale de votre entreprise' />
            </Form.Item>

            {/* SIRET */}
            <Form.Item
                name={['inscription', "Siret"]}
                label="N° SIRET :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner le SIRET de votre entreprise" },
                    { pattern: /^[0-9]*$/, message: "Veuillez utiliser uniquement des chiffres" },
                    { pattern: /^.{14,14}$/, message: "Veuillez renseigner 14 chiffres" },
                ]}
            >
                <Input showCount maxLength={14} placeholder='N° SIRET de votre entreprise' />
            </Form.Item>

            {/* Adresse */}
            <Form.Item
                name={['inscription', "Adresse"]}
                label="Adresse :"
                rules={[
                    { required: true, message: "Veuillez renseigner l'adresse de votre entreprise" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
            >
                <Input placeholder='Adresse de votre entreprise' />
            </Form.Item>

            {/* Code Postal */}
            <Form.Item
                name={['inscription', "CodePostal"]}
                label="Code postal :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner le code postal de votre entreprise" },
                    { pattern: /^[0-9]*$/, message: "Veuillez utiliser uniquement des chiffres" },
                    { pattern: /^.{5,5}$/, message: "Veuillez renseigner 5 chiffres" },
                ]}
            >
                <Input placeholder='Code postal de votre entreprise' showCount maxLength={5} />
            </Form.Item>

            {/* Ville */}
            <Form.Item
                name={['inscription', "Ville"]}
                label="Ville"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner la ville de votre entreprise" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
            >
                <Input placeholder='Ville de votre entreprise' />
            </Form.Item>

            {/* Nom & Prénom */}
            <Form.Item
                label="Prénom :"
                name={['inscription', "Prenom"]}
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner votre prénom" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
            >
                <Input placeholder='Prénom' />
            </Form.Item>

            <Form.Item
                label="Nom :"
                name={['inscription', "Nom"]}
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner votre nom" },
                    { pattern: /^.{1,100}$/, message: "Ce champ est limité à 100 caractères" },
                ]}
            >
                <Input placeholder='Nom' />
            </Form.Item>

            {/* Email */}
            <Form.Item
                name={['inscription', "Email"]}
                label="Email :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner votre email" },
                    { type: 'email', message: "Ce format n'est pas reconnu comme une adresse e-mail" },
                    { pattern: /^.{1,200}$/, message: "Ce champ est limité à 200 caractères" },
                ]}
            >
                <Input placeholder='Email' />
            </Form.Item>

            {/* Tel */}
            <Form.Item
                name={['inscription', "Tel"]}
                label="Téléphone :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { required: true, message: "Veuillez renseigner votre numéro de téléphone" },
                    { pattern: /^[0-9]*$/, message: "Veuillez utiliser uniquement des chiffres" },
                    { pattern: /^.{10,10}$/, message: "Veuillez renseigner 10 chiffres" },
                ]}
            >
                <Input showCount maxLength={10} placeholder='Téléphone' />
            </Form.Item>

            {/* Message */}
            <Form.Item
                name={['inscription', "Message"]}
                label="Message :"
                labelCol={labelLayout}
                wrapperCol={colLayout}
                labelAlign={labelAlign}
                rules={[
                    { pattern: /^.{0,2000}$/, message: "Ce champ est limité à 2000 caractères" },
                ]}
            >
                <TextArea rows={4} showCount maxLength={2000} placeholder='Message (optionnel)' />
            </Form.Item>

        </>
    );
};
