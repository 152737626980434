import { useCallback, useState } from 'react';

import registrationRequestsServices from '@/services/registration-requests/registrationRequestsServices';

export const useRegistrationRequests = () => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const sendRegistrationRequest = useCallback(
        async (registration: any): Promise<any | null> => {
            try {
                setIsPending(true);
                setIsError(false);
                await registrationRequestsServices
                    .sendRegistrationRequest(registration)
                    .then((response) => {
                        setIsPending(false);
                        return response;
                    });
            } catch (error) {
                setIsError(true);
                setIsPending(false);
                throw error;
            }
        },
        []
    );

    return {
        isPending,
        isError,
        sendRegistrationRequest,
    };
};
