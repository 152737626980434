import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22AD from '@/features/new-project/operations/forms/components/common/SecteurREF22AD';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography



const { Title } = Typography

export const Resch1033 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "RES-CH-103"
    const libelle_fost = "Réhabilitation d’un poste de livraison de chaleur d’un bâtiment tertiaire"
    const denomination_fost = <>Réhabilitation d’un poste de livraison de chaleur par le remplacement de la
        totalité des éléments constitutifs du primaire du poste de livraison</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-103%20vA36-3%20%C3%A0%20compter%20du%2012-10-2020.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Le remplacement des éléments constitutifs du primaire du poste de livraison porte sur :
            <Typography style={{ paddingLeft: '16px' }}>-
                <Typography.Text strong style={{ color: primary_color }}> l’échangeur</Typography.Text>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>-
                <Typography.Text strong style={{ color: primary_color }}> la régulation primaire</Typography.Text>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>-
                <Typography.Text strong style={{ color: primary_color }}> les pompes sur fluide primaire </Typography.Text>
                (lorsque le poste de livraison en est équipé)
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>-
                <Typography.Text strong style={{ color: primary_color }}> l’isolation thermique</Typography.Text>
            </Typography>
        </Paragraph>
        <Typography>Le
            <Typography.Text strong style={{ color: primary_color }}> pincement aux bornes </Typography.Text>
            du nouvel échangeur est
            <Typography.Text strong style={{ color: primary_color }}> inférieur à 5°C.</Typography.Text>
        </Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne le
            <Typography.Text strong style={{ color: primary_color }}> remplacement des équipements constitutifs du primaire du poste de livraison : </Typography.Text>
            échangeurs dont le pincement est inférieur à 5°C, éléments de régulation, éléments d'isolation thermique et pompes (lorsque le poste de livraison en est équipé).
        </Paragraph>
        <Typography>
            A défaut, la preuve de réalisation de l’opération mentionne le remplacement des équipements
            constitutifs du primaire du poste de livraison avec leurs marque et référence et est accompagnée
            d’un ou plusieurs documents issu(s) du fabricant indiquant que les équipements installés sont des
            échangeurs et leur performance (niveau de pincement), des éléments de régulation, des éléments d’isolation
            thermique et des pompes (lorsque le poste de livraison en est équipé).
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire plus de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "REF-22-AD"], undefined)
                    form.setFieldValue(['items', field.name, "REF-10"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            {/* Secteur */}
                            <SecteurREF22AD
                                name={[field.name, "REF-22-AD"]}
                                label="Secteur d'actvité :"
                            />

                            {/* Surface */}
                            <Form.Item
                                name={[field.name, "REF-10"]}
                                label="Surface totale chauffée (m²) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner la surface" },
                                ]}
                                tooltip={{
                                    title: <><Paragraph style={{color: 'white'}}>La surface prise en compte est la surface chauffée du ou des
                                        bâtiments tertiaires raccordés au poste de livraison réhabilité.</Paragraph>
                                        <Typography style={{color: 'white'}}>Si plusieurs postes de livraisons alimentent un même bâtiment alors :</Typography>
                                        <Typography style={{color: 'white'}}>- soit la totalité des postes est réhabilitée pour être éligible pour la surface totale chauffée ;</Typography>
                                        <Typography style={{color: 'white'}}>- soit, chaque poste réhabilité peut être éligible à une partie de la surface du bâtiment, en
                                            appliquant un prorata en fonction de la puissance de chaque poste de livraison par rapport à la puissance totale raccordée.</Typography>
                                    </>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber addonAfter="m²" min={0} decimalSeparator=',' />
                            </Form.Item>

                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>

    );
};
