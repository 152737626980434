import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function SurfaceHabitableREF14E({ label, name, disabled, hidden }: { label: string, name: NamePath, disabled: boolean, hidden?: boolean}) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner la surface habitable"
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">S &lt; 70</Radio.Button>
            <Radio.Button value="1">70 ≤ S &lt; 90</Radio.Button>
            <Radio.Button value="2">90 ≤ S &lt; 110</Radio.Button>
            <Radio.Button value="3">110 ≤ S &lt; 130</Radio.Button>
            <Radio.Button value="4">130 ≤ S</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
