import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import ChaleurRenouvelableREF32 from '@/features/new-project/operations/forms/components/common/ChaleurRenouvelableREF32';

const { Paragraph } = Typography

export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};

const { Title } = Typography

export const Resch1084 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "RES-CH-108"
    const libelle_fost = "Récupération de chaleur fatale pour valorisation vers un réseau de chaleur ou vers un tiers (France métropolitaine)"
    const denomination_fost = "Mise en place d’un système de récupération de chaleur fatale valorisée vers un réseau de chaleur ou un site tiers"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-108%20vA53-4%20%C3%A0%20compter%20du%2001-10-2023.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Est considéré comme un réseau de chaleur, un réseau alimentant des bâtiments appartenant à
            <Typography.Text strong style={{ color: primary_color }}> au moins deux abonnés distincts.</Typography.Text>
        </Paragraph>
        <Paragraph>La chaleur fatale (ou aussi perdue) est une chaleur générée par une installation existante qui
            <Typography.Text strong style={{ color: primary_color }}> n’en constitue pas une des finalités premières, et qui n’est pas récupérée.</Typography.Text>
        </Paragraph>
        <Paragraph>La chaleur nette valorisée est
            <Typography.Text strong style={{ color: primary_color }}> strictement inférieure à 12 GWh/an.</Typography.Text>
        </Paragraph>
        <Typography>La mise en place du système de récupération de chaleur fatale fait l'objet d'une
            <Typography.Text strong style={{ color: primary_color }}> étude préalable de dimensionnement </Typography.Text>
            établie, datée et signée par un professionnel ou un bureau d'étude et précisant les besoins de chaleur à récupérer.
        </Typography>

    </>
    const facture = <>
        <Paragraph>La preuve de réalisation de l'opération est le
            <Typography.Text strong style={{ color: primary_color }}> contrat de fourniture de chaleur entre le fournisseur de la
                chaleur et l'utilisateur de la chaleur récupérée. </Typography.Text>
            Il mentionne la mise en place d'un système de récupération de chaleur fatale et le type de chaleur fatale
            (incinération, chaleur industrielle, chaleur eaux grises, etc.). La date d'achèvement de l'opération est la date de
            prise d'effet du contrat de fourniture de chaleur.
        </Paragraph>
        <Typography>Le document de preuve de réalisation de l'opération produit à l'appui de la demande de certificats
            d'économies d'énergie comporte les extraits d'intérêt du contrat de fourniture de chaleur mentionnant :
            <Typography style={{ paddingLeft: '16px' }}>- les parties signataires et leurs signatures (nom ou raison sociale, adresse et représentants)</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la date de signature du contrat et celle de sa prise d'effet</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- l'adresse du tiers utilisant la chaleur fatale ou celle du gestionnaire du réseau de chaleur</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la quantité de chaleur fatale nette fournie par le procédé de récupération</Typography>
        </Typography>

    </>
    const justificatifs = <>
        <Typography>Le document justificatif spécifique à l'opération est
            <Typography.Text strong style={{ color: primary_color }}> l'étude de dimensionnement préalable </Typography.Text>
            du système de récupération de chaleur fatale.
        </Typography>
    </>
    const dimensionnement = <>
        <Typography>L'étude de dimensionnement, remise au bénéficiaire à l’achèvement des travaux, comporte :
            <Typography style={{ paddingLeft: '16px' }}>- la raison sociale et l'adresse du bénéficiaire</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- l'adresse du chantier si différente de l'adresse du bénéficiaire</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la nature de la chaleur fatale récupérée et la nature du besoin de chaleur à valoriser,
                accompagnée d'une description des installations en place et des équipements nécessaires à la récupération et la valorisation de la chaleur</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- dans le cas d'un raccordement à un réseau de chaleur, l'étude permet d'identifier le réseau de
                chaleur concerné, décrit par la zone géographique – quartier(s), ville(s) –, qu'il dessert et fournit la liste des bâtiments concernés,
                raccordés au réseau à la date d'achèvement de l'opération ou prévus dans un délai de 3 ans après cette date. Ce document indique leur
                date prévisionnelle de raccordement</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- dans le cas d’une valorisation vers un site tiers, l’étude permet d’identifier les
                installations raccordées à la date d’achèvement de l’opération ou prévus dans un délai de 3 ans après cette date. Ce document
                indique leur date prévisionnelle de raccordement</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la quantité de chaleur nette valorisée par l'opération (Q en kWh/an, déduction faite des pertes
                liées au réseau et à ses équipements)</Typography>
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Preuve de réalisation' },
        { key: '3', label: 'Pièces justificatives' },
        { key: '4', label: "Contenu de l'étude de dimensionnement" },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
        4: <>{dimensionnement}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Installation plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Installation2Ans"]}
                label="La chaleur fatale est-elle générée par une installation existante depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "ValoriseeVers"], undefined)
                    form.setFieldValue(['items', field.name, "REF-32"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si installation non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Installation2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur une installation existante depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si installation OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Installation2Ans"]) === "Oui" ? (
                        <>
                            {/* ValoriseeVers  */}
                            <Form.Item
                                name={[field.name, "ValoriseeVers"]}
                                label="La chaleur fatale est valorisée vers un : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value="ReseauDeChaleur">Réseau de chaleur</Radio.Button>
                                    <Radio.Button value="SiteTiers">Site tiers</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {/* Chaleur renouvelable nette */}
                            <ChaleurRenouvelableREF32
                                name={[field.name, "REF-32"]}
                                label="Quantité de chaleur nette utilisée ou valorisée dans le réseau de chaleur ou sur le site tiers (kWh/an) : "
                            />
                            {/* Alerte Chaleur >= 12GWh/an */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-32"]) >= 12000000 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si la chaleur nette valorisée est strictement inférieure à 12 GWh/an."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>

    );
};
