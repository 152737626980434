import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function TypeInstallationREF81I({ name, label, moduleeDisabled }: { name: NamePath, label: string, moduleeDisabled?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le type d'installation"
            },]}>
        <Select
            placeholder="Sélectionnez le type d'installation"
            style={{ maxWidth: '250px', height: 'auto' }}
        >
            <Option value="0">VMC double flux autogérable</Option>
            <Option disabled={moduleeDisabled} value="1">VMC double flux modulée</Option>
        </Select>
    </Form.Item >
}