import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function SecteurREF22AH({label, name}: {label: string ,name: NamePath}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner le secteur"
                    },]}>
                <Select
                    placeholder="Sélectionnez le secteur"
                    style={{width: '200px'}}
                  >
                    <Option value="0">Bureaux</Option>
                    <Option value="1">Enseignement</Option>
                    <Option value="2">Restauration</Option>
                    <Option value="3">Autres locaux</Option>
                  </Select>
            </Form.Item >
}