import { Form } from 'antd';

export const useCustomFormValidations = () => {
    const form = Form.useFormInstance();

    const requeiredFieldAction = (
        fieldName: string,
        errorMessage: string,
        scrollToField: boolean = false
    ) => {
        form.setFields([
            {
                name: fieldName,
                errors: [errorMessage],
            },
        ]);
        if (scrollToField)
            form.scrollToField(fieldName, { behavior: 'smooth' });
        return false;
    };

    return {
        requeiredFieldAction,
    };
};
