import { useCallback, useState } from 'react';

import newProjectServices from '@/services/new-project/newProjectServices';

import { FicheRevision } from './types';

export const useGetFicheRevision = () => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const getFicheRevision = useCallback(
        async (
            ficheId: string,
            quotationSignDate: number
        ): Promise<FicheRevision | void | null> => {
            try {
                setIsPending(true);
                return await newProjectServices
                    .getFicheRevision(ficheId, quotationSignDate)
                    .then((response) => {
                        setIsPending(true);
                        return response;
                    });
            } catch (error) {
                return null;
            } finally {
                setIsPending(false);
            }
        },
        []
    );
    return {
        isPending,
        getFicheRevision,
    };
};
