import React from 'react';

import { Footer } from '@/utils/constants';
import footerImgDs from '@assets/img/footer-img-ds.png';
import footerImgMb from '@assets/img/footer-img-mb.png';
import ceeCertif from '@assets/img/logo-cee-cropped.svg';
import { Col, Image, Row } from 'antd';
import { Link } from 'react-router-dom';

import './footer.scss';

export const BaseFooter: React.FC = React.memo(() => {
    return (
        <Footer className="footer__base">
            <Row align={'bottom'}>
                <Col span={11}>
                    <picture className="footer__image">
                        <source
                            srcSet={footerImgDs}
                            media="(min-width: 992px)"
                        />
                        <img src={footerImgMb} alt="footer" />
                    </picture>
                </Col>
                <Col span={13}>
                    <Row justify="space-between" align="bottom">
                        <Col className="footer__link">
                            <Link to="/privacy-policy">
                                Politique de confidentialité
                            </Link>
                        </Col>
                        <Col className="footer__link">
                            <Link to="/contact">Contact</Link>
                        </Col>
                        <Col className="footer__link">
                            <Link
                                to="https://www.cameo-energy.com/"
                                target="blank"
                            >
                                www.cameo-energy.com
                            </Link>
                        </Col>
                        <Col className="footer__cee-logo">
                            <Image preview={false} src={ceeCertif} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Footer>
    );
});
