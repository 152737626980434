import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
function ApplicationMoteurREF40G({ label, name }: { label: string, name: NamePath }) {
    return <Form.Item
        name={name}
        label={label}
        rules={[
            {
                required: true,
                message: "Veuillez renseigner l'application moteur"
            },
        ]}
    >
        <Select
            placeholder="Sélectionnez l'application"
            allowClear
            style={{width: '250px'}}
        >
            <Option value="0">Pompage</Option>
            <Option value="1">Ventilation</Option>
            <Option value="2">Compresseur d'air</Option>
            <Option value="3">Compresseur frigorifique</Option>
            <Option value="4">Autres applications</Option>
        </Select>
    </Form.Item>
}
export default ApplicationMoteurREF40G;
