import { VolumeType } from '@/features/new-project/types';
import { TInscriptionForm } from '@/store/slices/new-project/types';

export const createRegisterRequestJson = (
    inscription: TInscriptionForm,
    operations: any[],
    totalOperations: number,
    totalPrime: TotalPrime,
    volumes: VolumeType[],
    affiliation?: string
) => {
    const json = {} as any;

    json.inscription = inscription;

    const simulations = operations
        .filter((item) => {
            const volume = volumes?.find(
                (v) => v.ficheIndex === item.fiche_index
            );
            // Filter out items where volume.total is null, undefined, or 0
            return (
                volume &&
                volume.total !== null &&
                volume.total !== undefined &&
                volume.total !== 0
            );
        })
        .map((item) => {
            const volume = volumes?.find(
                (v) => v.ficheIndex === item.fiche_index
            );
            if (volume) {
                item['volume_cee_estimated'] = volume.total;
                item['prime_cee_estimated'] = {
                    fixPrime: volume.totalprimemedium ?? 0,
                    minPrime: volume.totalprimemin ?? 0,
                    maxPrime: volume.totalprimemax ?? 0,
                };
            }
            return item;
        });

    json.simulations = simulations;

    json.totalOperations = totalOperations;

    json.totalPrime = totalPrime;

    json.affiliation = affiliation;

    json.estimateVolumes = volumes;

    return json;
};

interface TotalPrime {
    fixPrime: number;
    minPrime: number;
    maxPrime: number;
}


export enum PriceRangeType {
  PUBLIC_TOTAL,
  PUBLIC_DETAIL,
  PRIVATE_TOTAL,
  PRIVATE_DETAIL,
  PRIVATE_RESUME
}
