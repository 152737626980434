import { Text } from '@/utils';
import { currencyFormatter } from '@utils/helpers';
import { Card, Col, Space } from 'antd';

export const InstalateurBusinessProviderPrime = ({
    prime,
    isInstaller,
}: {
    prime: number;
    isInstaller: boolean;
}) => {
    return (
        <Col>
            <Card size="small">
                <Space direction="vertical" size="small">
                    <Text className="dossier__primes-volume--type">
                        {`${
                            isInstaller
                                ? 'Part installateur'
                                : "Apporteur d'affaires"
                        }`}
                    </Text>
                    <Text className="dossier__primes-volume--currency">
                        {currencyFormatter(prime, true)}
                    </Text>
                </Space>
            </Card>
        </Col>
    );
};
