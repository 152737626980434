import { Form, Select, Typography } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
function TypeLampeIREF30F({label, name}: {label: string, name: NamePath}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner le type de lampe"
                    },]}>
                <Select
                    placeholder="Sélectionnez le type de lampe"
                    style={{maxWidth: '500px',height:'auto', whiteSpace: 'normal', wordBreak: 'break-word'}}
                  >
                    <Option value="0"><Typography style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Luminaire LED, dans le cas où l’IRC est inférieur à 90</Typography></Option>
                    <Option value="1"><Typography style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Luminaire LED, dans le cas où l’IRC est supérieur ou égal à 90 avec R9 &gt; 0</Typography></Option>
                  </Select>
            </Form.Item >
}
export default TypeLampeIREF30F;