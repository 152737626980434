import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function ApplicationMoteurREF40I({label, name}: {label: string, name: NamePath}) {
  return <Form.Item
            name={name}
            label={label}
            rules={[
                {
                required: true,
                message: "Veuillez renseigner l'application moteur"
                },
            ]}
            >
            <Select
                placeholder="Sélectionnez l'application"
                allowClear
                style={{maxWidth: '300px'}}
            >
                <Option value="0">Chauffage, pompage</Option>
                <Option value="1">Ventilation, renouvellement d’air</Option>
                <Option value="2">Réfrigération</Option>
                <Option value="3">Climatisation</Option>
                <Option value="4">Autres applications</Option>
            </Select>
            </Form.Item>
}