import { Layout, Typography } from 'antd';

export const Title = Typography.Title;
export const Text = Typography.Text;
export const Paragraph = Typography.Paragraph;
export const Header = Layout.Header;
export const Footer = Layout.Footer;
export const Sidebar = Layout.Sider;

export const NOT_SPECIFIED: string = 'Non renseigné';
export const BENEFICIARY: string = 'BENEFICIARY';
export const INSTALLER: string = 'INSTALLER';
export const BUSINESS_PROVIDER: string = 'BUSINESS_PROVIDER';

export const dateFormat = 'DD/MM/YYYY';

export const HAS_ACCOUNT: string = 'HAS_ACCOUNT';
