import { useCallback } from 'react';

import dossierServices from '@/services/dossier/dossierservices';
import { useAsync } from 'react-async';

export const useGetDossierSteps = () => {
    const getDossierSteps = useCallback(async () => {
        return await dossierServices.getDossierSteps();
    }, []);

    const { isPending, isResolved, isRejected, data } = useAsync({
        promiseFn: getDossierSteps,
    });

    return { isPending, isResolved, isRejected, data };
};
