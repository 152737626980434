import { useCallback } from 'react';

import { TCurrentDossier } from '@/features/dossier/types';
import dossierServices from '@/services/dossier/dossierservices';
import { setCurrentDossierDetails } from '@/store/slices/dossier/dossierSlice';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

export const useGetDossierDetails = () => {
    const dispatch = useDispatch();
    const getDossier = useCallback(async (param: number[]) => {
        const [id] = param;
        return await dossierServices.getDossierDetails(id);
    }, []);

    const {
        isPending,
        isResolved,
        isRejected,
        run: getDossierDetails,
    } = useAsync({
        deferFn: (param) => getDossier(param),
        onResolve(dossier: TCurrentDossier) {
            dispatch(setCurrentDossierDetails(dossier));
        },
    });

    return { isPending, isResolved, isRejected, getDossierDetails };
};
