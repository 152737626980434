import { Auth } from 'aws-amplify';
import { useAsync } from 'react-async';

export const useChangePassword = (oldPassword: string, newPassword: string) => {
    const {
        isPending,
        isResolved,
        isRejected,
        run: changePassword,
    } = useAsync({
        deferFn: () =>
            Auth.changePassword(
                async () => await Auth.currentAuthenticatedUser(),
                oldPassword,
                newPassword
            ),
    });

    return { isPending, isResolved, isRejected, changePassword };
};
