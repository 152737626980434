import { useCallback } from 'react';

import { TNextStepDescription } from '@/features/dossier/types';
import dossierServices from '@/services/dossier/dossierservices';
import { RootState } from '@/store/store';
import { useAsync } from 'react-async';
import { useSelector } from 'react-redux';

import { getUserType } from './utils';

export const useGetNextStepDescription = (dossierStep: string) => {
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );
    const userType = getUserType(heliosUser?.partner) || null;

    const getDescription = useCallback(async () => {
        return (await dossierServices.getDossierStepDescription({
            userType,
            dossierStep,
        })) as TNextStepDescription;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { isPending, isResolved, isRejected, data } = useAsync({
        promiseFn: getDescription,
    });

    return { isPending, isResolved, isRejected, data };
};
