import { TPartner } from '@/features/profile/types';
import { BENEFICIARY, BUSINESS_PROVIDER, INSTALLER } from '@/utils/constants';

export const getUserType = (partner: TPartner | null | undefined) => {
    if (!partner) return null;

    if (partner.isBeneficiary) {
        return BENEFICIARY;
    } else if (partner.isInstaller) {
        return INSTALLER;
    } else if (partner.isBusinessProvider) {
        return BUSINESS_PROVIDER;
    }

    return null;
};
