import { useCallback, useState } from 'react';

import {
    InputObject,
    VolumesResult,
    variableReference,
} from '@/features/new-project/types';
import { formFieldsFormat } from '@/features/new-project/utils';
import newProjectServices from '@/services/new-project/newProjectServices';
import { setOperationsVolumes } from '@/store/slices/new-project/operationsSlice';
import { useDispatch } from 'react-redux';

interface ProjectCalculateParams {
    items: Record<string, any>[];
    signatureQuotationDate: number;
}

export const useProjectCalculate = () => {
    const dispatch = useDispatch();
    const [isPending, setIsPending] = useState<boolean>(false);

    const calculateProjectValume = useCallback(
        async ({
            items,
            signatureQuotationDate,
        }: ProjectCalculateParams): Promise<VolumesResult | null> => {
            const valideFieldsFormat = items.reduce(
                (acc: variableReference[][], element: InputObject) => {
                    if (element && element.fiche) {
                        const operationValues: variableReference[] =
                            formFieldsFormat(element);
                        acc.push(operationValues);
                    }
                    return acc;
                },
                []
            );

            const data = {
                variables: valideFieldsFormat,
                signatureQuotationDate,
            };

            try {
                setIsPending(true);
                return await newProjectServices
                    .calculate(data)
                    .then((response) => {
                        setIsPending(false);
                        dispatch(setOperationsVolumes(response));
                        return response;
                    });
            } catch (error) {
                return null;
            } finally {
                setIsPending(false);
            }
        },
        [dispatch]
    );

    return {
        isPending,
        calculateProjectValume,
    };
};
