import React, { useEffect } from 'react';

import { ContentNotFound } from '@/components';
import { setIsAppError } from '@/store/slices';
import { RootState } from '@/store/store';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DashboardFooter } from './footer/Footer';
import { DashboardHeader } from './header/Header';
import { SideBar } from './sidebar/SideBar';
import './styles.scss';

const { Content } = Layout;

export const DashboardLayout: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAppError } = useSelector((state: RootState) => state.appReducer);

    useEffect(() => {
        return () => {
            dispatch(setIsAppError(false));
        };
    }, [navigate, dispatch]);

    return (
        <div className="container">
            <DashboardHeader />
            <Layout className="dashboard__layout" hasSider>
                <SideBar />
                <Layout>
                    {isAppError ? (
                        <ContentNotFound />
                    ) : (
                        <Content style={{ padding: 15 }}>{children}</Content>
                    )}

                    <DashboardFooter />
                </Layout>
            </Layout>
        </div>
    );
};
