import { StepItem } from '../types';

export function createStep(
    title: React.ReactNode,
    description: React.ReactNode
): StepItem {
    return {
        title,
        description,
    } as StepItem;
}
