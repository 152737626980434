// https://react-icons.github.io/react-icons/icons/bs/
import React from 'react';

import { Text } from '@/utils';
import { Card, Space, Tooltip, Typography, theme } from 'antd';
import * as ReactBootstrapIcons from 'react-icons/bs';

import { ConcernesProjectProps, IconType } from '../types';

const { useToken } = theme;

const CreateIcon = ({
    Icon,
}: {
    Icon: string | undefined;
}): JSX.Element | null => {
    const iconComponents: IconType = ReactBootstrapIcons;
    if (Icon && typeof Icon === 'string') {
        const IconComponent = iconComponents[Icon];
        return <IconComponent size={20} />;
    }

    return null;
};

export const LeveItem: React.FC<ConcernesProjectProps> = ({
    value,
    title,
    description,
    icon,
    onClick,
    isSelected,
}) => {
    const iconName = icon ? icon : undefined;
    const { token } = useToken();
    return (
        <Card
            onClick={() => onClick && onClick()}
            style={{
                minWidth: '250px',
                maxWidth: '100%',
                cursor: 'pointer',
                background: isSelected ? token.colorPrimary : 'none',
                border: isSelected ? 'none' : `1px solid ${token.colorPrimary}`,
                color: isSelected ? '#FFF' : token.colorPrimary,
            }}
        >
            <Space key={value} size="small">
                {icon && <CreateIcon Icon={iconName} />}
                <Space direction="vertical">
                    <Text
                        strong
                        style={{
                            color: isSelected ? '#FFF' : token.colorPrimary,
                        }}
                    >
                        {title}
                    </Text>
                    {description && (
                        <Text
                            style={{
                                width: '250px',
                                color: isSelected ? '#FFF' : token.colorPrimary,
                            }}
                            ellipsis
                        >
                            {description}
                        </Text>
                    )}
                </Space>
            </Space>
        </Card>
    );
};

export const FicheItem: React.FC<ConcernesProjectProps> = ({
    value,
    title,
    description,
    icon,
    onClick,
    isSelected,
}) => {
    const iconName = icon ? icon : undefined;
    const { token } = useToken();

    const largeur_fost = "450px"
    const hauteur_fost = '50px'
    const largeur_libelle = "400px"

    return (
        <Card
            onClick={() => onClick && onClick()}
            style={{
                minWidth: largeur_fost,
                maxWidth: '100%',
                height: hauteur_fost,
                cursor: 'pointer',
                background: isSelected ? token.colorPrimary : 'none',
                border: isSelected ? 'none' : `1px solid ${token.colorPrimary}`,
                color: isSelected ? '#FFF' : token.colorPrimary,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Space
                key={value}
                direction="horizontal"
                align="center"
                style={{ alignItems: 'left' }}
            >
                {icon && <CreateIcon Icon={iconName} />}
                <Typography>
                    <Tooltip
                        placement="bottom"
                        title={description}
                        color={token.colorPrimary}
                    >
                        <Typography style={{ lineHeight: 1 }}>
                            <Typography.Text
                                strong
                                style={{
                                    width: largeur_libelle,
                                    color: isSelected
                                        ? '#FFF'
                                        : token.colorPrimary,
                                }}
                                ellipsis
                            >
                                {description}
                            </Typography.Text>
                        </Typography>
                    </Tooltip>
                    <Typography style={{ lineHeight: 0.9 }}>
                        <Typography.Text
                            style={{
                                fontSize: '11px',
                                color: isSelected ? '#FFF' : token.colorPrimary,
                            }}
                        >
                            {title}
                        </Typography.Text>
                    </Typography>
                </Typography>
            </Space>
        </Card>
    );
};
