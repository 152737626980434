import { Form, Alert, FormListFieldData, InputNumber, Typography, Divider, Button, Flex } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22AH from '../refs/SecteurREF22AH';
import TypeInstallationREF81T from '../refs/TypeInstallationREF81T';
import SurfaceREF10 from '../refs/SurfaceREF10';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Batth1252S = ({ field }: { field: FormListFieldData }) => {

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Infos FOST */
    const code_fost = "BAT-TH-125"
    const libelle_fost = "Ventilation mécanique simple flux à débit d’air constant ou modulé"
    const denomination_fost = "Mise en place d’une ventilation mécanique simple flux à débit d’air constant ou modulée"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-125%20v%20A32-2%20%C3%A0%20compter%20du%2001-04-2020.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/*Secteur*/}
            <SecteurREF22AH
                label="Secteur :"
                name={[field.name, 'REF-22-AH']}
            />
            {/* Type d'installation */}
            <TypeInstallationREF81T
                label="Type de ventilation :"
                name={[field.name, 'REF-81-T']}
                tooltip='L’installation d’une ventilation mécanique hygroréglable dans le secteur de l’hôtellerie, quelle que soit son type, est 
                                assimilée à une ventilation mécanique simple flux modulée proportionnelle. Dans le cas d’une ventilation simple flux modulée 
                                (proportionnelle ou à détection de présence), le système bénéficie d’un avis technique de la Commission chargée de formuler des 
                                Avis Techniques (CCFAT), en cours de validité à la date d’engagement de l’opération ou équivalent.'
            />
            {/* Surface */}
            <SurfaceREF10
                label="Surface ventilée (m²) :"
                name={[field.name, 'REF-10']}
                tooltip="On entend par surface ventilée, la surface totale du bâtiment couverte par le système de ventilation mécanique simple flux 
                                à débit d’air constant ou modulée"
                
            />
            {/* P élec absorbée */}
            <Form.Item
                name={[field.name, "PElecAbsorbee"]}
                label="Puissance électrique absorbée du caisson de ventilation au débit nominal (W/(m³/h)) :"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <InputNumber addonAfter="W/(m³/h)" decimalSeparator=',' style={{maxWidth : '200px'}} controls={false}/>
            </Form.Item>
            {/* Alerte si P elec > 0,3 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "PElecAbsorbee"]) > 0.3 ? (
                        <Alert
                            message="Cette opération n'est éligible que si Le caisson de ventilation a une puissance électrique absorbée inférieure ou égale à 0,3 W/(m3/h) au débit nominal."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>


        </>
    );
};
