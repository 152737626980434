import { useCallback } from 'react';

import { Auth } from 'aws-amplify';
import { useAsync } from 'react-async';

export const useSendVerificationCode = () => {
    const onForgetPassword = useCallback(async (params: string[]) => {
        const [userName] = params;
        await Auth.forgotPassword(userName);
    }, []);

    const {
        isPending,
        isResolved,
        isRejected,
        run: changePassword,
    } = useAsync({
        deferFn: (param) => onForgetPassword(param),
    });

    return { isPending, isResolved, isRejected, changePassword };
};
