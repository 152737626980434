import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Flex, Button, Collapse, Card, Row, Col } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceChaudiereREF24B from '@/features/new-project/operations/forms/components/common/PuissanceChaudiereREF24B';
import UsageREF15A from '@/features/new-project/operations/forms/components/common/UsageREF15A';
import RREF142 from '@/features/new-project/operations/forms/components/common/RREF142';
import CopREF12L from '@/features/new-project/operations/forms/components/common/CopREF12L';
import EfficaciteEnergetiqueREF140C from '@/features/new-project/operations/forms/components/common/EfficaciteEnergetiqueREF140C';
import SecteurREF22K from '@/features/new-project/operations/forms/components/common/SecteurREF22K';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

const { Title } = Typography

export const Batth1412 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-TH-141"
    const libelle_fost = "Pompe à chaleur à moteur gaz de type air/eau"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) à moteur gaz de type air/eau"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-141%20v%20A28-2%20%C3%A0%20compter%20du%2001-01-2019.pdf"

    /* Textes explicatifs */
    const eligibilite = <>
        <Row gutter={8}>
            <Col span={12}>
                <Card size='small' title="Pour une PAC de puissance thermique nominale ≤ 400 kW :">
                    <Typography>L’
                        <Typography.Text strong style={{ color: primary_color }}>efficacité énergétique saisonnière (Etas) </Typography.Text>
                        selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 est
                        <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à :</Typography.Text>
                        <Typography style={{ paddingLeft: '16px' }}>-
                            <Typography.Text strong style={{ color: primary_color }}> 111% </Typography.Text>
                            pour les PAC
                            <Typography.Text strong style={{ color: primary_color }}> moyenne et haute température </Typography.Text>
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>-
                            <Typography.Text strong style={{ color: primary_color }}> 126% </Typography.Text>
                            pour les PAC
                            <Typography.Text strong style={{ color: primary_color }}> basse température </Typography.Text>
                        </Typography>
                    </Typography>
                </Card>
            </Col>
            <Col span={12}>
                <Card size='small' title="Pour une PAC de puissance nominale &gt; 400 kW :">
                    <Paragraph>Le
                        <Typography.Text strong style={{ color: primary_color }}> coefficient de performance (COP) </Typography.Text>
                        est mesuré selon la norme EN 12309 pour des températures d’entrée et de sortie
                        <Typography.Text strong style={{ color: primary_color }}> égales à :</Typography.Text>
                        <Typography style={{ paddingLeft: '16px' }}>- PAC air/eau :
                            <Typography.Text strong style={{ color: primary_color }}> 7°C (A) / 35 °C (E) </Typography.Text>

                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- PAC eau/eau ou PAC eau glycolée/eau
                            <Typography.Text strong style={{ color: primary_color }}> 10°C (E) / 35°C (E) </Typography.Text>
                        </Typography>
                    </Paragraph>
                    <Typography>Le COP est
                        <Typography.Text strong style={{ color: primary_color }}> supérieur ou égal à 1,3. </Typography.Text>
                    </Typography>
                </Card>
            </Col>
        </Row>
    </>
    const facture = <>
        <Row gutter={8}>
            <Col span={12}>
                <Card size='small' title="Pour une PAC de puissance thermique nominale ≤ 400 kW :">
                    <Typography>La facture mentionne :
                        <Typography style={{ paddingLeft: '16px' }}>- la
                            <Typography.Text strong style={{ color: primary_color }}> mise en place d’une pompe à chaleur à absorption de type air/eau ou eau/eau </Typography.Text>
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- la
                            <Typography.Text strong style={{ color: primary_color }}> puissance thermique </Typography.Text>
                            de la pompe à chaleur
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- le
                            <Typography.Text strong style={{ color: primary_color }}> type </Typography.Text>
                            de pompe à chaleur (basse, moyenne ou haute température)
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- l'
                            <Typography.Text strong style={{ color: primary_color }}>efficacité énergétique saisonnière (Etas) </Typography.Text>
                            de la pompe à chaleur
                        </Typography>
                    </Typography>
                </Card>
            </Col>
            <Col span={12}>
                <Card size='small' title="Pour une PAC de puissance nominale &gt; 400 kW :">
                    <Typography>La facture mentionne :
                        <Typography style={{ paddingLeft: '16px' }}>- la
                            <Typography.Text strong style={{ color: primary_color }}> mise en place d’une pompe à chaleur à absorption de type air/eau ou eau/eau </Typography.Text>
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- la
                            <Typography.Text strong style={{ color: primary_color }}> puissance thermique </Typography.Text>
                            de la pompe à chaleur
                        </Typography>
                        <Typography style={{ paddingLeft: '16px' }}>- le
                            <Typography.Text strong style={{ color: primary_color }}> COP </Typography.Text>
                            explicitement mesuré selon les conditions de la norme EN 12309
                        </Typography>
                    </Typography>
                </Card>
            </Col>
        </Row>

        <Paragraph>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et
            référence et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l'Espace
            économique européen et accrédité selon la norme NF EN 45011 par le Comité français d'accréditation (COFRAC)
            ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans le cadre de
            European co-operation for Accreditation (EA), coordination européenne des organismes d'accréditation.
        </Paragraph>
        <Typography>Ce document indique :
            <Typography style={{ paddingLeft: '16px' }}>- que l’équipement de marque et référence mis en place est une pompe à chaleur à absorption de type
                air/eau ou eau/eau et, pour les opérations engagées à partir du 26/09/2015, la puissance thermique de la pompe à chaleur, et pour les PAC
                de puissance ≤ 400 kW, le type de pompe à chaleur (basse, moyenne ou haute température) ;</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- et la performance énergétique de l’équipement installé : selon la date d’engagement de l’opération et la
                puissance thermique de la pompe à chaleur, le COP explicitement mesuré selon les conditions de la norme EN 12309, ou l’Etas.</Typography>
        </Typography>
    </>
    const incompatibilites = <>
        <Paragraph>Lorsque :
            <Typography style={{ paddingLeft: '16px' }}>- la chaufferie après rénovation comporte également des équipements
                <Typography.Text strong style={{ color: primary_color }}> relevant de la fiche BAT-TH-102 </Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}>et</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la puissance thermique de la (ou des) PAC installée(s) est <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à 40 % </Typography.Text>de la puissance de la nouvelle chaufferie</Typography>
        </Paragraph>
        <Typography>Alors <Typography.Text strong style={{ color: primary_color }}>seule la fiche BAT-TH-141 </Typography.Text>donne lieu à la délivrance de CEE.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Tertiaire de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est réalisée sur une bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "Pinstallee"], undefined)
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-24-B"], undefined)
                        form.setFieldValue(['items', field.name, "REF-15-A"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                        form.setFieldValue(['items', field.name, "REF-22-K"], undefined)
                        form.setFieldValue(['items', field.name, "REF-140-C"], undefined)
                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                        form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Tertiaire + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Tertiaire OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            {/* Surface */}
                            <SurfaceREF10
                                label="Surface totale chauffée du bâtiment :"
                                name={[field.name, 'REF-10']}
                                tooltip="Surface totale chauffée du bâtiment"
                            />

                            {/* Usage */}
                            <UsageREF15A
                                label="Usage de la pompe à chaleur :"
                                name={[field.name, 'REF-15-A']}
                            />

                            {/* Secteur */}
                            <SecteurREF22K
                                label="Secteur d'activité :"
                                name={[field.name, 'REF-22-K']}
                            />

                            {/* Puissance PAC */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align="end">
                                    <Form.Item
                                        name={[field.name, "Pinstallee"]}
                                        label="Puissance de la PAC installée (kW) : "
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                    >
                                        <InputNumber
                                            addonAfter="kW"
                                            min='0'
                                            controls={false}
                                            style={{ width: '150px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && getFieldValue(['items', field.name, "Pinstallee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "Pinstallee"]) > 400) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "1" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "REF-24-B"], value: undefined }])
                                                    form.setFields([{ name: ["items", field.name, "TemperaturePAC"], value: undefined }])
                                                }
                                                form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                                                form.setFieldValue(['items', field.name, "REF-142"], undefined)
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    <PuissanceChaudiereREF24B
                                        label=""
                                        name={[field.name, 'REF-24-B']}
                                        disabled={true}
                                        onChange={
                                            () => {
                                                form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                                                form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                form.setFieldValue(['items', field.name, "REF-140-C"], undefined)
                                                form.setFieldValue(['items', field.name, "COP"], undefined)
                                                form.setFieldValue(['items', field.name, "REF-142"], undefined)
                                            }
                                        }
                                    />
                                </Space>
                            </Form.Item>
                            {/* Temperature et Etas si Puissance PAC <= 400 kW */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-24-B"]) === '0' ? (
                                        <>
                                            {/*Temperature*/}
                                            <Form.Item
                                                name={[field.name, "TemperaturePAC"]}
                                                label="Quel est le type de la PAC installée ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {
                                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                    }}>
                                                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                                                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* ETAS variable continue */}
                                            <Form.Item style={{ margin: "0px" }}>
                                                <Space align="end" size="middle">
                                                    <Form.Item
                                                        label="Etas (%) :"
                                                        name={[field.name, 'Etas']}
                                                        rules={[
                                                            { required: true, message: "Veuillez renseigner ce champ" },
                                                        ]}
                                                        tooltip={{
                                                            title: "L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                            color: tooltip_color,
                                                        }}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            addonAfter="%"
                                                            controls={false}
                                                            style={{ width: '100px' }}
                                                            onChange={() => {
                                                                if (getFieldValue(['items', field.name, "Etas"]) >= 111 && getFieldValue(['items', field.name, "Etas"]) < 126) { form.setFields([{ name: ["items", field.name, "REF-140-C"], value: "0" }]) }
                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 126) { form.setFields([{ name: ["items", field.name, "REF-140-C"], value: "1" }]) }
                                                                else if (getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "REF-140-C"], value: undefined }]) }

                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                    </Form.Item>
                                                    {/* ETAS */}
                                                    <EfficaciteEnergetiqueREF140C
                                                        label=""
                                                        name={[field.name, 'REF-140-C']}
                                                        disabled={true}
                                                    />
                                                </Space>
                                            </Form.Item>
                                            {/* Alerte si basse température et ETAS inférieur à 126 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                                                        <Alert
                                                            message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Alerte si moyenne/haute température et ETAS inférieur à 111 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                                                        <Alert
                                                            message="Pour une PAC moyenne/haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* COP si Puissance PAC > 400 kW */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "REF-24-B"]) === "1" ? (
                                        <>
                                            <Form.Item style={{ margin: "0px" }}>
                                                <Space align='end'>
                                                    {/*COP variable continue*/}
                                                    <Form.Item
                                                        label="Coefficient de performance (COP) : "
                                                        name={[field.name, 'COP']}
                                                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                                        tooltip={{
                                                            title: <><Typography style={{ color: 'white' }}>Le coefficient de performance (COP) est mesuré selon la norme EN 12309 pour des températures d’entrée et de
                                                                sortie égales à :</Typography>
                                                                <Typography style={{ color: 'white', paddingLeft: '16px' }}>- PAC air/eau : 7°C (A) / 35 °C (E) ;</Typography>
                                                                <Typography style={{ color: 'white', paddingLeft: '16px' }}>- PAC eau/eau ou PAC eau glycolée/eau : 10°C (E) / 35°C (E).</Typography>
                                                            </>,
                                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                            color: tooltip_color,
                                                        }}
                                                    >
                                                        <InputNumber
                                                            addonAfter="%"
                                                            style={{ width: '100px' }}
                                                            controls={false}
                                                            min={0}
                                                            decimalSeparator=','
                                                            onChange={() => {
                                                                if (getFieldValue(['items', field.name, "COP"]) >= 1.3 && getFieldValue(['items', field.name, "COP"]) < 1.6) { form.setFields([{ name: ["items", field.name, "REF-12-L"], value: "0" }]) }
                                                                else if (getFieldValue(['items', field.name, "COP"]) >= 1.6) { form.setFields([{ name: ["items", field.name, "REF-12-L"], value: "1" }]) }
                                                                else if (getFieldValue(['items', field.name, "COP"]) < 1.3) { form.setFields([{ name: ["items", field.name, "REF-12-L"], value: undefined }]) }

                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                    </Form.Item>

                                                    {/* COP */}
                                                    <CopREF12L
                                                        name={[field.name, 'REF-12-L']}
                                                        label=""
                                                        disabled={true}
                                                    />
                                                </Space>
                                            </Form.Item>
                                            {/* Alerte si COP > 1,3 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "COP"]) < 1.3 ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si le COP est supérieur ou égal à 1,3."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Puissance totale chaufferie */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align='end'>
                                    <Form.Item
                                        name={[field.name, "Pchaufferie"]}
                                        label="Puissance totale de la nouvelle chaufferie : "
                                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                        tooltip={{
                                            title: <>La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                        extra="Puissance de la chaufferie après travaux"
                                        style={{ margin: "0px" }}
                                    >
                                        <InputNumber
                                            addonAfter="kW"
                                            style={{ width: '150px' }}
                                            controls={false}
                                            min='0'
                                            decimalSeparator=','
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Pinstallee"]) >= (0.4 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) >= getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Pinstallee"]) < (0.4 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) > getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (getFieldValue(['items', field.name, "Pinstallee"]) / getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                                                else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    {/* R */}
                                    <RREF142
                                        label="Facteur R :"
                                        name={[field.name, 'REF-142']}
                                        disabled={true}
                                    />
                                </Space>
                            </Form.Item>
                            {/* Alerte si Pchaufferie < Pinstallée */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                                        <Alert
                                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
