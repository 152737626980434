import { useState } from 'react';

import { RootState } from '@/store/store';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useGuardRoute = () => {
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );

    const guardRoute = async () => {
        try {
            const currentUser = await Auth.currentSession();
            if (currentUser && heliosUser?.email) {
                setIsloading(false);
                return navigate('/dashboard');
            }
            setIsloading(false);
        } catch (error) {
            setIsloading(false);
        }
    };

    return {
        isLoading,
        guardRoute,
    };
};
