import newProjectServices from '@/services/new-project/newProjectServices';
import {useState, useEffect} from 'react';
import { RequestedDocuments } from './project-request-types';

interface UseProjectRequestedDocumentsReturn {
  data: RequestedDocuments | null;
  isLoading: boolean;
}

export const useProjectRequestedDocuments = (projectRequestedId: string): UseProjectRequestedDocumentsReturn => {
  const [data, setData] = useState<RequestedDocuments | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await  newProjectServices.getProjectRequestedDocuments(projectRequestedId);
        setData(result);
      } catch (error) {
        console.error('Error fetching project requested documents:', error);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectRequestedId]);

  return {
    data,
    isLoading
  };
};
