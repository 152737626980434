import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function TemperatureSourceREF67B({ label, name, style, onChange }: { label: string, name: NamePath, style?: React.CSSProperties, onChange?: () => void }) {
    return <Form.Item
        name={name}
        label={label}
        style={style}
        
        rules={
            [{
                required: true,
                message: "Veuillez renseigner la température de sortie du four."
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            onChange={onChange}
        >
            <Radio.Button value="0">600°C ≤ T ≤ 750°C</Radio.Button>
            <Radio.Button value="1">750°C &lt; T ≤ 1000°C</Radio.Button>
            <Radio.Button value="2">1000°C &lt; T ≤ 1250°C</Radio.Button>
            <Radio.Button value="3">1250°C &lt; T</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
