import { Form, InputNumber } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

export default function ProductionAnnuelleREF101({label, name, unite, tooltip}: {label: string, name:NamePath, unite: string, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[]}) { 
    return <Form.Item
                name={name}
                label={label}
                style={{color:'#002766'}}
                rules={[
                {required: true, message:"Veuillez renseigner ce champ"},
                ]}
                tooltip={{
                    title:<>{tooltip}</>,
                    icon: <InfoCircleOutlined style={{ color:'#002766' }}/>,
                    color: '#0086CA',
                    }}
            >
                <InputNumber addonAfter={unite} controls={false} decimalSeparator=','/>
            </Form.Item>
}
