import { Text } from '@/utils';
import { numberFormatter } from '@utils/helpers';
import { Card, Space } from 'antd';

export const DossierVolume = ({ volumeDossier }: { volumeDossier: number }) => {
    return (
        <Card size="small">
            <Space direction="vertical" size="small">
                <Text className="dossier__primes-volume--type">Volume CEE</Text>
                <Text className="dossier__primes-volume--currency">{`${numberFormatter(
                    volumeDossier
                )} kWhc`}</Text>
            </Space>
        </Card>
    );
};
