import { IHeliosUser } from '@/features/profile';
import BaseAPI from '@apis/base';
import { AxiosResponse } from 'axios';

class UsersServices {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }
    async getHeliosUser() {
        const response: AxiosResponse<IHeliosUser> = await this.axios
            .get(`/me`)
            .then((response) => response);
        return response.data;
    }

    async changPhonesNumber(
        phoneNumber: string | '',
        isToChangeMobNumber: boolean
    ) {
        let data = {};
        if (isToChangeMobNumber) {
            data = { mobileNumber: phoneNumber };
        } else {
            data = { phoneNumber };
        }
        const response: AxiosResponse<IHeliosUser> = await this.axios
            .put(`/me/update`, data)
            .then((response) => response);
        return response.data;
    }

    async updateCognitoAccountStatus(status: string) {
        const response: AxiosResponse<IHeliosUser> = await this.axios
            .put(`/update/cognito-account-status`, {
                cognitoAccount: status,
            })
            .then((response) => response);
        return response.data;
    }
}

const usersServices = new UsersServices();

export default usersServices;
