import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function CopREF12L({ label, name, disabled, hidden }: { label: string, name: NamePath, disabled?: boolean, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le COP"
            },]}>
        <Radio.Group 
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">1,3 ≤ COP &lt; 1,6</Radio.Button>
            <Radio.Button value="1">1,6 ≤ COP</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
