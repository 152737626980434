import { Form, FormListFieldData, Typography, Divider, Flex, Button, Alert, InputNumber } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import ModeFonctionnementREF36A from '../refs/ModeFonctionnementREF36A';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';


const { Title } = Typography

export const Indut1051S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "IND-UT-105"
    const libelle_fost = "Brûleur micro-modulant sur chaudière industrielle"
    const denomination_fost = <>Mise en place d’un brûleur micro-modulant sur une chaudière
        industrielle de puissance thermique nominale inférieure à 20 MW</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-105.pdf"



    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Puissance chaudière */}
            <Form.Item
                name={[field.name, "REF-26"]}
                label="Puissance utile nominale de la chaudière (kW) :"
                rules={[
                    {required: true, message:"Veuillez renseigner la puissance totale"},
                    ]}
                tooltip={{
                    title: <>Puissance définie par l’article R.224-20 du code de l’environnement comme « la puissance thermique maximale fixée et
                        garantie par le constructeur comme pouvant être délivrée au fluide caloporteur en marche continue ». La mise en place d’un
                        brûleur micro-modulant sur une chaudière de secours n’est pas éligible à cette opération</>,
                    icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber
                    addonAfter="kW"
                    min={0}
                    decimalSeparator=','
                />
            </Form.Item>

            {/* Alerte si Puissance >= 20000 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-26"]) >= 20000 ? (
                        <>
                            <Alert
                                message="L'opération n'est éligible que pour une chaudière de puissance nomimale inférieure à 20 MW."
                                type="error"
                                showIcon
                            />
                            {
                                () => {
                                    form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                }}
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Mode de fonctionnement */}
            <ModeFonctionnementREF36A
                name={[field.name, "REF-36-A"]}
                label="Mode de fonctionnement du site : "
            />
        </>
    );
};
