import * as constant from '@/features/new-project/constants';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray } from '@/utils';

export const createProcessUtilitiesAnswers = (
    item: any,
    values: TNewProjectForm
) => {
    const newItem: typeof item = {};
    newItem.name = item.name;
    newItem.label = item.label;
    newItem.children = [];

    if (
        values[constant.PROCESS_UTILITIES_AND_COLD_TYPES] &&
        values[constant.PROCESS_UTILITIES_AND_COLD_TYPES].length > 0
    ) {
        const processUtilitiesColdTypes =
            values[constant.PROCESS_UTILITIES_AND_COLD_TYPES];

        for (let index = 0; index < processUtilitiesColdTypes.length; index++) {
            const element = processUtilitiesColdTypes[index];
            const { children: processUtilitiesChildren } = getObjectFromArray(
                item.children,
                'name',
                element
            );

            if (values[processUtilitiesChildren[0].name]) {
                const answer: any = {};
                answer.name = processUtilitiesChildren[0].name;
                answer.label = processUtilitiesChildren[0].label.replace(
                    constant.STR_VALUE,
                    values[processUtilitiesChildren[0].name]
                );

                newItem.children.push(answer);
            }
        }
    }
    return newItem;
};
