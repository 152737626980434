import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
export default function TypeInstallationREF81G({ label, name, tooltip, onChange }: { label: string, name: NamePath, tooltip?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[], onChange?: () => void}) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner le type d'installation"
      },]}
    tooltip={{
      title: tooltip,
      icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
      color: '#0086CA',
    }}
  >
    <Select
      placeholder="Sélectionnez le type d'installation"
      style={{ maxWidth: '250px' }}
      onChange={onChange}
    >
      <Option value="0">Brûleurs auto-récupérateurs</Option>
      <Option value="1">Brûleurs régénératifs</Option>
      <Option value="2">Récupérateur de chaleur</Option>
    </Select>
  </Form.Item >
}