import { useCallback } from 'react';

import newProjectServices from '@/services/new-project/newProjectServices';
import { RootState } from '@/store/store';
import { useAsync } from 'react-async';
import { useSelector } from 'react-redux';

import { FormType } from './types';

export const useFormTypeByPartner = () => {
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );
    const getFormType = useCallback(async (): Promise<FormType> => {
        const { siret } = heliosUser?.partner! || 'default';
        return await newProjectServices.getProjectFormTypeByPartner(siret);
    }, [heliosUser?.partner]);

    const { isPending, isResolved, isRejected, data } = useAsync({
        promiseFn: getFormType,
    });

    return { isPending, isResolved, isRejected, data };
};
