import { IDossier } from '@/features/dashboard';
import { TCurrentDossier } from '@/features/dossier/types';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    details: {} as TCurrentDossier,
    currentDossierFromList: {} as IDossier,
};

export const dossierSlice = createSlice({
    name: 'currentDossierSlice',
    initialState,
    reducers: {
        setCurrentDossierDetails: (
            state,
            action: PayloadAction<TCurrentDossier>
        ) => {
            state.details = action.payload;
        },
        setCurrentDossierFromList: (state, action: PayloadAction<IDossier>) => {
            state.currentDossierFromList = action.payload;
        },
        resetCurrentDossier: (state) => {
            state = initialState;
        },
    },
});

export const {
    setCurrentDossierDetails,
    setCurrentDossierFromList,
    resetCurrentDossier,
} = dossierSlice.actions;
export default dossierSlice.reducer;
