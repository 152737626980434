import React from 'react';

import theme from '@assets/styles/theme/base';
import AppRoutes from '@routes/AppRoutes';
import * as Sentry from '@sentry/react';
import { store } from '@store/store';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

import frFR from 'antd/locale/fr_FR';

import { ContentNotFound } from './components';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ConfigProvider theme={theme} locale={frFR}>
                <Sentry.ErrorBoundary fallback={<ContentNotFound />}>
                    <AppRoutes />
                </Sentry.ErrorBoundary>
            </ConfigProvider>
        </Provider>
    );
};

export default App;
