import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Flex, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useState } from 'react';
import TypeLogementREF3 from '@/features/new-project/operations/forms/components/common/TypeLogementREF3';
import SurfaceHabitableREF14F from '@/features/new-project/operations/forms/components/common/SurfaceHabitableREF14F';
import CopREF12J from '@/features/new-project/operations/forms/components/common/CopREF12J';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography



const { Title } = Typography

export const Barth1293 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    // Lien vers le PDF de la FOST
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-129%20mod%20A27-3%20%C3%A0%20compter%20du%2001-04-2018.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>
            <Typography>- La puissance nominale de la PAC air/air est <Typography.Text strong style={{ color: primary_color }}>inférieure ou égale à 12 kW</Typography.Text></Typography>
            <Typography>- Le coefficient de performance saisonnier de la PAC air/air est <Typography.Text strong style={{ color: primary_color }}>supérieur ou égal à 3,9</Typography.Text></Typography>
        </Paragraph>
        <Typography>La puissance nominale de la PAC air/air ainsi que son coefficient de performance saisonnier sont déterminés selon le règlement n°206/2012 de la
            commission du 6 mars 2012.</Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography>- la mise en place d’une pompe à chaleur air/air</Typography>
            <Typography>-  et la puissance nominale de la pompe à chaleur</Typography>
            <Typography>- le coefficient de performance saisonnier (SCOP) de l’équipement.</Typography>
        </Paragraph>
        <Typography>À défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence et elle est complétée par
            un document issu du fabricant ou d’un organisme établi dans l'Espace économique européen et accrédité selon la norme NF EN 45011 par le Comité français
            d'accréditation (COFRAC) ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans le cadre de European
            co-operation for Accreditation (EA), coordination européenne des organismes d'accréditation.</Typography>
        <Typography>Ce document indique que l’équipement de marque et référence mis en place est une pompe à chaleur de type air/air et précise le SCOP de l’équipement
            installé ainsi que sa puissance nominale. En cas de mention d'une date de validité, ce document est considéré comme valable jusqu’à un an après sa date de
            fin de validité.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };


    return (
        <>
            <Title level={3} style={{ color: primary_color }}>BAR-TH-129 : Pompe à chaleur de type air/air</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’une pompe à chaleur (PAC) de type air/air</Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Batiment plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Batiment2Ans"]}
                label="L'opération est réalisée sur un bâtiment existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-3"], undefined)
                        form.setFieldValue(['items', field.name, "REF-14-F"], undefined)
                        form.setFieldValue(['items', field.name, "REF-12-J"], undefined)
                        form.setFieldValue(['items', field.name, "Scop"], undefined)
                        form.setFieldValue(['items', field.name, "SurfaceChauffee"], undefined)
                        form.setFieldValue(['items', field.name, "Pinstallee"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Batiment + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Batiment2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Batiment2Ans"]) === "Oui" ? (
                        <>

                            {/* Type de logement */}
                            <TypeLogementREF3
                                label="Type de logement :"
                                name={[field.name, 'REF-3']}
                            />

                            {/* Surface chauffée */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align="end">
                                    <Form.Item
                                        name={[field.name, "SurfaceChauffee"]}
                                        label="Surface chauffée par la PAC (m²) : "
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                    >
                                        <InputNumber
                                            addonAfter="m²"
                                            min='0'
                                            controls={false}
                                            style={{ width: '150px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 35 && getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 60 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 35) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 60) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "2" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "3" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "4" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) <= 130 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "5" }]) }
                                                else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) > 130) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "6" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    <SurfaceHabitableREF14F
                                        label=""
                                        name={[field.name, 'REF-14-F']}
                                        disabled={true}
                                    />
                                </Space>
                            </Form.Item>
                            {/* SCOP variable continue */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align="end" size="middle">
                                    <Form.Item
                                        label="SCOP :"
                                        name={[field.name, 'Scop']}
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                        tooltip={{
                                            title: "Le coefficient de performance saisonnier est déterminé selon le règlement n°206/2012 de la commission du 6 mars 2012.",
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                    >
                                        <InputNumber
                                            min={0}
                                            controls={false}
                                            style={{ width: '100px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Scop"]) >= 3.9 && getFieldValue(['items', field.name, "Scop"]) < 4.3) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "Scop"]) >= 4.3) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Scop"]) < 3.9) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    {/* SCOP */}
                                    <CopREF12J
                                        label=""
                                        name={[field.name, 'REF-12-J']}
                                        disabled={true}
                                    />
                                </Space>
                                {/* Alerte si SCOP < 3.9 */}
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) =>
                                        getFieldValue(['items', field.name, "Scop"]) < 3.9 ? (
                                            <Alert
                                                message="Cette opération n'est éligible que si le SCOP est supérieur ou égal à 3,9."
                                                type="error"
                                                showIcon
                                            />
                                        ) : null
                                    }
                                </Form.Item>

                            </Form.Item>
                            {/* Puissance PAC */}
                            <Form.Item
                                name={[field.name, "Pinstallee"]}
                                label="Puissance nominale de la PAC (kW) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: "La puissance nominale est déterminée selon le règlement n°206/2012 de la commission du 6 mars 2012.",
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber
                                    addonAfter="kW"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                />
                            </Form.Item>
                            {/* Alerte Pinstallee > 12 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) > 12 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si la puissance nominale est inférieure ou égale à 12 kW."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                        </>
                    ) : null
                }
            </Form.Item>
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
