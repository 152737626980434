import React from 'react';

import { RootState } from '@/store/store';
import { Text, Title } from '@/utils/constants';
import { NOT_SPECIFIED } from '@/utils/constants';
import { Card, Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

import { PartnerContact } from './PartnerContact';
import './style.scss';

export const Partners: React.FC = () => {
    const { details, currentDossierFromList } = useSelector(
        (state: RootState) => state.dossierReducer
    );
    return (
        <Card>
            <Space size="small" direction="vertical" style={{ width: '100%' }}>
                <Title level={4} style={{ marginTop: 0 }}>
                    Partenaires
                </Title>
                <Row align="top" gutter={[0, 20]}>
                    <Col md={10} xs={24}>
                        <Space size="middle" direction="vertical">
                            <Space size="small" direction="vertical">
                                <Text>Bénéficiaire</Text>
                                <Text strong>
                                    {currentDossierFromList?.beneficiary
                                        ? currentDossierFromList.beneficiary
                                              .companyName
                                        : NOT_SPECIFIED}
                                </Text>
                                <Text className="partners__contact">
                                    SIRET :{' '}
                                    {currentDossierFromList?.beneficiary
                                        ? currentDossierFromList.beneficiary
                                              .siret
                                        : NOT_SPECIFIED}
                                </Text>
                            </Space>
                            <PartnerContact
                                blockName="Contacts"
                                signator={details?.beneficiarySignatoryContact}
                                commercial={
                                    details?.beneficiaryCommercialContact
                                }
                            />
                        </Space>
                    </Col>
                    <Col md={10} xs={24}>
                        <Space size="middle" direction="vertical">
                            <Space size="small" direction="vertical">
                                <Text>Installateur</Text>
                                <Text strong>
                                    {currentDossierFromList?.installer
                                        ? currentDossierFromList.installer
                                              .companyName
                                        : NOT_SPECIFIED}
                                </Text>
                                <Text className="partners__contact">
                                    SIRET :{' '}
                                    {currentDossierFromList?.installer
                                        ? currentDossierFromList.installer.siret
                                        : NOT_SPECIFIED}
                                </Text>
                            </Space>
                            <PartnerContact
                                blockName="Contacts"
                                signator={details?.installerCommercialContact}
                                commercial={details?.installerSignatoryContact}
                            />
                        </Space>
                    </Col>
                    {details?.businessProvider && (
                        <Col md={4} xs={24}>
                            <Space size="middle" direction="vertical">
                                <Space size="small" direction="vertical">
                                    <Text>Apporteur d'affaires</Text>
                                    <>
                                        <Text strong>
                                            {
                                                details?.businessProvider
                                                    .companyName
                                            }
                                        </Text>
                                        <Text className="partners__contact">
                                            SIRET :{' '}
                                            {details?.businessProvider.siret}
                                        </Text>
                                    </>
                                </Space>
                            </Space>
                        </Col>
                    )}
                </Row>
            </Space>
        </Card>
    );
};
