import { useCallback } from 'react';

import { IHeliosUser } from '@/features/profile';
import usersServices from '@/services/user/userServices';
import { setHeliosUser, setIsAppError } from '@/store/slices';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

export const useGetUser = () => {
    const dispatch = useDispatch();

    const getUser = useCallback(async () => {
        return await usersServices.getHeliosUser();
    }, []);

    const { isPending, isResolved, isRejected, data } = useAsync({
        promiseFn: getUser,
        onResolve(user: IHeliosUser) {
            dispatch(setHeliosUser(user));
        },
        onReject(error) {
            dispatch(setIsAppError(true));
        },
    });

    return { isPending, isResolved, isRejected, data };
};
