import { TStepDossier } from '@/features/dashboard';
import { useGetDossierSteps } from '@/hooks/dossier/useGetDossierSteps';
import { RootState } from '@/store/store';
import { Text } from '@/utils';
import { Card, Steps } from 'antd';
import { useSelector } from 'react-redux';

import { StepItem, TStep, TSubStep } from '../types';
import './style.scss';
import { createStep } from './utlis';

export const DossierSteps = () => {
    const { isPending, data: steps } = useGetDossierSteps();
    const { currentDossierFromList } = useSelector(
        (state: RootState) => state.dossierReducer
    );

    const CreateSubStep = (
        subsSteps: TSubStep[],
        stepOfDossier: TStepDossier
    ) => {
        return (
            <ul className="steps__sub-step">
                {subsSteps.map((sbStep) => (
                    <li
                        className={`${
                            sbStep.subStep <= stepOfDossier?.subStep
                                ? 'active-substep'
                                : ''
                        }`}
                        key={sbStep.subStep}
                    >
                        <Text type="secondary">{sbStep?.subStepName}</Text>
                    </li>
                ))}
            </ul>
        );
    };

    const createSteps = () => {
        return steps?.reduce((items: StepItem[], element: TStep) => {
            const description =
                element.subStep &&
                CreateSubStep(
                    element.subStep,
                    currentDossierFromList!.stepOfDossier
                );

            const stepItem = createStep(element.title, description);
            items.push(stepItem);
            return items;
        }, []);
    };

    return (
        <Card className="steps__card" loading={isPending}>
            <Steps
                size="small"
                className="steps__dossier"
                current={currentDossierFromList?.stepOfDossier?.step}
                initial={0}
                items={createSteps()}
                status={
                    currentDossierFromList?.stepOfDossier?.progression === 100
                        ? 'finish'
                        : 'process'
                }
            />
        </Card>
    );
};
