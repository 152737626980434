import { useCallback } from 'react';

import newProjectServices from '@services/new-project/newProjectServices';
import { useAsync } from 'react-async';

export type ProjectsRequestsStatusProps = {
    id: number;
    projectName: string;
    createdAt: number;
    status: string;
    projectAddress: string;
};

export const useGetProjectsRequests = () => {
    const projectsRequests = useCallback(async (): Promise<
        ProjectsRequestsStatusProps[]
    > => {
        return await newProjectServices.getProjectsRequests();
    }, []);

    const { isPending, data } = useAsync({
        promiseFn: projectsRequests,
    });

    return { isPending, data: data };
};
