import { Form, Alert, FormListFieldData, InputNumber, Typography, Divider, Button, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceChaudiereREF24B from '../refs/PuissanceChaudiereREF24B';
import RREF142 from '../refs/RREF142';
import NbAppartementsREF131 from '../refs/NbAppartementsREF131';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1071S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    const formItemMarginBottom = 10

    const formItemStyle: React.CSSProperties = {
        marginBottom: formItemMarginBottom,
    };

    /* Infos FOST */
    const code_fost = "BAR-TH-107"
    const libelle_fost = "Chaudière collective haute performance énergétique"
    const denomination_fost = "Mise en place d’une chaudière collective haute performance énergétique"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-107.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>



            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Nb Appartements */}
            <NbAppartementsREF131
                label="Nombre d'appartements :"
                name={[field.name, 'REF-131']}
                style={formItemStyle}
            />

            {/* Puissance Chaudière */}
            <Form.Item
                name={[field.name, "Pinstallee"]}
                label="Puissance de la chaudière (kW) : "
                style={formItemStyle}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <InputNumber
                    addonAfter="kW"
                    decimalSeparator=','
                    min='0'
                    controls={false}
                    style={{ width: '150px' }}
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && form.getFieldValue(['items', field.name, "Pinstallee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Pinstallee"]) > 400) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "1" }]) }
                        else { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: undefined }]) }
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                    }}
                />
            </Form.Item>

            <PuissanceChaudiereREF24B
                label=""
                name={[field.name, 'REF-24-B']}
                disabled={true}
                hidden
                style={formItemStyle}
            />


            {/* Puissance totale chaufferie */}
            <Form.Item
                name={[field.name, "Pchaufferie"]}
                label="Puissance totale de la nouvelle chaufferie : "
                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                tooltip={{
                    title: <>Puissance de la chaufferie après travaux. La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
                
            >
                <InputNumber
                    addonAfter="kW"
                    style={{ width: '150px' }}
                    controls={false}
                    min='0'
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Pinstallee"]) >= (1 / 3 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                            && form.getFieldValue(['items', field.name, "Pchaufferie"]) >= form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Pinstallee"]) < (1 / 3 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                            && form.getFieldValue(['items', field.name, "Pchaufferie"]) > form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (form.getFieldValue(['items', field.name, "Pinstallee"]) / form.getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                        else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                    }}
                />
            </Form.Item>

            {/* R */}
            <RREF142
                label="Facteur R :"
                name={[field.name, 'REF-142']}
                disabled={true}
                hidden
            />

            {/* Alerte si Pchaufferie < Pinstallée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                        <Alert
                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
        </>
    );
};
