import { Col, Row, Typography } from "antd";

interface CustomListItemProps {
  itemKey: string;
  value: string | number;
}

export const CustomListItem: React.FC<CustomListItemProps> = ({ itemKey, value }) => {
  return (
      <Row>
          <Col flex="none" style={{ marginRight: '12px' }}>
              <Typography.Text type="secondary">{itemKey}:</Typography.Text>
          </Col>
          <Col flex="auto">
              <Typography.Text>{value}</Typography.Text>
          </Col>
      </Row>
  );
};
