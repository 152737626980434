import React from 'react';

import { PatternFormat } from 'react-number-format';

const PHONE_NUMBER_FORMAT = '##############';
const MASK = '';

export const SiretInputFormat = ({
    name,
    value,
    classes,
    onChange,
    ...props
}: {
    name?: string;
    value?: string;
    classes?: string;
    onChange?: (value: string) => void;
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        onChange && onChange(inputValue);
    };
    return (
        <PatternFormat
            id={name}
            format={PHONE_NUMBER_FORMAT}
            value={value || ''}
            mask={MASK}
            className={classes}
            onChange={handleChange}
        />
    );
};

export default React.memo(SiretInputFormat);
