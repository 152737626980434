import React from 'react';

import { NumericInput } from '@/components';
import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Form, Row, Space } from 'antd';

export const Isolation = () => {
    return (
        <div className="work-type-answer">
            <Text>
                <ul style={{ listStyle: 'square' }}>
                    <Space direction="vertical" size="small">
                        <li style={{ paddingLeft: 0 }}>
                            <Row align="bottom">
                                <Text>Surface d’isolant : </Text>
                                <Form.Item
                                    className="form-item--same-line"
                                    name={constant.ISOLATION_INSULATION_SURFACE}
                                >
                                    <NumericInput
                                        maxLength={7}
                                        placeholder="0"
                                        classes="input-sameline-answer"
                                    />
                                </Form.Item>
                                <Text>
                                    m<sup>2</sup>
                                </Text>
                            </Row>
                        </li>
                    </Space>
                </ul>
            </Text>
        </div>
    );
};
