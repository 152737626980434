import { Text } from '@/utils/constants';
import { PhoneFilled } from '@ant-design/icons';
import { Avatar, Col, Row, Space, Tooltip } from 'antd';

import { TInterLocutorCard } from './types';

export const InterLocutorCard: React.FC<TInterLocutorCard> = ({
    title,
    firstName,
    lastName,
    mobileNumber,
}) => {
    const DisplayCard = () => {
        return (
            <Row>
                <Col>
                    <Avatar
                        style={{
                            backgroundColor: '#72B8EC',
                            marginRight: '8px',
                        }}
                        size={'large'}
                    >
                        <Text strong>{`${firstName.charAt(0)}${lastName.charAt(
                            0
                        )}`}</Text>
                    </Avatar>
                </Col>
                <Col>
                    <Space direction="vertical">
                        <Text>{title}</Text>
                        <Space size="small">
                            <Text strong>{`${firstName} ${lastName}`}</Text>
                            {mobileNumber && (
                                <Tooltip placement="right" title={mobileNumber}>
                                    <PhoneFilled />
                                </Tooltip>
                            )}
                        </Space>
                    </Space>
                </Col>
            </Row>
        );
    };
    return (
        <Space direction="vertical">
            <DisplayCard />
        </Space>
    );
};
