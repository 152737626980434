export const PROJECT_NAME = 'projectName';

export const IS_INSTALLER = 'isInstaller';
export const IS_BENEFICIARY = 'isBeneficiary';
export const IS_BUSINESS_PROVIDER = 'isBusinessProvider';

export const INSTALLER_SIRET = 'installerSiret';
export const BENEFICIARY_SIRET = 'beneficiarySiret';

export const AGRICULTURAL_FORESTRY_EXPLOITATION =
    'agriculturalForestryExploitation';

export const PRINCIPALE_USE_OF_BUILDING = 'principaleUseOfBuilding';
export const SECONDE_USE_OF_BUILDING = 'secondeUseOfBuilding';

export const HOUSING = 'housing';
export const COMMERCE_AND_SERVICE_ACTIVITY = 'commerceAndServiceActivity';
export const EQUIPMENT_OF_INTEREST = 'equipmentOfInterest';
export const INDUSTRY = 'industry';
export const STOREHOUSE = 'storehouse';
export const CONVENTION_CENTER = 'conventionCenter';
export const OTHER_CONSTRUCTIONS = 'otherConstructions';

export const START_WORK_DATE = 'startWorkDate';
export const END_WORK_DATE = 'endWorkDate';
export const ESTIMATE_SIGNATURE_DATE = 'estimateSignatureDate';
export const ESTIMATE_WORK_DATE = 'estimateWorkDate';

export const WORK_ZIPCODE = 'workZipCode';
export const BULDING_EXISTING = 'buldingExisting';

export const YES = 'yes';
export const NO = 'no';
export const I_DONT_KNOW = 'dontKnow';

export const WROKS_TYPE = 'worksType';

export const PROJECT_DESCRIPTION = 'projectDescription';
export const WORK_DESCRIPTION_COMMENTS = 'workDescriptionComments';

export const NEW_PROJECT_FILES = 'newProjectFiles';

export const HEATING = 'heating';
export const ISOLATION = 'isolation';
export const REGULATION = 'regulation';
export const CALORIFUGEAGE = 'calorifugeage';
export const PROCESS_UTILITIES_AND_COLD = 'processUtilitiesAndCold';
export const HEATING_RECOVERY = 'heatingRecovery';
export const OTHERS_WORK_TYPES = 'othersWorkTypes';

export const HEATING_WORK = 'heatingWork';

export const TERTIARY = 'tertiary';
export const TERTIARY_WORK_BUILDING_AREA = 'heatingWorkBuildingArea';
export const TERTIARY_WORK_BOILER_POWER = 'tertiaryWorkBoilerPower';

export const RESIDENTIALY = 'residentialy';
export const RESIDENTIALY_NUMBER_OF_HOUSINGS = 'residentialyNumberOfHousings';
export const RESIDENTIALY_WORK_BOILER_POWER = 'residentialyWorkBoilerPower';

export const ISOLATION_INSULATION_SURFACE = 'isolationInsulationSurface';

export const REGULATION_TYPE = 'regulationType';
export const REGULATION_GTB = 'regulationGtb';
export const REGULATION_MANAGED_SURFACE = 'regulationManagedSurface';
export const REGULATION_USES_CONCERNED = 'regulationUsesConcerned';
export const REGULATION_USES_CONCERNED_HEATING =
    'regulationUsesConcernedHeating';
export const REGULATION_USES_CONCERNED_CLIM = 'regulationUsesConcernedClim';
export const REGULATION_USES_CONCERNED_ESC = 'regulationUsesConcernedEsc';
export const REGULATION_USES_CONCERNED_ECLAIRAGE =
    'regulationUsesConcernedEclairage';
export const OTHERS_REGULATION_GBT = 'othersRegulationGtb';
export const OTHERS_REGULATION_GBT_NAMES = 'othersRegulationGtbNames';
export const REGULATION_VARIATOR_MOTORS = 'regulaionVariatorMotor';
export const REGULATION_ENGINE_POWER = 'regulaionEnginePower';
export const REGULATION_GROUP_PRODUCTION = 'regulationGroupProduction';
export const REGULATION_COLD_GROUP_POWER = 'regulationColdGroupPower';

export const CALORIFUGEAGE_LENGTH = 'calorifugeageLength';
export const CALORIFUGEAGE_POINT_NUMBER = 'calorifugeagePointNumber';

export const PROCESS_UTILITIES_COLD = 'processUtilitiesCold';
export const PROCESS_UTILITIES_AND_COLD_TYPES = 'processUtilitiesColdTypes';
export const PROCESS_UTILITIES_COLD_COMPRESSOR_POWER =
    'processUtilitiesColdCompressorPower';
export const PROCESS_UTILITIES_PRESSURIZED_AIR =
    'processUtilitiesPressurizedAir';
export const PROCESS_UTILITIES_PRESSURIZED_AIR_COMPRESSOR_POWER =
    'processUtilitiesPressurizedAirCompressorPower';
export const PROCESS_UTILITIES_ENGINE_POWER = 'processUtilitiesEnginePower';
export const PROCESS_UTILITIES_ENGINE = 'processUtilitiesEngine';
export const PROCESS_UTILITIES_OTHERS = 'processUtilitiesOthers';
export const PROCESS_UTILITIES_OTHERS_NAMES = 'processUtilitiesOthersNames';

export const HEATING_RECOVERY_TYPES = 'heatingRecoveryTypes';
export const ON_COLD_GROUP = 'onColdGroup';
export const HEATING_RECOVERY_NEEDS = 'heatingRecoveryNeeds';
export const HEATING_RECOVERY_THERMAL_POWER = 'heatingRecoveryThermalPower';
export const HEATING_RECOVERY_NEEDS_ELEMENTS = 'heatingRecoveryNeedsElements';
export const HEATING_RECOVERY_LOCAL_HEATING = 'heatingRecoveryLocalHeating';
export const HEATING_RECOVERY_ESC_HEATING = 'heatingRecoveryEcsHeating';
export const HEATING_RECOVERY_PROCESS_HEATING = 'heatingRecoveryProcessHeating';
export const HEATING_RECOVERY_OTHERS_NEEDS = 'heatingRecoveryOthersNeeds';
export const HEATING_RECOVERY_OTHERS_NEEDS_NAMES =
    'heatingRecoveryOthersNeedsNames';
export const HEATING_RECOVERY_ON_AIR_COMPRESSOR =
    'heatingRecoveryOnAirCompressor';
export const HEATING_RECOVERY_ON_AIR_COMPRESSOR_THERMAL_POWER =
    'heatingRecoveryOnAirCompressorThermalPower';
export const HEATING_RECOVERY_ELECTRIC_COMPRESSOR_POWER =
    'heatingRecoveryElectricCompressorPower';

export const HEATING_RECOVERY_ON_AIR_COMPRESSOR_ELECTRIC_POWER =
    'heatingRecoveryOnAirCompressorElectricPower';

export const OTHERS_WORK_TYPES_NAMES = 'othersWorkTypesNames';
export const OTHERS_TYPES = 'otherTypes';

export const STR_VALUE = '%value%';
export const NAME_ATTRIBUT = 'name';

export const PROJECT_STATES: { [key: string]: string } = {
    AWAITING: 'En traitement',
    REFUSED: 'Refusé',
    ACCEPTED: 'Accepté',
};

export const emailValidation = [
    {
        required: false,
        message: 'Veuillez saisir votre email',
    },
    {
        max: 100,
        message: '',
    },
    {
        pattern: new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        ),
        message: "Ce format d'email n'est pas valide",
    },
];
export const projectNameValidation = [
    {
        required: true,
        message: 'Ce champ est obligatoire',
    },
    {
        max: 150,
        message: 'Le nom du projet ne dépasse pas 150 caractères',
    },
    {
        pattern: new RegExp(/^[a-z0-9àâçéèêëîïôûùüÿñæœ .-]*$/i),
        message: "Ce format n'est pas valide",
    },
];

export const firstLastNameValidation = [
    {
        required: false,
    },
    {
        max: 50,
        message: 'Le nom du projet ne dépasse pas 50 caractères',
    },
    {
        pattern: new RegExp(/^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i),
        message: "Ce format n'est pas valide",
    },
];

export const functionValidation = [
    {
        required: false,
    },
    {
        max: 100,
        message: 'La fonction ne dépasse pas 100 caractères',
    },
    {
        pattern: new RegExp(/^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i),
        message: "Ce format n'est pas valide",
    },
];
