import React, { useEffect, useState } from 'react';

import { RootState } from '@/store/store';
import { Title } from '@/utils/constants';
import { Card } from 'antd';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Title as ChartTitle,
    Legend,
    LinearScale,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { chartConfig, countDossierByStep } from '../utils';

export const DossiersStatus: React.FC = () => {
    const [chartData, setChartData] = useState<number[]>([0, 0, 0, 0, 0, 0, 0]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartTitle,
        Tooltip,
        Legend
    );

    const config = chartConfig(chartData);

    const { inprogressDossiers } = useSelector(
        (state: RootState) => state.dashboardReducer
    );

    useEffect(() => {
        const data = countDossierByStep(inprogressDossiers);
        setChartData(data);
    }, [inprogressDossiers]);

    return (
        <Card>
            <Title level={3} style={{ marginTop: 0 }}>
                Statut des dossiers
            </Title>
            <Bar options={config.options} data={config.data} height={50} />
        </Card>
    );
};
