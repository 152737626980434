import { setProfileType } from '@/store/slices';
import { Text } from '@/utils';
import { Form, Radio, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { useDispatch } from 'react-redux';

import applicantProfileTypes from '../../data/applicantProfileTypes.json';

export const ApplicantProfileType = () => {
    const dispatch = useDispatch();
    return (
        <Form.Item
            label={
                <Text className="field-description" strong>
                    Dans le cadre de ce projet, vous êtes :
                </Text>
            }
            rules={[{ required: true }]}
            name="applicantProfile"
        >
            <Radio.Group
                onChange={(e: RadioChangeEvent) =>
                    dispatch(setProfileType(e.target.value))
                }
            >
                <Space direction="vertical" size="small">
                    {applicantProfileTypes.map((applicantProfileType) => (
                        <Radio
                            key={applicantProfileType.key}
                            value={applicantProfileType.value}
                            id={applicantProfileType.value}
                        >
                            {applicantProfileType.label} {applicantProfileType.description}
                        </Radio>
                    ))}
                </Space>
            </Radio.Group>
        </Form.Item>
    );
};
