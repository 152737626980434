import React, { useCallback, useState } from 'react';

import { AuthenticationCard } from '@/components';
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    UserOutlined,
} from '@ant-design/icons';
import { useConnexion } from '@hooks/coginto/useConnexion';
import { Text } from '@utils/constants';
import { Button, Checkbox, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { validation } from '../index';
import type { TconnexionForm } from '../index';
import '../styles.scss';

const Connexion: React.FC = () => {
    const [authFields, setAuthFields] = useState<TconnexionForm>({
        username: '',
        password: '',
    });

    const { isPending, onConnexion, isRejected } = useConnexion();

    const [rememberme, setRememberme] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({ ...authFields, [e.target.name]: e.target.value });
    };

    const onChangeRememberme = (e: CheckboxChangeEvent) => {
        setRememberme(!rememberme);
    };

    const handleLogin = useCallback(async () => {
        onConnexion(authFields.username, authFields.password);
    }, [onConnexion, authFields.username, authFields.password]);

    return (
        <AuthenticationCard title="Connexion">
            <div className="user-avatar">
                <UserOutlined className="user-avatar__icon" />
            </div>
            {isRejected && (
                <Text type="danger" className="auth-error-msg">
                    L'email ou le mot de passe est incorrect
                </Text>
            )}
            <Form
                name="connexion-form"
                style={{ width: '100%' }}
                className="form__authentication"
                onFinish={handleLogin}
            >
                <Form.Item
                    name="usernameItem"
                    rules={validation.emailValidation}
                >
                    <Input
                        name="username"
                        placeholder="Email"
                        onChange={handleChange}
                    />
                </Form.Item>
                <Form.Item
                    name="passwordItem"
                    rules={validation.passwordValidation}
                >
                    <Input.Password
                        name="password"
                        placeholder="Mot de passe"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={handleChange}
                    />
                </Form.Item>
                <Form.Item className="remembermeItem">
                    <Checkbox
                        checked={rememberme}
                        name="rememberme"
                        onChange={onChangeRememberme}
                    >
                        <Text className="remember__me--txt">
                            Se souvenir de moi
                        </Text>
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Link
                        to="/forget-password"
                        style={{ textDecoration: 'underline' }}
                    >
                        Mot de passe oublié ?
                    </Link>
                </Form.Item>
                <Row justify={'center'}>
                    <Form.Item className="form__btn">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isPending}
                            disabled={isPending}
                        >
                            SE CONNECTER
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </AuthenticationCard>
    );
};

export default Connexion;
