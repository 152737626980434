import type { IHeliosUser } from '@/features/profile';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IInitailState } from './types';

const initialState: IInitailState = {
    heliosUser: null,
};

export const heliosUserSlice = createSlice({
    name: 'heliosUser',
    initialState,
    reducers: {
        setHeliosUser: (
            state: IInitailState,
            action: PayloadAction<IHeliosUser>
        ) => {
            state.heliosUser = action.payload;
        },
        resetHeliosUser: (state) => {
            state.heliosUser = null;
        },
    },
});

export const { setHeliosUser, resetHeliosUser } = heliosUserSlice.actions;
export default heliosUserSlice.reducer;
