import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function HauteurREF109({ label, name }: { label: string, name: NamePath }) {
    return <Form.Item
        name={name}
        label={label}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le type d'installation"
            },]}
        >
        <Radio.Group
            buttonStyle='solid'
            disabled
        >
            <Radio.Button value="0">5 m ≤ h &lt; 10 m</Radio.Button>
            <Radio.Button value="1">h ≥ 10 m</Radio.Button>
        </Radio.Group>
    </Form.Item >
}