import { Form, Radio, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

export default function EfficaciteEnergetiqueIREF140F({name, label, disabled, hidden}: {name: NamePath, label: string, disabled?: boolean, hidden?: boolean}) {
  return <Form.Item
            name={name}
            label={label}
            hidden={hidden}
            rules={[
                { required: true, message: "Veuillez renseigner l'efficacité énergétique saisonnière" },
              ]}
            tooltip={{
                title: <><Typography>L’efficacité énergétique saisonnière et les émissions de polluants sont mesurés selon les normes suivantes :</Typography>
                            <Typography>- pour les poêles : norme NF EN 13240 ou NF EN 14785 ou NF EN 15250 ;</Typography>
                            <Typography>- pour les foyers fermés, inserts de cheminées intérieures : norme NF EN 13229 ;</Typography>
                            <Typography>- pour les cuisinières utilisées comme mode de chauffage : norme NF EN 12815.</Typography></>,
                icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                color: '#0086CA'
            }}
            >
            <Radio.Group
                buttonStyle='solid'
                disabled={disabled}
            >
                <Radio.Button value="0">66% ≤ Etas &lt; 72%</Radio.Button>
                <Radio.Button value="1">72% ≤ Etas &lt; 80%</Radio.Button>
                <Radio.Button value="2">80% ≤ Etas</Radio.Button>
            </Radio.Group>
        </Form.Item>
}