import React, { useEffect } from 'react';

import { RootState } from '@/store/store';
import { Title } from '@/utils/constants';
import { Card, Empty, Table } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';

import type { IDossier, TDataTable } from '../types';

const columns: ColumnsType<
    Pick<TDataTable, 'key' | 'name' | 'progress' | 'state'>
> = [
    {
        title: 'Nom du dossier',
        dataIndex: 'name',
        key: 'name',
        render: (text, value) => (
            <Link to={`/dossier/in-progress/${value.key}`}>{text}</Link>
        ),
    },
    {
        title: 'Progression',
        dataIndex: 'progress',
        key: 'progress',
    },
    {
        title: 'Statut',
        dataIndex: 'state',
        key: 'state',
    },
];

export const MobileProgressTable: React.FC = () => {
    const { inprogressDossiers } = useSelector(
        (state: RootState) => state.dashboardReducer
    );

    useEffect(() => {}, [inprogressDossiers]);

    const customizeRenderEmpty = {
        emptyText: (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Vous n'avez pas encore des projets en cours"
            />
        ),
    };

    const createInprogressTableData = (
        dossiers: IDossier[]
    ): Pick<TDataTable, 'key' | 'name' | 'progress' | 'state'>[] => {
        return dossiers.reduce(
            (
                items: Pick<
                    TDataTable,
                    'key' | 'name' | 'progress' | 'state'
                >[],
                dossier: IDossier
            ) => {
                const item: Pick<
                    TDataTable,
                    'key' | 'name' | 'progress' | 'state'
                > = {
                    key: dossier.id,
                    name: dossier.name,
                    progress: dossier.stepOfDossier.progression + '%',
                    state: dossier.stepOfDossier.name,
                };
                items.push(item);
                return items;
            },
            []
        );
    };

    return (
        <Card className="card__current-projects-table">
            <Title level={3} style={{ marginTop: 0 }}>
                Dossiers en cours
            </Title>
            <Table
                locale={customizeRenderEmpty}
                columns={columns}
                dataSource={createInprogressTableData(inprogressDossiers)}
                pagination={false}
            />
        </Card>
    );
};
