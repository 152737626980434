import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Button, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceChaudiereREF24B from '../refs/PuissanceChaudiereREF24B';
import EfficaciteEnergetiqueREF140A from '../refs/EfficaciteEnergetiqueREF140A';
import CopREF12H from '../refs/CopREF12H';
import UsageREF15A from '../refs/UsageREF15A';
import RREF142 from '../refs/RREF142';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import NbAppartementsREF131 from '../refs/NbAppartementsREF131';


const { Title } = Typography

export const Barth1501S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAR-TH-150"
    const libelle_fost = "Pompe à chaleur collective à absorption de type air/eau ou eau/eau"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) à absorption de type air/eau ou eau/eau fonctionnant au gaz naturel ou au propane pour un système de chauffage collectif."
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-150.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Usage */}
            <UsageREF15A
                label="Usage de la pompe à chaleur :"
                name={[field.name, 'REF-15-A']}
            />
            
            {/* Nb d'appartements */}
            <NbAppartementsREF131
                label="Nombre d'appartements :"
                name={[field.name, 'REF-131']}
            />    

            {/* Puissance PAC */}
                    <Form.Item
                        name={[field.name, "Pinstallee"]}
                        label="Puissance de la PAC installée (kW) : "
                        rules={[
                            { required: true, message: "Veuillez renseigner ce champ" },
                        ]}
                    >
                        <InputNumber
                            addonAfter="kW"
                            min='0'
                            controls={false}
                            style={{ width: '150px' }}
                            onChange={() => {
                                if (form.getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && form.getFieldValue(['items', field.name, "Pinstallee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "0" }]) }
                                else if (form.getFieldValue(['items', field.name, "Pinstallee"]) > 400) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "1" }]) }
                                else {
                                    form.setFields([{ name: ["items", field.name, "REF-24-B"], value: undefined }])
                                    form.setFields([{ name: ["items", field.name, "TemperaturePAC"], value: undefined }])
                                }
                                form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                                form.setFieldValue(['items', field.name, "REF-142"], undefined)
                            }}
                        />
                    </Form.Item>

                    <PuissanceChaudiereREF24B
                        label=""
                        name={[field.name, 'REF-24-B']}
                        disabled={true}
                        hidden
                        onChange={
                            () => {
                                form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                                form.setFieldValue(['items', field.name, "Etas"], undefined)
                                form.setFieldValue(['items', field.name, "REF-140-A"], undefined)
                                form.setFieldValue(['items', field.name, "COP"], undefined)
                                form.setFieldValue(['items', field.name, "REF-142"], undefined)
                            }
                        }
                    />

            {/* Temperature et Etas si Puissance PAC <= 400 kW */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-24-B"]) === '0' ? (
                        <>
                            {/*Temperature*/}
                            <Form.Item
                                name={[field.name, "TemperaturePAC"]}
                                label="Quel est le type de la PAC installée ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                    }}>
                                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {/* ETAS variable continue */}
                                    <Form.Item
                                        label="Etas (%) :"
                                        name={[field.name, 'Etas']}
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                        tooltip={{
                                            title: "L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                    >
                                        <InputNumber
                                            min={0}
                                            addonAfter="%"
                                            controls={false}
                                            style={{ width: '100px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Etas"]) >= 102 && getFieldValue(['items', field.name, "Etas"]) < 110) { form.setFields([{ name: ["items", field.name, "REF-140-A"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 110 && getFieldValue(['items', field.name, "Etas"]) < 120) { form.setFields([{ name: ["items", field.name, "REF-140-A"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 120) { form.setFields([{ name: ["items", field.name, "REF-140-A"], value: "2" }]) }
                                                else if (getFieldValue(['items', field.name, "Etas"]) < 102) { form.setFields([{ name: ["items", field.name, "REF-140-A"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>

                                    {/* ETAS */}
                                    <EfficaciteEnergetiqueREF140A
                                        label=""
                                        name={[field.name, 'REF-140-A']}
                                        disabled={true}
                                        hidden
                                    />
  
                            {/* Alerte si basse température et ETAS inférieur à 126 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                                        <Alert
                                            message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Alerte si moyenne/haute température et ETAS inférieur à 111 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                                        <Alert
                                            message="Pour une PAC moyenne/haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* COP si Puissance PAC > 400 kW */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-24-B"]) === "1" ? (
                        <>
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align='end'>
                                    {/*COP variable continue*/}
                                    <Form.Item
                                        label="Coefficient de performance (COP) : "
                                        name={[field.name, 'COP']}
                                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                        tooltip={{
                                            title: <><Typography style={{ color: 'white' }}>Le coefficient de performance (COP) est mesuré selon la norme EN 12309 pour des températures d’entrée et de
                                                sortie égales à :</Typography>
                                                <Typography style={{ color: 'white', paddingLeft: '16px' }}>- PAC air/eau : 7°C (A) / 35 °C (E) ;</Typography>
                                                <Typography style={{ color: 'white', paddingLeft: '16px' }}>- PAC eau/eau ou PAC eau glycolée/eau : 10°C (E) / 35°C (E).</Typography>
                                            </>,
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                    >
                                        <InputNumber
                                            addonAfter="%"
                                            style={{ width: '100px' }}
                                            controls={false}
                                            min={0}
                                            decimalSeparator=','
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "COP"]) >= 1.3 && getFieldValue(['items', field.name, "COP"]) < 1.6) { form.setFields([{ name: ["items", field.name, "REF-12-H"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "COP"]) >= 1.6) { form.setFields([{ name: ["items", field.name, "REF-12-H"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "COP"]) < 1.3) { form.setFields([{ name: ["items", field.name, "REF-12-H"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>

                                    {/* COP */}
                                    <CopREF12H
                                        name={[field.name, 'REF-12-H']}
                                        label=""
                                        disabled={true}
                                    />
                                </Space>
                            </Form.Item>
                            {/* Alerte si COP > 1,3 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "COP"]) < 1.3 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si le COP est supérieur ou égal à 1,3."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>


            {/* Puissance totale chaufferie */}
            <Form.Item style={{ margin: "0px" }}>
                <Space align='center'>
                    <Form.Item
                        name={[field.name, "Pchaufferie"]}
                        label="Puissance totale de la nouvelle chaufferie : "
                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                        tooltip={{
                            title: <>Puissance de la chaufferie après travaux. La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                            color: tooltip_color,
                        }}
                        //extra="Puissance de la chaufferie après travaux"
                        style={{ margin: "0px" }}
                    >
                        <InputNumber
                            addonAfter="kW"
                            style={{ width: '150px' }}
                            controls={false}
                            min='0'
                            onChange={() => {
                                if (form.getFieldValue(['items', field.name, "Pinstallee"]) >= (0.4 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                                    && form.getFieldValue(['items', field.name, "Pchaufferie"]) >= form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                                else if (form.getFieldValue(['items', field.name, "Pinstallee"]) < (0.4 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                                    && form.getFieldValue(['items', field.name, "Pchaufferie"]) > form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (form.getFieldValue(['items', field.name, "Pinstallee"]) / form.getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                                else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                            }}
                        />
                    </Form.Item>


                    {/* R */}
                    <RREF142
                        label="Facteur R :"
                        name={[field.name, 'REF-142']}
                        disabled={true}
                        hidden
                    />
                </Space>
            </Form.Item>
            {/* Alerte si Pchaufferie < Pinstallée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                        <Alert
                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
        </>
    );
};
