import React, { useEffect } from 'react';

import { TPartner } from '@/features/profile/types';
import { RootState } from '@/store/store';
import { dateFormatter } from '@/utils';
import { Title } from '@/utils/constants';
import { Card, Empty, Progress, Table } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';

import type { IDossier, TDataTable } from '../types';

const columns: ColumnsType<TDataTable> = [
    {
        title: 'Nom du dossier',
        dataIndex: 'name',
        key: 'name',
        render: (text, value) => (
            <Link to={`/dossier/in-progress/${value.key}`}>{text}</Link>
        ),
    },
    {
        title: 'Progression',
        dataIndex: 'progress',
        key: 'progress',
    },
    {
        title: 'Bénéficiaire (ou installateur)',
        dataIndex: 'benif',
        key: 'benif',
    },
    {
        title: 'Date de création',
        dataIndex: 'datec',
        key: 'datec',
    },
    {
        title: 'Statut',
        dataIndex: 'state',
        key: 'state',
    },
];

export const ProgressTable: React.FC = () => {
    const { inprogressDossiers } = useSelector(
        (state: RootState) => state.dashboardReducer
    );

    const { heliosUser: user } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );
    const partner: TPartner | undefined = user?.partner;

    useEffect(() => {}, [inprogressDossiers]);

    const customizeRenderEmpty = {
        emptyText: (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Vous n'avez pas encore des projets en cours"
            />
        ),
    };
      
    const createInprogressTableData = (dossiers: IDossier[]): TDataTable[] => {
        return dossiers.reduce((items: TDataTable[], dossier: IDossier) => {
            const item: TDataTable = {
                key: dossier.id,
                name: dossier.name,
                progress: (
                    <Progress
                        strokeColor={'#72B8EC'}
                        percent={dossier.stepOfDossier.progression}
                    />
                ),
                state: dossier.stepOfDossier.name,
                benif: partner?.isBeneficiary
                    ? `${
                          dossier.installer
                              ? dossier.installer?.companyName
                              : ''
                      }`
                    : `${
                          dossier.beneficiary
                              ? dossier.beneficiary?.companyName
                              : ''
                      }`,
                datec: dateFormatter(dossier.createdAt),
            };
            items.push(item);
            return items;
        }, []);
    };

    return (
        <Card className="card__current-projects-table">
            <Title level={3} style={{ marginTop: 0 }}>
                Dossiers en cours
            </Title>
            <Table
                locale={customizeRenderEmpty}
                columns={columns}
                dataSource={createInprogressTableData(inprogressDossiers)}
                pagination={false}
            />
        </Card>
    );
};
