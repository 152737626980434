import { Form, Select, Typography } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function TypeInstallationREF81H({name, label}: {name: NamePath, label: string}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner le type d'installation"
                    },]}>
                <Select
                    placeholder="Sélectionnez le type d'installation"
                    style={{maxWidth: '580px',height:'auto', whiteSpace: 'normal', wordBreak: 'break-word'}}
                  >
                    <Option value="0"><Typography style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Installation d’une ventilation mécanique double flux modulée proportionnelle</Typography></Option>
                    <Option value="1"><Typography style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Installation d’une ventilation mécanique double flux modulée à détection de présence</Typography></Option>
                    <Option value="2"><Typography style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Installation d’une ventilation mécanique double flux à débit d’air constant</Typography></Option>
                  </Select>
            </Form.Item >
}