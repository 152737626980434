import { Form, Alert, FormListFieldData, Typography, List, Radio, Button, Flex, Divider, Collapse, Card, Select } from 'antd';
import { useState } from 'react';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import TypeCondenseurREF60C from '@/features/new-project/operations/forms/components/common/TypeCondenseurREF60C';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography
const { Title } = Typography
const { Option } = Select

export const Batth1341 = ({ field }: { field: FormListFieldData }) => {

  const form = useFormInstance()

  // Couleurs
  const primary_color = '#002766'
  const border_color = '#76B8DE'
  const subtitle_color = '#5E5E5E'
  const tooltip_color = '#0086CA'

  /* Infos FOST */
  const code_fost = "BAT-TH-134"
  const libelle_fost = "Système de régulation sur un groupe de production de froid permettant d’avoir une haute pression flottante (France métropolitaine)"
  const denomination_fost = "Mise en place d’un système de régulation sur un groupe de production de froid permettant d’avoir une haute pression flottante"
  const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-134.pdf"

  /* Textes explicatifs */
  const eligibilite = <>
    <Typography>La mise en place d’un système de régulation permettant d’avoir une haute pression flottante sur un groupe de
      production de froid pour la climatisation de confort des occupants, hors centre de données informatiques (ou Datacenter),
      <Typography.Text strong style={{ color: primary_color }}> ne s’applique pas aux bâtiments neufs relevant de la catégorie CE1 </Typography.Text>
      telle que définie par les arrêtés du 26 octobre 2010 et du 28 décembre 2012 relatifs aux caractéristiques thermiques et aux exigences de performance
      énergétique des bâtiments nouveaux et des parties nouvelles de bâtiments.
    </Typography>
  </>
  const facture = <>
    <Paragraph>La facture mentionne mentionne la mise en place d’un système de régulation sur un groupe de production de froid.</Paragraph>
    <Typography>  À défaut, la preuve de réalisation de l’opération mentionne la mise en place d'un équipement avec ses marque et
      référence et elle est complétée par un document issu du fabricant indiquant que l'équipement de marque et référence
      installé est un système de régulation sur un groupe de production de froid. </Typography>
  </>
  const justificatifs = <>
    <Typography>Le document justificatif spécifique à l’opération est<Typography.Text strong style={{ color: primary_color }}> l’étude technique préalable </Typography.Text>à la mise en place de la régulation.</Typography>
  </>
  const dimensionnement = <>
    <Typography>La mise en place du système de régulation permettant d’avoir une haute pression flottante fait l’objet d’une étude
      technique préalable établie par un professionnel ou un bureau d’étude précisant<Typography.Text strong style={{ color: primary_color }}> les besoins en froid </Typography.Text>
      de l’installation et<Typography.Text strong style={{ color: primary_color }}> la puissance électrique nominale </Typography.Text>nécessaire à son fonctionnement.
      Cette étude mentionne les caractéristiques du
      groupe de production de froid <Typography.Text strong style={{ color: primary_color }}>(mono-compresseur ou multi-compresseurs) et sa puissance électrique nominale totale
        en kW.</Typography.Text></Typography>
  </>

  /* Constantes Tabs informations */
  const tabListNoTitle = [
    { key: '1', label: "Critères d'éligibilité" },
    { key: '2', label: 'Contenu de la facture' },
    { key: '3', label: 'Pièces justificatives' },
    { key: '4', label: "Contenu de l'étude technique préalable" },
  ];
  const contentListNoTitle: Record<string, React.ReactNode> = {
    1: <>{eligibilite}</>,
    2: <>{facture}</>,
    3: <>{justificatifs}</>,
    4: <>{dimensionnement}</>,
  };
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const onTabChange = (key: string) => { setActiveTabKey(key) };

  return (
    <>
      <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
      <Flex justify='space-between' align="center">
        <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
          {denomination_fost}
        </Paragraph>
        <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
      </Flex>
      <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

      {/* Code Postal */}
      <ZoneClimatique field={field} />

      {/* Radio Secteur */}
      <Form.Item
        name={[field.name, "BatimentTertiaire"]}
        label="L'opération est réalisée sur un bâtiment tertiaire :"
        rules={[
          { required: true, message: "Veuillez renseigner ce champ" },
        ]}
      >
        <Radio.Group buttonStyle='solid' onChange={() => {
          form.setFieldValue(['items', field.name, "Datacenter"], undefined)
          form.setFieldValue(['items', field.name, "BatimentNeufCe1"], undefined)
          form.setFieldValue(['items', field.name, "REF-25-C"], undefined)
          form.setFieldValue(['items', field.name, "REF-60-C"], undefined)
          form.setFieldValue(['items', field.name, "REF-26"], undefined)
        }}>
          <Radio.Button value="Oui">Oui</Radio.Button>
          <Radio.Button value="Non">Non</Radio.Button>
        </Radio.Group>
      </Form.Item >
      {/* Alerte si bâtiment non tertiaire */}
      < Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "BatimentTertiaire"]) === "Non" ? (
            <Alert
              message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire."
              type="error"
              showIcon
            />
          ) : null
        }
      </Form.Item >
      {/* Radio Datacenter */}
      < Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "BatimentTertiaire"]) === "Oui" ? (
            <Form.Item
              name={[field.name, "Datacenter"]}
              label="L'opération est réalisée sur un centre de données informatiques (ou Datacenter) :"
              rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
              ]}
              tooltip={{
                title: <>Un centre de données informatiques (ou Datacenter) est un bâtiment ou un local au sein d’un bâtiment regroupant des
                  équipements informatiques (serveurs, baies de stockage, équipements réseaux, etc…) permettant le stockage, le traitement et la
                  protection des données informatiques. </>,
                icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                color: tooltip_color,
              }}
            >
              <Radio.Group buttonStyle='solid' onChange={() => {
                form.setFieldValue(['items', field.name, "BatimentNeufCe1"], undefined)
                form.setFieldValue(['items', field.name, "REF-25-C"], undefined)
                form.setFieldValue(['items', field.name, "REF-60-C"], undefined)
                form.setFieldValue(['items', field.name, "REF-26"], undefined)
              }}>
                <Radio.Button value="Oui">Oui</Radio.Button>
                <Radio.Button value="Non">Non</Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : null
        }
      </Form.Item >


      {/* Batiment neuf CE1 si Datacenter NON */}
      < Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "BatimentTertiaire"]) === "Oui" &&
            getFieldValue(['items', field.name, "Datacenter"]) === "Non" ? (
            <Form.Item
              name={[field.name, "BatimentNeufCe1"]}
              label="S'agit-il d'un bâtiment neuf relevant de la catégorie CE1 ?"
              rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
              ]}
              tooltip={{
                title: <>La catégorie CE1 est définie par les arrêtés du 26 octobre 2010 et du 28 décembre 2012 relatifs aux
                  caractéristiques thermiques et aux exigences de performance énergétique des bâtiments nouveaux et des parties
                  nouvelles de bâtiments.</>,
                icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                color: tooltip_color,
              }}
            >
              <Radio.Group buttonStyle='solid' onChange={() => {
                form.setFieldValue(['items', field.name, "REF-25-C"], undefined)
                form.setFieldValue(['items', field.name, "REF-60-C"], undefined)
                form.setFieldValue(['items', field.name, "REF-26"], undefined)
              }}>
                <Radio.Button value="Oui">Oui</Radio.Button>
                <Radio.Button value="Non">Non</Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : null
        }
      </Form.Item >

      {/* Suite  si bâtiment neuf CE1 OUI (désactiver l'option Climatisation Confort)*/}
      < Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "BatimentNeufCe1"]) === "Oui" ? (
            /* Application custom pour NeufCE1 */
            <Form.Item
              name={[field.name, 'REF-25-C']}
              label="Application du groupe de production de froid :"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner l'application"
                },
              ]}
            >
              <Select
                placeholder="Sélectionnez l'application"
                allowClear
                style={{ width: '500px' }}
              >
                <Option disabled value="0">Climatisation de confort d’un local (hors datacenter)</Option>
                <Option disabled value="1">Climatisation d'un datacenter</Option>
                <Option value="2">Autres applications de type réfrigération ou conditionnement d'ambiance (hors confort des occupants)</Option>
              </Select>
            </Form.Item>

          ) : null
        }
      </Form.Item >

      {/* Suite si bâtiment neuf CE1 NON (désactiver l'option Datacenter)*/}
      <Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "BatimentNeufCe1"]) === "Non" ? (
            /* Application custom pour hors NeufCE1 et hors datacenter */
            <Form.Item
              name={[field.name, 'REF-25-C']}
              label="Application du groupe de production de froid :"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner l'application"
                },
              ]}
            >
              <Select
                placeholder="Sélectionnez l'application"
                allowClear
                style={{ width: '500px' }}
              >
                <Option value="0">Climatisation de confort d’un local (hors datacenter)</Option>
                <Option disabled value="1">Climatisation d'un datacenter</Option>
                <Option value="2">Autres applications de type réfrigération ou conditionnement d'ambiance (hors confort des occupants)</Option>
              </Select>
            </Form.Item>

          ) : null
        }
      </Form.Item >

      {/* Suite si datacenter OUI (désactiver l'option confort)*/}
      <Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "Datacenter"]) === "Oui" ? (
            /* Application custom pour hors NeufCE1 et hors datacenter */
            <Form.Item
              name={[field.name, 'REF-25-C']}
              label="Application du groupe de production de froid :"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner l'application"
                },
              ]}
            >
              <Select
                placeholder="Sélectionnez l'application"
                allowClear
                style={{ width: '500px' }}
              >
                <Option disabled value="0">Climatisation de confort d’un local (hors datacenter)</Option>
                <Option value="1">Climatisation d'un datacenter</Option>
                <Option value="2">Autres applications de type réfrigération ou conditionnement d'ambiance (hors confort des occupants)</Option>
              </Select>
            </Form.Item>

          ) : null
        }
      </Form.Item >

      <Form.Item noStyle shouldUpdate >
        {({ getFieldValue }) =>
          getFieldValue(['items', field.name, "Datacenter"]) === "Oui" || (getFieldValue(['items', field.name, "Datacenter"]) === "Non" && (getFieldValue(['items', field.name, "BatimentNeufCe1"]) === "Oui" || getFieldValue(['items', field.name, "BatimentNeufCe1"]) === "Non")) ? (
            <>
              {/* Type condenseur */}
              < TypeCondenseurREF60C
                label="Type de condenseur :"
                name={[field.name, 'REF-60-C']}
                tooltip={<>
                  <Paragraph style={{ color: 'white' }} >Sont considérés comme systèmes de condensation par rapport à l’atmosphère, les condenseurs suivants :
                    <List.Item>- condenseur à air sec adiabatique ou non,</List.Item>
                    <List.Item>- condenseur à eau plus aéroréfrigérant à air sec adiabatique ou non,</List.Item>
                    <List.Item>- condenseur évaporatif hybride ou non,</List.Item>
                    <List.Item>- condenseur à eau plus tour ouverte hybride ou non,</List.Item>
                    <List.Item>- condenseur à eau plus tour fermée hybride ou non.</List.Item>
                  </Paragraph>
                  Sont considérés comme systèmes de condensation à eau seule, les condenseurs à eau provenant d’une nappe ou d’un cours d’eau.
                </>}
              />
              {/* Puissance */}
              <PuissanceREF26
                label="Puissance électrique nominale totale du groupe de production de froid en kW"
                name={[field.name, 'REF-26']}
                unite="kW"
                tooltip="Dans chaque cas, la puissance électrique nominale à retenir est celle figurant sur la plaque signalétique du groupe de
              production de froid (mono-compresseur ou multi-compresseurs) ou à défaut celle indiquée sur un document issu du fabricant du
              groupe mono-compresseur ou multi-compresseurs. La puissance des compresseurs de secours n’est pas comptabilisée. "
              />
            </>
          ) : null
        }
      </Form.Item >
      {/* Conseils CAMEO */}
      <Collapse
        bordered={false}
        style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
        size='small'
        collapsible='icon'
        expandIcon={
          ({ isActive }) => isActive ?
            <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
        expandIconPosition='end'
        items={[{
          key: '1',
          label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
          children:
            <Card
              tabList={tabListNoTitle}
              activeTabKey={activeTabKey}
              onTabChange={onTabChange}
              size='small'
            >
              {contentListNoTitle[activeTabKey]}
            </Card>,
        }]}
      />

    </>
  );
};
