import BaseAPI from '@apis/base';
import {
    ApiCalculateResponse,
    VolumeType,
    simpleNewProjectForm,
} from '@features/new-project/types';
import { FicheRevision } from '@hooks/new-project/types';
import { TNewProjectForm } from '@store/slices/new-project/types';
import humps from 'humps';

import { convertirClesEnMinuscules } from '../utils';

class NewProjectServices {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }

    async sendNewProjectFormValues(formValues: TNewProjectForm) {
        const body = new FormData();
        const { newProjectFiles, ...rest } = formValues;

        body.append('project_data', JSON.stringify(humps.decamelizeKeys(rest)));

        if (newProjectFiles) {
            for (let index = 0; index < newProjectFiles.length; index++) {
                const element: any = newProjectFiles[index];
                const blob = new Blob([element.originFileObj], {
                    type: element.type,
                });
                const customFile = new File([blob], element.name);
                body.append(
                    'project_files',
                    customFile,
                    `piece-jointe_${index + 1}.${
                        /[.]/.exec(element.name)
                            ? /[^.]+$/.exec(element.name)
                            : undefined
                    }`
                );
            }
        }

        return await this.axios
            .post('/project/new', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => response.data);
    }

    async calculate(data: any) {
        const result = await this.axios
            .post(`/project/operations/calculate`, data)
            .then((response) => response.data);
        const { volumes } = result;

        if (volumes?.length > 0) {
            const volumesFormated = volumes.reduce(
                (acc: VolumeType[], volume: ApiCalculateResponse) => {
                    const nouveauVolume = convertirClesEnMinuscules(volume);
                    acc.push(nouveauVolume);
                    return acc;
                },
                []
            );
            result.volumes = volumesFormated;
        }

        return result;
    }

    async getFicheRevision(
        ficheId: string,
        quotationSingDate: number
    ): Promise<FicheRevision> {
        return await this.axios
            .get(`/project/revision/${ficheId}/${quotationSingDate}`)
            .then((response) => response.data);
    }

    async addNewProjectSimpleForm(data: simpleNewProjectForm) {
        const body = new FormData();
        const { uploadFiles, ...rest } = data;

        body.append('project_data', JSON.stringify(humps.decamelizeKeys(rest)));

        if (uploadFiles) {
            for (let index = 0; index < uploadFiles.length; index++) {
                const element: any = uploadFiles[index];
                const blob = new Blob([element.originFileObj], {
                    type: element.type,
                });
                const customFile = new File([blob], element.name);
                body.append(
                    'project_files',
                    customFile,
                    `piece-jointe_${index + 1}.${
                        /[.]/.exec(element.name)
                            ? /[^.]+$/.exec(element.name)
                            : undefined
                    }`
                );
            }
        }

        return await this.axios
            .post('/project/new', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => response.data);
    }

    async getProjectsRequests() {
        return await this.axios
            .get('/project/projects-requested')
            .then((response) => response.data);
    }

    async getProjectRequestById(projectRequestedId: string) {
        return await this.axios
      .get(`/project-request/${projectRequestedId}/details`)
      .then((response) => response.data);
    }

    async getProjectRequestedDocuments(projectRequestedId: string) {
      return await this.axios
        .get(`/project-request/${projectRequestedId}/documents`)
        .then((response) => response.data);
    }


    async getProjectFormTypeByPartner(siret = 'default') {
        return await this.axios
            .get(`/project/form-type/${siret}`)
            .then((response) => response.data);
    }
}

const newProjectServices = new NewProjectServices();

export default newProjectServices;
