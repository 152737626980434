import { Form, Alert, FormListFieldData, Typography, Divider, Button, Flex, InputNumber, Radio } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import EfficaciteEnergetiqueIREF140F from '../refs/EfficaciteEnergetiqueIREF140F';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import TypeCoupDePouceIREF159Z from '../refs/TypeCoupDePouceIREF159Z';
import MenageModesteREF175 from '../refs/MenageModesteREF175';


const { Title } = Typography

export const Barth1123S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "BAR-TH-112"
    const libelle_fost = "Appareil indépendant de chauffage au bois"
    const denomination_fost = <>Mise en place d’un appareil indépendant de chauffage au bois</>
    const lien_pdf = "https://www.ecologie.gouv.fr/operations-standardisees-deconomies-denergie"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code postal */}
            <ZoneClimatiqueS field={field} />

            {/* ETAS variable continue */}
            <Form.Item
                label="Efficacité énergétique saisonnière :"
                name={[field.name, 'Etas']}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <><Typography>L’efficacité énergétique saisonnière et les émissions de polluants sont mesurés selon les normes suivantes :</Typography>
                        <Typography>- pour les poêles : norme NF EN 13240 ou NF EN 14785 ou NF EN 15250 ;</Typography>
                        <Typography>- pour les foyers fermés, inserts de cheminées intérieures : norme NF EN 13229 ;</Typography>
                        <Typography>- pour les cuisinières utilisées comme mode de chauffage : norme NF EN 12815.</Typography></>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber
                    min={0}
                    controls={false}
                    style={{ maxWidth: '100px' }}
                    addonAfter='%'
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Etas"]) >= 66 && form.getFieldValue(['items', field.name, "Etas"]) < 72) { form.setFields([{ name: ["items", field.name, "IREF-140-F"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 72 && form.getFieldValue(['items', field.name, "Etas"]) < 80) { form.setFields([{ name: ["items", field.name, "IREF-140-F"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 80) { form.setFields([{ name: ["items", field.name, "IREF-140-F"], value: "2" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) < 66) { form.setFields([{ name: ["items", field.name, "IREF-140-F"], value: undefined }]) }

                    }}
                />
            </Form.Item>
            {/* Etas */}
            <EfficaciteEnergetiqueIREF140F
                label="Efficacité énergétique saisonnière :"
                name={[field.name, 'IREF-140-F']}
                disabled
                hidden
            />

            {/* Alerte si ETAS < 66 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Etas"]) < 66 ? (
                        <Alert
                            message="L'efficacité énergétique saisonnière doit être supérieure ou égale à 66%."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Coup de pouce */}
            <Form.Item
                name={[field.name, "EligibleCoupDePouce"]}
                label="Le nouvel appareil de chauffage au bois vient-il en remplacement d'un poêle à charbon ?"
                colon={false}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                  ]}
            >
                <Radio.Group buttonStyle='solid'  onChange={() => {
                        if (form.getFieldValue(['items', field.name, "EligibleCoupDePouce"]) === "Oui") { 
                            form.setFields([{ name: ["items", field.name, "IREF-159-Z"], value: "2" }])
                        }
                        else {
                            form.setFieldValue(['items', field.name, "IREF-159-Z"], undefined)
                            form.setFieldValue(['items', field.name, "REF-175"], undefined)
                        }
                    }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Energie remplacée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "EligibleCoupDePouce"]) === "Oui" ? (
                        <>  
                            <Typography style={{marginBottom: 10}}><Typography.Text strong>L'opération est éligible au bonus Coup de Pouce, renseignez les informations suivantes pour connaître le montant bonifié :</Typography.Text></Typography>
                            <TypeCoupDePouceIREF159Z
                                name={[field.name, "IREF-159-Z"]}
                                label="Énergie remplacée"
                                hidden
                            />
                            <MenageModesteREF175
                                name={[field.name, "REF-175"]}
                                label="Ménage modeste :"
                            />
                        </>
                    ) : null
                }
            </Form.Item>

        </>
    );
};
