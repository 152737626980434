import { useGetNextStepDescription } from '@/hooks/dossier';
import { RootState } from '@/store/store';
import { Title } from '@/utils/constants';
import { Card, Space } from 'antd';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

export const DossierStepDescription: React.FC = () => {
    const { currentDossierFromList } = useSelector(
        (state: RootState) => state.dossierReducer
    );

    const { isPending, data } = useGetNextStepDescription(
        currentDossierFromList?.stepOfDossier?.next
    );

    if (!data?.step) return null;
    const { step, description } = data;

    return (
        <Card className="dossier-description-step" loading={isPending}>
            <Space direction="vertical" size="small">
                <Title level={3} style={{ marginTop: 0 }}>
                    {step}
                </Title>
            </Space>
            {parse(description)}
        </Card>
    );
};
