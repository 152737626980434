import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { TNewProject } from './types';

const initialState = {} as TNewProject;

export const newProjectSlice = createSlice({
    name: 'newProjectSlice',
    initialState,
    reducers: {
        setProfileType: (state, action: PayloadAction<string>) => {
            state.profileType = action.payload;
        },
        setInstallerSiret: (state, action: PayloadAction<string>) => {
            state.installerSiret = action.payload;
        },
        setBenificiarySiret: (state, action: PayloadAction<string>) => {
            state.beneficiarySiret = action.payload;
        },
    },
});

export const { setProfileType, setInstallerSiret, setBenificiarySiret } =
    newProjectSlice.actions;
export default newProjectSlice.reducer;
