import { IDossier, IDossierList } from '@/features/dashboard';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IDossierList = {
    inprogressDossiers: [],
    archivedDossiers: [],
    primes: 0,
};

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        setInprogressDossiers: (state, action: PayloadAction<IDossier[]>) => {
            state.inprogressDossiers = action.payload;
        },
        setArchivedDossiers: (state, action: PayloadAction<IDossier[]>) => {
            state.archivedDossiers = action.payload;
        },
        setPrimes: (state, action: PayloadAction<number>) => {
            state.primes = action.payload;
        },
        resetDashboard: (state) => {
            state.inprogressDossiers = [];
            state.archivedDossiers = [];
            state.primes = 0;
        },
    },
});

export const {
    setInprogressDossiers,
    setArchivedDossiers,
    setPrimes,
    resetDashboard,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
