import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray, removeSpacesFromString } from '@/utils';
import _ from 'lodash';

import * as constant from './constants';
import applicantProfileTypes from './data/applicantProfileTypes.json';
import useOfBuildingTypes from './data/useOfBuildingTypes.json';
import workDetails from './data/workDetails.json';
import {
    createHeatingAnswers,
    createHeatingRecoveryAnswers,
    createOthersWorkAnswers,
    createProcessUtilitiesAnswers,
    createRegulationAnswers,
} from './operations/forms/components/other-operation/operations-types';
import * as constants from './operations/forms/constants';
import {
    InputObject,
    VolumeType,
    variableReference,
} from './types';

const STR_VALUE = '%value%';

interface Item {
    name: string;
    label?: string;
    children?: Item[];
    [key: string]: any;
}

export const cleanNewProjectForm = (
    formValues: TNewProjectForm | string[] | string
) => {
    return JSON.parse(JSON.stringify(formValues));
};

export const checkFileSize = (fileSize: number, acceptedSize: number = 3) => {
  return fileSize > acceptedSize * 1024 * 1024;
};

export const checkTotalFilesSize = (
    fileSizes: number[],
    acceptedSize: number = 10
) => {
    const totalSizeInBytes = fileSizes.reduce((acc, size) => acc + size, 0);
    const acceptedSizeInBytes = acceptedSize * 1024 * 1024;
    return totalSizeInBytes > acceptedSizeInBytes;
};

export const updateFormValues = (
    formValues: TNewProjectForm,
    {
        installerSiret,
        beneficiarySiret,
    }: { installerSiret: string; beneficiarySiret: string }
) => {
    formValues.beneficiarySiret = beneficiarySiret;
    formValues.installerSiret = installerSiret;

    return formValues;
};

export const createJsonForm = (
    values: TNewProjectForm,
) => {
    const jsonValues = {} as any;

    jsonValues.projectName = values.projectName;
    const { label: applicantProfileLabel } = getObjectFromArray(
        applicantProfileTypes,
        'value',
        values.applicantProfile
    );
    jsonValues.applicantProfileLabel = applicantProfileLabel;
    jsonValues.applicantProfile = values.applicantProfile;
    jsonValues.installerSiret = values.installerSiret || undefined;
    jsonValues.beneficiarySiret = values.beneficiarySiret || undefined;
    jsonValues.beneficiaryName = values.beneficiaryName || undefined;
    jsonValues.beneficiaryLastname = values.beneficiaryLastname || undefined;
    jsonValues.beneficiaryFunction = values.beneficiaryFunction || undefined;
    jsonValues.beneficiaryEmail = values.beneficiaryEmail || undefined;
    jsonValues.beneficiaryMobileNumber =
        removeSpacesFromString(values.beneficiaryMobileNumber) || undefined;
    jsonValues.beneficiaryPhoneNumber =
        removeSpacesFromString(values.beneficiaryPhoneNumber) || undefined;

    const useOfBuildingTypeObj = getObjectFromArray(
        useOfBuildingTypes,
        'value',
        values.principaleUseOfBuilding
    );

    const useOfBuildingType = useOfBuildingTypeObj
        ? useOfBuildingTypeObj.label
        : null;
    jsonValues.principaleUseOfBuilding = useOfBuildingType;

    if (
        values.secondeUseOfBuilding &&
        values.secondeUseOfBuilding.length !== 0
    ) {
        const reasonsLables = values.secondeUseOfBuilding.reduce(
            (items: string[], reasons: string) => {
                const { label } = getObjectFromArray(
                    useOfBuildingTypes,
                    'value',
                    reasons
                );
                const item: string = label;
                items.push(item);
                return items;
            },
            []
        );

        jsonValues.secondeUseOfBuilding = reasonsLables;
    }

    const estimateWorkDates = values.estimateWorkDate
        ? cleanNewProjectForm(values.estimateWorkDate)
        : [];
    jsonValues.estimateStartWorkDate = estimateWorkDates[0];
    jsonValues.estimateEndWorkDate = estimateWorkDates[1];

    jsonValues.estimateSignatureDate = cleanNewProjectForm(
        values.estimateSignatureDate
    );
    jsonValues.buldingExisting =
        values.buldingExisting === constant.NO
            ? 'Non'
            : values.buldingExisting === constant.YES
            ? 'Oui'
            : 'Je ne sais pas';

    jsonValues.projectDescription = values.projectDescription || undefined;
    jsonValues.comment = values.workDescriptionComments || undefined;

    if (values.worksType && values.worksType.length !== 0) {
        jsonValues.workDetailsComplete = createWorkTypesValues(values);
    }

    jsonValues.newProjectFiles = values.uploadFiles;

    jsonValues.streetNumber = values.streetNumber
        ? values.streetNumber.replaceAll(' ', '')
        : undefined;
    jsonValues.streetName = values.streetName;
    jsonValues.building = values.building;
    jsonValues.cadastralPlot = values.cadastralPlot;
    jsonValues.zipCode = values.zipCode;
    jsonValues.city = values.city;

    jsonValues.simulations = values.simulations || undefined;
    jsonValues.estimateVolumes = values.estimatevolumes || undefined;

    jsonValues.classicTotalOperations =
        values.classicTotalOperations || undefined;
    jsonValues.totalOperations = values.totalOperations || undefined;

    const {
        projectName,
        applicantProfile,
        installerSiret,
        beneficiarySiret,
        beneficiaryLastnamen,
        beneficiaryFunction,
        beneficiaryEmail,
        beneficiaryMobileNumber,
        beneficiaryPhoneNumber,
        estimateWorkDate,
        estimateSignatureDate,
        streetNumber,
        streetName,
        building,
        cadastralPlot,
        zipCode,
        city,
        projectDescription,
        newProjectFiles,
        workDescriptionComments,
        simulations,
        estimateVolumes,
        classicTotalOperations,
        totalOperations,
        ...nativeData
    } = values;

    jsonValues.nativeDetailsWorksData = nativeData;

    return jsonValues;
};

const createWorkTypesValues = (values: any) => {
    const { worksType } = values;
    const workDetailsComplete = [];
    for (let index = 0; index < worksType.length; index++) {
        const element = worksType[index];

        const item = getObjectFromArray(workDetails, 'name', element);

        switch (item.name) {
            case 'heating':
                workDetailsComplete.push(createHeatingAnswers(item, values));
                break;
            case 'isolation':
                workDetailsComplete.push(
                    createSimpleAnswersOfElement(item, values)
                );
                break;
            case 'regulation':
                workDetailsComplete.push(createRegulationAnswers(item, values));
                break;
            case 'calorifugeage':
                workDetailsComplete.push(
                    createSimpleAnswersOfElement(item, values)
                );
                break;
            case 'processUtilitiesAndCold':
                workDetailsComplete.push(
                    createProcessUtilitiesAnswers(item, values)
                );
                break;
            case 'heatingRecovery':
                workDetailsComplete.push(
                    createHeatingRecoveryAnswers(item, values)
                );
                break;
            case 'othersWorkTypes':
                workDetailsComplete.push(createOthersWorkAnswers(item, values));
                break;
            default:
                return workDetailsComplete;
        }
    }
    return workDetailsComplete;
};

export const setValues = (item: Item, values: any): Item | undefined => {
    if (item.children && Array.isArray(item.children)) {
        const updatedChildren: Item[] = item.children
            .map((child) => {
                const updatedChild = setValues(child, values);
                return updatedChild as Item;
            })
            .filter(Boolean);

        if (updatedChildren.length > 0) {
            return { ...item, children: updatedChildren };
        }

        return undefined;
    } else if (item[values] && Array.isArray(item[values])) {
    } else if (typeof item === 'object' && item !== null) {
        const newItem: Item = { ...item };
        for (const [key, value] of Object.entries(item)) {
            if (key === 'label' && typeof value === 'string') {
                newItem[key] = value.replace(
                    new RegExp(STR_VALUE, 'g'),
                    values[item.name]
                );
            }
        }
        if (newItem.name && !values[newItem.name]) {
            return undefined;
        }
        return newItem;
    } else {
        return item;
    }
};

export const createSimpleAnswersOfElement = (
    item: any,
    values: TNewProjectForm
) => {
    const newItem: typeof item = {};
    newItem.name = item.name;
    newItem.label = item.label;
    newItem.children = [];
    const { children } = item;

    for (let index = 0; index < children.length; index++) {
        const element = children[index];
        if (values[element.name]) {
            const asnwers: any = {};
            asnwers.name = element.name;
            asnwers.label = element.label.replace(
                STR_VALUE,
                values[element.name]
            );
            newItem.children.push(asnwers);
        }
    }

    return newItem;
};

export const createComponentName = (name: string): string => {
    let componentName = name.replace(/[^\w]/gi, '');

    componentName =
        componentName.charAt(0).toUpperCase() +
        componentName.slice(1).toLowerCase();

    return componentName;
};

export const transformToFormat = (
    name: string,
    value: string | number | boolean
) => {
    return {
        referenceName: name,
        referenceValue: value,
        isNa: value === 0 ? true : false,
    };
};

const EXCLUDE_FIELDS = ['cdb'];
export const formFieldsFormat = (input: InputObject): variableReference[] => {
    return Object.entries(input)
        .filter(
            ([key, value]) =>
                value !== undefined &&
                !EXCLUDE_FIELDS.includes(key) &&
                value !== ''
        )
        .map(([key, value]) => ({
            referenceName: key,
            referenceValue:
                typeof value === 'string'
                    ? removeSpacesFromString(value)
                    : value,
            isNa: value === '' || value === undefined ? true : false,
        }));
};

export const getClimaticZoneByZipCode = (value: string) => {
    const dep = value.slice(0, 2);

    if (constants.zone_h1.includes(dep)) {
        return 'H1';
    } else if (constants.zone_h2.includes(dep)) {
        return 'H2';
    } else if (constants.zone_h3.includes(dep)) {
        return 'H3';
    } else {
        return '';
    }
};

export const transformCheckboxValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    replaceWith: string | number | boolean
) => {
    return event.target.checked ? replaceWith : undefined;
};

export const getFicheOperation = (
    operations: VolumeType[],
    targetFicheId: string,
    targetFicheIndex: number
): VolumeType | undefined => {
    return _.find(operations, {
        ficheId: targetFicheId,
        ficheIndex: targetFicheIndex,
    });
};

export const onRemoveOperation = (
    operations: any,
    targetFicheId: string,
    targetFicheIndex: number
) => {
    return _.map(operations, (item) =>
        item.fiche_index === targetFicheIndex && item.fiche === targetFicheId
            ? null
            : item
    );
};

export const filterForProjectCalculation = (
    objects: Record<string, any>[]
): Record<string, any>[] =>{
    const filteredObjects: Record<string, any>[] = [];

    for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];
        if (obj) {
            const filteredObj: Record<string, any> = {};

            filteredObj['fiche_index'] = i;

            let hasRefKey = false;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (key.startsWith('REF') || key.startsWith('IREF') || key === 'climatic_zone') {
                        hasRefKey = true;
                    }
                    if (
                        key === 'fiche' ||
                        key === 'fiche_index' ||
                        key === 'zip_code' ||
                        key === 'climatic_zone' ||
                        key.startsWith('REF') ||
                        key.startsWith('IREF')
                    ) {
                        filteredObj[key] = obj[key];
                    }
                    if (key === 'ChoixFost') {
                        filteredObj['fiche'] = obj[key]['Fost'];
                    }
                }
            }

            if (hasRefKey) {
                filteredObjects.push(filteredObj);
            }
        }
    }
    return filteredObjects;
};

type SortableType = string | number;

export const sortByKey = <T>(
    array: T[],
    key: keyof T,
    type: 'string' | 'number' = 'string'
): T[] => {
    return array.slice().sort((a, b) => {
        let valueA: SortableType = a[key] as SortableType;
        let valueB: SortableType = b[key] as SortableType;

        if (type === 'string') {
            valueA = (valueA as string).toUpperCase();
            valueB = (valueB as string).toUpperCase();
        }

        if (type === 'string') {
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        } else if (type === 'number') {
            return (valueA as number) - (valueB as number);
        } else {
            throw new Error('Unsupported type');
        }
    });
};

export const formatDate = (dateString: string | null | undefined): string =>  {

  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  // Check if the date is invalid
  if (isNaN(date.getTime())) {
    return "";
  }

  // Extract the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
  const year = date.getUTCFullYear();

  // Format the date as "DD-MM-YYYY"
  return `${day}/${month}/${year}`;
}
