import BaseAPI from '@apis/base';

class DashboardServices {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }

    async getUserDossiers() {
        return await this.axios
            .get('/dossiers/')
            .then((response) => response.data);
    }
}

const dashboardServices = new DashboardServices();

export default dashboardServices;
