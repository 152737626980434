import { useCallback } from 'react';

import { IDossierList } from '@/features/dashboard';
import dashboardServices from '@/services/dashboard/dashboardServices';
import {
    setArchivedDossiers,
    setInprogressDossiers,
    setIsAppError,
    setPrimes,
} from '@/store/slices';
import { useAsync } from 'react-async';
import { useDispatch } from 'react-redux';

import { TUseGetDossierList } from './types';

export const useGetDossiersList = (): TUseGetDossierList => {
    const dispatch = useDispatch();

    const getDossiers = useCallback(async () => {
        return await dashboardServices.getUserDossiers();
    }, []);

    const { isPending, isResolved, isRejected, data } = useAsync({
        promiseFn: getDossiers,
        onResolve(dossierList: IDossierList) {
            dispatch(setInprogressDossiers(dossierList.inprogressDossiers));
            dispatch(setArchivedDossiers(dossierList.archivedDossiers));
            dispatch(setPrimes(dossierList.primes));
        },
        onReject(error) {
            dispatch(setIsAppError(true));
        },
    });

    return { isPending, isResolved, isRejected, data };
};
