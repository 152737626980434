import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import DnAllerREF34F from '@/features/new-project/operations/forms/components/common/DnAllerREF34F';
import LongueurCanalisationREF46 from '@/features/new-project/operations/forms/components/common/LongueurCanalisationREF46';
import DureeAnnuelleUtilisationReseauREF130 from '@/features/new-project/operations/forms/components/common/DureeAnnuelleUtilisationReseauREF130';
import NatureFluideREF121C from '@/features/new-project/operations/forms/components/common/NatureFluideREF121C';
import TypeCanalisationREF33C from '@/features/new-project/operations/forms/components/common/TypeCanalisationREF33C';

const { Paragraph } = Typography
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
const { Title } = Typography
export const Resch1064 = ({ field }: { field: FormListFieldData }) => {
    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'
    /* Infos FOST */
    const code_fost = "RES-CH-106"
    const libelle_fost = "Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur"
    const denomination_fost = <>Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur enterré ou en caniveau dans tout ou partie du réseau primaire.</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/documents/RES-CH-106%20vA60-4%20%C3%A0%20compter%20du%2001-03-2024.pdf"
    /* Textes explicatifs */
    const eligibilite = <>
        <Typography>La présente opération s’applique à un 
            <Typography.Text strong style={{ color: primary_color }}> réseau de chaleur primaire </Typography.Text>
            alimentant des bâtiments appartenant à
            <Typography.Text strong style={{ color: primary_color }}> au moins deux abonnés distincts. </Typography.Text>
			La conductivité thermique λ de l’isolant doit être inférieure ou égale à 0,06 W/m.K. L’isolant installé doit garantir que le coefficient de perte thermique Umax (W/m².K) est inférieur à certaines valeurs. Celles-ci sont présentées dans un tableau
disponible dans la fiche descriptive de l'opération.
        </Typography>
    </>
    const facture = <>
        <Typography>La preuve de la réalisation de l’opération mentionne la mise en place d’un calorifugeage sur les canalisations du réseau
de chaleur primaire, la nature du fluide caloporteur (eau chaude, eau surchauffée, vapeur), la longueur (en m), le diamètre
nominal (en mm) des canalisations isolées et les marques et références ainsi que les caractéristiques de l’isolant
(coefficient de conductivité thermique en W/m.K et épaisseur).
        </Typography>
    </>
    const justificatifs = <>
        <Typography>Le document justificatif spécifique à l’opération est 
		<Typography.Text strong style={{ color: primary_color }}> le descriptif de la portion concernée par l’isolation du réseau de chaleur primaire</Typography.Text>
		, précisant sa longueur (en m), son diamètre nominal (en mm), le type de fluide caloporteur (eau chaude,
eau surchauffée ou vapeur) et la durée annuelle d’utilisation du réseau. Ce document identifie le réseau de chaleur
concerné ; il est daté et signé par le bénéficiaire de l’opération et le gestionnaire de ce réseau.
        </Typography>
    </>
    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Preuve de réalisation' },
        { key: '3', label: 'Pièces justificatives' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };
    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>
            {/* réseau de chaleur plus de 1 ans  */}
            <Form.Item
                name={[field.name, "ReseauDeChaleur1An"]}
                label="L'opération est-elle réalisée sur un réseau de chaleur existant depuis plus d'un an ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <>Est considéré comme existant un réseau de chaleur dont la date de première livraison de chaleur
                        est antérieure d’au moins un an à la date d’engagement de l’opération.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "2abo"], undefined)
					form.setFieldValue(['items', field.name, "REF-34-F"], undefined)
					form.setFieldValue(['items', field.name, "REF-46"], undefined)
                    form.setFieldValue(['items', field.name, "REF-130"], undefined)
					form.setFieldValue(['items', field.name, "REF-121-C"], undefined)
					form.setFieldValue(['items', field.name, "REF-33-C"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si réseau de chaleur non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ReseauDeChaleur1An"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un réseau de chaleur existant depuis plus d'un an."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si réseau de chaleur OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ReseauDeChaleur1An"]) === "Oui" ? (
                        <>
                            {/* 2abo */}
                            <Form.Item
                                name={[field.name, "2abo"]}
                                label="Le chaleur primaire alimentant des bâtiments appartenant à au moins deux abonnés distincts. ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: <>Cette opération n'est éligible que si elle est réalisée sur un réseau de chaleur primaire alimentant des bâtiments appartenant à au moins deux abonnés distincts.</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <Radio.Group buttonStyle='solid' onChange={() => {
                                    form.setFieldValue(['items', field.name, "REF-130"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-46"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-34-E"], undefined)
                                }}>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si réseau de chaleur non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "2abo"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si le chaleur primaire alimente des bâtiments appartenant à au moins deux abonnés distincts."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Suite formulaire si réseau de chaleur OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "2abo"]) === "Oui" ? (
                                        <>
                                                        {/* DN Aller */}
														<DnAllerREF34F
															name={[field.name, "REF-34-F"]}
															label="Diamètre initial DN du réseau :"
															tooltip="Le diamètre nominal (DN) correspond à la désignation de dimension commune à tous les 
																	éléments d'une même tuyauterie autre que ceux désignés par leur diamètre extérieur ou intérieur."
														/>
														{/* Longueur canalisation */}
														<LongueurCanalisationREF46
															name={[field.name, "REF-46"]}
															label="Longueur de canalisation concernée (m) :"
														/>
														{/* Durée d'utilisation du réseau */}
														<DureeAnnuelleUtilisationReseauREF130
															name={[field.name, "REF-130"]}
															label="Durée annuelle d’utilisation de la portion du réseau de chaleur concernée :"
															tooltip="La durée annuelle d’utilisation du réseau de chaleur est celle de l’année calendaire précédant la date d’achèvement de l’opération."
														/>
														{/* Type de canalisation */}
														<TypeCanalisationREF33C
															name={[field.name, "REF-33-C"]}
															label="Type de canalisation :"
															tooltip="Les canalisations respectant les exigences relatives à la classe d’isolation thermique 4 sont définies par la norme NF EN 12828. Les canalisations respectant les exigences relatives aux séries d’isolation thermique 1, 2 et 3 sont définies par la norme NF EN 253."
														/>
														{/* Nature du Fluide */}
														<NatureFluideREF121C
															name={[field.name, "REF-121-C"]}
															label="Nature du fluide caloporteur :"
														/>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>
            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};