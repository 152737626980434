import { Form, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

export default function ClasseEnergetiqueREF78I({ name, label, disabled, hidden, tooltip }: { name: NamePath, label: string, disabled?: boolean, hidden?: boolean, tooltip?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
    return (
        <Form.Item
            name={name}
            label={label}
            hidden={hidden}
            rules={[
                { required: true, message: "Veuillez renseigner l'efficacité énergétique saisonnière" },
            ]}
            tooltip={tooltip
                ?
                {
                    title: tooltip,
                    icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                    color: '#002766',
                }
                : null
            }
        >
            <Radio.Group
                buttonStyle='solid'
                disabled={disabled}
            >
                <Radio.Button value="0">Classe A</Radio.Button>
                <Radio.Button value="1">Classe B</Radio.Button>
            </Radio.Group>
        </Form.Item >
    )
}