import React, { useEffect, useState } from 'react';

import usePreserveWithDelay from '@/hooks/antdform/usePreserveWithDelay';
import { useProjectCalculate } from '@/hooks/new-project/useProjectCalculate';
import { useSubmitNewProject } from '@/hooks/new-project/useSubmitNewProject';
import { Container } from '@/layouts';
import { setBenificiarySiret, setInstallerSiret } from '@/store/slices';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { RootState } from '@/store/store';
import { Text, Title } from '@/utils';
import {
    convertCalenderFieldToTimeStamp,
    numberFormatter,
} from '@/utils/helpers';
import { CloseOutlined } from '@ant-design/icons';
import { resetOperationsVolumes } from '@store/slices/new-project/operationsSlice';
import { Button, Card, Divider, Flex, Form, Row, Space, message } from 'antd';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { useWatch } from 'antd/es/form/Form';

import { UploadFiles } from '../new-project/upload/UploadFile';
import PrimeCeeRange from '../public-simulator/PrimeCeeRange';
import { PriceRangeType } from '../public-simulator/utils/utils';
import { ESTIMATE_SIGNATURE_DATE } from './constants';
import { GeneralDescription } from './general-description/GeneralDescription';
import OperationTitle from './operations/OperationTitle';
import { SimulateProject } from './operations/SimulateProject';
import './styles.scss';
import {
    createJsonForm,
    filterForProjectCalculation,
    updateFormValues,
} from './utils';

const NewProject: React.FC = () => {
    /* Couleurs thème */
    const primary_color = '#002766';
    const border_color = '#76B8DE';

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const watchEstimaeSignatureDate = useWatch(ESTIMATE_SIGNATURE_DATE, form);
    const [messageApi, contextHolder] = message.useMessage();
    const { isPending, submitAddNewProject, isResolved, isRejected, error } =
        useSubmitNewProject();
    const { calculateProjectValume, isPending: isCalculatePending } =
        useProjectCalculate();
    const { installerSiret, beneficiarySiret } = useSelector(
        (state: RootState) => state.newProjectReducer
    );
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );

    const { classicTotalOperations, totalOperations, volumes } = useSelector(
        (state: RootState) => state.operationsReducer
    );

    const result = useSelector((state: RootState) => state.operationsReducer);

    const [simulatedItemIndices, setSimulatedItemIndices] = useState<number[]>(
        []
    );

    const [isPreserve, setIsPreserve] = usePreserveWithDelay(true, 1500);

    const onFinish = () => {
        const formvalues: TNewProjectForm = form.getFieldsValue();

        const { items: simulations } = form.getFieldsValue();

        const updateValues = updateFormValues(formvalues, {
            installerSiret,
            beneficiarySiret,
        });
        const data = createJsonForm(updateValues);
        data.accountSiret = heliosUser?.partner?.siret!;
        data.companyName = heliosUser?.partner?.companyName!;
        data.contactFullName = `${heliosUser?.firstName} ${heliosUser?.lastName}`;
        data.projectFormType = 'SIMULATOR';
        data.simulations = simulations;
        data.estimateVolumes = volumes;
        data.classicTotalOperations = classicTotalOperations;
        data.totalOperations = totalOperations;
        submitAddNewProject(data);
    };

    useEffect(() => {
        if (isRejected) {
            if (error && (error as AxiosError).request) {
                const responseData = JSON.parse(
                    (error as AxiosError).request.response
                );
                const errorMessage = responseData?.detail?.project_files?.[0];

                if (errorMessage && errorMessage.includes('fichier')) {
                    messageApi.error(errorMessage);
                } else {
                    messageApi.error(
                        `Un problème s'est produit, veuillez réessayer plus tard.`
                    );
                }
            } else {
                // Handle the case where there is no error message available
                messageApi.error(
                    `Un problème s'est produit, veuillez réessayer plus tard.`
                );
            }
        }

        if (isResolved) {
            messageApi.success(`La demande a bien été prise en compte`);
            dispatch(setBenificiarySiret(''));
            dispatch(setInstallerSiret(''));
            form.resetFields();
        }
    }, [isRejected, isResolved, isPending, form, messageApi, error, dispatch]);

    const simulateProjects = async () => {
        const { items: data } = form.getFieldsValue();
        const items = filterForProjectCalculation(data);

        const estimateSignatureDate = form.getFieldValue(
            ESTIMATE_SIGNATURE_DATE
        );

        const result = await calculateProjectValume({
            items: items,
            signatureQuotationDate: convertCalenderFieldToTimeStamp(
                estimateSignatureDate
            ),
        });

        if (result?.volumes?.length && result.volumes.length > 0) {
            const successfulIndices = items.map((item) => item?.fiche_index);
            setSimulatedItemIndices(successfulIndices);
        }
    };

    const handleSimulateProject = () => {
        form.validateFields()
            .then(() => {
                simulateProjects();
            })
            .catch((error) => {
                form.scrollToField(error['errorFields'][0]['name'][0], {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            });
    };

    useEffect(() => {
        return () => {
            dispatch(resetOperationsVolumes());
        };
    }, [dispatch]);

    return (
        <Container>
            <Card style={{ height: '100%' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={{
                        required: 'Ce champ est obligatoire',
                    }}
                    className="add-project-form"
                    name="projects-simulate-form"
                    initialValues={{ items: [{}] }}
                    preserve={isPreserve}
                    scrollToFirstError={{
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    }}
                >
                    <Space direction="vertical" size="large">
                        <div>
                            <GeneralDescription isSimulator={true} />
                            <Divider />
                            <Title level={4}>3. Simulez votre projet</Title>
                            <Text>
                                Cette section vous permet de nous fournir le
                                détail des opérations que vous souhaitez
                                réaliser et vous propose une estimation du
                                montant de la prime associée pour le
                                bénéficiaire des travaux. Si une ou plusieurs
                                des opérations nécessaires à votre projet sont
                                manquantes dans ce simulateur, vous pouvez nous
                                fournir les éléments dans la section «
                                Décrivez-nous votre projet ». Vous pouvez aussi
                                utiliser l’espace de collecte de documents en
                                bas de ce formulaire.
                            </Text>

                            <div>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                rowGap: 16,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {fields.map((field, index) => (
                                                <Card
                                                    size="small"
                                                    title={
                                                        <OperationTitle
                                                            field={field}
                                                            isDone={simulatedItemIndices.includes(
                                                                index
                                                            )}
                                                        />
                                                    }
                                                    key={field.key}
                                                    extra={
                                                        (field.name !== 0 ||
                                                            simulatedItemIndices.includes(
                                                                0
                                                            )) && (
                                                            <CloseOutlined
                                                                onClick={() => {
                                                                    remove(
                                                                        field.name
                                                                    );
                                                                    simulateProjects();
                                                                    if (
                                                                        field.name ===
                                                                            0 &&
                                                                        simulatedItemIndices.length ===
                                                                            1
                                                                    ) {
                                                                        setSimulatedItemIndices(
                                                                            []
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }
                                                >
                                                    <SimulateProject
                                                        field={field}
                                                        isSimulated={simulatedItemIndices.includes(
                                                            index
                                                        )}
                                                        onFicheCancelled={() =>
                                                            setIsPreserve(false)
                                                        }
                                                    />

                                                    {/* Bandeau pour afficher la prime opération */}
                                                    <Divider
                                                        orientation="left"
                                                        orientationMargin={0}
                                                        style={{
                                                            color: primary_color,
                                                            borderColor:
                                                                border_color,
                                                            marginTop: '12px',
                                                            marginBottom:
                                                                '12px',
                                                        }}
                                                    ></Divider>

                                                    <Flex
                                                        justify="flex-end"
                                                        align="center"
                                                    >
                                                        {result.volumes?.find(
                                                            (volume) =>
                                                                volume?.ficheIndex ===
                                                                index
                                                        ) && (
                                                            <Space
                                                                direction="vertical"
                                                                align="end"
                                                            >
                                                                <Space size="small">
                                                                    <Text
                                                                        strong
                                                                    >
                                                                        {`Volume total: ${volumes[index]?.total} kWhc`}
                                                                    </Text>
                                                                </Space>
                                                                <PrimeCeeRange
                                                                    priceRangeType={
                                                                        PriceRangeType.PRIVATE_DETAIL
                                                                    }
                                                                    minPrice={
                                                                        result.volumes?.find(
                                                                            (
                                                                                volume
                                                                            ) =>
                                                                                volume?.ficheIndex ===
                                                                                index
                                                                        )
                                                                            ?.totalprimemin
                                                                    }
                                                                    maxPrice={
                                                                        result.volumes?.find(
                                                                            (
                                                                                volume
                                                                            ) =>
                                                                                volume?.ficheIndex ===
                                                                                index
                                                                        )
                                                                            ?.totalprimemax
                                                                    }
                                                                    mediumPrice={
                                                                        result.volumes?.find(
                                                                            (
                                                                                volume
                                                                            ) =>
                                                                                volume?.ficheIndex ===
                                                                                index
                                                                        )
                                                                            ?.totalprimemedium
                                                                    }
                                                                    isPublic={
                                                                        false
                                                                    }
                                                                />
                                                            </Space>
                                                        )}
                                                    </Flex>
                                                </Card>
                                            ))}
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add();
                                                }}
                                                block
                                            >
                                                + Ajouter une opération
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                            </div>

                            {volumes?.length > 0 && (
                                <Card style={{ marginTop: '15px' }}>
                                    <Flex align="flex-end" justify="flex-end">
                                        <Space direction="vertical" align="end">
                                            <Space size="small">
                                                <Text strong>
                                                    {`Volume${
                                                        volumes.length > 1
                                                            ? 's'
                                                            : ''
                                                    } classique${
                                                        volumes.length > 1
                                                            ? 's'
                                                            : ''
                                                    } : ${numberFormatter(
                                                        classicTotalOperations
                                                    )} kWhc`}
                                                </Text>
                                            </Space>
                                            <Space size="small">
                                                <Text strong>
                                                    {`Volume${
                                                        volumes.length > 1
                                                            ? 's'
                                                            : ''
                                                    } tot${
                                                        volumes.length > 1
                                                            ? 'aux'
                                                            : 'al'
                                                    } : ${numberFormatter(
                                                        totalOperations
                                                    )} kWhc`}
                                                </Text>
                                            </Space>

                                            <PrimeCeeRange
                                                priceRangeType={
                                                    PriceRangeType.PRIVATE_TOTAL
                                                }
                                                minPrice={result.totalPrimeMin}
                                                maxPrice={result.totalPrimeMax}
                                                mediumPrice={
                                                    result.totalPrimeMedium
                                                }
                                                isPublic={false}
                                            />
                                        </Space>
                                    </Flex>
                                </Card>
                            )}

                            <Divider />
                            <UploadFiles />
                        </div>

                        <Row justify="center">
                            <Space
                                direction="vertical"
                                size="middle"
                                className="add-project-submit"
                            >
                                <Space size="large" wrap>
                                    <Button
                                        type="primary"
                                        loading={
                                            isPending || isCalculatePending
                                        }
                                        disabled={
                                            isPending ||
                                            isCalculatePending ||
                                            !watchEstimaeSignatureDate
                                        }
                                        onClick={handleSimulateProject}
                                        htmlType="submit"
                                    >
                                        Simulez votre projet
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ alignSelf: 'center' }}
                                        loading={
                                            isPending || isCalculatePending
                                        }
                                    >
                                        Soumettre mon projet
                                    </Button>
                                </Space>
                                <Text>
                                    Après soumission, nous analysons votre
                                    projet et revenons vers vous sous 48h
                                </Text>
                            </Space>
                        </Row>
                    </Space>
                </Form>
            </Card>
            {contextHolder}
        </Container>
    );
};

export default NewProject;
