import React, { useEffect, useState } from 'react';

import { AuthenticationCard, PasswordValidation, Spinner } from '@/components';
import { useCompletePassword } from '@/hooks/coginto/useCompletePassword';
import { RootState } from '@/store/store';
import { Text } from '@/utils';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { validation } from '@features/auth/index';
import { Button, Form, Input, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import type { TCompleteNewPassword } from '../index';
import '../styles.scss';

const CompleteNewPassword: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.congintoReducer);
    const [isUserLoaded, setIsUserLoaded] = useState<boolean>(true);
    const [passwordsFields, setPasswordsFields] =
        useState<TCompleteNewPassword>({
            password: '',
            confirmPassword: '',
        });

    useEffect(() => {
        if (!user) {
            navigate('/');
        }

        setIsUserLoaded(false);
    }, [user, navigate]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordsFields({
            ...passwordsFields,
            [e.target.name]: e.target.value,
        });
    };

    const { isPending, isRejected, onCompletePassword } = useCompletePassword();

    const handleVerificationPassowrd = async () => {
        onCompletePassword(user!, passwordsFields.password);
    };

    if (isUserLoaded) {
        <Spinner />;
    }
    return (
        <AuthenticationCard title="Création du mot de passe">
            {isRejected && (
                <Text type="danger">
                    Un problème s'est produit, veuillez réessayer plus tard.
                </Text>
            )}
            <Form
                name="compelete-new-password"
                style={{ width: '100%' }}
                autoComplete="off"
                className="form__authentication"
                onFinish={handleVerificationPassowrd}
            >
                <Form.Item
                    name="password"
                    rules={validation.passwordValidation}
                >
                    <Input.Password
                        name="password"
                        placeholder="Mot de passe"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={handleChange}
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    rules={[
                        validation.confirmPasswordValidation,
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        'Le mot de passe et la confirmation ne sont pas identiques'
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        name="confirmPassword"
                        placeholder="Confirmation du mot de passe"
                        iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        onChange={handleChange}
                    />
                </Form.Item>
                <Form.Item>
                    <PasswordValidation value={passwordsFields.password} />
                </Form.Item>
                <Row justify={'center'}>
                    <Form.Item className="form__btn">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isPending}
                        >
                            Enregistrer
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </AuthenticationCard>
    );
};

export default CompleteNewPassword;
