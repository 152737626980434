import { Form, InputNumber } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

export default function PuissanceREF26({label, name, unite, tooltip}: {label: string, name: NamePath, unite: string, tooltip?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[]}) { 
    return <Form.Item
                name={name}
                label={label}
                tooltip={{
                    title:tooltip,
                    icon: <InfoCircleOutlined style={{ color:'#002766' }}/>,
                    color: '#0086CA',
                    }}
                rules={[
                {required: true, message:"Veuillez renseigner la puissance totale"},
                ]}
            >
                <InputNumber addonAfter={unite} min='0' decimalSeparator=','/>
            </Form.Item>
}
