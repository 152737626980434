import { ChangeEvent, useState } from 'react';

import {
  BranchesOutlined,
  BulbOutlined,
  ExperimentOutlined,
  FileOutlined,
  FireOutlined,
  HomeOutlined,
  SettingOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import {
  ConfigProvider,
  Empty,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Radio,
  Space,
  Tooltip,
  Typography,
} from 'antd';

import useFormInstance from 'antd/es/form/hooks/useFormInstance';

export const MenuChoix = ({
  field,
  buttonStyle,
  fostStyle,
}: {
  field: FormListFieldData;
  buttonStyle: React.CSSProperties;
  fostStyle: React.CSSProperties;
}) => {
  /* Dimensions */
  const hauteur_bouton = '36px';

  const form = useFormInstance();
  /* Couleurs */
  const primary_color = '#002766';
  const subtitle_color = '#5E5E5E';

  // Recherche par mot-clé
  const [recherche, setRecherche] = useState('');
  const handleRecherche = (e: ChangeEvent<HTMLInputElement>) => {
    // 👇 Store the input value to local state
    setRecherche(e.target.value);
  };

  /* Liste des secteurs */
  const secteurProps = [
    {
      key: 1,
      code: 'BAR',
      libelle: 'Résidentiel',
      icon: <HomeOutlined />,
    },
    {
      key: 2,
      code: 'BAT',
      libelle: 'Tertiaire',
      icon: <ShopOutlined />,
    },
    {
      key: 3,
      code: 'IND',
      libelle: 'Industrie',
      icon: <ExperimentOutlined />,
    },
    {
      key: 4,
      code: 'RES',
      libelle: 'Réseaux',
      icon: <BranchesOutlined />,
    },
  ];

  /* Liste des typologies */
  const typoBarProps = [
    {
      key: 1,
      code: 'TH',
      libelle: 'Thermique',
      icon: <FireOutlined />,
    },
    {
      key: 2,
      code: 'EQ',
      libelle: 'Equipements',
      icon: <SettingOutlined />,
    },
  ];
  const typoBatProps = [
    {
      key: 1,
      code: 'TH',
      libelle: 'Thermique',
      icon: <FireOutlined />,
    },
    {
      key: 2,
      code: 'EQ',
      libelle: 'Equipements',
      icon: <SettingOutlined />,
    },
  ];
  const typoIndProps = [
    {
      key: 1,
      code: 'BA',
      libelle: 'Bâtiment',
      icon: <ExperimentOutlined />,
    },
    {
      key: 2,
      code: 'UT',
      libelle: 'Utilités',
      icon: <ExperimentOutlined />,
    },
  ];
  const typoResProps = [
    {
      key: 1,
      code: 'CH',
      libelle: 'Chauffage',
      icon: <FireOutlined />,
    },
    {
      key: 2,
      code: 'EC',
      libelle: 'Eclairage',
      icon: <BulbOutlined />,
    },
  ];

  /* Liste des infos FOST pour remplir les radio.buttons */
  const fostBarThProps = [
    {
      key: 1,
      code: 'BAR-TH-101',
      libelle: 'Chauffe-eau solaire individuel (France métropolitaine)',
      denomination: 'Mise en place d’un chauffe-eau solaire individuel (CESI)',
    },
    {
      key: 2,
      code: 'BAR-TH-107',
      libelle: 'Chaudière collective à haute performance énergétique',
      denomination:
        'Mise en place d’une chaudière collective haute performance énergétique',
    },
    {
      key: 3,
      code: 'BAR-TH-107-SE',
      libelle:
        'Chaudière collective haute performance énergétique avec contrat assurant la conduite de l’installation',
      denomination:
        'Mise en place d’une chaudière collective haute performance énergétique accompagnée d’un contrat assurant la conduite de l’installation',
    },
    {
      key: 4,
      code: 'BAR-TH-112',
      libelle: 'Appareil indépendant de chauffage au bois',
      denomination:
        'Mise en place d’un appareil indépendant de chauffage au bois',
    },
    {
      key: 5,
      code: 'BAR-TH-113',
      libelle: 'Chaudière biomasse individuelle',
      denomination: 'Mise en place d’une chaudière biomasse individuelle',
    },
    {
      key: 6,
      code: 'BAR-TH-123',
      libelle:
        'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
      denomination:
        'Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative sur un circuit de chauffage collectif à combustible existant',
    },
    {
      key: 7,
      code: 'BAR-TH-125',
      libelle:
        'Système de ventilation double flux autoréglable ou modulé à haute performance (France métropolitaine)',
      denomination:
        'Mise en place d’un système de ventilation mécanique contrôlée double flux autoréglable en installation individuelle ou collective, ou modulé avec bouches d’extraction hygroréglables en installation individuelle seulement',
    },
    {
      key: 8,
      code: 'BAR-TH-129',
      libelle: 'Pompe à chaleur de type air/air',
      denomination: 'Mise en place d’une pompe à chaleur (PAC) de type air/air',
    },
    {
      key: 9,
      code: 'BAR-TH-143',
      libelle: 'Système solaire combiné (France métropolitaine)',
      denomination:
        'Mise en place d’un système solaire combiné (SSC) destiné au chauffage et à la production d’eau chaude sanitaire',
    },
    {
      key: 10,
      code: 'BAR-TH-148',
      libelle: 'Chauffe-eau thermodynamique à accumulation',
      denomination:
        'Mise en place d’un chauffe-eau thermodynamique individuel à accumulation',
    },
    {
      key: 11,
      code: 'BAR-TH-150',
      libelle:
        'Pompe à chaleur collective à absorption de type air/eau ou eau/eau',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) à absorption de type air/eau ou eau/eau fonctionnant au gaz naturel ou au propane pour un système de chauffage collectif',
    },
    {
      key: 12,
      code: 'BAR-TH-159',
      libelle:
      "Pompe à chaleur hybride individuelle",
      denomination:
      "Mise en place d’une pompe à chaleur air/eau individuelle comportant un dispositif d’appoint utilisant un combustible liquide ou gazeux et une régulation qui les pilote",
    },
    {
      key: 13,
      code: 'BAR-TH-166',
      libelle: 'Pompe à chaleur collective de type air/eau ou eau/eau',
      denomination:
        'Mise en place d’une ou plusieurs pompes à chaleur (PAC) de type air/eau ou eau/eau de puissance thermique nominale inférieure ou égale à 400 kW pour un système de chauffage collectif',
    },
    {
      key: 14,
      code: 'BAR-TH-171',
      libelle: 'Pompe à chaleur de type air/eau',
      denomination: 'Mise en place d’une pompe à chaleur (PAC) de type air/eau',
    },
    {
      key: 15,
      code: 'BAR-TH-172',
      libelle: 'Pompe à chaleur de type eau/eau ou sol/eau',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) de type eau/eau ou sol/eau',
    },
  ];
  const fostBatThProps = [
    {
      key: 1,
      code: 'BAT-TH-102',
      libelle: 'Chaudière collective à haute performance énergétique',
      denomination:
        'Mise en place d’une chaudière à haute performance énergétique pour un système de chauffage central à combustible',
    },
    {
      key: 2,
      code: 'BAT-TH-109',
      libelle:
        'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
      denomination:
        'Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative sur un circuit de chauffage collectif à combustible existant',
    },
    {
      key: 3,
      code: 'BAT-TH-112',
      libelle:
        'Système de variation électronique de vitesse sur un moteur asynchrone',
      denomination:
        'Mise en place d’un système de variation électronique de vitesse (VEV) sur un moteur asynchrone existant dépourvu de ce système, ou neuf de puissance nominale inférieure ou égale à 3 MW',
    },
    {
      key: 4,
      code: 'BAT-TH-113',
      libelle: 'Pompe à chaleur de type air/eau ou eau/eau',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) de type air/eau ou eau/eau',
    },
    {
      key: 5,
      code: 'BAT-TH-116',
      libelle:  "Système de gestion technique du bâtiment pour le chauffage, l’eau chaude sanitaire, le refroidissement/climatisation, l’éclairage et les auxiliaires",
      denomination:
        "Mise en place d’un système de gestion technique du bâtiment pour un usage chauffage et, le cas échéant, eau chaude sanitaire, refroidissement/climatisation, éclairage et auxiliaires"
    },
    {
      key: 6,
      code: 'BAT-TH-125',
      libelle:
        'Ventilation mécanique simple flux à débit d’air constant ou modulé',
      denomination:
        'Mise en place d’une ventilation mécanique simple flux à débit d’air constant ou modulée',
    },
    {
      key: 7,
      code: 'BAT-TH-126',
      libelle:
        'Ventilation mécanique double flux avec échangeur à débit d’air constant ou modulé',
      denomination:
        'Mise en place d’une ventilation mécanique double flux, avec échangeur, à débit d’air constant ou modulée',
    },
    {
      key: 8,
      code: 'BAT-TH-139',
      libelle:
        'Système de récupération de chaleur sur un groupe de production de froid',
      denomination:
        'Mise en place d’un système de récupération de chaleur sur un groupe de production de froid afin de chauffer ou préchauffer un fluide caloporteur (e.g. de l’eau), sur site, pour le chauffage du bâtiment, la production d’eau chaude sanitaire ou un besoin en procédé',
    },
    {
      key: 9,
      code: 'BAT-TH-140',
      libelle: 'Pompe à chaleur à absorption de type air/eau ou eau/eau',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) à absorption de type air/eau ou eau/eau fonctionnant au gaz naturel ou au propane',
    },
    {
      key: 10,
      code: 'BAT-TH-141',
      libelle: 'Pompe à chaleur à moteur gaz de type air/eau',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) à moteur gaz de type air/eau',
    },
    {
      key: 11,
      code: 'BAT-TH-158',
      libelle: 'Pompe à chaleur réversible de type air/air',
      denomination:
        'Mise en place d’une pompe à chaleur (PAC) réversible de type air/air de puissances calorifique et frigorifique nominales inférieures ou égales à 1 MW',
    },
  ];
  const fostBatEqProps = [
    {
      key: 1,
      code: 'BAT-EQ-127',
      libelle: 'Luminaire à modules LED',
      denomination: 'Mise en place d’un luminaire à modules LED',
    },
  ];
  const fostIndUtProps = [
    {
      key: 1,
      code: 'IND-UT-102',
      libelle:
        'Système de variation électronique de vitesse sur un moteur asynchrone',
      denomination:
        'Mise en place d’un système de variation électronique de vitesse (VEV) sur un moteur asynchrone existant dépourvu de ce système, ou neuf de puissance nominale inférieure ou égale à 3 MW',
    },
    {
      key: 2,
      code: 'IND-UT-104',
      libelle:
        'Économiseur sur les effluents gazeux d’une chaudière de production de vapeur',
      denomination:
        'Mise en place d’un économiseur sur les effluents gazeux d’une chaudière de production de vapeur de puissance thermique nominale inférieure à 20 MW et alimentée au gaz naturel ou au GPL',
    },
    {
      key: 3,
      code: 'IND-UT-105',
      libelle: 'Brûleur micro-modulant sur chaudière industrielle',
      denomination:
        'Mise en place d’un brûleur micro-modulant sur une chaudière industrielle de puissance thermique nominale inférieure à 20 MW',
    },
    {
      key: 4,
      code: 'IND-UT-117',
      libelle:
        'Système de récupération de chaleur sur un groupe de production de froid',
      denomination:
        'Mise en place d’un système de récupération de chaleur sur un groupe de production de froid afin de chauffer ou préchauffer un fluide caloporteur (e.g. de l’eau), sur site',
    },
    {
      key: 5,
      code: 'IND-UT-118',
      libelle:
        'Brûleur avec dispositif de récupération de chaleur sur four industriel',
      denomination:
        'Mise en place d’un brûleur auto-récupérateur ou d’un brûleur régénératif (autorégénératif ou paire de brûleurs régénératifs) ou d’un récupérateur de chaleur sur les fumées pour préchauffer l’air comburant sur un four industriel',
    },
    {
      key: 6,
      code: 'IND-UT-130',
      libelle:
        'Condenseur sur les effluents gazeux d’une chaudière de production de vapeur',
      denomination:
        'Mise en place d’un condenseur sur les effluents gazeux d’une chaudière de production de vapeur de puissance thermique nominale inférieure à 20 MW, équipée d’un économiseur et alimentée au gaz naturel ou au GPL',
    },
  ];
  const fostResChProps = [
    {
      key: 1,
      code: 'RES-CH-103',
      libelle:
        'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment tertiaire',
      denomination:
        'Réhabilitation d’un poste de livraison de chaleur par le remplacement de la totalité des éléments constitutifs du primaire du poste de livraison',
    },
    {
      key: 2,
      code: 'RES-CH-104',
      libelle:
        'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment résidentiel',
      denomination:
        'Réhabilitation d’un poste de livraison de chaleur par le remplacement de la totalité des éléments constitutifs du primaire du poste de livraison',
    },
    {
      key: 3,
      code: 'RES-CH-105',
      libelle: 'Passage d’un réseau de chaleur en basse température',
      denomination:
        'Passage d’une utilisation d’un réseau de chaleur en haute pression (eau surchauffée), ou d’une partie d’un réseau de chaleur haute pression, à une utilisation du réseau de chaleur en basse pression et basse température (eau chaude inférieure à 120°C)',
    },
    {
      key: 4,
      code: 'RES-CH-106',
      libelle: 'Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur',
      denomination:
        'Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur enterré ou en caniveau dans tout ou partie du réseau primaire',
    },
    {
      key: 5,
      code: 'RES-CH-108',
      libelle:
        'Récupération de chaleur fatale pour valorisation vers un réseau de chaleur ou vers un tiers (France métropolitaine)',
      denomination:
        'Mise en place d’un système de récupération de chaleur fatale valorisée vers un réseau de chaleur ou un site tiers',
    },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: primary_color,
            colorText: primary_color,
          },
          components: {
            Radio: {
              padding: 0,
            },
          },
        }}
      >
        <Form.Item noStyle>
          <Flex wrap="wrap" gap="large" align="top">
            <Flex gap="small">
                {/* Secteurs */}
            <Form.Item
              name={[field.name, 'ChoixFost', 'Secteur']}
              style={{ margin: 0 }}
              rules={[{ required: true }]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={() => {
                  form.setFieldValue(
                    ['items', field.name, 'ChoixFost', 'Typologie'],
                    undefined
                  );
                  form.setFieldValue(
                    ['items', field.name, 'ChoixFost', 'Fost'],
                    undefined
                  );
                }}
              >
                <Space direction="vertical">
                  <Typography.Text strong style={{ color: subtitle_color }}>
                    Secteur :
                  </Typography.Text>
                  {secteurProps.map((secteur) => (
                    <Radio.Button
                      key={secteur.key}
                      value={secteur.code}
                      style={buttonStyle}
                    >
                      <Space key={secteur.key} direction="horizontal">
                        {secteur.icon}
                        <Typography.Text
                          key={secteur.key}
                          strong
                          style={{ lineHeight: '34px' }}
                        >
                          {secteur.libelle}
                        </Typography.Text>
                      </Space>
                    </Radio.Button>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>

            {/* Typologies */}

            {/* BAR */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue(['items', field.name, 'ChoixFost', 'Secteur']) ===
                'BAR' ? (
                  <Form.Item
                    name={[field.name, 'ChoixFost', 'Typologie']}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={() => {
                        form.resetFields([
                          ['items', field.name, 'ChoixFost', 'Fost'],
                        ]);
                      }}
                    >
                      <Space direction="vertical">
                        <Typography.Text
                          strong
                          style={{ color: subtitle_color }}
                        >
                          Typologie :
                        </Typography.Text>
                        {typoBarProps.map((typo) => (
                          <Radio.Button
                            key={typo.key}
                            value={typo.code}
                            style={buttonStyle}
                          >
                            <Space direction="horizontal">
                              {typo.icon}
                              <Typography.Text
                                strong
                                style={{ lineHeight: hauteur_bouton }}
                              >
                                {typo.libelle}
                              </Typography.Text>
                            </Space>
                          </Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            {/* BAT */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue(['items', field.name, 'ChoixFost', 'Secteur']) ===
                'BAT' ? (
                  <Form.Item
                    name={[field.name, 'ChoixFost', 'Typologie']}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={() => {
                        form.resetFields([
                          ['items', field.name, 'ChoixFost', 'Fost'],
                        ]);
                      }}
                    >
                      <Space direction="vertical">
                        <Typography.Text
                          strong
                          style={{ color: subtitle_color }}
                        >
                          Typologie :
                        </Typography.Text>
                        {typoBatProps.map((typo) => (
                          <Radio.Button
                            key={typo.key}
                            value={typo.code}
                            style={buttonStyle}
                          >
                            <Space direction="horizontal">
                              {typo.icon}
                              <Typography.Text
                                strong
                                style={{ lineHeight: hauteur_bouton }}
                              >
                                {typo.libelle}
                              </Typography.Text>
                            </Space>
                          </Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            {/* IND */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue(['items', field.name, 'ChoixFost', 'Secteur']) ===
                'IND' ? (
                  <Form.Item
                    name={[field.name, 'ChoixFost', 'Typologie']}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={() => {
                        form.resetFields([
                          ['items', field.name, 'ChoixFost', 'Fost'],
                        ]);
                      }}
                    >
                      <Space direction="vertical">
                        <Typography.Text
                          strong
                          style={{ color: subtitle_color }}
                        >
                          Typologie :
                        </Typography.Text>
                        {typoIndProps.map((typo) => (
                          <Radio.Button
                            key={typo.key}
                            value={typo.code}
                            style={buttonStyle}
                          >
                            <Space direction="horizontal">
                              {typo.icon}
                              <Typography.Text
                                strong
                                style={{ lineHeight: hauteur_bouton }}
                              >
                                {typo.libelle}
                              </Typography.Text>
                            </Space>
                          </Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            {/* RES */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue(['items', field.name, 'ChoixFost', 'Secteur']) ===
                'RES' ? (
                  <Form.Item
                    name={[field.name, 'ChoixFost', 'Typologie']}
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={() => {
                        form.resetFields([
                          ['items', field.name, 'ChoixFost', 'Fost'],
                        ]);
                      }}
                    >
                      <Space direction="vertical">
                        <Typography.Text
                          strong
                          style={{ color: subtitle_color }}
                        >
                          Typologie :
                        </Typography.Text>
                        {typoResProps.map((typo) => (
                          <Radio.Button
                            key={typo.key}
                            value={typo.code}
                            style={buttonStyle}
                          >
                            <Space direction="horizontal">
                              {typo.icon}
                              <Typography.Text
                                strong
                                style={{ lineHeight: hauteur_bouton }}
                              >
                                {typo.libelle}
                              </Typography.Text>
                            </Space>
                          </Radio.Button>
                        ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Flex>


            {/* Fiches */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) =>
                getFieldValue([
                  'items',
                  field.name,
                  'ChoixFost',
                  'Typologie',
                ]) !== undefined ? (
                  <>
                    <Space direction="vertical">
                      <Flex
                        align="flex-start"
                        gap="small"
                        style={{ marginTop: 3 }}
                      >
                        <Typography.Text
                          strong
                          style={{ color: subtitle_color, minWidth: '72px' }}
                        >
                          Opération :
                        </Typography.Text>

                        {/* Recherche par mots-clés */}
                        <Flex align="center" gap="small">
                          <Typography.Text
                            strong
                            style={{
                              fontSize: '0.65rem',
                              lineHeight: 1,
                              minWidth: '70px',
                            }}
                          >
                            Recherche par mot-clé :
                          </Typography.Text>
                          <Input
                            allowClear
                            size="small"
                            value={recherche}
                            onChange={handleRecherche}
                          />
                        </Flex>
                      </Flex>

                      {/* Fiches BAR-TH */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'BAR' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'TH' ? (
                            <Form.Item
                              name={[field.name, 'ChoixFost', 'Fost']}
                              rules={[{ required: true }]}
                            >
                              <Radio.Group buttonStyle="solid">
                                <Space direction="vertical">
                                  {fostBarThProps
                                    .filter((fost) =>
                                      fost.libelle
                                        .toLowerCase()
                                        .includes(recherche.toLowerCase()) ||
                                      fost.code.toLowerCase().includes(recherche.toLowerCase())
                                    )
                                    .map((fost) => (
                                      <Radio.Button
                                        key={fost.key}
                                        value={fost.code}
                                        style={fostStyle}
                                      >
                                        <Tooltip
                                          title={fost.denomination}
                                          color={primary_color}
                                          mouseEnterDelay={1}
                                        >
                                          <Space
                                            direction="horizontal"
                                            align="center"
                                            style={{
                                              height: '100%',
                                              alignItems: 'left',
                                            }}
                                          >
                                            <FileOutlined />
                                            <Typography>
                                              <Typography>
                                                <Typography.Text
                                                  strong
                                                  style={{
                                                    whiteSpace: 'normal',
                                                    fontSize: '0.9rem',
                                                    lineHeight: 0.5,
                                                  }}
                                                >
                                                  {fost.libelle}
                                                </Typography.Text>
                                              </Typography>
                                              <Typography
                                                style={{ lineHeight: 0.5 }}
                                              >
                                                <Typography.Text
                                                  style={{ fontSize: '0.6rem' }}
                                                >
                                                  {fost.code}
                                                </Typography.Text>
                                              </Typography>
                                            </Typography>
                                          </Space>
                                        </Tooltip>
                                      </Radio.Button>
                                    ))}
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches BAR-EQ */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'BAR' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'EQ' ? (
                            <>
                              <Empty description="Prochainement"></Empty>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches BAT-TH */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'BAT' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'TH' ? (
                            <>
                              <Form.Item
                                name={[field.name, 'ChoixFost', 'Fost']}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group buttonStyle="solid">
                                  <Space direction="vertical">
                                    {fostBatThProps
                                      .filter((fost) =>
                                        fost.libelle
                                          .toLowerCase()
                                          .includes(recherche.toLowerCase()) ||
                                          fost.code.toLowerCase().includes(recherche.toLowerCase())
                                      )
                                      .map((fost) => (
                                        <Radio.Button
                                          key={fost.key}
                                          value={fost.code}
                                          style={fostStyle}
                                        >
                                          <Tooltip
                                            title={fost.denomination}
                                            color={primary_color}
                                            mouseEnterDelay={1}
                                          >
                                            <Space
                                              direction="horizontal"
                                              align="center"
                                              style={{
                                                height: '100%',
                                                alignItems: 'left',
                                              }}
                                            >
                                              <FileOutlined />
                                              <Typography>
                                                <Typography>
                                                  <Typography.Text
                                                    strong
                                                    style={{
                                                      whiteSpace: 'normal',
                                                      fontSize: '0.9rem',
                                                      lineHeight: 0.5,
                                                    }}
                                                  >
                                                    {fost.libelle}
                                                  </Typography.Text>
                                                </Typography>
                                                <Typography
                                                  style={{ lineHeight: 0.5 }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      fontSize: '0.6rem',
                                                    }}
                                                  >
                                                    {fost.code}
                                                  </Typography.Text>
                                                </Typography>
                                              </Typography>
                                            </Space>
                                          </Tooltip>
                                        </Radio.Button>
                                      ))}
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches BAT-EQ */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'BAT' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'EQ' ? (
                            <>
                              <Form.Item
                                name={[field.name, 'ChoixFost', 'Fost']}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group buttonStyle="solid">
                                  <Space direction="vertical">
                                    {fostBatEqProps
                                      .filter((fost) =>
                                        fost.libelle
                                          .toLowerCase()
                                          .includes(recherche.toLowerCase()) ||
                                          fost.code.toLowerCase().includes(recherche.toLowerCase())
                                      )
                                      .map((fost) => (
                                        <Radio.Button
                                          value={fost.code}
                                          style={fostStyle}
                                        >
                                          <Tooltip
                                            title={fost.denomination}
                                            color={primary_color}
                                            mouseEnterDelay={1}
                                          >
                                            <Space
                                              direction="horizontal"
                                              align="center"
                                              style={{
                                                height: '100%',
                                                alignItems: 'left',
                                              }}
                                            >
                                              <FileOutlined />
                                              <Typography>
                                                <Typography>
                                                  <Typography.Text
                                                    strong
                                                    style={{
                                                      whiteSpace: 'normal',
                                                      fontSize: '0.9rem',
                                                      lineHeight: 0.5,
                                                    }}
                                                  >
                                                    {fost.libelle}
                                                  </Typography.Text>
                                                </Typography>
                                                <Typography
                                                  style={{ lineHeight: 0.5 }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      fontSize: '0.6rem',
                                                    }}
                                                  >
                                                    {fost.code}
                                                  </Typography.Text>
                                                </Typography>
                                              </Typography>
                                            </Space>
                                          </Tooltip>
                                        </Radio.Button>
                                      ))}
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches IND-BA */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'IND' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'BA' ? (
                            <>
                              <Empty description="Prochainement"></Empty>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches IND-UT */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'IND' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'UT' ? (
                            <>
                              <Form.Item
                                name={[field.name, 'ChoixFost', 'Fost']}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group buttonStyle="solid">
                                  <Space direction="vertical">
                                    {fostIndUtProps
                                      .filter((fost) =>
                                        fost.libelle
                                          .toLowerCase()
                                          .includes(recherche.toLowerCase()) ||
                                          fost.code.toLowerCase().includes(recherche.toLowerCase())
                                      )
                                      .map((fost) => (
                                        <Radio.Button
                                          value={fost.code}
                                          style={fostStyle}
                                          key={fost.key}
                                        >
                                          <Tooltip
                                            title={fost.denomination}
                                            color={primary_color}
                                            mouseEnterDelay={1}
                                          >
                                            <Space
                                              direction="horizontal"
                                              align="center"
                                              style={{
                                                height: '100%',
                                                alignItems: 'left',
                                              }}
                                            >
                                              <FileOutlined />
                                              <Typography>
                                                <Typography>
                                                  <Typography.Text
                                                    strong
                                                    style={{
                                                      whiteSpace: 'normal',
                                                      fontSize: '0.9rem',
                                                      lineHeight: 0.5,
                                                    }}
                                                  >
                                                    {fost.libelle}
                                                  </Typography.Text>
                                                </Typography>
                                                <Typography
                                                  style={{ lineHeight: 0.5 }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      fontSize: '0.6rem',
                                                    }}
                                                  >
                                                    {fost.code}
                                                  </Typography.Text>
                                                </Typography>
                                              </Typography>
                                            </Space>
                                          </Tooltip>
                                        </Radio.Button>
                                      ))}
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches RES-EC */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'RES' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'EC' ? (
                            <>
                              <Empty description="Prochainement"></Empty>
                            </>
                          ) : null
                        }
                      </Form.Item>

                      {/* Fiches RES-CH */}
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Secteur',
                          ]) === 'RES' &&
                          getFieldValue([
                            'items',
                            field.name,
                            'ChoixFost',
                            'Typologie',
                          ]) === 'CH' ? (
                            <>
                              <Form.Item
                                name={[field.name, 'ChoixFost', 'Fost']}
                                rules={[{ required: true }]}
                              >
                                <Radio.Group buttonStyle="solid">
                                  <Space direction="vertical">
                                    {fostResChProps
                                      .filter((fost) =>
                                        fost.libelle
                                          .toLowerCase()
                                          .includes(recherche.toLowerCase()) ||
                                          fost.code.toLowerCase().includes(recherche.toLowerCase())
                                      )
                                      .map((fost) => (
                                        <Radio.Button
                                          value={fost.code}
                                          style={fostStyle}
                                        >
                                          <Tooltip
                                            title={fost.denomination}
                                            color={primary_color}
                                            mouseEnterDelay={1}
                                          >
                                            <Space
                                              direction="horizontal"
                                              align="center"
                                              style={{
                                                height: '100%',
                                                alignItems: 'left',
                                              }}
                                            >
                                              <FileOutlined />
                                              <Typography>
                                                <Typography>
                                                  <Typography.Text
                                                    strong
                                                    style={{
                                                      whiteSpace: 'normal',
                                                      fontSize: '0.9rem',
                                                      lineHeight: 0.5,
                                                    }}
                                                  >
                                                    {fost.libelle}
                                                  </Typography.Text>
                                                </Typography>
                                                <Typography
                                                  style={{ lineHeight: 0.5 }}
                                                >
                                                  <Typography.Text
                                                    style={{
                                                      fontSize: '0.6rem',
                                                    }}
                                                  >
                                                    {fost.code}
                                                  </Typography.Text>
                                                </Typography>
                                              </Typography>
                                            </Space>
                                          </Tooltip>
                                        </Radio.Button>
                                      ))}
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : null
                        }
                      </Form.Item>
                    </Space>
                  </>
                ) : null
              }
            </Form.Item>
          </Flex>
        </Form.Item>
      </ConfigProvider>
    </>
  );
};
