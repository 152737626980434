import { FormListFieldData, Typography, Divider, Button, Flex } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import NbAppartementsREF131 from '../refs/NbAppartementsREF131';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1232S = ({ field }: { field: FormListFieldData }) => {

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    const formItemMarginBottom = 10

    const formItemStyle: React.CSSProperties = {
        marginBottom: formItemMarginBottom,
    };

    /* Infos FOST */
    const code_fost = "BAR-TH-123"
    const libelle_fost = "Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative"
    const denomination_fost = <>Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative
        sur un circuit de chauffage collectif à combustible existant</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-123%20vA54-2%20%C3%A0%20compter%20du%2001-01-2024.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Nb Appartements */}
            <NbAppartementsREF131
                label="Nombre d'appartements :"
                name={[field.name, 'REF-131']}
                style={formItemStyle}
            />
        </>
    );
};
