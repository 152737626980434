import { Alert, FormListFieldData, Typography, List, Form, InputNumber, Radio, Card, Divider, Collapse, Button, Flex } from 'antd';
import ModeFonctionnementREF36A from '@/features/new-project/operations/forms/components/common/ModeFonctionnementREF36A';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';

export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */

const { Paragraph } = Typography
const { Title } = Typography

export const Indba1162 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs thème */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "IND-BA-116"
    const libelle_fost = "Luminaires à modules LED"
    const denomination_fost = <>Remplacement d'un luminaire industriel équipé de lampes à décharge d'une puissance supérieure ou égale 200 watts
        par un luminaire à modules LED, contrôlé par détection de présence et variation en fonction de la lumière du jour</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-BA-116%20vA40-2%20%C3%A0%20compter%20du%2001-04-2022.pdf"

    /* Textes explicatifs */
    const eligibilite = <>
        <List.Item>- le ou les modules LED et leurs appareillages d'alimentation associés sont <Typography.Text strong style={{ color: primary_color }}>remplaçables</Typography.Text> ;</List.Item>
        <List.Item>- durée de vie calculée à 25°C <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à 50 000 heures</Typography.Text> avec une chute de flux lumineux inférieure ou égale à 20 % conformément
            à la norme EN 62722-2-1 et à la méthode d'extrapolation TM21 ;</List.Item>
        <List.Item>- efficacité lumineuse <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à 160 lm/W</Typography.Text> ;</List.Item>
        <List.Item>- facteur de déphasage<Typography.Text strong style={{ color: primary_color }}> supérieur ou égal à 0,9</Typography.Text> quelle que soit la puissance, selon la norme EN 61000-3-2 ;</List.Item>
        <List.Item>- taux de distorsion total harmonique sur le courant<Typography.Text strong style={{ color: primary_color }}> inférieur à 15 %</Typography.Text>, selon la norme
            EN 61000-3-2 ;</List.Item>
        <List.Item>- le luminaire est<Typography.Text strong style={{ color: primary_color }}> adapté pour la régulation automatique </Typography.Text>par gradation de puissance en fonction de l’éclairage naturel du local dès lors que cet
            éclairage est possible.</List.Item>
    </>
    const justificatifs = <>
        <Typography>- l'étude de dimensionnement de l'éclairage</Typography>
        <Typography>- le justificatif de la qualification du professionnel ou du bureau d’études ayant effectué cette étude</Typography>
    </>
    const facture = <>
        <Typography>La facture mentionne :</Typography>
        <Typography>- la mise en place de luminaires à modules LED</Typography>
        <Typography>- la quantité d’équipements installés</Typography>
        <Typography>- leur puissance</Typography>
        <Typography>- leur durée de vie calculée à 25°C</Typography>
        <Typography>- leur chute de flux lumineux à l’issue de leur durée de vie</Typography>
        <Typography>- leur efficacité lumineuse, auxiliaire d’alimentation compris</Typography>
        <Typography>- leur facteur de déphasage</Typography>
        <Typography>- leur taux de distorsion harmonique</Typography>
        <Typography>- l’installation d’un dispositif de gestion de l’éclairage incluant une détection de présence et une variation de lumière tenant compte
            des apports de lumière du jour</Typography>
    </>
    const dimensionnement = <>
        <Typography>L'étude de dimensionnement :</Typography>
        <Typography>- dresse l’état des lieux des équipements en place avant rénovation, </Typography>
        <Typography>- identifie les besoins afin de garantir le bon éclairage des locaux et la maîtrise des consommations d’énergie</Typography>
        <Typography>- indique les caractéristiques, le nombre et l’implantation des nouveaux luminaires</Typography>
        <Typography>- dimensionne les économies d’énergie attendues</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Pièces justificatives' },
        { key: '3', label: 'Contenu de la facture' },
        { key: '4', label: "Contenu de l'étude de dimensionnement" },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{justificatifs}</>,
        3: <>{facture}</>,
        4: <>{dimensionnement}</>
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };


    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Remplaçables */}
            <Form.Item
                label="Le ou les modules LED et leurs appareillages d'alimentation associés sont-ils remplaçables ?"
                name={[field.name, 'LEDRemplacables']}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner ce champ"
                    },]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => form.setFieldValue(['items', field.name, "DureeDeVieLuminaires"], undefined)}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si LED non remplaçables */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "LEDRemplacables"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si le ou les modules LED et leurs appareillages d'alimentation associés sont remplaçables."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Suite si LED remplaçables */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "LEDRemplacables"]) === "Oui" ? (
                        <>
                            {/* Durée de vie */}
                            <Form.Item
                                label="Durée de vie des luminaires à modules LED avec une chute de flux lumineux ≤ 20 % :"
                                name={[field.name, 'DureeDeVieLuminaires']}
                                rules={
                                    [{
                                        required: true,
                                        message: "Veuillez renseigner la durée de vie"
                                    },]}
                                tooltip={{
                                    title: "La durée de vie est déterminée à 25°C.",
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}>
                                <InputNumber addonAfter="heures" onChange={() => form.setFieldValue(['items', field.name, "EfficaciteLumineuse"], undefined)} />
                            </Form.Item>
                            {/* Alerte si durée de vie non éligible */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DureeDeVieLuminaires"]) < 50000 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si la durée de vie est supérieure ou égale à 50 000 heures."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Suite si durée de vie OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DureeDeVieLuminaires"]) >= 50000 ? (
                                        <>
                                            {/* Efficacité lumineuse */}
                                            <Form.Item
                                                label="Efficacité lumineuse (lm/W) :"
                                                name={[field.name, 'EfficaciteLumineuse']}
                                                rules={
                                                    [{
                                                        required: true,
                                                        message: "Veuillez renseigner l'efficacité lumineuse"
                                                    },]}
                                                tooltip={{
                                                    title: "L’efficacité lumineuse est égale au flux lumineux total sortant du luminaire divisé par sa puissance totale, y compris les auxiliaires d'alimentation.",
                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                    color: tooltip_color,
                                                }}>

                                                <InputNumber addonAfter="lm/W" onChange={() => form.setFieldValue(['items', field.name, "FacteurDephasage"], undefined)} />
                                            </Form.Item>
                                            {/* Alerte si efficacité lumineuse non éligible */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "EfficaciteLumineuse"]) < 160 ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si l'efficacité lumineuse est supérieure ou égale à 160 lm/W'."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Suite si efficacité lumineuse OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "EfficaciteLumineuse"]) >= 160 ? (
                                                        <>
                                                            {/* Facteur de déphasage */}
                                                            <Form.Item
                                                                label="Facteur de déphasage :"
                                                                name={[field.name, 'FacteurDephasage']}
                                                                rules={
                                                                    [{
                                                                        required: true,
                                                                        message: "Veuillez renseigner le facteur de déphasage"
                                                                    },]}
                                                            >
                                                                <InputNumber decimalSeparator=',' onChange={() => form.setFieldValue(['items', field.name, "TauxDistorsion"], undefined)} />
                                                            </Form.Item>

                                                            {/* Alerte si facteur de déphasage non éligible */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "FacteurDephasage"]) < 0.9 ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si le facteur de déphasage est supérieur ou égal à 0,9."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Suite si facteur déphasage OK */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "FacteurDephasage"]) >= 0.9 ? (
                                                                        <>
                                                                            {/* Taux de distorsion */}
                                                                            <Form.Item
                                                                                label="Taux de distorsion harmonique sur le courant (en %) :"
                                                                                name={[field.name, 'TauxDistorsion']}
                                                                                rules={
                                                                                    [{
                                                                                        required: true,
                                                                                        message: "Veuillez renseigner le taux de distortion"
                                                                                    },]}
                                                                                tooltip={{
                                                                                    title: "Le taux de distorsion harmonique sur le courant est déterminé conformément à la norme EN 61000-3-2.",
                                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                                    color: tooltip_color,
                                                                                }}>

                                                                                <InputNumber addonAfter="%" decimalSeparator=',' onChange={() => form.setFieldValue(['items', field.name, "AdapteRegulation"], undefined)} />
                                                                            </Form.Item>
                                                                            {/* Alerte si taux de distorsion non éligible */}
                                                                            <Form.Item noStyle shouldUpdate>
                                                                                {({ getFieldValue }) =>
                                                                                    getFieldValue(['items', field.name, "TauxDistorsion"]) >= 15 ? (
                                                                                        <Alert
                                                                                            message="Cette opération n'est éligible que si le taux de distorsion harmonique est inférieur à 15%."
                                                                                            type="error"
                                                                                            showIcon
                                                                                        />
                                                                                    ) : null
                                                                                }
                                                                            </Form.Item>
                                                                            {/* Suite si taux distorsion OK */}
                                                                            <Form.Item noStyle shouldUpdate>
                                                                                {({ getFieldValue }) =>
                                                                                    getFieldValue(['items', field.name, "TauxDistorsion"]) < 15 ? (
                                                                                        <>
                                                                                            {/* Adapté pour régulation */}
                                                                                            <Form.Item
                                                                                                label="Le luminaire est-il adapté pour la régulation automatique par gradation de puissance en fonction de l’éclairage naturel du local,
                                                                                                dès lors que cet éclairage est possible ?"
                                                                                                name={[field.name, 'AdapteRegulation']}
                                                                                                rules={
                                                                                                    [{
                                                                                                        required: true,
                                                                                                        message: "Veuillez renseigner ce champ"
                                                                                                    },]}
                                                                                            >
                                                                                                <Radio.Group buttonStyle='solid' onChange={() => {
                                                                                                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                                                                                    form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                                                                                }}>
                                                                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                                                                </Radio.Group>
                                                                                            </Form.Item>
                                                                                            {/* Alerte si non adapté */}
                                                                                            <Form.Item noStyle shouldUpdate>
                                                                                                {({ getFieldValue }) =>
                                                                                                    getFieldValue(['items', field.name, "AdapteRegulation"]) === "Non" ? (
                                                                                                        <Alert
                                                                                                            message="Cette opération n'est éligible que si le luminaire est adapté pour la régulation automatique par gradation de puissance en fonction de l’éclairage naturel
                                                                                                            du local, dès lors que cet éclairage est possible."
                                                                                                            type="error"
                                                                                                            showIcon
                                                                                                        />
                                                                                                    ) : null
                                                                                                }
                                                                                            </Form.Item>
                                                                                            {/* Suite si adapté OK */}
                                                                                            <Form.Item noStyle shouldUpdate>
                                                                                                {({ getFieldValue }) =>
                                                                                                    getFieldValue(['items', field.name, "AdapteRegulation"]) === "Oui" ? (
                                                                                                        <>
                                                                                                            {/* Mode de fonctionnement */}
                                                                                                            <ModeFonctionnementREF36A
                                                                                                                label="Mode de fonctionnement du site :"
                                                                                                                name={[field.name, 'REF-36-A']}
                                                                                                            />

                                                                                                            {/* Puissance */}
                                                                                                            <PuissanceREF26
                                                                                                                label="Puissance totale des luminaires à modules LED installés (W) :"
                                                                                                                name={[field.name, 'REF-26']}
                                                                                                                unite="W"
                                                                                                                tooltip="La puissance totale à prendre en compte est égale à la somme des puissances totales des luminaires à modules LED mis en place"
                                                                                                            />
                                                                                                        </>
                                                                                                    ) : null
                                                                                                }
                                                                                            </Form.Item>
                                                                                        </>
                                                                                    ) : null
                                                                                }
                                                                            </Form.Item>

                                                                        </>
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            <Collapse
                bordered={false}
                //defaultActiveKey={['1']}
                ghost
                style={{ margin: '0px', padding: '0px' }}
                size='small'
                collapsible='icon'
                expandIcon={({ isActive }) => isActive ? <Button size='small'>Masquer</Button> : <Button size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        //bordered={false}
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>
    );
};
