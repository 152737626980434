import React, { useCallback, useEffect, useState } from 'react';

import { AuthenticationCard, Message, Spinner } from '@/components';
import { PasswordValidation } from '@/components';
import { useResetPassword } from '@/hooks/coginto/useResetPassword';
import { useSendVerificationCode } from '@/hooks/coginto/useSendVerificationCode';
import { RootState } from '@/store/store';
import {
    CheckCircleOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
} from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import type { TResetPasword } from '../index';
import { validation } from '../index';
import '../styles.scss';

const ResetPassword: React.FC = () => {
    const { username } = useSelector(
        (state: RootState) => state.congintoReducer
    );
    const navigate = useNavigate();
    const [resetPasswordsFields, setResetPasswordsFields] =
        useState<TResetPasword>({
            code: '',
            password: '',
            confirmPassword: '',
        });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordsFields({
            ...resetPasswordsFields,
            [e.target.name]: e.target.value,
        });
    };

    const { isPending, changePassword, isRejected, isResolved } =
        useSendVerificationCode();

    const {
        isPending: isResetPwdPinding,
        isRejected: isResetPwdRejected,
        isResolved: isResetPwdResolved,
        onResetPassword,
    } = useResetPassword();

    const handleSubmitForm = useCallback(async () => {
        const { code, password } = resetPasswordsFields;
        onResetPassword(username, code, password);
    }, [resetPasswordsFields, username, onResetPassword]);

    const handleSendVerificationCode = useCallback(() => {
        changePassword(username);
    }, [changePassword, username]);

    useEffect(() => {
        if (username === '') {
            return navigate('/forget-password');
        }
    }, [username, navigate]);

    if (username === '') {
        <Spinner />;
    }

    return (
        <AuthenticationCard title="Réinitialiser votre mot de passe">
            <Space
                direction="vertical"
                size="small"
                style={{ display: 'flex' }}
            >
                {isResetPwdRejected ? (
                    <Message
                        message="Un problème s'est produit, veuillez réessayer plus tard."
                        type="danger"
                        withBackBtn={true}
                        icon={<CheckCircleOutlined />}
                    />
                ) : isResetPwdResolved ? (
                    <Message
                        message="Votre mot de passe a bien été réinitialisé."
                        type="success"
                        withBackBtn={true}
                        icon={<CheckCircleOutlined />}
                    />
                ) : (
                    <>
                        {isResolved && (
                            <Message
                                message="Le code de vérification a bien été envoyé à votre adresse email."
                                type="success"
                            />
                        )}

                        {isRejected && (
                            <Message
                                message="Un problème s'est produit lors de l'envoi du code, veuillez réessayer plus tard."
                                type="danger"
                                withBackBtn={true}
                                icon={<CheckCircleOutlined />}
                            />
                        )}
                        <Form
                            name="reset-password"
                            style={{ width: '100%' }}
                            autoComplete="off"
                            onFinish={handleSubmitForm}
                        >
                            <Row
                                align="middle"
                                justify="space-between"
                                className="form__authentication--validation-code"
                            >
                                <Col>
                                    <Form.Item
                                        name="code"
                                        rules={
                                            validation.resetPasswordCodeValidation
                                        }
                                        style={{ marginBottom: '0px' }}
                                    >
                                        <Input
                                            name="code"
                                            type="number"
                                            placeholder="Code"
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button
                                        type="text"
                                        onClick={() =>
                                            handleSendVerificationCode()
                                        }
                                    >
                                        Renvoyer le code ?
                                    </Button>
                                </Col>
                            </Row>
                            <Form.Item
                                name="password"
                                rules={validation.passwordValidation}
                            >
                                <Input.Password
                                    name="password"
                                    placeholder="Nouveau mot de passe"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmpassword"
                                rules={[
                                    validation.confirmPasswordValidation,
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Le mot de passe et la confirmation ne sont pas identiques'
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    name="confirmpassword"
                                    placeholder="Confirmation du mot de passe"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                    onChange={handleChange}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item>
                                <PasswordValidation
                                    value={resetPasswordsFields.password}
                                />
                            </Form.Item>
                            <Row justify="end" style={{ marginBottom: '15px' }}>
                                <Link to="/">Retour à la connexion</Link>
                            </Row>
                            <Row justify={'center'}>
                                <Form.Item className="form__btn">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isPending || isResetPwdPinding}
                                    >
                                        Envoyer
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </>
                )}
            </Space>
        </AuthenticationCard>
    );
};

export default ResetPassword;
