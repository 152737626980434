import React, { useEffect } from 'react';

import { useGetDossiersList } from '@/hooks/dashboard';
import { Container } from '@/layouts';
import { currencyFormatter } from '@/utils';
import { Text, Title } from '@/utils/constants';
import { Card, Col, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';

import { DossiersStatus } from './components/DossiersStatus';
import { MobileProgressTable } from './components/MobileProgressTable';
import { ProgressTable } from './components/ProgressTable';
import { RequestProjectsTable } from './components/RequestProjectsTable';
import './styles.scss';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const { isPending: isloading, data: dossiers } = useGetDossiersList();

    useEffect(() => {}, [dossiers, dispatch]);

    return (
        <Container loading={isloading}>
            <Title className="dashboard__title">Tableau de bord</Title>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Row gutter={[0, 24]} align={'middle'}>
                    <Col lg={8} xs={24} className="indicator-block">
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ width: '100%' }}
                        >
                            <Card className="card__projects-informations">
                                <Text
                                    strong
                                    className="card__projects-informations__title"
                                >
                                    <Text
                                        strong
                                        className="card__projects-informations__title  card__projects-informations__title--dossiers"
                                    >
                                        {`${dossiers?.inprogressDossiers.length} `}
                                    </Text>
                                    dossiers en cours
                                </Text>
                            </Card>
                            <Card className="card__projects-informations">
                                <Text
                                    strong
                                    className="card__projects-informations__title"
                                >
                                    <Text
                                        strong
                                        className="card__projects-informations__title card__projects-informations__title--currency"
                                    >
                                        {`${currencyFormatter(
                                            dossiers?.primes
                                        )} `}
                                    </Text>
                                    primes en cours
                                </Text>
                            </Card>
                        </Space>
                    </Col>
                    <Col lg={16} xs={24}>
                        <DossiersStatus />
                    </Col>
                </Row>
                <Row justify="center">
                    {window.innerWidth > 576 ? (
                        <ProgressTable />
                    ) : (
                        <MobileProgressTable />
                    )}
                </Row>

                <Row justify="center">
                    <RequestProjectsTable />
                </Row>
            </Space>
        </Container>
    );
};

export default Dashboard;
