import React, { useState } from 'react';

// import { NumberInput, ZipCodeFormat } from '@/components';
import { useAdd } from '@/hooks/new-project/useAdd';
import { RootState } from '@/store/store';
import { Text, Title } from '@/utils';
import { Button, Card, Form, Input, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

import { WorkAdress } from '../commun/WorkAdress';
import * as constant from '../constants';
import { simpleNewProjectForm } from '../types';
import { UploadFiles } from '../upload/UploadFile';
import './styles.scss';

const { TextArea } = Input;

export const SimpleForm: React.FC = () => {

    const [value, setValue] = useState('');
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );
    const { addNew, isPending } = useAdd();

    const handleFormSubmit = (values: simpleNewProjectForm) => {

        values.partner = heliosUser?.partner?.siret!;
        values.projectFormType = 'SIMPLIFIED';


        addNew(values);

    };
    return (
        <>
            <Card style={{ height: '100%' }}>
                <Form
                    layout="vertical"
                    validateMessages={{
                        required: 'Ce champ est obligatoire',
                    }}
                    className="add-project-form-simplified"
                    name="projects-simulate-form"
                    onFinish={handleFormSubmit}
                    disabled={isPending}
                >
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Space
                            direction="vertical"
                            className="add-project-form-simplified--project-name"
                        >
                            <Form.Item
                                name="projectName"
                                label={
                                    <Text className="field-description" strong>
                                        Quel nom souhaitez-vous donner à votre
                                        projet
                                    </Text>
                                }
                                rules={constant.projectNameValidation}
                            >
                                <Input />
                            </Form.Item>
                        </Space>
                        <Title level={4}>Adresse des travaux</Title>
                        <WorkAdress />
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <div>
                                <UploadFiles />
                            </div>

                            <Title level={4}>
                                Commentaires complémentaires
                            </Title>
                            <Form.Item name="comment">
                                <TextArea
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    autoSize={{ minRows: 10, maxRows: 10 }}
                                />
                            </Form.Item>
                        </Space>
                        <Row justify="center">
                            <Space
                                direction="vertical"
                                className="add-project-submit"
                                style={{ textAlign: 'center' }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ alignSelf: 'center' }}
                                    loading={isPending}
                                >
                                    Soumettre mon projet
                                </Button>
                                <Text>
                                    Après soumission, nous analysons votre
                                    projet et revenons vers vous sous 48h ouvré
                                </Text>
                            </Space>
                        </Row>
                    </Space>
                </Form>
            </Card>
        </>
    );
};
