import { Form, Alert, FormListFieldData, Typography, Divider, Button, Flex } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import ApplicationMoteurREF40I from '../refs/ApplicationMoteurREF40I';
import PuissanceREF26 from '../refs/PuissanceREF26';


const { Title } = Typography

export const Batth1122S = ({ field }: { field: FormListFieldData }) => {


    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-112"
    const libelle_fost = "Système de variation électronique de vitesse sur un moteur asynchrone"
    const denomination_fost = <>Mise en place d’un système de variation électronique de vitesse (VEV) sur un moteur asynchrone
        existant dépourvu de ce système, ou neuf de puissance nominale inférieure ou égale à 3 MW</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-112%20v%20A22-2.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>



            {/* Puissance */}
            <PuissanceREF26
                name={[field.name, 'REF-26']}
                label="Puissance totale (kW)"
                unite="kW"
                tooltip="La puissance totale à prendre en compte est égale à la somme des puissances totales des moteurs équipés de VEV."
            />
            {/* Alerte si Puissance > 3000  */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-26"]) > 3000 ? (
                        <Alert
                            message="Cette opération n'est éligible que si le système de VEV est installé sur un moteur de puissance nominale inférieure ou égale à 3 MW."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Application moteur */}
            <ApplicationMoteurREF40I
                label="Application du moteur électrique sur lequel est installé le système de VEV"
                name={[field.name, 'REF-40-I']}
            />

        </>
    );
};
