const colors = {
    white: '#FFFFFF',
    blue: '#72B8EC',
    darkBlue: '#002766',
    grey: '#CECECE',
    mineralGreen: '#3F5759',
};
const theme = {
    token: {
        colorPrimary: colors.darkBlue,
        colorTextHeading: colors.darkBlue,
        colorBgHeader: colors.white,
        lineHeight: 1,
        fontSize: 14,
        colorText: colors.darkBlue,
        colorLink: colors.blue,
        fontSizeHeading1: 28,
        colorTextSecondary: colors.mineralGreen,
    },
    components: {
        Layout: {
            headerBg: colors.white,
            Header: {
                lineHeight: 'inherit',
            },
        },
        Menu: {
            subMenuItemBg: colors.white,
            activeBarBorderWidth: 0,
            itemActiveBg: colors.blue,
            itemHoverBg: colors.blue,
            itemSelectedBg: colors.blue,
        },
        Cascader: {
            optionSelectedBg: 'rgba(0, 0, 0, 0.04)',
        },
        Alert: {
            withDescriptionIconSize: 18,
        },
    },
    Typography: {
        Title: {
            titleMarginTop: '0em',
            titleMarginBottom: '0em',
        },
    },
    Steps: {
        customIconTop: '',
    },
};

export default theme;
