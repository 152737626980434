import { useCallback } from 'react';

import simulatorService from '@/services/simulator/simulatorService';
import { useAsync } from 'react-async';

export type AffiliationRequestStatusProps = {
    co_branded: boolean;
    logo: string;
    synthesis_model: string;
    tag: string;
    url: string;
    email_template: string;
    id: string;
};

export const useGetAffiliationRequestByTag = (tag: string) => {
    const affiliationRequest =
        useCallback(async (): Promise<AffiliationRequestStatusProps> => {
            return await simulatorService.getAffiliation(tag);
        }, [tag]);

    const { isPending, data } = useAsync({
        promiseFn: affiliationRequest,
    });

    return { isPending, data: data };
};
