import { Auth } from 'aws-amplify';
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import humps from 'humps';

const APPLICATION_JSON_TYPE: string = 'application/json';

function getAxiosInstance(): AxiosInstance {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': APPLICATION_JSON_TYPE,
    },
  });
}

const BaseAPI: AxiosInstance = getAxiosInstance();

BaseAPI.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers['content-type'] === APPLICATION_JSON_TYPE
    ) {
      response.data = humps.camelizeKeys(response.data);
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseAPI.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const accessToken = await getUserToken();
    const newConfig = { ...config, url: '/api/mycameo' + config.url };

    if (accessToken) {
      newConfig.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    if (config.headers['Content-Type'] === APPLICATION_JSON_TYPE) {
      if (config.params) {
        newConfig.params = humps.decamelizeKeys(config.params);
      }
      if (config.data) {
        newConfig.data = humps.decamelizeKeys(config.data);
      }
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getUserToken = async (): Promise<string | null> => {
  try {
    const getSession = await Auth.currentSession();
    const idToken = getSession.getIdToken();
    const jwtToken: string = idToken.getJwtToken();
    return jwtToken;
  } catch (error) {
    return null;
  }
};

export default BaseAPI;
