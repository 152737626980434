import { Form, FormListFieldData, Alert, Typography, Divider, Flex, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '../refs/PuissanceREF26';
import ModeFonctionnementREF36A from '../refs/ModeFonctionnementREF36A';

const { Title } = Typography

export const Indut1301S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "IND-UT-130"
    const libelle_fost = "Condenseur sur les effluents gazeux d’une chaudière de production de vapeur"
    const denomination_fost = <>Mise en place d’un condenseur sur les effluents gazeux d’une chaudière de production de vapeur de puissance
        thermique nominale inférieure à 20 MW, équipée d’un économiseur et alimentée au gaz naturel ou au GPL</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-130.pdf"



    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Puissance chaudière */}
            <PuissanceREF26
                name={[field.name, "REF-26"]}
                label="Puissance utile nominale de la chaudière (kW) :"
                unite="kW"
                tooltip="La puissance utile nominale à retenir est celle figurant sur la plaque signalétique de la chaudière ou à défaut celle
                    indiquée sur un document issu du fabricant de la chaudière. Elle est définie par l’article R.224-20 du code de
                    l’environnement comme « la puissance thermique maximale fixée et garantie par le constructeur comme pouvant
                    être délivrée au fluide caloporteur en marche continue »."
            />
            {/* Alerte si Puissance >= 20000 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-26"]) >= 20000 ? (
                        <Alert
                            message="Cette opération n'est éligible que si le condenseur est installé sur une chaudière de puissance thermique nominale inférieure à 20 MW."
                            type="error"
                            showIcon
                            style={{marginBottom:12}}
                        />
                    ) : null
                }
            </Form.Item>

            {/* Mode de fonctionnement */}
            <ModeFonctionnementREF36A
                name={[field.name, "REF-36-A"]}
                label="Mode de fonctionnement du site industriel :"
            />

        </>
    );
};
