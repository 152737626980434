import { VolumesResult } from '@/features/new-project/types';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as VolumesResult;

export const operationsSlice = createSlice({
    name: 'operationsSlice',
    initialState,
    reducers: {
        setOperationsVolumes(state, action: PayloadAction<VolumesResult>) {
            const {
                classicTotalOperations,
                totalOperations,
                totalPrimeMax,
                totalPrimeMedium,
                totalPrimeMin,
                volumes,
            } = action.payload;
            state.classicTotalOperations = classicTotalOperations;
            state.totalOperations = totalOperations;
            state.totalPrimeMax = totalPrimeMax;
            state.totalPrimeMin = totalPrimeMin;
            state.totalPrimeMedium = totalPrimeMedium;
            state.volumes = volumes;
        },
        resetOperationsVolumes(state) {
            state.classicTotalOperations = 0;
            state.totalOperations = 0;
            state.totalPrimeMax = 0;
            state.totalPrimeMedium = 0;
            state.totalPrimeMin = 0;
            state.volumes = [];
        },
    },
});

export const { setOperationsVolumes, resetOperationsVolumes } =
    operationsSlice.actions;
export default operationsSlice.reducer;
