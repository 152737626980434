import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function UsageREF15A({ label, name }: { label: string, name: NamePath }) {
    return <Form.Item
        name={name}
        label={label}
        rules={[
            {
                required: true,
                message: "Veuillez renseigner l'usage"
            },
        ]}
    >
        <Radio.Group
            buttonStyle='solid'
        >
            <Radio.Button value="0">Chauffage</Radio.Button>
            <Radio.Button value="1">Chauffage et eau chaude sanitaire</Radio.Button>
        </Radio.Group>
    </Form.Item>
}