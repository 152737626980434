import React from 'react';

import { InterLocutorCard } from '@/components';
import { RootState } from '@/store/store';
import { Title } from '@/utils/constants';
import { Card, Space } from 'antd';
import { useSelector } from 'react-redux';

import './style.scss';

export const Interlocutors: React.FC = () => {
    const { details } = useSelector((state: RootState) => state.dossierReducer);
    return (
        <Card className="interlocutors__card">
            <Title level={5} style={{ marginTop: 0 }}>
                Vos interlocuteurs Cameo
            </Title>
            <Space size="small" direction="vertical">
                {details.businessEngineer && (
                    <Space direction="vertical">
                        <InterLocutorCard
                            firstName={details.businessEngineer.firstName}
                            lastName={details.businessEngineer.lastName}
                            mobileNumber={details.businessEngineer.mobileNumber}
                            title="Commercial"
                        />
                    </Space>
                )}

                <Space direction="vertical">
                    {details.managerEngineer && (
                        <Space direction="vertical">
                            <InterLocutorCard
                                firstName={details.managerEngineer.firstName}
                                lastName={details.managerEngineer.lastName}
                                mobileNumber={
                                    details.managerEngineer.mobileNumber
                                }
                                title="Suivi de projets"
                            />
                        </Space>
                    )}
                </Space>
            </Space>
        </Card>
    );
};
