import { Form, Alert, FormListFieldData, Typography, Radio, InputNumber, Divider, Space, Collapse, Card, Flex, Button } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import TypeInstallationREF81D from '@/features/new-project/operations/forms/components/common/TypeInstallationREF81D';
import ModeFonctionnementREF36A from '@/features/new-project/operations/forms/components/common/ModeFonctionnementREF36A';
import HauteurREF109 from '@/features/new-project/operations/forms/components/common/HauteurREF109';

const { Paragraph } = Typography
const { Title } = Typography

export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};

export const Indba1102 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "IND-BA-110"
    const libelle_fost = "Déstratificateur ou brasseur d’air"
    const denomination_fost = <>Mise en place de déstratificateurs ou brasseurs d’air pour l’homogénéisation de la
        température de l’air d’un local industriel de grande hauteur chauffé par un système convectif et/ou radiatif</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-BA-110%20v%20A20-2.pdf"

    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Les<Typography.Text strong style={{ color: primary_color }}> besoins </Typography.Text>
            en déstratificateurs ou brasseurs d’air sont déterminés par une
            <Typography.Text strong style={{ color: primary_color }}> note de dimensionnement </Typography.Text>
            établie par un professionnel ou un bureau d’études.
        </Paragraph>
        <Paragraph>Le local industriel équipé de déstratificateurs ou brasseurs d’air a une
            <Typography.Text strong style={{ color: primary_color }}> hauteur sous plafond ou sous faîtage d’au moins 5 mètres. </Typography.Text>
        </Paragraph>
        <Typography>
            Les déstratificateurs ou brasseurs d’air sont équipés d’un
            <Typography.Text strong style={{ color: primary_color }}> thermostat. </Typography.Text>
        </Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne la
            <Typography.Text strong style={{ color: primary_color }}> mise en place de déstratificateurs ou brasseurs d’air équipés
                d’un thermostat</Typography.Text>
            , ainsi que
            <Typography.Text strong style={{ color: primary_color }}> leur nombre. </Typography.Text>
        </Paragraph>
        <Typography>
            A défaut, la preuve de réalisation de l'opération mentionne la mise en place d'équipements avec leurs marques,
            références et nombre et elle est complétée par un document issu du fabricant indiquant que les équipements de
            marques et références installés sont des déstratificateurs ou brasseurs d’air équipés d’un thermostat.
        </Typography>
    </>
    const justificatifs = <>
        <Typography>Le document justificatif spécifique à l’opération est
            <Typography.Text strong style={{ color: primary_color }}> la note de dimensionnement. </Typography.Text>
            Le nombre d’équipements installés doit être cohérent avec les préconisations de la note de dimensionnement.
        </Typography>
    </>
    const dimensionnement = <>
        <Paragraph>La note de dimensionnement mentionne au minimum :
            <Typography>- la
                <Typography.Text strong style={{ color: primary_color }}> hauteur du local</Typography.Text></Typography>
            <Typography>- le descriptif des moyens de chauffage avec leurs
                <Typography.Text strong style={{ color: primary_color }}> puissances</Typography.Text></Typography>
            <Typography>- les
                <Typography.Text strong style={{ color: primary_color }}> préconisations </Typography.Text>
                d’installation de déstratificateurs ou brasseurs d’air précisant en particulier
                <Typography.Text strong style={{ color: primary_color }}> leur nombre</Typography.Text></Typography>
        </Paragraph>

    </>
    const incompatibilites = <>
        <Typography>Les systèmes radiatifs de chauffage de
            <Typography.Text strong style={{ color: primary_color }}> « zone » ou de « poste » </Typography.Text>
            ne sont pas éligibles à l'opération.
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Contenu de la note de dimensionnement' },
        { key: '4', label: 'Pièces justificatives' },
        { key: '5', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{dimensionnement}</>,
        4: <>{justificatifs}</>,
        5: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Local industriel pas équipé de chauffage de zone ou de poste */}
            <Form.Item
                name={[field.name, "ChauffageZonePoste"]}
                label="Le local industriel est-il équipé d’un chauffage de « zone » ou « de poste » ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "EquipeThermostat"], undefined)
                    }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si chauffage par zone ou poste */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ChauffageZonePoste"]) === "Oui" ? (
                        <Alert
                            message="Cette opération n'est pas éligible si le local est équipé d'un chauffage de « zone » ou « de poste »."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>


            {/* Reste du formulaire */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ChauffageZonePoste"]) === "Non" ? (
                        <>
                            {/* Equipé d'un thermostat */}
                            <Form.Item
                                name={[field.name, "EquipeThermostat"]}
                                label="Le(s) déstratificateur(s) ou brasseur(s) d’air installé(s) est-il (sont-ils) équipé(s) d’un thermostat ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "REF-81-D"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-109"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                        form.setFieldValue(['items', field.name, "HauteurLocal"], undefined)
                                    }}>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si pas de thermostat */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "EquipeThermostat"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est pas éligible si se(s) déstratificateur(s) ou brasseur(s) d’air installé(s) est (sont) équipé(s) d’un thermostat."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Reste du formulaire */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "EquipeThermostat"]) === "Oui" ? (
                                        <>
                                            <Form.Item style={{ margin: "0px" }}>
                                                <Space align='end' size='middle'>
                                                    {/* Hauteur local */}
                                                    <Form.Item
                                                        name={[field.name, "HauteurLocal"]}
                                                        label="Quelle est la hauteur sous-plafond ou sous-faîtage du local industriel (m) ?"
                                                        rules={[
                                                            { required: true, message: "Veuillez renseigner ce champ" },
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            addonAfter="m"
                                                            min={0}
                                                            decimalSeparator=','
                                                            onChange={() => {
                                                                if (getFieldValue(['items', field.name, "HauteurLocal"]) < 10 && getFieldValue(['items', field.name, "HauteurLocal"]) >= 5) { form.setFields([{ name: ["items", field.name, "REF-109"], value: "0" }]) }
                                                                else if (getFieldValue(['items', field.name, "HauteurLocal"]) >= 10) { form.setFields([{ name: ["items", field.name, "REF-109"], value: "1" }]) }
                                                                else if (getFieldValue(['items', field.name, "HauteurLocal"]) < 5) { form.setFields([{ name: ["items", field.name, "REF-109"], value: undefined }]) }

                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                    </Form.Item>
                                                    <HauteurREF109
                                                        label=""
                                                        name={[field.name, 'REF-109']}
                                                    />
                                                </Space>
                                            </Form.Item>
                                            {/* Alerte si hauteur de moins de 5 mètres */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "HauteurLocal"]) < 5 ? (
                                                        <>
                                                            <Alert
                                                                message="Cette opération n'est éligible que si le local a une hauteur sous plafond ou sous faîtage d’au moins 5 mètres."
                                                                type="error"
                                                                showIcon
                                                            />
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Suite formulaire si hauteur OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "HauteurLocal"]) >= 5 ? (
                                                        <>
                                                            {/* Type d'installation */}
                                                            <TypeInstallationREF81D
                                                                label="Le local est chauffé par un système :"
                                                                name={[field.name, 'REF-81-D']}
                                                                tooltip={
                                                                    <>Par exemple sont considérés comme faisant partie d’un système convectif de chauffage : centrale de traitement d’air, unité
                                                                        de toiture ou « rooftop », aérotherme électrique ou à combustible ou à fluide caloporteur, générateur de ventilation tempérée ou
                                                                        « make-up », générateur d’air chaud.<br />Par exemple sont considérés comme faisant partie d’un système radiatif de chauffage : cassettes,
                                                                        tubes et panneaux radiants électriques, à combustible ou à fluide caloporteur.</>}
                                                            />

                                                            {/* Puissance */}
                                                            <PuissanceREF26
                                                                label="Puissance nominale du moteur en kW :"
                                                                name={[field.name, 'REF-26']}
                                                                unite="kW"
                                                                tooltip="La puissance nominale totale du système de chauffage est la somme des puissances nominales des équipements qui le
                                                                            composent en vue d’assurer le chauffage du local." />

                                                            {/* Mode de fonctionnement */}
                                                            <ModeFonctionnementREF36A
                                                                label="Mode de fonctionnement du site :"
                                                                name={[field.name, 'REF-36-A']}
                                                            />

                                                            {/* Note de dimensionnement */}
                                                            <Form.Item
                                                                name={[field.name, "NoteDimensionnement"]}
                                                                label="L'étude de dimensionnement a-t-elle été réalisée ?"
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                            >
                                                                <Radio.Group buttonStyle='solid'>
                                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {/* Alerte si NoteDimensionnement non */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Non" ? (
                                                                        <Alert
                                                                            message="La note de dimensionnement devra être réalisée et remise au bénéficiaire à l'achèvement des travaux."
                                                                            type="info"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Message si NoteDimensionnement oui */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "NoteDimensionnement"]) === "Oui" ? (
                                                                        <Alert
                                                                            message="Vous pouvez déposer la note de dimensionnement dans les pièces jointes du projet, à la fin du formulaire."
                                                                            type="info"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
