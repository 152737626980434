import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function SecteurREF22AA({label, name}: {label: string, name: NamePath}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner le secteur"
                    },]}>
                <Select
                    placeholder="Sélectionnez le secteur"
                    style={{width: '200px'}}
                  >
                    <Option value="0">Hôtellerie, restauration</Option>
                    <Option value="1">Santé</Option>
                    <Option value="2">Enseignement</Option>
                    <Option value="3">Bureaux</Option>
                    <Option value="4">Commerces</Option>
                    <Option value="5">Autres</Option>
                  </Select>
            </Form.Item >
}