import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function PuissanceChaudiereREF24B({ label, name, onChange, disabled, style, hidden }: { label: string, name: NamePath, onChange?: () => void, disabled: boolean, style?: React.CSSProperties, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        rules={[{
                required: true,
                message: "Veuillez renseigner la puissance"
            },]}
        style={style}
        hidden={hidden}
        >
        <Radio.Group buttonStyle='solid'
            style={{ width: '250px' }}
            onChange={onChange}
            disabled={disabled}
        >
            <Radio.Button value="0">P ≤ 400 kW</Radio.Button>
            <Radio.Button value="1">P &gt; 400 kW</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
