import { useFormTypeByPartner } from '@/hooks/new-project/useFormTypeByPartner';
import { Container } from '@/layouts';
import { Title } from '@/utils';

import NewProject from './NewProject';
import GenericForm from './forms/GenericForm';
import { SimpleForm } from './forms/SimpleForm';

export const NewProjectRequest = () => {
    const { data, isPending } = useFormTypeByPartner();

    const NewProjectRequestForm = () => {
        switch (data?.formType) {
            case 'GENERIC':
                return <GenericForm />;
            case 'SIMULATOR':
                return <NewProject />;
            case 'SIMPLIFIED':
                return <SimpleForm />;
            default:
                return <NewProject />;
        }
    };
    return (
        <Container loading={isPending}>
            <Title level={1} className="page__title">
                Soumettre un nouveau projet de travaux
            </Title>
            <NewProjectRequestForm />
        </Container>
    );
};
