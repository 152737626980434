import { useCallback, useEffect, useState } from 'react';

import { PhoneNumber, SiretInputFormat } from '@/components';
import { RootState } from '@/store/store';
import { Text } from '@/utils';
import { setBenificiarySiret, setInstallerSiret } from '@store/slices';
import { Col, Form, Input, Row, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import * as constant from '../../constants';
import { ApplicantProfileType } from './ApplicantProfileType';

export const ApplicantProfile = () => {
    const dispatch = useDispatch();
    const { profileType, installerSiret, beneficiarySiret } = useSelector(
        (state: RootState) => state.newProjectReducer
    );
    const [benifPhoneNumber, setBenifPhoneNumber] = useState<string>();
    const [benifMobileNumber, setBenifMobileNumber] = useState<string>();
    const { heliosUser } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );

    const siret = heliosUser?.partner?.siret;

    const fillSiretField = useCallback(() => {
        if (!profileType) return;

        if (profileType === constant.IS_INSTALLER) {
            dispatch(setInstallerSiret(siret!));
            dispatch(setBenificiarySiret(''));
        } else if (profileType === constant.IS_BENEFICIARY) {
            dispatch(setInstallerSiret(''));
            dispatch(setBenificiarySiret(siret!));
        } else {
            dispatch(setInstallerSiret(''));
            dispatch(setBenificiarySiret(''));
        }
    }, [profileType, siret, dispatch]);

    useEffect(() => {
        fillSiretField();
    }, [fillSiretField]);

    return (
        <>
            <ApplicantProfileType />
            <Form.Item
                label={
                    <Text className="field-description" strong>
                        SIRET Installateur
                    </Text>
                }
                rules={[{ required: false }]}
                className="middle-input-width"
                name={constant.INSTALLER_SIRET}
                valuePropName={constant.INSTALLER_SIRET}
            >
                <SiretInputFormat
                    value={installerSiret}
                    name={constant.INSTALLER_SIRET}
                    classes="ant-input antd-copy-input"
                    onChange={(value) => {
                        dispatch(setInstallerSiret(value));
                    }}
                />
            </Form.Item>
            <Form.Item
                label={
                    <Text className="field-description" strong>
                        SIRET Bénéficiaire
                    </Text>
                }
                rules={[{ required: false }]}
                className="middle-input-width"
                getValueFromEvent={(event) => event.taget.value}
            >
                <SiretInputFormat
                    value={beneficiarySiret}
                    name={constant.BENEFICIARY_SIRET}
                    classes="ant-input antd-copy-input"
                    onChange={(value) => {
                        dispatch(setBenificiarySiret(value));
                    }}
                />
            </Form.Item>
            {profileType !== undefined &&
                profileType !== constant.IS_BENEFICIARY && (
                    <Space
                        direction="vertical"
                        className="middle-width-section"
                    >
                        <Text className="field-description" strong>
                            Coordonnées bénéficiaire :
                        </Text>
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Prénom
                                        </Text>
                                    }
                                    rules={constant.firstLastNameValidation}
                                    className="middle-input-width"
                                    name="beneficiaryLastname"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Nom
                                        </Text>
                                    }
                                    rules={constant.firstLastNameValidation}
                                    name="beneficiaryName"
                                    className="middle-input-width"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Fonction
                                        </Text>
                                    }
                                    rules={constant.functionValidation}
                                    className="middle-input-width"
                                    name="beneficiaryFunction"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Email
                                        </Text>
                                    }
                                    rules={constant.emailValidation}
                                    className="middle-input-width"
                                    name="beneficiaryEmail"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Numéro mobile
                                        </Text>
                                    }
                                    rules={[{ required: false }]}
                                    className="middle-input-width"
                                    name="beneficiaryMobileNumber"
                                >
                                    <PhoneNumber
                                        classes="ant-input antd-copy-input"
                                        onChange={(value) =>
                                            setBenifMobileNumber(value)
                                        }
                                        value={benifMobileNumber}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Text className="field-description">
                                            Numéro fixe
                                        </Text>
                                    }
                                    rules={[{ required: false }]}
                                    name="beneficiaryPhoneNumber"
                                    className="middle-input-width"
                                >
                                    <PhoneNumber
                                        classes="ant-input antd-copy-input"
                                        onChange={(value) =>
                                            setBenifPhoneNumber(value)
                                        }
                                        value={benifPhoneNumber}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                )}
        </>
    );
};
