import { Form, FormListFieldData, Typography, Divider, Flex, Button, InputNumber } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';

const { Title } = Typography

export const Resch1043S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "RES-CH-104"
    const libelle_fost = "Réhabilitation d’un poste de livraison de chaleur d’un bâtiment résidentiel"
    const denomination_fost = <>Réhabilitation d’un poste de livraison de chaleur par le remplacement de la
        totalité des éléments constitutifs du primaire du poste de livraison</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-104%20vA36-3%20%C3%A0%20compter%20du%2012-10-2020.pdf"


    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Nb appartements */}
            <Form.Item
                name={[field.name, "REF-131"]}
                label="Nombre d'appartements :"
                rules={[
                    { required: true, message: "Veuillez renseigner le nombre d'appartements." },
                    { type: 'integer', message: "Veuillez renseigner un nombre entier." },
                ]}
                tooltip={{
                    title: <>Le nombre d’appartements pris en compte correspond au nombre de logements des
                        bâtiments résidentiels raccordés au poste de livraison réhabilité.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber min={0} decimalSeparator=',' />
            </Form.Item>

        </>
    );
};
