import newProjectServices from '@/services/new-project/newProjectServices';
import { simpleNewProjectForm } from '@features/new-project/types';
import { useAsync } from 'react-async';
import { useNavigate } from 'react-router-dom';

export const useAdd = () => {
    const navigate = useNavigate();
    const {
        isPending,
        isResolved,
        isRejected,
        run: addNew,
    } = useAsync({
        deferFn: (param) => {
            return newProjectServices.addNewProjectSimpleForm(
                param[0] as simpleNewProjectForm
            );
        },
        onResolve() {
            navigate('/dashboard');
        },
    });

    return { isPending, isResolved, isRejected, addNew };
};
