import '@assets/styles/scss/styles.scss';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { isProduction } from './utils';

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// Sentry in staging / production env
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_VERSION || 'dev',
    enabled: isProduction,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
