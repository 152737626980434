import React from 'react';

import { Text } from '@/utils/constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Space } from 'antd';
import { Link } from 'react-router-dom';

import { TMessage } from './types';

export const Message: React.FC<TMessage> = ({
    message,
    type,
    withBackBtn,
    backTo,
    icon,
}) => {
    const msg = message || "Vous n'avez pas ajouter un message ici";
    const msgType = type || 'warning';
    const backBtn = withBackBtn || false;
    const msgIcon = icon || <ExclamationCircleOutlined />;
    const backToUrl = backTo || '/';

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Text type={msgType}>
                {msgIcon} {msg}
            </Text>
            {backBtn && (
                <Row justify="end">
                    <Link to={backToUrl}>Retour à la connexion</Link>
                </Row>
            )}
        </Space>
    );
};
