import React from 'react';

import type { RootState } from '@store/store';
import { Card } from 'antd';
import { useSelector } from 'react-redux';

import './styles.scss';
import type { ContainerProps } from './types';

export const Container: React.FC<ContainerProps> = ({ children, loading }) => {
    const { isAppLoading } = useSelector(
        (state: RootState) => state.appReducer
    );
    return (
        <Card className="card--base" loading={isAppLoading || loading}>
            {children}
        </Card>
    );
};
