import { Text } from '@/utils';
import { Form, Input, Row } from 'antd';

import { WORK_DESCRIPTION_COMMENTS } from '../constants';

const { TextArea } = Input;

export const WorkDetailsDescription = () => {
    return (
        <Row>
            <Form.Item
                className="new-project__form-item"
                style={{ width: '100%', marginTop: '8px' }}
                label={
                    <Text className="field-description" strong>
                        Commentaires complémentaires :
                    </Text>
                }
                name={WORK_DESCRIPTION_COMMENTS}
            >
                <TextArea
                    style={{
                        height: 150,
                        resize: 'none',
                    }}
                />
            </Form.Item>
        </Row>
    );
};
