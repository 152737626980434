import { useCallback } from 'react';

import { Auth } from 'aws-amplify';
import { useAsync } from 'react-async';

export const useResetPassword = () => {
    const resetPassword = useCallback(async (params: string[]) => {
        const [userName, code, password] = params;
        await Auth.forgotPasswordSubmit(userName, code, password);
    }, []);

    const {
        isPending,
        isResolved,
        isRejected,
        run: onResetPassword,
    } = useAsync({
        deferFn: (params) => resetPassword(params),
    });

    return { isPending, isResolved, isRejected, onResetPassword };
};
