import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function PuissanceChaudiereREF24E({label, name, onChange}: {label: string, name: NamePath, onChange?: () => void}) {
    return <Form.Item
                name={name}
                label={label}
                rules={
                    [{
                        required: true,
                        message: "Veuillez renseigner la puissance"
                    },]}>
                <Radio.Group
                    buttonStyle='solid'
                    onChange={onChange}
                  >
                    <Radio.Button value="0">P ≤ 12 kW</Radio.Button>
                    <Radio.Button value="1">P &gt; 12 kW</Radio.Button>
                    <Radio.Button value="2">PAC en toiture</Radio.Button>
                  </Radio.Group>
            </Form.Item >
}
