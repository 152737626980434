import { ConditionType } from './types';

export const conditions: ConditionType[] = [
    {
        id: 1,
        currenStateName: 'hasTenCaracters',
        description: 'Longueur minimale du mot de passe 10 caractères',
    },
    {
        id: 2,
        currenStateName: 'hasAtLeastOneDegit',
        description: 'Contient au moins 1 chiffre',
    },
    {
        id: 3,
        currenStateName: 'hasAtLeastOneCapitalLetter',
        description: 'Contient au moins 1 majuscule',
    },
    {
        id: 4,
        currenStateName: 'hasAtLeastOneLowerCaseLetter',
        description: 'Contient au moins 1 minuscule',
    },
    {
        id: 5,
        currenStateName: 'hasAtLeastOneSpecialCaracter',
        description: 'Contient au moins 1 caractère spécial',
        tooltipTitle:
            "Les caractères suivants sont comptabilisés comme des caractères spéciaux : ^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ ` + = Le caractère d'espace ne se trouvant ni au début ni à la fin est également traité comme un caractère spécial.",
    },
];
