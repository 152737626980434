import { Form, InputNumber } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function SurfaceREF10({label, name, tooltip, style}:{label: string, name: NamePath, tooltip?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[], style?: React.CSSProperties}) { 
    return <Form.Item
                name={name}
                label={label}
                style={style}
                rules={[
                {required: true, message:"Veuillez renseigner la surface"},
                ]}
                tooltip={tooltip
                    ?
                    {
                        title: tooltip,
                        icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                        color: '#002766',
                    }
                    : null
                }
            >
                <InputNumber addonAfter="m²" min={0} decimalSeparator=',' style={{maxWidth : '150px' }}/>
            </Form.Item>
}