import { Form, Alert, Space, FormListFieldData, Typography, Radio, Flex, Divider, Button, Collapse, Card } from 'antd';
import { useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import SecteurIREF22AX from '@/features/new-project/operations/forms/components/common/SecteurIREF22AX';
import TypeLampeIREF30F from '@/features/new-project/operations/forms/components/common/TypeLampeIREF30F';
import FluxLumineuxIREF168B from '@/features/new-project/operations/forms/components/common/FluxLumineuxIREF168B';

const { Paragraph } = Typography
const { Title } = Typography

export const Bateq1274 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    // Lien vers le PDF de la FOST
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-EQ-127%20vA40-4%20%C3%A0%20compter%20du%2001-04-2022.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Typography>Les luminaires à modules LED mis en place respectent les critères suivants :
            <Typography style={{ paddingLeft: '16px' }}>- durée de vie calculée à 25°C <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à 50 000 heures </Typography.Text>
                pour une chute de flux lumineux inférieure ou égale à 20 % conformément à la norme EN 62722-2-1 et à la méthode d'extrapolation TM21</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- efficacité lumineuse (flux lumineux total sortant du luminaire divisé par la puissance totale du luminaire auxiliaire d'alimentation compris) est
                <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à :</Typography.Text>
                <Typography style={{ paddingLeft: '32px' }}>-<Typography.Text strong style={{ color: primary_color }}>  120 lumens par watt </Typography.Text>
                    pour les luminaires ayant un indice de protection aux chocs (IK) supérieur ou égal à 10 selon la norme NF EN 62262</Typography>
                <Typography style={{ paddingLeft: '32px' }}>-<Typography.Text strong style={{ color: primary_color }}>  140 lumens par watt </Typography.Text>
                    pour les autres luminaires</Typography>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- toutefois, dans le cas où l’indice de rendu des couleurs (IRC) est<Typography.Text strong style={{ color: primary_color }}> supérieur ou égal à 90 </Typography.Text>
                selon la norme NF EN 62717, avec R9 &gt; 0, l’efficacité lumineuse est <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à :</Typography.Text>
                <Typography style={{ paddingLeft: '32px' }}>-<Typography.Text strong style={{ color: primary_color }}> 108 lumens par watt </Typography.Text>
                    pour les luminaires ayant un indice de protection aux chocs (IK) supérieur ou égal à 10 selon la norme NF EN 62262</Typography>
                <Typography style={{ paddingLeft: '32px' }}>-<Typography.Text strong style={{ color: primary_color }}> 126 lumens par watt </Typography.Text>
                    pour les autres luminaires</Typography>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- facteur de déphasage <Typography.Text strong style={{ color: primary_color }}> supérieur ou égal à 0,9 </Typography.Text>
                quelle que soit la puissance selon la norme EN 61000-3-2 </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- taux de distorsion harmonique sur le courant <Typography.Text strong style={{ color: primary_color }}> inférieur à 25 % </Typography.Text>
                selon la norme EN 61000-3-2</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- groupe de risque photobiologique <Typography.Text strong style={{ color: primary_color }}> strictement inférieur à « 2 » </Typography.Text>
                selon la norme NF EN 60598-1 Luminaires - Partie 1 : exigences générales et essais</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- le luminaire est<Typography.Text strong style={{ color: primary_color }}> adapté pour la régulation automatique </Typography.Text>
                par gradation de puissance en fonction de l’éclairage naturel du local dès lors que cet éclairage est possible</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- le ou les modules LED et leurs appareillages d'alimentation associés sont<Typography.Text strong style={{ color: primary_color }}> remplaçables</Typography.Text></Typography>
        </Typography>

    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography style={{ paddingLeft: '16px' }}>- la mise en place de luminaires à modules LED</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la quantité d’équipements installés</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur puissance</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur durée de vie calculée à 25°C</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur chute de flux lumineux à l’issue de leur durée de vie</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur efficacité lumineuse (auxiliaire d'alimentation compris)</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur indice de rendu des couleurs (IRC) et leur R9</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur indice de protection aux chocs (IK) si l’efficacité lumineuse est inférieure à 140 lm/W</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur facteur de déphasage</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- leur taux de distorsion harmonique</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- le groupe de risque photobiologique</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- le fait que le luminaire est adapté pour la régulation automatique par gradation de puissance en fonction de
                l’éclairage naturel du local dès lors que cet éclairage est possible</Typography>
        </Paragraph>
        <Typography>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un nombre donné de luminaires identifiés par leur marque et référence
            ainsi que la puissance de ces luminaires. Elle est complétée dans ce cas par un document issu du fabricant indiquant que les équipements de marque et référence
            mis en place sont des luminaires à modules LED. Ce document précise la durée de vie des luminaires calculée à 25°C, leur chute de flux lumineux à l’issue de
            leur durée de vie, leur efficacité lumineuse (auxiliaire d'alimentation compris), leur indice de protection aux chocs (IK), ou leur IRC, si l’efficacité
            lumineuse est inférieure à 140 lm/W, leur facteur de déphasage, leur taux de distorsion harmonique, le groupe de risque photobiologique et le fait que le
            luminaire est adapté pour la régulation automatique par gradation de puissance en fonction de l’éclairage naturel du local dès lors que cet éclairage est
            possible.</Typography>
    </>

    const dimensionnement = <>
        <Paragraph>L'étude de dimensionnement :
            <Typography style={{ paddingLeft: '16px' }}>- dresse l’état des lieux des équipements en place avant rénovation</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- identifie les besoins afin de garantir le bon éclairage général des locaux et la maîtrise des consommations
                d’énergie dans le respect des exigences réglementaires</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- indique les caractéristiques, le nombre et l’implantation des nouveaux luminaires</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- indique la puissance installée par m² de surface utile éclairée</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- dimensionne les économies d’énergie attendues</Typography>
        </Paragraph>
        <Typography>Le professionnel ou le bureau d’études dispose d’une qualification « RGE étude » dans le domaine de l’éclairage.</Typography>

    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: "Contenu de l'étude de dimensionnement" },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
        3: <>{dimensionnement}</>
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };




    return (
        <>
            <Title level={3} style={{ color: primary_color }}>BAT-EQ-127 : Luminaire à modules LED</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’un luminaire à modules LED</Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Bâtiment tertiaire existant depuis plus de 2 ans */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2ans"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "IREF-22-AX"], undefined)
                    form.setFieldValue(['items', field.name, "IREF-30-F"], undefined)
                    form.setFieldValue(['items', field.name, "IREF-168-B"], undefined)
                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                    }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si bâtiment non tertiaire */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Oui" ? (
                        <>
                            {/*Secteur*/}
                            < SecteurIREF22AX
                                label="Secteur :"
                                name={[field.name, 'IREF-22-AX']}
                            />

                            {/* Type lampe */}
                            <TypeLampeIREF30F
                                label="Type de lampe :"
                                name={[field.name, 'IREF-30-F']}
                            />

                            {/* Flux lumineux */}
                            <FluxLumineuxIREF168B
                                label="Efficacité lumineuse :"
                                name={[field.name, 'IREF-168-B']}
                                tooltip="L’efficacité lumineuse est égale au flux lumineux total sortant du luminaire divisé par sa puissance totale, y compris les
                                auxiliaires d'alimentation"
                            />

                            {/* Puissance */}
                            <PuissanceREF26
                                label="Puissance totale des luminaires à modules LED installés (en watt) :"
                                name={[field.name, 'REF-26']}
                                unite="W"
                                tooltip='Puissance totale des luminaires à modules LED installés (en watt)'
                            />
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>

    );
};
