import newProjectServices from '@/services/new-project/newProjectServices';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { useAsync } from 'react-async';
import { useNavigate } from 'react-router-dom';

export const useSubmitNewProject = () => {
    const navigate = useNavigate();
    const {
        isPending,
        isResolved,
        isRejected,
        error,
        run: submitAddNewProject,
    } = useAsync({
        deferFn: (param) => {
            return newProjectServices.sendNewProjectFormValues(
                param[0] as TNewProjectForm
            );
        },
        onResolve() {
            navigate('/dashboard');
        }
    });

    return { isPending, isResolved, isRejected, error, submitAddNewProject };
};
