import BaseAPI from '@apis/base';

class DossierServices {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }

    async getDossierDetails(dossierId: number) {
        return await this.axios
            .get(`/dossier/${dossierId}`)
            .then((response) => response.data);
    }

    async getDossierDocuments(dossierId: number) {
        return await this.axios
            .get(`/dossier/documents/${dossierId}`)
            .then((response) => response.data);
    }

    async getDossierSteps() {
        return await this.axios
            .get('/dossier/steps')
            .then((response) => response.data);
    }

    async getDossierStepDescription(data: {
        dossierStep: string;
        userType: string | null;
    }) {
        return await this.axios
            .get(`/dossier/step_description`, { params: data })
            .then((response) => response.data);
    }
}

const dossierServices = new DossierServices();

export default dossierServices;
