import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, FormListFieldData, Radio, Row, Space } from 'antd';

import { WorkTypes, projectConcernsData } from '../data/projectConcernsData';
import { sortByKey } from '../utils';
import KeywordSearchInput from './KeywordSearchInput';
import { FicheItem, LeveItem } from './LeveItem';

export const ProjectConcerns: React.FC<{
  field: FormListFieldData;
  onFicheSelected: (option: WorkTypes) => void;
  onBack?: (operationIndex: number) => void;
}> = ({ field, onFicheSelected, onBack }) => {
  const [ficheValue, setFicheValue] = useState<string>();
  const [optionValue, setOptionValue] = useState<string>();
  const [areaValue, setAreaValue] = useState<string>();

  const [listAreas] = useState<WorkTypes[]>(projectConcernsData);
  const [listOptions, setListOptions] = useState<WorkTypes[]>();
  const [fichesList, setFichesList] = useState<WorkTypes[]>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortedFichesList, setSortedFichesList] = useState<WorkTypes[]>([]);

  const resetListAndValues = () => {
    setFichesList([]);
    setListOptions([]);
    setFicheValue('');
    setOptionValue('');
    setSearchQuery('');
  };

  const onClickArea = (option: WorkTypes) => {
    setAreaValue(option.value);
    resetListAndValues();

    if (option.children && option.children.length > 0) {
      setListOptions(option.children);
      if (!option.children.some((child) => child.children)) {
        setFichesList(option.children);
        setListOptions([]);
      }
    } else {
      onFicheSelected(option);
      setFicheValue(option.value);
      setFichesList([]);
    }
  };

  const onClickOption = (option: WorkTypes) => {
    setFicheValue('');
    setSearchQuery('');
    if (option.children && option.children.length > 0) {
      setOptionValue(option.value);
      setFichesList(option.children);
    } else {
      onFicheSelected(option);
      setOptionValue(option.value);
      setFichesList([]);
    }
  };

  const onClickFiche = (fiche: WorkTypes) => {
    onFicheSelected(fiche);
    setFicheValue(fiche.value);
  };

  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const filterAndSortFichesList = useMemo(() => {
    return () => {
      let filteredList = fichesList?.filter(
        (fiche) =>
          fiche.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          fiche.description.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setSortedFichesList(sortByKey(filteredList, 'value'));
    };
  }, [fichesList, searchQuery]);

  useEffect(() => {
    filterAndSortFichesList();
  }, [filterAndSortFichesList, searchQuery]);

  useEffect(() => {
    setSortedFichesList(sortByKey(fichesList, 'value'));
  }, [fichesList]);

  return (
    <Space direction="vertical" size="large" key={field.name}>
      <Row>
        <Button
          onClick={() => {
            setAreaValue('');
            resetListAndValues();
            onBack && onBack(field.name);
          }}
          key={field.name}
        >
          <ArrowLeftOutlined />
        </Button>
      </Row>
      <Form.Item name={[field.name, 'fiche']}>
        <Radio.Group>
          <Space size="large" align="start">
            <Space direction="vertical" size="small">
              {listAreas?.map((option) => {
                return (
                  <Fragment key={option.id.toString()}>
                    <Space direction="vertical" size="large">
                      <Radio.Button
                        style={{
                          border: 'none',
                          height: '100%',
                        }}
                        value={option.value}
                        onClick={() => onClickArea(option)}
                      >
                        <LeveItem
                          title={option.title}
                          description={option.description}
                          icon={option.icon}
                          value={option.value}
                          isSelected={areaValue === option.value ? true : false}
                        />
                      </Radio.Button>
                    </Space>
                  </Fragment>
                );
              })}
            </Space>
            <Space direction="vertical" size="small">
              {listOptions?.map((option) => {
                return (
                  <Fragment key={option.id.toString()}>
                    <Space direction="vertical" size="large">
                      <Radio.Button
                        style={{
                          border: 'none',
                          height: '100%',
                        }}
                        value={option.value}
                        onClick={() => onClickOption(option)}
                      >
                        <LeveItem
                          title={option.title}
                          description={option.description}
                          icon={option.icon}
                          value={option.value}
                          isSelected={
                            optionValue === option.value ? true : false
                          }
                        />
                      </Radio.Button>
                    </Space>
                  </Fragment>
                );
              })}
            </Space>
            <Space
              direction="vertical"
              size="small"
              style={{
                alignItems: 'left',
              }}
            >
              {fichesList.length > 0 && (
                <KeywordSearchInput
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                />
              )}

              {sortedFichesList?.map((fiche) => {
                return (
                  <Fragment key={fiche.id.toString()}>
                    <Radio.Button
                      style={{
                        border: 'none',
                        height: '100%',
                      }}
                      value={fiche.value}
                      onClick={() => onClickFiche(fiche)}
                    >
                      <FicheItem
                        title={fiche.title}
                        description={fiche.description}
                        icon={fiche.icon}
                        value={fiche.value}
                        isSelected={ficheValue === fiche.value ? true : false}
                      />
                    </Radio.Button>
                  </Fragment>
                );
              })}
            </Space>
          </Space>
        </Radio.Group>
      </Form.Item>
    </Space>
  );
};
