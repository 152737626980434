import { Form, FormListFieldData, Typography, Divider, Button, Flex, Switch, Radio } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22C from '../refs/SecteurREF22C';
import SurfaceREF10 from '../refs/SurfaceREF10';
import ClasseEnergetiqueREF78I from '../refs/ClasseEnergetiqueREF78I';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


const { Title } = Typography

export const Batth1165S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-116"
    const libelle_fost = "Système de gestion technique du bâtiment pour le chauffage, l’eau chaude sanitaire, le refroidissement/climatisation, l’éclairage et les auxiliaires"
    const denomination_fost = "Mise en place d’un système de gestion technique du bâtiment pour un usage chauffage et, le cas échéant, eau chaude sanitaire, refroidissement/climatisation, éclairage et auxiliaires"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-116%20vA59-5%20%C3%A0%20compter%20du%2001-01-2024.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code postal */}
            <ZoneClimatiqueS field={field} />
            
            {/* Secteur */}
            <SecteurREF22C
                label="Secteur d'activité :"
                name={[field.name, 'REF-22-C']}
            />

            {/* Classe énergétique */}
            <ClasseEnergetiqueREF78I
                name={[field.name, "REF-78-I"]}
                label="Classe du système de GTB installé"
                tooltip="Selon la norme NF EN ISO 52120-1 : 2022"
            />

            {/* DOM-TOM */}
            <Form.Item
                name={[field.name, "DomTom"]}
                label="Le site des travaux est-il dans les DOM-TOM ?"
                colon={false}
                rules={[{required: true, message: "Veuillez renseigner ce champ"},]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "REF-10"], '')
                    form.setFieldValue(['items', field.name, "IREF-2-A"], '')
                    form.setFieldValue(['items', field.name, "IREF-2-B"], '')
                    form.setFieldValue(['items', field.name, "IREF-2-C"], '')
                    form.setFieldValue(['items', field.name, "IREF-2-D"], '')
                    if (form.getFieldValue(['items',field.name, "DomTom"]) === "Oui") {
                        form.setFieldValue(['items', field.name, "UsageRefroidissement"], true)
                        form.setFieldValue(['items', field.name, "IREF-3-C"], '1')
                        form.setFieldValue(['items', field.name, "UsageChauffage"], undefined)}
                    if (form.getFieldValue(['items',field.name, "DomTom"]) === "Non") {
                        form.setFieldValue(['items', field.name, "UsageChauffage"], true)
                        form.setFieldValue(['items', field.name, "IREF-3-A"], '0')
                        form.setFieldValue(['items', field.name, "UsageRefroidissement"], undefined)
                        form.setFieldValue(['items', field.name, "IREF-3-C"], '')}
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Usages et surfaces associées */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "DomTom"]) ? (
                        <>
                            <Typography.Text strong>Usages gérés par le système de GTB :</Typography.Text>
                            {/* Chauffage si DomTom Non*/}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DomTom"]) === "Non" ? (
                                        <Form.Item
                                            name={[field.name, "UsageChauffage"]}
                                            valuePropName='checked'
                                            initialValue={true}
                                            label="Usage chauffage"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Switch size='small' defaultChecked={true} disabled={true} onChange={() => {
                                                if (form.getFieldValue(['items', field.name, "UsageChauffage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: "0" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: '' }])
                                                    form.setFields([{ name: ["items", field.name, "REF-10"], value: '' }])
                                                }
                                            }}
                                            />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                            {/* Chauffage si DomTom Oui*/}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DomTom"]) === "Oui" ? (
                                        <Form.Item
                                            name={[field.name, "UsageChauffage"]}
                                            valuePropName='checked'
                                            label="Usage chauffage"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Switch size='small' onChange={() => {
                                                if (form.getFieldValue(['items', field.name, "UsageChauffage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: "0" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "IREF-3-A"], value: '' }])
                                                    form.setFields([{ name: ["items", field.name, "REF-10"], value: '' }])
                                                }
                                            }}
                                            />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>


                            {/* Surface chauffage */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "UsageChauffage"]) ? (
                                        <SurfaceREF10
                                            name={[field.name, "REF-10"]}
                                            label="Surface gérée (m²)"
                                            style={{ marginLeft: 16 }}
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* ECS */}
                            <Form.Item
                                name={[field.name, "UsageECS"]}
                                valuePropName='checked'
                                label="Usage eau chaude sanitaire"
                                style={{ marginBottom: 0 }}
                            >
                                <Switch size='small' onChange={() => {
                                    if (form.getFieldValue(['items', field.name, "UsageECS"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-B"], value: "2" }]) }
                                    else {
                                        form.setFields([{ name: ["items", field.name, "IREF-3-B"], value: '' }])
                                        form.setFields([{ name: ["items", field.name, "IREF-2-A"], value: '' }])
                                    }
                                }}
                                />
                                {/* Usage eau chaude sanitaire</Checkbox> */}
                            </Form.Item>
                            {/* Surface ECS */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "UsageECS"]) ? (
                                        <SurfaceREF10
                                            name={[field.name, "IREF-2-A"]}
                                            label="Surface gérée (m²)"
                                            style={{ marginLeft: 16 }}
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Refroidissement-Climatisation */}
                            {/* Refroidissement si DomTom Non*/}
                            < Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DomTom"]) === "Non" ? (
                                        <Form.Item
                                            name={[field.name, "UsageRefroidissement"]}
                                            valuePropName='checked'
                                            label='Usage refroidissement-climatisation'
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Switch size='small' onChange={() => {
                                                if (form.getFieldValue(['items', field.name, "UsageRefroidissement"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: "1" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: '' }])
                                                    form.setFields([{ name: ["items", field.name, "IREF-2-B"], value: '' }])
                                                }
                                            }}
                                            />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                            {/* Refroidissement si DomTom Oui*/}
                            < Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "DomTom"]) === "Oui" ? (
                                        <Form.Item
                                            name={[field.name, "UsageRefroidissement"]}
                                            valuePropName='checked'
                                            initialValue={true}
                                            label='Usage refroidissement-climatisation'
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Switch size='small' defaultChecked={true} disabled={true} onChange={() => {
                                                if (form.getFieldValue(['items', field.name, "UsageRefroidissement"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: "1" }]) }
                                                else {
                                                    form.setFields([{ name: ["items", field.name, "IREF-3-C"], value: '' }])
                                                    form.setFields([{ name: ["items", field.name, "IREF-2-B"], value: '' }])
                                                }
                                            }}
                                            />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                            {/* Surface Refroidissement */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "UsageRefroidissement"]) ? (
                                        <SurfaceREF10
                                            name={[field.name, "IREF-2-B"]}
                                            label="Surface gérée (m²)"
                                            style={{ marginLeft: 16 }}
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Eclairage */}
                            <Form.Item
                                name={[field.name, "UsageEclairage"]}
                                label="Usage éclairage"
                                valuePropName='checked'
                                style={{ marginBottom: 0 }}
                            >
                                <Switch size='small' onChange={() => {
                                    if (form.getFieldValue(['items', field.name, "UsageEclairage"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-D"], value: "3" }]) }
                                    else {
                                        form.setFields([{ name: ["items", field.name, "IREF-3-D"], value: '' }])
                                        form.setFields([{ name: ["items", field.name, "IREF-2-C"], value: '' }])
                                    }
                                }}
                                />
                            </Form.Item>
                            {/* Surface Eclairage */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "UsageEclairage"]) ? (
                                        <SurfaceREF10
                                            name={[field.name, "IREF-2-C"]}
                                            label="Surface gérée (m²)"
                                            style={{ marginLeft: 16 }}
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Auxiliaires */}
                            <Form.Item
                                name={[field.name, "UsageAuxiliaires"]}
                                label="Usage auxiliaires"
                                valuePropName='checked'
                                style={{ marginBottom: 0 }}
                            >
                                <Switch size='small' onChange={() => {
                                    if (form.getFieldValue(['items', field.name, "UsageAuxiliaires"]) === true) { form.setFields([{ name: ["items", field.name, "IREF-3-E"], value: "4" }]) }
                                    else {
                                        form.setFields([{ name: ["items", field.name, "IREF-3-E"], value: '' }])
                                        form.setFields([{ name: ["items", field.name, "IREF-2-D"], value: '' }])
                                    }
                                }}
                                />
                            </Form.Item>
                            {/* Surface Auxiliaires */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "UsageAuxiliaires"]) ? (
                                        <SurfaceREF10
                                            name={[field.name, "IREF-2-D"]}
                                            label="Surface gérée (m²)"
                                            style={{ marginLeft: 16 }}
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item >

            {/* Références d'usages masquées qui doivent toutes être envoyées (soit avec la valeur de l'usage soit "" transformé en n/a) */}

            {/* Ref usage Chauffage */}
            <Form.Item hidden name={[field.name, "IREF-3-A"]} initialValue='' />
            {/* Ref usage ECS */}
            <Form.Item hidden name={[field.name, "IREF-3-B"]} initialValue='' />
            {/*Ref usage Refroidissement-Climatisation */}
            <Form.Item hidden name={[field.name, "IREF-3-C"]} initialValue='' />
            {/* Ref usage Eclairage */}
            <Form.Item hidden name={[field.name, "IREF-3-D"]} initialValue='' />
            {/* Ref usage Auxiliaires */}
            <Form.Item hidden name={[field.name, "IREF-3-E"]} initialValue='' />



        </>
    );
};
