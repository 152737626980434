import React, { useEffect, useState } from 'react';

import { IDossier } from '@/features/dashboard';
import { RootState } from '@/store/store';
import { Text } from '@/utils';
import {
    FileProtectOutlined,
    FolderOpenOutlined,
    PieChartOutlined,
    PlusSquareOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { MenuItem } from '../types';
import { getItem } from '../utils';

export const SideBarMenu: React.FC = React.memo(() => {
    const { inprogressDossiers, archivedDossiers } = useSelector(
        (state: RootState) => state.dashboardReducer
    );

    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname);

    const createSideBarMenu = (dossiers: IDossier[], isInprogress: boolean) => {
        return dossiers.reduce((items: MenuItem[], dossier: IDossier) => {
            const item: MenuItem = getItem(
                <Link
                    to={`/dossier/${
                        isInprogress ? 'in-progress' : 'archived'
                    }/${dossier.id}`}
                >
                    <Text type="secondary" strong>
                        {dossier.name}
                    </Text>
                </Link>,
                `/dossier/${isInprogress ? 'in-progress' : 'archived'}/${
                    dossier.id
                }`
            );

            items.push(item);
            return items;
        }, []);
    };

    const items = [
        getItem(
            <Link to="/dashboard">
                <Text type="secondary" strong>
                    Tableau de bord
                </Text>
            </Link>,
            '/dashboard',
            <PieChartOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
        ),
        getItem(
            <Link to="/new">
                <Text type="secondary" strong>
                    Nouveau projet
                </Text>
            </Link>,
            '/new',
            <PlusSquareOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
        ),
        getItem(
            <Text type="secondary" strong className="groupe-name">
                Dossiers en cours
            </Text>,
            'in-progress',
            <FolderOpenOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />,
            createSideBarMenu(inprogressDossiers, true)
        ),
        getItem(
            <Text type="secondary" strong className="groupe-name">
                Dossiers archivés
            </Text>,
            'archived',
            <FileProtectOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />,
            createSideBarMenu(archivedDossiers, false)
        ),
    ];

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [inprogressDossiers, archivedDossiers, location, current]);
    return (
        <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['in-progress']}
            mode="inline"
            items={items}
            selectedKeys={[current]}
        />
    );
});
