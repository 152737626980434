import { Form, FormListFieldData, InputNumber, Typography, Divider, Flex, Button } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import ApplicationMoteurREF40G from '../refs/ApplicationMoteurREF40G';


const { Title } = Typography

export const Indut1022S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "IND-UT-102"
    const libelle_fost = "Système de variation électronique de vitesse sur un moteur asynchrone"
    const denomination_fost = <>Mise en place d’un système de variation électronique de vitesse (VEV) sur un moteur asynchrone
        existant dépourvu de ce système, ou neuf de puissance nominale inférieure ou égale à 3 MW</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-102%20v%20A19-2.pdf"



    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Application moteur */}
            <ApplicationMoteurREF40G
                label="Application du moteur électrique sur lequel est installé le système de VEV"
                name={[field.name, 'REF-40-G']}
            />
            {/* Puissance */}
            <Form.Item
                name={[field.name, 'REF-26']}
                label="Puissance totale (kW)"
                tooltip={{
                    title: "La puissance totale à prendre en compte est égale à la somme des puissances totales des moteurs équipés de VEV.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
                rules={[
                    { required: true, message: "Veuillez renseigner la puissance totale" },
                ]}
            >
                <InputNumber addonAfter='kW' min='0' decimalSeparator=',' />
            </Form.Item>

        </>
    );
};
