import React from 'react';

import { resetIsAppLoading } from '@/store/slices/app/appSlice';
import { resetDashboard } from '@/store/slices/dashboard/dashboardSlice';
import { resetCurrentDossier } from '@/store/slices/dossier/dossierSlice';
import { resetCognitoUser } from '@/store/slices/users/congnito/userSlice';
import { resetHeliosUser } from '@/store/slices/users/helios/heliosUserSlice';
import { RootState } from '@/store/store';
import { Header, Text } from '@/utils/constants';
import logo from '@assets/img/logo_cameo.png';
import { Avatar, Col, Dropdown, Row, Space } from 'antd';
import type { MenuProps } from 'antd';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import './style.scss';

// import { NewDossierBtn } from '@/components';

export const DashboardHeader: React.FC = React.memo(() => {
    return (
        <Header className="dashboard__header">
            <Row align="middle" justify="space-between">
                <Link to="/dashboard">
                    <img
                        src={logo}
                        className="dashboard__logo"
                        alt="logo cameo"
                    />
                </Link>
            </Row>
            {/* <Row align="middle">
                <Space size="large">
                    <NewDossierBtn /> */}
            <UserMenu />
            {/* </Space>
            </Row> */}
        </Header>
    );
});

const UserMenu: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Disconnect = async () => {
        await Auth.signOut();
        dispatch(resetCognitoUser());
        dispatch(resetHeliosUser());
        dispatch(resetCurrentDossier());
        dispatch(resetDashboard());
        dispatch(resetIsAppLoading());
        navigate('/');
    };

    const { heliosUser: user } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );

    const items: MenuProps['items'] = [
        {
            label: (
                <Link to="/profile">
                    <Text strong>Profil</Text>
                </Link>
            ),
            key: '0',
        },
        {
            label: (
                <Link to="/" onClick={() => Disconnect()}>
                    <Text strong>Déconnexion</Text>
                </Link>
            ),
            key: '1',
        },
    ];

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <Row align="middle">
                <Col>
                    <Avatar
                        style={{
                            backgroundColor: '#72B8EC',
                            marginRight: '8px',
                        }}
                        size={'large'}
                    >
                        {user?.firstName && user?.lastName && (
                            <Text strong>{`${user?.firstName.charAt(
                                0
                            )}${user?.lastName.charAt(0)}`}</Text>
                        )}
                    </Avatar>
                </Col>
                <Col className="dashboard__header__user-menu">
                    <Space direction="vertical" size={2}>
                        {user?.firstName && user?.lastName && (
                            <Text style={{ lineHeight: 'inherit' }} strong>
                                {`${user?.firstName} ${user?.lastName}`}
                            </Text>
                        )}
                        <Text style={{ lineHeight: 'inherit' }}>
                            {user?.partner?.companyName}
                        </Text>
                    </Space>
                </Col>
            </Row>
        </Dropdown>
    );
};
