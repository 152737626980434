import React, { useState } from 'react';

import { NumericInput } from '@/components';
import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Checkbox, Form, Input, Row, Space } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const HeatingRecovery = () => {
    const [heatingRecoveryTypes, setHeatingRecoveryTypes] =
        useState<CheckboxValueType[]>();

    const onChange = (checkedValues: CheckboxValueType[]) => {
        setHeatingRecoveryTypes(checkedValues);
    };
    return (
        <Form.Item
            className="new-project__under-form-item"
            name={constant.HEATING_RECOVERY_TYPES}
        >
            <Checkbox.Group onChange={onChange}>
                <Space direction="vertical">
                    <div>
                        <Checkbox value={constant.ON_COLD_GROUP}>
                            <Text>Sur groupe froid : </Text>
                        </Checkbox>
                        {heatingRecoveryTypes?.includes(
                            constant.ON_COLD_GROUP
                        ) && (
                            <div className="work-type-answer">
                                <Text>
                                    <ul style={{ listStyle: 'square' }}>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        Puissance thermique de
                                                        l’échangeur :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.HEATING_RECOVERY_THERMAL_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        Puissance électrique des
                                                        compresseurs :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.HEATING_RECOVERY_ELECTRIC_COMPRESSOR_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                        </Space>
                                    </ul>
                                </Text>
                            </div>
                        )}
                    </div>
                    <div>
                        <Checkbox value={constant.HEATING_RECOVERY_NEEDS}>
                            <Text>Besoins :</Text>
                        </Checkbox>
                        {heatingRecoveryTypes?.includes(
                            constant.HEATING_RECOVERY_NEEDS
                        ) && (
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name={constant.HEATING_RECOVERY_NEEDS_ELEMENTS}
                            >
                                <Checkbox.Group>
                                    <div className="work-type-answer">
                                        <div className="form-answer-third-level">
                                            <Checkbox
                                                value={
                                                    constant.HEATING_RECOVERY_LOCAL_HEATING
                                                }
                                            >
                                                <Text>
                                                    chauffage des locaux
                                                </Text>
                                            </Checkbox>
                                        </div>
                                        <div className="form-answer-third-level">
                                            <Checkbox
                                                value={
                                                    constant.HEATING_RECOVERY_ESC_HEATING
                                                }
                                            >
                                                <Text>ECS</Text>
                                            </Checkbox>
                                        </div>
                                        <div className="form-answer-third-level">
                                            <Checkbox
                                                value={
                                                    constant.HEATING_RECOVERY_PROCESS_HEATING
                                                }
                                            >
                                                <Text>Process</Text>
                                            </Checkbox>
                                        </div>
                                        <div className="form-answer-third-level">
                                            <Row align="bottom">
                                                <Checkbox
                                                    value={
                                                        constant.HEATING_RECOVERY_OTHERS_NEEDS_NAMES
                                                    }
                                                >
                                                    <Text>Autre</Text>
                                                </Checkbox>
                                                <Form.Item
                                                    className="form-item--same-line"
                                                    name={
                                                        constant.HEATING_RECOVERY_OTHERS_NEEDS_NAMES
                                                    }
                                                >
                                                    <Input className="input-text-sameline" />
                                                </Form.Item>
                                            </Row>
                                        </div>
                                    </div>
                                </Checkbox.Group>
                            </Form.Item>
                        )}
                    </div>
                    <div>
                        <Checkbox
                            value={constant.HEATING_RECOVERY_ON_AIR_COMPRESSOR}
                        >
                            <Text>Sur compresseur d’air : </Text>
                        </Checkbox>
                        {heatingRecoveryTypes?.includes(
                            constant.HEATING_RECOVERY_ON_AIR_COMPRESSOR
                        ) && (
                            <div className="work-type-answer">
                                <Text>
                                    <ul style={{ listStyle: 'square' }}>
                                        <Space
                                            direction="vertical"
                                            size="small"
                                        >
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        Puissance thermique de
                                                        l’échangeur :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.HEATING_RECOVERY_ON_AIR_COMPRESSOR_THERMAL_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                            <li style={{ paddingLeft: 0 }}>
                                                <Row align="bottom">
                                                    <Text>
                                                        OU puissance électrique
                                                        du compresseur :
                                                    </Text>
                                                    <Form.Item
                                                        className="form-item--same-line"
                                                        name={
                                                            constant.HEATING_RECOVERY_ON_AIR_COMPRESSOR_ELECTRIC_POWER
                                                        }
                                                    >
                                                        <NumericInput
                                                            maxLength={7}
                                                            placeholder="0"
                                                            classes="input-sameline-answer"
                                                        />
                                                    </Form.Item>
                                                    <Text>kW</Text>
                                                </Row>
                                            </li>
                                        </Space>
                                    </ul>
                                </Text>
                            </div>
                        )}
                    </div>
                </Space>
            </Checkbox.Group>
        </Form.Item>
    );
};
