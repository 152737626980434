import React, { useState } from 'react';

import { Input } from 'antd';

import type { NumericInputProps } from './types';

export const NumericInput = (props: NumericInputProps) => {
    const { onChange, maxLength, placeholder, classes, value, disabled } =
        props;
    const numberInput = value || '';
    const [numericInputValue, setNumericInputValue] =
        useState<string>(numberInput);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            setNumericInputValue(inputValue);
            onChange && onChange(inputValue);
        }
    };

    return (
        <Input
            {...props}
            onChange={handleChange}
            placeholder={placeholder ? placeholder : ''}
            maxLength={maxLength}
            className={classes}
            value={numericInputValue}
            disabled={disabled ? disabled : false}
        />
    );
};
