import { Form, Select, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';


const { Option } = Select;
function TypeInstallationREF81D({ label, name, tooltip }: { label: string, name: NamePath, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
    return <Form.Item
        name={name}
        label={label}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le type d'installation"
            },]}
        // style={{width: '600px'}}
        tooltip={{
            title: <>{tooltip}</>,
            icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
            color: '#0086CA',
        }}>

        <Radio.Group buttonStyle='solid'
            // placeholder="Sélectionnez le type d'installation"
            // size='small'
        >
            <Radio.Button value="0">Convectif</Radio.Button>
            <Radio.Button value="1">Radiatif</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
export default TypeInstallationREF81D;