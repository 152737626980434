export const emailValidation = [
    {
        required: true,
        message: 'Veuillez saisir votre email',
    },
    {
        max: 250,
        message: '',
    },
    {
        pattern: new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        ),
        message: "Ce format d'email n'est pas valide",
    },
];

export const passwordValidation = [
    {
        required: true,
        message: 'Veuillez saisir votre mot de passe',
    },
    {
        min: 10,
        message: '',
    },
    {
        max: 100,
        message: 'Le mot de passe ne dépasse pas 100 caractères',
    },
    {
        pattern: new RegExp(
            /^(?=.*[0-9])(?=.*[!@#$%^&*()_+={}\\[\]:;"'<>,.?/~|\\s-])(?=.*[A-Z])(?=.*[a-z]).{10,}$/
        ),
        message: "Le format de mot de passe n'est pas valide",
    },
];

export const confirmPasswordValidation = {
    required: true,
    message: 'Veuillez confirmer votre mot de passe',
};

export const resetPasswordCodeValidation = [
    {
        required: true,
        message: 'Veuillez saisir le code de confirmation',
    },
];
