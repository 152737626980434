import React from 'react';

import { NOT_SPECIFIED, Text } from '@/utils/constants';
import { Space } from 'antd';

import { TPartnerContactProps } from '../types';

export const PartnerContact: React.FC<TPartnerContactProps> = ({
    blockName,
    signator,
    commercial,
}) => {
    return (
        <Space direction="vertical" size="small">
            <Text strong>{blockName}</Text>
            <Text className="partners__contact">
                Signataire : {signator ? signator : NOT_SPECIFIED}
            </Text>
            <Text className="partners__contact">
                Commercial : {commercial ? commercial : NOT_SPECIFIED}
            </Text>
        </Space>
    );
};
