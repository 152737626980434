import { NewProjectRequest } from '@/features/new-project/NewProjectRequest';
import PublicSimulator from '@/features/public-simulator/PublicSimulator';
import {
    CompleteNewPassword,
    Connexion,
    ForgetPassword,
    ResetPassword,
} from '@features/auth';
import Dashboard from '@features/dashboard/Dashboard';
import Dossier from '@features/dossier/Dossier';
import NotFound from '@features/not-foud/NotFound';
import Profile from '@features/profile/Profile';
import { Route, Routes } from 'react-router-dom';

import { PrivateOutlet } from './PrivateOutlet';
import { PublicOutlet } from './PublicOutlet';
import ProjectRequestDetail from '@/features/projectRequest/ProjectRequestDetail';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route element={<PrivateOutlet />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dossier/:slug/:dossierId" element={<Dossier />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/new" element={<NewProjectRequest />} />
                <Route path="/demande-projet/:requestId" element={<ProjectRequestDetail />}/>
            </Route>
            <Route element={<PublicOutlet />}>
                <Route path="/" element={<Connexion />} />
                <Route path="forget-password" element={<ForgetPassword />} />
                <Route
                    path="compelete-new-password"
                    element={<CompleteNewPassword />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
            </Route>
            <Route path="/simulateur" element={<PublicSimulator />} />
            <Route path="/simulateur/:id" element={<PublicSimulator />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
