import React from 'react';

import { ZipCodeFormat } from '@/components';
import { Text } from '@/utils';
import { Col, Form, Input, Row } from 'antd';
import { PatternFormat } from 'react-number-format';

export const WorkAdress = () => {
    return (
        <Row gutter={16}>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="streetNumber"
                    label={
                        <Text className="field-description" strong>
                            N° de voie
                        </Text>
                    }
                >
                    <PatternFormat customInput={Input} format="#####" />
                </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="streetName"
                    label={
                        <Text className="field-description" strong>
                            Nom de la voie
                        </Text>
                    }
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="zipCode"
                    label={
                        <Text className="field-description" strong>
                            Code postal
                        </Text>
                    }
                    rules={[{ required: true }]}
                >
                    <ZipCodeFormat />
                </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="city"
                    label={
                        <Text className="field-description" strong>
                            Ville
                        </Text>
                    }
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="building"
                    label={
                        <Text className="field-description" strong>
                            Bâtiment
                        </Text>
                    }
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col xs={24} xl={8}>
                <Form.Item
                    name="cadastralPlot"
                    label={
                        <Text className="field-description" strong>
                            Parcelle cadastrale
                        </Text>
                    }
                >
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    );
};
