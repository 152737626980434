import * as constant from '@/features/new-project/constants';
import { createSimpleAnswersOfElement } from '@/features/new-project/utils';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray } from '@/utils';

export const createHeatingRecoveryAnswers = (
    item: any,
    values: TNewProjectForm
) => {
    const newItem: typeof item = {};
    newItem.name = item.name;
    newItem.label = item.label;
    newItem.children = [];

    if (
        values[constant.HEATING_RECOVERY_TYPES] &&
        values[constant.HEATING_RECOVERY_TYPES].length > 0
    ) {
        const heatingRecoveryTypes = values[constant.HEATING_RECOVERY_TYPES];
        const { children } = item;

        for (let index = 0; index < heatingRecoveryTypes.length; index++) {
            const element = heatingRecoveryTypes[index];

            if (
                element === constant.ON_COLD_GROUP ||
                element === constant.HEATING_RECOVERY_ON_AIR_COMPRESSOR
            ) {
                const question = getObjectFromArray(children, 'name', element);
                const answer = createSimpleAnswersOfElement(question, values);

                newItem.children.push(answer);
            }
        }

        if (
            values[constant.HEATING_RECOVERY_NEEDS_ELEMENTS] &&
            values[constant.HEATING_RECOVERY_NEEDS_ELEMENTS].length > 0
        ) {
            const heatingRecoveryNeedsAnswers: any = {};

            const heatingRecoveryNeedsElements =
                values[constant.HEATING_RECOVERY_NEEDS_ELEMENTS];

            const {
                children: heatingRecoveryNeedsChildren,
                name,
                label,
            } = getObjectFromArray(
                children,
                constant.NAME_ATTRIBUT,
                constant.HEATING_RECOVERY_NEEDS
            );

            heatingRecoveryNeedsAnswers.name = name;
            heatingRecoveryNeedsAnswers.label = label;
            heatingRecoveryNeedsAnswers.children = [];

            for (let i = 0; i < heatingRecoveryNeedsElements.length; i++) {
                const heatingRecoveryNeedsElement =
                    heatingRecoveryNeedsElements[i];

                const needsAnswer = getObjectFromArray(
                    heatingRecoveryNeedsChildren,
                    constant.NAME_ATTRIBUT,
                    heatingRecoveryNeedsElement
                );

                if (
                    needsAnswer.name ===
                    constant.HEATING_RECOVERY_OTHERS_NEEDS_NAMES
                ) {
                    needsAnswer.label = needsAnswer.label.replace(
                        constant.STR_VALUE,
                        values[constant.HEATING_RECOVERY_OTHERS_NEEDS_NAMES]
                    );
                }

                heatingRecoveryNeedsAnswers.children.push(needsAnswer);
            }

            newItem.children.push(heatingRecoveryNeedsAnswers);
        }
    }

    return newItem;
};
