import React from 'react';

import { getFicheOperation } from '@/features/new-project/utils';
import { RootState } from '@/store/store';
import { Text, numberFormatter } from '@/utils';
import { Flex, Space } from 'antd';
import { useSelector } from 'react-redux';

export const VolumeResult: React.FC<{
    ficheId: string;
    ficheIndex: number;
}> = ({ ficheId, ficheIndex }) => {
    const { volumes } = useSelector(
        (state: RootState) => state.operationsReducer
    );
    const ficheVolume = getFicheOperation(volumes, ficheId, ficheIndex);
    return (
        <Flex align="flex-end" justify="flex-end" style={{ width: '100%' }}>
            {ficheVolume && (
                <Space direction="vertical" align="end">
                    <Space size="small">
                        <Text strong>
                            {`Opération volume classique : ${numberFormatter(
                                ficheVolume && ficheVolume.classique
                            )} kWhc`}
                        </Text>
                    </Space>
                    <Space size="small">
                        <Text strong>
                            {`Opération volume total : ${numberFormatter(
                                ficheVolume && ficheVolume.total
                            )} kWhc`}
                        </Text>
                    </Space>
                </Space>
            )}
        </Flex>
    );
};
