import React, { useEffect, useState } from 'react';

import { Message } from '@/components';
import { NumericInput } from '@/components';
import { useUpdateUserPhones } from '@/hooks';
import { Text, inputValidation } from '@/utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import type { TChangePhoneNumbersModalProps } from '../types';

export const ChangePhoneNumbersModal: React.FC<
    TChangePhoneNumbersModalProps
> = ({ isOpen, onCancel, oldNumber, isToChangeMobNumber }) => {
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isChangeError, setIsError] = useState<boolean>(false);
    const [isSuccessChange, setIsSuccessChange] = useState<boolean>(false);
    const { isPending, isRejected, updateUserPhoneNumber } =
        useUpdateUserPhones(newPhoneNumber, isToChangeMobNumber);

    const handleOk = () => {
        const validation = inputValidation(
            newPhoneNumber,
            new RegExp(/^\d{10}$/)
        );
        setIsValid(validation);

        if (validation) {
            updateUserPhoneNumber();
            if (!isRejected) {
                setIsSuccessChange(true);
                setIsError(false);
            } else {
                setIsSuccessChange(false);
                setIsError(true);
            }
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    const onCloseModal = () => {
        setIsValid(true);
        setNewPhoneNumber(oldNumber);
        setIsSuccessChange(false);
        setIsError(false);
        onCancel();
    };

    useEffect(() => {
        setNewPhoneNumber(oldNumber);
    }, [isPending, isSuccessChange, isChangeError, oldNumber]);

    return (
        <Modal
            title={`Changer votre numéro`}
            open={isOpen}
            onOk={isChangeError || isSuccessChange ? onCloseModal : handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: isPending }}
            cancelButtonProps={{
                disabled: isPending,
                style: {
                    display: `${
                        isChangeError || isSuccessChange
                            ? 'none'
                            : 'inline-block'
                    }`,
                },
            }}
            okText={`${isChangeError || isSuccessChange ? 'Ok' : 'Valider'}`}
            cancelText="Annuler"
            afterClose={() => onCloseModal()}
        >
            {isChangeError ? (
                <Message
                    message="Un problème s'est produit, veuillez réessayer plus tard."
                    type="danger"
                />
            ) : isSuccessChange ? (
                <Message
                    message="Votre numéro a bien été changé."
                    type="success"
                    icon={<CheckCircleOutlined />}
                />
            ) : (
                <>
                    <NumericInput
                        value={newPhoneNumber}
                        onChange={setNewPhoneNumber}
                        maxLength={10}
                    />
                    {!isValid && (
                        <Text
                            type="danger"
                            style={{ marginTop: '5px', display: 'block' }}
                        >
                            Le numéro de téléphone doit contenir 10 chiffres
                        </Text>
                    )}
                </>
            )}
        </Modal>
    );
};
