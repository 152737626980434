import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const lien_site_ministere = "https://www.ecologie.gouv.fr/coup-pouce-chauffage"

export default function MenageModesteREF175({ label, name, disabled, hidden }: { label: string, name: NamePath, disabled?: boolean, hidden?: boolean }) {
    return <Form.Item
        name={name}
        label={label}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le type de ménage"
            },]}
            tooltip={{
                    title: <>Reportez-vous au tableau disponible sur le <a href={lien_site_ministere} rel="noreferrer" target="_blank" style={{color: 'white', textDecoration:'underline'}}>site du ministère en charge de l'énergie</a></>,
                    icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
                    color: '#0086CA',
                }}
        >
        
        <Radio.Group 
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">Non</Radio.Button>
            <Radio.Button value="1">Oui</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
