import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
function FluxLumineuxIREF168B({ label, name, tooltip }: { label: string, name: NamePath, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner le flux lumineux"
      },]}
      tooltip={{
        title:tooltip,
        icon: <InfoCircleOutlined style={{ color:'#002766' }}/>,
        color: '#0086CA',
        }}
  >

    <Select
      placeholder="Sélectionnez le flux lumineux"
      style={{ width: '720px' }}

    >
      <Option value="0">Efficacité lumineuse entre 120 et 139 lm/W OU Efficacité lumineuse entre 108 et 125 lm/W</Option>
      <Option value="1">Efficacité lumineuse entre 140 et 159 lm/W OU Efficacité lumineuse entre 126 et 143 lm/W</Option>
      <Option value="2">Efficacité lumineuse entre 160 et 184 lm/W OU Efficacité lumineuse entre 144 et 166 lm/W</Option>
      <Option value="3">Efficacité lumineuse supérieure ou égale à 185 lm/W OU Efficacité lumineuse supérieure ou égale à 167 lm/W</Option>
    </Select>
  </Form.Item >
}
export default FluxLumineuxIREF168B;