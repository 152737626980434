import { Form, FormListFieldData, Typography, Divider, Button, Flex, InputNumber } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import TypeLogementREF3 from '../refs/TypeLogementREF3';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import TypeInstallationREF81I from '../refs/TypeInstallationREF81I';
import NbAppartementsREF131 from '../refs/NbAppartementsREF131';
import SurfaceHabitableREF14F from '../refs/SurfaceHabitableREF14F';



const { Title } = Typography

export const Barth1255S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Infos FOST */
    const code_fost = "BAR-TH-125"
    const libelle_fost = "Système de ventilation double flux autoréglable ou modulé à haute performance (France métropolitaine)"
    const denomination_fost = <>Mise en place d’un système de ventilation mécanique contrôlée double flux autoréglable en installation
        individuelle ou collective, ou modulé avec bouches d’extraction hygroréglables en installation individuelle seulement"</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-125%20vA54-5%20%C3%A0%20compter%20du%2001-01-2024.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Type de logement */}
            <TypeLogementREF3
                name={[field.name, "REF-3"]}
                label="Type de logement"
                onChange={() => {
                    form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                    form.setFields([{ name: ["items", field.name, "REF-81-I"], value: undefined }])
                    form.setFields([{ name: ["items", field.name, "REF-131"], value: undefined }])
                    form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                    form.setFields([{ name: ["items", field.name, "SurfaceHabitable"], value: undefined }])
                }}
            />

            {/* Type d'installation si Appartement */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "1" ? (
                        <>
                            <TypeInstallationREF81I
                                name={[field.name, "REF-81-I"]}
                                label="Type de ventilation :"
                            />
                            <NbAppartementsREF131
                                name={[field.name, "REF-131"]}
                                label="Nombre d'appartements :"
                            />

                        </>
                    ) : null
                }
            </Form.Item>

            {/* Type d'installation si Maison individuelle */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "0" ? (
                        <>
                            <TypeInstallationREF81I
                                name={[field.name, "REF-81-I"]}
                                label="Type de ventilation :"
                                moduleeDisabled
                            />
                            {/* Surface chauffée */}
                            <Form.Item
                                name={[field.name, "SurfaceHabitable"]}
                                label="Surface habitable (m²) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="m²"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 35 && getFieldValue(['items', field.name, "SurfaceHabitable"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "0" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 60 && getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 35) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "1" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 70 && getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 60) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "2" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 90 && getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "3" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 110 && getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "4" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) < 130 && getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "5" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceHabitable"]) >= 130) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "6" }]) }
                                        else {form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])}
                                    }}
                                />
                            </Form.Item>

                            <SurfaceHabitableREF14F
                                name={[field.name, "REF-14-F"]}
                                label="Surface habitable :"
                                disabled
                                hidden
                            />
                        </>
                    ) : null
                }
            </Form.Item>
        </>
    );
};
