import { Form, InputNumber } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function LongueurCanalisationREF46({ label, name }: { label: string, name: NamePath }) {
    return <Form.Item
        name={name}
        label={label}
        rules={[
            { required: true, message: "Veuillez renseigner ce champ" },
            { type: 'integer', message: "Veuillez renseigner un nombre entier." },
        ]}
    >
        <InputNumber min={0} addonAfter='m' decimalSeparator=','/>
    </Form.Item>
}