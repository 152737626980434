import React from 'react';

import { useGetProjectRequestById } from '@/hooks/new-project/useGetProjectRequestById';
import { useProjectRequestedDocuments } from '@/hooks/new-project/useProjectRequestDocument';
import { Container } from '@/layouts';
import { Text, Title, numberFormatter } from '@/utils';
import { Card, Divider, Flex, Form, Row, Space, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { WorkDetailsDescription } from '../new-project/operations/WorkDetailsDescription';
import { formatDate } from '../new-project/utils';
import PrimeCeeRange from '../public-simulator/PrimeCeeRange';
import { PriceRangeType } from '../public-simulator/utils/utils';
import { ProjectRequestInfo } from './ProjectRequestInfo';
import ProjectRequestOperation from './ProjectRequestOperation';
import { ProjectRequestWorkDetails } from './ProjectRequestWorkDetails';
import ProjectRequestDocuments from './components/ProjectRequestDocuments';
import { sumEstimations, sumVolumes } from './utils/utils';

const ProjectRequestDetail: React.FC = () => {
    const [form] = Form.useForm();

    const { requestId } = useParams<{ requestId: string }>();

    const { isPending, data } = useGetProjectRequestById(requestId ?? '');

    const { isLoading, data: documents } = useProjectRequestedDocuments(
        requestId ?? ''
    );

    const { sumClassique, sumPrecariousness } = sumVolumes(
        data?.estimateVolumes
    );

    const { sumTotalMax, sumTotalMin, sumTotalMedium } = sumEstimations(
        data?.estimateVolumes
    );

    form.setFieldValue('project_name', data?.projectName);
    form.setFieldValue('streetNumber', data?.address?.streetNumber);
    form.setFieldValue('streetName', data?.address?.streetName);
    form.setFieldValue('zipCode', data?.address?.zipCode);
    form.setFieldValue('city', data?.address?.city);
    form.setFieldValue('building', data?.address?.building);
    form.setFieldValue('cadastralPlot', data?.address?.cadastralPlot);
    form.setFieldValue('siret-installer', data?.projectInstaller?.siret);
    form.setFieldValue('siret-beneficiary', data?.projectBeneficiary?.siret);

    form.setFieldValue(
        'beneficiaryLastname',
        data?.beneficiaryInformations?.beneficiaryLastname
    );
    form.setFieldValue(
        'beneficiaryName',
        data?.beneficiaryInformations?.beneficiaryName
    );
    form.setFieldValue(
        'beneficiaryFunction',
        data?.beneficiaryInformations?.beneficiaryFunction
    );
    form.setFieldValue(
        'beneficiaryEmail',
        data?.beneficiaryInformations?.beneficiaryEmail
    );
    form.setFieldValue(
        'beneficiaryMobileNumber',
        data?.beneficiaryInformations?.beneficiaryMobileNumber
    );
    form.setFieldValue(
        'beneficiaryPhoneNumber',
        data?.beneficiaryInformations?.beneficiaryPhoneNumber
    );

    form.setFieldValue(
        'estimate_work_start_date',
        formatDate(data?.estimateStartWorkDate)
    );
    form.setFieldValue(
        'estimate_work_end_date',
        formatDate(data?.estimateEndWorkDate)
    );
    form.setFieldValue(
        'estimate_signature_date',
        formatDate(data?.estimateSignatureDate)
    );
    form.setFieldValue('project_description', data?.projectDescription);
    form.setFieldValue('applicantProfile', data?.applicantProfile);
    form.setFieldValue('building_existing', data?.isOver2YearsOld);
    form.setFieldValue(
        'principaleUseOfBuilding',
        data?.principaleUseOfBuilding
    );

    const uses = data?.secondeUseOfBuilding || [];
    form.setFieldValue('secondeUseOfBuilding', uses);

    form.setFieldValue('workDescriptionComments', data?.comment);

    const workDetails = data?.workDetailsComplete || [];
    const names = workDetails.map((item) => item.name);

    form.setFieldValue('worksType', names);

    return (
        <Container>
            <Card style={{ height: '100%' }}>
                <Form
                    form={form}
                    layout="vertical"
                    validateMessages={{
                        required: 'Ce champ est obligatoire',
                    }}
                    className="add-project-form"
                    name="projects-simulate-form"
                    disabled={true}
                    initialValues={{ items: [{}] }}
                    scrollToFirstError={{
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    }}
                >
                    <Space direction="vertical" size="large">
                        <div>
                            <ProjectRequestInfo
                                projectFormType={
                                    data?.projectFormType ?? 'SIMULATOR'
                                }
                            />
                            <Divider />

                            {data?.projectFormType === 'GENERIC' && (
                                <ProjectRequestWorkDetails />
                            )}

                            {data?.projectFormType === 'SIMPLIFIED' && (
                                <WorkDetailsDescription />
                            )}

                            {data?.projectFormType === 'SIMULATOR' && (
                                <Title level={4}>3. Vos simulations</Title>
                            )}

                            {isLoading || isPending ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                    }}
                                >
                                    <Spin tip="Loading..." size="large" />
                                </div>
                            ) : (
                                data?.operations?.[0]?.map(
                                    (operation, index) =>
                                        operation.fiche !== null ? (
                                            <div
                                                key={index}
                                                style={{ marginBottom: '20px' }}
                                            >
                                                <ProjectRequestOperation
                                                    operationNumber={index + 1}
                                                    operation={operation}
                                                    estimateVolume={
                                                        data?.estimateVolumes[
                                                            index
                                                        ]
                                                    }
                                                />
                                            </div>
                                        ) : null
                                )
                            )}

                            <Divider />
                        </div>

                        <Row justify="center"></Row>
                    </Space>

                    {data?.estimateVolumes &&
                        data?.estimateVolumes?.length > 0 && (
                            <Card style={{ marginTop: '15px' }}>
                                <Flex align="flex-end" justify="flex-end">
                                    <Space direction="vertical" align="end">
                                        <Space size="small">
                                            <Text strong>
                                                {`Volume total : ${numberFormatter(
                                                    sumClassique +
                                                        sumPrecariousness
                                                )} kWhc`}
                                            </Text>
                                        </Space>

                                        <PrimeCeeRange
                                            priceRangeType={
                                                PriceRangeType.PRIVATE_TOTAL
                                            }
                                            minPrice={sumTotalMin ?? undefined}
                                            maxPrice={sumTotalMax ?? undefined}
                                            mediumPrice={
                                                sumTotalMedium ?? undefined
                                            }
                                            isPublic={false}
                                        />
                                    </Space>
                                </Flex>
                            </Card>
                        )}
                </Form>

                {documents && documents.length > 0 && (
                    <div style={{ marginTop: '15px' }}>
                        <ProjectRequestDocuments data={documents} />
                    </div>
                )}
            </Card>
        </Container>
    );
};

export default ProjectRequestDetail;
