import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import DnAllerREF34E from '@/features/new-project/operations/forms/components/common/DnAllerREF34E';
import LongueurCanalisationREF46 from '@/features/new-project/operations/forms/components/common/LongueurCanalisationREF46';
import DureeAnnuelleUtilisationReseauREF130 from '@/features/new-project/operations/forms/components/common/DureeAnnuelleUtilisationReseauREF130';

const { Paragraph } = Typography

export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};

const { Title } = Typography

export const Resch1053 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "RES-CH-105"
    const libelle_fost = "Passage d’un réseau de chaleur en basse température"
    const denomination_fost = <>Passage d’une utilisation d’un réseau de chaleur en haute pression
        (eau surchauffée), ou d’une partie d’un réseau de chaleur haute pression, à une utilisation du
        réseau de chaleur en basse pression et basse température (eau chaude inférieure à 120°C)</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-105%20vA36-3%20%C3%A0%20compter%20du%2012-10-2020.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Typography>La
            <Typography.Text strong style={{ color: primary_color }}> température maximale de service (TMS) </Typography.Text>
            est abaissée à une valeur
            <Typography.Text strong style={{ color: primary_color }}> inférieure à 120°C </Typography.Text>
            dans la totalité du réseau ou la partie de celui-ci
            <Typography.Text strong style={{ color: primary_color }}> passée en basse pression et basse température.</Typography.Text>
        </Typography>

    </>
    const facture = <>
        <Typography>La preuve de réalisation de l’opération est apportée par
            <Typography.Text strong style={{ color: primary_color }}> le courrier de déclaration </Typography.Text>
            à l’administration compétente du passage total ou partiel du réseau de chaleur en basse pression et basse température.
        </Typography>
    </>
    const justificatifs = <>
        <Typography>Le document justificatif spécifique à l’opération est
            <Typography.Text strong style={{ color: primary_color }}> le descriptif des portions du réseau de chaleur
                existant passées en basse pression. </Typography.Text>
            Il identifie le réseau de chaleur concerné et précise la durée annuelle d’utilisation et, pour chaque
            portion de diamètre différent, la longueur du réseau passée en basse pression et basse température et
            son diamètre nominal initial. Ce document est daté et signé par le bénéficiaire de l’opération et le
            gestionnaire de ce réseau.
        </Typography>

    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Preuve de réalisation' },
        { key: '3', label: 'Pièces justificatives' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* réseau de chaleur plus de 2 ans  */}
            <Form.Item
                name={[field.name, "ReseauDeChaleur1An"]}
                label="L'opération est-elle réalisée sur un réseau de chaleur existant depuis plus d'un an ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <>Est considéré comme existant un réseau de chaleur dont la date de première livraison de chaleur
                        est antérieure d’au moins un an à la date d’engagement de l’opération.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "Tms120C"], undefined)
                    form.setFieldValue(['items', field.name, "REF-130"], undefined)
                    form.setFieldValue(['items', field.name, "REF-46"], undefined)
                    form.setFieldValue(['items', field.name, "REF-34-E"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si réseau de chaleur non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ReseauDeChaleur1An"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un réseau de chaleur existant depuis plus d'un an."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si réseau de chaleur OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "ReseauDeChaleur1An"]) === "Oui" ? (
                        <>
                            {/* TMS120  */}
                            <Form.Item
                                name={[field.name, "Tms120C"]}
                                label="La température maximale de service (TMS), dans la portion du réseau de chaleur concernée, est-elle abaissée à une valeur inférieure à 120°C ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: <>Est considéré comme existant un réseau de chaleur dont la date de première livraison de chaleur
                                        est antérieure d’au moins un an à la date d’engagement de l’opération.</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <Radio.Group buttonStyle='solid' onChange={() => {
                                    form.setFieldValue(['items', field.name, "REF-130"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-46"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-34-E"], undefined)
                                }}>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si réseau de chaleur non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Tms120C"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si la température maximale de service (TMS), dans la portion du réseau de chaleur concernée, est abaissée à une valeur inférieure à 120°C."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Suite formulaire si réseau de chaleur OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Tms120C"]) === "Oui" ? (
                                        <>
                                            <DnAllerREF34E
                                                name={[field.name, "REF-34-E"]}
                                                label="Diamètre initial DN du réseau :"
                                                tooltip="Le diamètre nominal (DN) correspond à la désignation de dimension commune à tous les
                                                    éléments d'une même tuyauterie autre que ceux désignés par leur diamètre extérieur ou intérieur.
                                                    C'est un nombre entier utilisé aux fins de référence. Il correspond au diamètre nominal de la
                                                    canalisation du réseau de chaleur avant son passage en basse température."
                                            />
                                            <LongueurCanalisationREF46
                                                name={[field.name, "REF-46"]}
                                                label="Longueur de canalisation concernée (m) :"
                                            />
                                            <DureeAnnuelleUtilisationReseauREF130
                                                name={[field.name, "REF-130"]}
                                                label="Durée annuelle d’utilisation de la portion du réseau de chaleur concernée :"
                                                tooltip="La durée annuelle d’utilisation du réseau de chaleur est celle de l’année
                                                calendaire précédant la date d’achèvement de l’opération."
                                            />
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>

    );
};
