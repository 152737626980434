import { Form, FormListFieldData, Radio, Space, Input } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ArrowRightOutlined } from '@ant-design/icons';


/* Couleurs */
const border_color = '#76B8DE'

export const ZoneClimatique = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    return (
        <>

            {/* Code Postal */}
            <Space align='end' size='middle' >
                <Form.Item
                    name={[field.name, "zip_code"]}
                    label="Code postal du site des travaux :"
                    rules={[
                        { required: true, message: "Veuillez renseigner le code postal" },
                        { pattern: /^[0-9]*$/, message: "Veuillez utiliser uniquement des chiffres" },
                        { pattern: /^.{5,5}$/, message: "Veuillez renseigner 5 chiffres" },
                    ]}
                    validateTrigger="onBlur"
                >
                    <Input
                        style={{ maxWidth: '100px' }}
                        maxLength={5}
                        onBlur={(event) => {
                            const { value } = event.target
                            const dep = value.slice(0, 2)
                            const h1 = ['01', '02', '03', '05', '08', '10', '14', '15', '19', '21', '23', '25', '27', '28', '38', '39', '42', '43', '45', '51', '52', '54', '55', '57', '58', '59', '60', '61', '62', '63', '67', '68', '69', '70', '71', '73', '74', '75', '76', '77', '78', '80', '87', '88', '89', '90', '91', '92', '93', '94', '95']
                            const h2 = ['04', '07', '09', '12', '16', '17', '18', '22', '24', '26', '29', '31', '32', '33', '35', '36', '37', '40', '41', '44', '46', '47', '48', '49', '50', '53', '56', '64', '65', '72', '79', '81', '82', '84', '85', '86']
                            const h3 = ['06', '11', '13', '20', '30', '34', '66', '83', '97']
                            if (h1.includes(dep)) { form.setFields([{ name: ["items", field.name, "climatic_zone"], value: "H1" }]) }
                            else if (h2.includes(dep)) { form.setFields([{ name: ["items", field.name, "climatic_zone"], value: "H2" }]) }
                            else if (h3.includes(dep)) { form.setFields([{ name: ["items", field.name, "climatic_zone"], value: "H3" }]) }
                            else { form.setFields([{ name: ["items", field.name, "climatic_zone"], value: undefined }]) }
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                </Form.Item>
                {/* Zone Climatique */}
                <Form.Item
                    name={[field.name, "climatic_zone"]}
                    label=""
                    rules={[
                        { required: true, message: "Veuillez renseigner le code postal pour déterminer la zone climatique" },
                    ]}
                >
                    <Radio.Group
                        buttonStyle='solid'
                        disabled={true}
                    >
                        <Radio.Button value="H1">H1</Radio.Button>
                        <Radio.Button value="H2">H2</Radio.Button>
                        <Radio.Button value="H3">H3</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Space >
        </>
    );
};
