import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Button, Collapse, Card, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import UsageREF15A from '@/features/new-project/operations/forms/components/common/UsageREF15A';
import RREF142 from '@/features/new-project/operations/forms/components/common/RREF142';
import EfficaciteEnergetique140E from '@/features/new-project/operations/forms/components/common/EfficaciteEnergetiqueREF140E';
import NbAppartementsREF131 from '@/features/new-project/operations/forms/components/common/NbAppartementsREF131';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

const { Title } = Typography

export const Barth1661 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAR-TH-166"
    const libelle_fost = "Pompe à chaleur collective de type air/eau ou eau/eau"
    const denomination_fost = "Mise en place d’une ou plusieurs pompes à chaleur (PAC) de type air/eau ou eau/eau de puissance thermique nominale inférieure ou égale à 400 kW pour un système de chauffage collectif"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-166%20v%20A38-1%20%C3%A0%20compter%20du%2031-07-2021.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Typography>- La PAC installée est de puissance nominale <Typography.Text strong style={{ color: primary_color }}>inférieure ou égale à 400 kW</Typography.Text></Typography>
        <Typography>- La PAC installée est dimensionnée pour répondre aux besoins du bâtiment <Typography.Text strong style={{ color: primary_color }}>en chauffage ou en chauffage et en eau chaude sanitaire</Typography.Text></Typography>
        <Typography>- <Typography.Text strong style={{ color: primary_color }}>L’efficacité énergétique saisonnière (Etas)</Typography.Text> selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 est supérieure ou égale à :
            <Typography style={{ paddingLeft: '16px' }}><Typography.Text strong style={{ color: primary_color }}>- 111 %</Typography.Text> pour les PAC <Typography.Text strong style={{ color: primary_color }}>moyenne et haute température</Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}><Typography.Text strong style={{ color: primary_color }}>- 126 %</Typography.Text> pour les PAC <Typography.Text strong style={{ color: primary_color }}>basse température</Typography.Text></Typography>
        </Typography>

    </>
    const justificatifs = <>
        <Typography>Le professionnel réalisant l’opération est titulaire d’un signe de qualité conforme aux exigences prévues à l’article 2 du décret n° 2014-812 du
            16 juillet 2014 pris pour l’application du second alinéa du 2 de l’article 200 quater du code général des impôts et du dernier alinéa du 2 du I de l’article
            244 quater U du code général des impôts et des textes pris pour son application. Ce signe de qualité correspond à des travaux relevant du 5° pour les besoins
            en chauffage et des 5° et 6° pour les besoins en chauffage et eau chaude sanitaire du I de l'article 1er du décret précité.</Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography>- la mise en place d’une pompe à chaleur de type air/eau ou eau/eau</Typography>
            <Typography>- sa puissance thermique nominale</Typography>
            <Typography>- le type de pompe à chaleur (basse, moyenne ou haute température)</Typography>
            <Typography>- l’efficacité énergétique saisonnière (Etas) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013</Typography>
        </Paragraph>

        <Typography>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence et elle est complétée par
            un document issu du fabricant ou d’un organisme établi dans l'Espace économique européen et accrédité selon la norme NF EN ISO/IEC 17065 par le Comité français
            d'accréditation (COFRAC) ou tout autre organisme d'accréditation signataire de l'accord européen multilatéral pertinent pris dans le cadre de la coordination
            européenne des organismes d'accréditation.</Typography>
        Ce document indique :
        <Typography>- que l’équipement de marque et référence mis en place est une pompe à chaleur à de type air/eau ou eau/eau ;</Typography>
        <Typography>- la puissance thermique nominale de la pompe à chaleur ;</Typography>
        <Typography>- le type de pompe à chaleur (basse, moyenne ou haute température) ;</Typography>
        <Typography>- l’efficacité énergétique saisonnière (Etas) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.</Typography>
    </>
    const incompatibilites = <>
        <Paragraph>Lorsque :
            <Typography style={{ paddingLeft: '16px' }}>- la chaufferie après rénovation comporte des équipements relevant des fiches <Typography.Text strong style={{ color: primary_color }}>BAR-TH-107 et/ou BAR-TH-150 et de la fiche BAR-TH-166</Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}>- et la puissance thermique de la (ou des) PAC installée(s) est <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à 40 % </Typography.Text>de la puissance de la nouvelle chaufferie</Typography>
        </Paragraph>
        <Typography>Alors <Typography.Text strong style={{ color: primary_color }}>seule la fiche BAR-TH-166 </Typography.Text>donne lieu à la délivrance de certificats.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Pièces justificatives' },
        { key: '3', label: 'Contenu de la facture' },
        { key: '4', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{justificatifs}</>,
        3: <>{facture}</>,
        4: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>{denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Appartements plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Appartements2Ans"]}
                label="L'opération est réalisée sur des appartements existants depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "Pinstallee"], undefined)
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-24-B"], undefined)
                        form.setFieldValue(['items', field.name, "REF-15-A"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                        form.setFieldValue(['items', field.name, "TemperaturePAC"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Apparts + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur des appartements existants depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Appartements OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Oui" ? (
                        <>
                            {/* Nb Appartements */}
                            <NbAppartementsREF131
                                name={[field.name, 'REF-131']}
                                label="Nombre d'appartements :"
                            />

                            {/* Usage */}
                            <UsageREF15A
                                name={[field.name, 'REF-15-A']}
                                label="Usage de la pompe à chaleur :"
                            />

                            {/* Puissance PAC */}
                            <Form.Item
                                name={[field.name, "Pinstallee"]}
                                label="Puissance de la PAC installée (kW) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="kW"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                                    }}
                                />
                            </Form.Item>

                            {/* Alerte si Puissance PAC supérieure à 400 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) > 400 ? (
                                        <Alert
                                            message="L'opération est éligible pour une puissance nominale inférieure ou égale à 400kW."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Temperature et Etas si Puissance PAC <= 400 kW */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) <= 400 ? (
                                        <>
                                            {/*Temperature*/}
                                            <Form.Item
                                                name={[field.name, "TemperaturePAC"]}
                                                label="Quel est le type de la PAC installée ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {
                                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                                    }}>
                                                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                                                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* ETAS variable continue */}
                                            <Form.Item style={{ margin: "0px" }}>
                                                <Space align="end" size="middle">
                                                    <Form.Item
                                                        label="Etas (%) :"
                                                        name={[field.name, 'Etas']}
                                                        rules={[
                                                            { required: true, message: "Veuillez renseigner ce champ" },
                                                        ]}
                                                        tooltip={{
                                                            title: "L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                            color: tooltip_color,
                                                        }}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            addonAfter="%"
                                                            controls={false}
                                                            style={{ width: '100px' }}
                                                            onChange={() => {
                                                                if (getFieldValue(['items', field.name, "Etas"]) >= 111 && getFieldValue(['items', field.name, "Etas"]) < 120) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: "0" }]) }
                                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 120) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: "1" }]) }
                                                                else if (getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: undefined }]) }

                                                            }}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                                    </Form.Item>
                                                    {/* ETAS */}
                                                    <EfficaciteEnergetique140E
                                                        label=""
                                                        name={[field.name, 'REF-140-E']}
                                                        disabled={true}
                                                    />
                                                </Space>
                                            </Form.Item>
                                            {/* Alerte si basse température et ETAS inférieur à 126 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                                                        <Alert
                                                            message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Alerte si moyenne/haute température et ETAS inférieur à 111 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                                                        <Alert
                                                            message="Pour une PAC moyenne/haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Puissance totale chaufferie */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align='end'>
                                    <Form.Item
                                        name={[field.name, "Pchaufferie"]}
                                        label="Puissance totale de la nouvelle chaufferie : "
                                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                        tooltip={{
                                            title: <>La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                        extra="Puissance de la chaufferie après travaux"
                                        style={{ margin: "0px" }}
                                    >
                                        <InputNumber
                                            addonAfter="kW"
                                            style={{ width: '150px' }}
                                            controls={false}
                                            min='0'
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Pinstallee"]) >= (0.4 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) >= getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Pinstallee"]) < (0.4 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) > getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (getFieldValue(['items', field.name, "Pinstallee"]) / getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                                                else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    {/* R */}
                                    <RREF142
                                        label="Facteur R :"
                                        name={[field.name, 'REF-142']}
                                        disabled={true}
                                    />
                                </Space>
                            </Form.Item>
                            {/* Alerte si Pchaufferie < Pinstallée */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                                        <Alert
                                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item>

            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
