import { Form, Alert, FormListFieldData, Typography, Divider, Flex, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import ChaleurRenouvelableREF32 from '../refs/ChaleurRenouvelableREF32';

const { Title } = Typography

export const Resch1084S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "RES-CH-108"
    const libelle_fost = "Récupération de chaleur fatale pour valorisation vers un réseau de chaleur ou vers un tiers (France métropolitaine)"
    const denomination_fost = "Mise en place d’un système de récupération de chaleur fatale valorisée vers un réseau de chaleur ou un site tiers"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-108%20vA53-4%20%C3%A0%20compter%20du%2001-10-2023.pdf"


    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Chaleur renouvelable nette */}
            <ChaleurRenouvelableREF32
                name={[field.name, "REF-32"]}
                label="Quantité de chaleur nette utilisée ou valorisée dans le réseau de chaleur ou sur le site tiers (kWh/an) : "
            />
            {/* Alerte Chaleur >= 12GWh/an */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-32"]) >= 12000000 ? (
                        <Alert
                            message="Cette opération n'est éligible que si la chaleur nette valorisée est strictement inférieure à 12 GWh/an."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

        </>
    );
};
