import React from 'react';

import {
    EstimateVolume,
    Operation,
} from '@/hooks/new-project/project-request-types';
import { Card, Divider, Flex, List,Typography } from 'antd';

import PrimeCeeRange from '../public-simulator/PrimeCeeRange';
import { PriceRangeType } from '../public-simulator/utils/utils';
import { formatIfNumber, formatKey } from './utils/utils';
import { CustomListItem } from './components/CustomListItem';

const { Title } = Typography;
const { Paragraph } = Typography;

interface ProjectRequestOperationProps {
    operationNumber: number;
    operation: Operation;
    estimateVolume: EstimateVolume;
}

const ProjectRequestOperation: React.FC<ProjectRequestOperationProps> = ({
    operationNumber,
    operation,
    estimateVolume,
}) => {
    const primary_color = '#002766';
    const border_color = '#76B8DE';
    const subtitle_color = '#5E5E5E';
    return (
        <div>
            <Card size="small" title={`Operation n° ${operationNumber}`}>
                <Title level={3} style={{ color: primary_color }}>
                    {operation.fiche}
                </Title>
                <Flex justify="space-between" align="top">
                    <Paragraph
                        strong
                        style={{
                            color: subtitle_color,
                            fontSize: '1.1em',
                            margin: '0px',
                        }}
                    >
                        <Typography>
                            <b>Données de calcul:</b>
                        </Typography>

                        <List>
                            {Object.keys(operation?.references || {}).map(
                                (item) => {
                                    const reference =
                                        operation.references[item];
                                    const itemKey = `${reference.variableLabel}`;
                                    return (
                                        <List.Item key={item}>
                                            <CustomListItem
                                                itemKey={itemKey}
                                                value={formatIfNumber(
                                                    reference.value
                                                )}
                                            />
                                        </List.Item>
                                    );
                                }
                            )}
                        </List>
                    </Paragraph>

                    <Paragraph
                        strong
                        style={{
                            color: subtitle_color,
                            fontSize: '1.1em',
                            margin: '0px',
                        }}
                    >
                        <Typography>
                            <b>Autres informations:</b>
                        </Typography>

                        <List>
                            {Object.entries(operation?.informations).map(
                                ([key, value]) => {
                                    return (
                                        <List.Item key={key}>
                                            <CustomListItem
                                                itemKey={formatKey(key)}
                                                value={formatIfNumber(value)}
                                            />
                                        </List.Item>
                                    );
                                }
                            )}
                        </List>
                    </Paragraph>

                    <Paragraph
                        strong
                        style={{
                            color: subtitle_color,
                            fontSize: '1.1em',
                            margin: '0px',
                        }}
                    >
                        <Typography>
                            <b>Volume estimé:</b>
                        </Typography>

                        <List>
                            {
                                <List.Item>
                                    <CustomListItem
                                        itemKey={'Volume classique'}
                                        value={`${formatIfNumber(
                                            estimateVolume?.classique
                                        )} kWhc`}
                                    />
                                </List.Item>
                            }
                            {
                                <List.Item>
                                    <CustomListItem
                                        itemKey={'Volume précarité'}
                                        value={`${formatIfNumber(
                                            estimateVolume?.precariousness
                                        )} kWhc`}
                                    />
                                </List.Item>
                            }

                            {
                                <List.Item>
                                    <PrimeCeeRange
                                        priceRangeType={
                                            PriceRangeType.PRIVATE_RESUME
                                        }
                                        minPrice={
                                            estimateVolume?.totalprimemin ??
                                            undefined
                                        }
                                        maxPrice={
                                            estimateVolume?.totalprimemax ??
                                            undefined
                                        }
                                        mediumPrice={
                                            estimateVolume?.totalprimemedium ??
                                            undefined
                                        }
                                        isPublic={false}
                                    />
                                </List.Item>
                            }
                        </List>
                    </Paragraph>
                </Flex>
                <Divider
                    orientation="left"
                    orientationMargin={0}
                    style={{ color: primary_color, borderColor: border_color }}
                ></Divider>
            </Card>
        </div>
    );
};

export default ProjectRequestOperation;
