import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import TypeInstallationREF81H from '@/features/new-project/operations/forms/components/common/TypeInstallationREF81H';
import SecteurREF22AG from '@/features/new-project/operations/forms/components/common/SecteurREF22AG';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

const { Title } = Typography

export const Batth1262 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-TH-126"
    const libelle_fost = "Ventilation mécanique double flux avec échangeur à débit d’air constant ou modulé"
    const denomination_fost = "Mise en place d’une ventilation mécanique double flux, avec échangeur, à débit d’air constant ou modulée"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-126%20v%20A32-2%20%C3%A0%20compter%20du%2001-04-2020.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Le système de ventilation mécanique double flux modulée bénéficie d’un
            <Typography.Text strong style={{ color: primary_color }}> avis technique de la Commission chargée de formuler des Avis Techniques (CCFAT) </Typography.Text>
            en cours de validité à la date d’engagement de l’opération ou possède
            des caractéristiques de performance et de qualité équivalentes établies par un organisme implanté dans l’Espace
            économique européen et accrédité selon la norme NF EN ISO/CEI 17065 par le comité français d’accréditation
            (COFRAC) ou tout autre organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans
            le cadre de la coordination européenne des organismes d’accréditation.
        </Paragraph>
        <Paragraph>
            <Typography.Text strong style={{ color: primary_color }}>L’efficacité de récupération de l’échangeur est supérieure ou égale à 75 % </Typography.Text>
            selon la norme NF EN 13053 ou NF EN 308. Est réputé satisfaire cette exigence, un échangeur de chaleur certifié Eurovent Certified Performance
            Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) ou possédant des caractéristiques de
            performance et de qualité équivalentes établies par un organisme implanté dans l’Espace économique européen et
            accrédité selon la norme NF EN ISO/CEI 17065 par le comité français d’accréditation (COFRAC) ou tout autre
            organisme d’accréditation signataire de l’accord européen multilatéral pertinent pris dans le cadre de la coordination
            européenne des organismes d’accréditation.
        </Paragraph>
        <Paragraph>Le caisson de ventilation a une
            <Typography.Text strong style={{ color: primary_color }}> puissance électrique absorbée inférieure ou égale à 0,35 W/(m<sup>3</sup>/h) </Typography.Text>
            par ventilateur au débit nominal (filtres et échangeurs inclus).
        </Paragraph>

    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography style={{ paddingLeft: '16px' }}>- La
                <Typography.Text strong style={{ color: primary_color }}> mise en place d’une ventilation mécanique double flux à débit d’air constant ou modulée </Typography.Text>
                (proportionnelle ou à détection de présence)
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- L'
                <Typography.Text strong style={{ color: primary_color }}>efficacité de récupération </Typography.Text>
                de l’échangeur mesurée selon la norme NF EN 13053 ou NF EN 308, ou en référence à la certification Eurovent Certified Performance Echangeurs
                à plaques air-air (AAHE) ou Echangeur régénératif (AARE) du matériel
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- La
                <Typography.Text strong style={{ color: primary_color }}> puissance électrique absorbée </Typography.Text>
                du caisson de ventilation au débit nominal
            </Typography>
        </Paragraph>
        <Typography>
            A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’équipements avec leurs marques et
            références et elle est accompagnée d’un ou plusieurs document(s) issu(s) du fabricant indiquant que les équipements
            installés constituent un système de ventilation mécanique double flux avec échangeur, à débit d’air constant ou
            modulée (proportionnelle ou à détection de présence). Ce(s) document(s) précise(nt) l’efficacité de récupération de
            l’échangeur, mesurée selon la norme NF EN 13053 ou NF EN 308 ou en référence à la certification Eurovent
            Certified Performance Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) de cet équipement
            ou son équivalent, et la puissance électrique absorbée du caisson de ventilation au débit nominal.
        </Typography>
    </>
    const justificatifs = <>
        <Paragraph>Dans le cas d’une
            <Typography.Text strong style={{ color: primary_color }}> ventilation double flux modulée (proportionnelle ou à détection de présence)</Typography.Text>
            , le document justificatif spécifique à l’opération est l’
            <Typography.Text strong style={{ color: primary_color }}>avis technique de la Commission chargée de formuler des Avis Techniques (CCFAT)</Typography.Text>
            , en cours de validité à la date d’engagement de l’opération ou les éléments de preuve équivalents.
        </Paragraph>

    </>
    const incompatibilites = <>
        <Paragraph>
            <Typography.Text strong style={{ color: primary_color }}></Typography.Text>
            <Typography style={{ paddingLeft: '16px' }}></Typography>
        </Paragraph>

    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Pièces justificatives' },
        { key: '4', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{justificatifs}</>,
        4: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>

            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire plus de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "EfficaciteRecuperation"], undefined)
                    form.setFieldValue(['items', field.name, "PuissanceElecAbsorbee"], undefined)
                    form.setFieldValue(['items', field.name, "REF-22-AG"], undefined)
                    form.setFieldValue(['items', field.name, "REF-10"], undefined)
                    form.setFieldValue(['items', field.name, "REF-81-H"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item >
            {/* Alerte si batiment tertiaire non */}
            < Form.Item noStyle shouldUpdate >
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item >
            {/* Suite formulaire si Batiment OK */}
            < Form.Item noStyle shouldUpdate >
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            {/* Efficacité de récup  */}
                            <Form.Item
                                name={[field.name, "EfficaciteRecuperation"]}
                                label="Efficacité de récupération de l’échangeur (en %) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                                tooltip={{
                                    title: <>L’efficacité de récupération de l’échangeur est déterminée selon la norme NF EN 13053 ou NF EN 308 ou le matériel est
                                        certifié Eurovent Certified Performance Echangeurs à plaques air-air (AAHE) ou Echangeur régénératif (AARE) ou équivalent</>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                            >
                                <InputNumber min={0} decimalSeparator=',' addonAfter='%' onChange={() => {
                                    form.setFieldValue(['items', field.name, "PuissanceElecAbsorbee"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-22-AG"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-81-H"], undefined)
                                }} />
                            </Form.Item>
                            {/* Alerte si Efficacité de récup < 75% */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "EfficaciteRecuperation"]) < 75 ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si l'efficacité de récupération de l'échangeur est supérieure ou égale à 75%."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Suite formulaire si Efficacité de récup OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "EfficaciteRecuperation"]) >= 75 ? (
                                        <>
                                            {/* P elec absorbee  */}
                                            <Form.Item
                                                name={[field.name, "PuissanceElecAbsorbee"]}
                                                label="Puissance électrique absorbée du caisson de ventilation au débit nominal (W/(m3/h)) :"
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <InputNumber min={0} decimalSeparator=',' addonAfter="W/(m3/h)" onChange={() => {
                                                    form.setFieldValue(['items', field.name, "REF-22-AG"], undefined)
                                                    form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                                    form.setFieldValue(['items', field.name, "REF-81-H"], undefined)
                                                }} />
                                            </Form.Item>
                                            {/* Alerte si P elec absorbee > 0,35 */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "PuissanceElecAbsorbee"]) > 0.35 ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si la puissance électrique absorbée du caisson de ventilation au débit nominal (W/(m3/h)) est inférieure ou égale à 0,35 W/(m3/h))."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* Suite formulaire si P elec absorbee OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "PuissanceElecAbsorbee"]) <= 0.35 ? (
                                                        <>
                                                            <TypeInstallationREF81H
                                                                name={[field.name, "REF-81-H"]}
                                                                label="Type de ventilation :"
                                                            />

                                                            <SecteurREF22AG
                                                                name={[field.name, "REF-22-AG"]}
                                                                label="Secteur d'application :"
                                                            />

                                                            <SurfaceREF10
                                                                name={[field.name, "REF-10"]}
                                                                label="Surface ventilée :"
                                                                tooltip="On entend par surface ventilée, la surface totale du bâtiment couverte par le
                                                                        système de ventilation mécanique double flux avec échangeur, à débit d’air constant ou modulée."
                                                            />
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item >

            {/* Conseils CAMEO */}
            < Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={
                    [{
                        key: '1',
                        label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                        children:
                            <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                                {contentListNoTitle[activeTabKey]}
                            </Card>,
                    }]}
            />
        </>
    );
};
