import { Form, FormListFieldData, Typography, Divider, Flex, Button, InputNumber } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import ProductionAnnuelleREF101 from '../refs/ProductionAnnuelleREF101';



const { Title } = Typography

export const Indut1174S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "IND-UT-117"
    const libelle_fost = "Système de récupération de chaleur sur un groupe de production de froid"
    const denomination_fost = <>Mise en place d’un système de récupération de chaleur sur un groupe de
        production de froid afin de chauffer ou préchauffer un fluide caloporteur (e.g. de l’eau), sur site</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-117%20v%20A35-4%20%C3%A0%20compter%20du%2001-10-2020.pdf"

    /* onChange Calcul de Pretenue */
    const onPChange = () => {
        if (form.getFieldValue(['items', field.name, "Pcompresseur"]) > 0 &&
            form.getFieldValue(['items', field.name, "Precuperee"]) > 0 &&
            form.getFieldValue(['items', field.name, "Pdejarecuperee"]) >= 0) {
            if (form.getFieldValue(['items', field.name, "Precuperee"]) <= ((2 * form.getFieldValue(['items', field.name, "Pcompresseur"])) - form.getFieldValue(['items', field.name, "Pdejarecuperee"]))) { form.setFields([{ name: ["items", field.name, "REF-26"], value: form.getFieldValue(['items', field.name, "Precuperee"]) }]) }
            else { form.setFields([{ name: ["items", field.name, "REF-26"], value: ((2 * form.getFieldValue(['items', field.name, "Pcompresseur"])) - form.getFieldValue(['items', field.name, "Pdejarecuperee"])) }]) }
        }
        else { form.setFields([{ name: ["items", field.name, "REF-26"], value: undefined }]) }
    }

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Production annuelle */}
            <ProductionAnnuelleREF101
                name={[field.name, "REF-101"]}
                label="Durée annuelle d’utilisation de la chaleur récupérée (heures) :"
                unite='heures'
                tooltip="Données de l’étude de dimensionnement préalable."
            />
            {/* Puissance compresseur */}
            <Form.Item
                name={[field.name, "Pcompresseur"]}
                label="Puissance électrique du ou des compresseur(s) frigorifique(s) (kW) :"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <>Données de l’étude de dimensionnement préalable. Aucune autre information que la puissance
                        nominale plaquée ou à défaut celle fournie sur justificatif du fabricant n’est utilisable dans la
                        fiche CEE (par exemple, les valeurs d’intensité ne sont pas applicables).</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
            </Form.Item>
            {/* Puissance récupérée */}
            <Form.Item
                name={[field.name, "Precuperee"]}
                label="Puissance thermique récupérée (kW) :"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: "Données de l’étude de dimensionnement préalable.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
            </Form.Item>
            {/* Puissance déjà récupérée */}
            <Form.Item
                name={[field.name, "Pdejarecuperee"]}
                label="Puissance thermique déjà récupérée sur le groupe de production de froid (kW) :"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <>Données de l’étude de dimensionnement préalable.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber onChange={onPChange} min={0} addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pcompresseur"]) > 0 &&
                        getFieldValue(['items', field.name, "Precuperee"]) > 0 &&
                        getFieldValue(['items', field.name, "Pdejarecuperee"]) >= 0 ? (
                        <>
                            {/* Puissance retenue */}
                            <Form.Item style={{ margin: '0px' }}
                                name={[field.name, "REF-26"]}
                                label="Puissance récupérée retenue (kW) :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner les champs précédents" },
                                ]}
                                tooltip={{
                                    title: <>La puissance récupérée est limitée à (2 x P<sub>compresseur</sub>) - P<sub>déjà récupérée</sub></>,
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                                extra="Calculée automatiquement"
                            >
                                <InputNumber min={0} disabled addonAfter='kW' decimalSeparator=',' controls={false} style={{ width: '150px' }} />
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>
        </>
    );
};
