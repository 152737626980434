import React from 'react';

import { RootState } from '@store/store';
import { Title } from '@utils/constants';
import { Card, Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

import { BenificiaryPrime } from './BenificiaryPrime';
import { DossierVolume } from './DossierVolume';
import { InstalateurBusinessProviderPrime } from './InstalateurBusinessProviderPrime';
import './style.scss';

export const DossierHeader: React.FC = () => {
    const { details, currentDossierFromList } = useSelector(
        (state: RootState) => state.dossierReducer
    );
    return (
        <Card className="dossier__header__card dossier__header">
            <Row align="middle" justify="space-between">
                <Col>
                    <Title
                        level={2}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            color: '#FFFFFF',
                            fontWeight: '700',
                        }}
                    >
                        {`${currentDossierFromList.name}`}
                    </Title>
                </Col>
                <Col className="dossier__primes-volume">
                    <Row align="middle" justify="space-between">
                        <Space size="large" wrap>
                            {details.benificiaryPrime > 0 && (
                                <Col>
                                    <BenificiaryPrime
                                        benificiaryPrime={
                                            details.benificiaryPrime
                                        }
                                    />
                                </Col>
                            )}
                            <Col>
                                <DossierVolume
                                    volumeDossier={details.volumeDossier}
                                />
                            </Col>
                            {details.prime !== null && details.prime !== 0 && (
                                <InstalateurBusinessProviderPrime
                                    prime={details.prime}
                                    isInstaller={details.isInstaller}
                                />
                            )}
                        </Space>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};
