import { Form, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

export default function EfficaciteEnergetiqueREF140C({name, label, disabled, hidden}: {name: NamePath, label: string, disabled?: boolean, hidden?: boolean}) {
  return <Form.Item
            name={name}
            label={label}
            hidden={hidden}
            rules={[
                { required: true, message: "Veuillez renseigner l'efficacité énergétique saisonnière" },
              ]}
            tooltip={{
                title: <>L’efficacité énergétique saisonnière (η<sub>s</sub>) est calculée selon le règlement (EU) n°813/2013 de la commission du 2 août 2013 (hors dispositif de régulation).</>,
                icon: <InfoCircleOutlined style={{ color: 'blue' }} />,
                color: 'blue'
            }}
            >
            <Radio.Group buttonStyle='solid' disabled={disabled}>
                <Radio.Button value="0">111% ≤ η<sub>s</sub> &lt; 126%</Radio.Button>
                <Radio.Button value="1">126% ≤ η<sub>s</sub></Radio.Button>
            </Radio.Group>
        </Form.Item>
}