import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Button, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import UsageREF15A from '../refs/UsageREF15A';
import RREF142 from '../refs/RREF142';
import EfficaciteEnergetique140E from '../refs/EfficaciteEnergetiqueREF140E';
import NbAppartementsREF131 from '../refs/NbAppartementsREF131';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';



/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1661S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAR-TH-166"
    const libelle_fost = "Pompe à chaleur collective de type air/eau ou eau/eau"
    const denomination_fost = "Mise en place d’une ou plusieurs pompes à chaleur (PAC) de type air/eau ou eau/eau de puissance thermique nominale inférieure ou égale à 400 kW pour un système de chauffage collectif"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-166%20v%20A38-1%20%C3%A0%20compter%20du%2031-07-2021.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Nb Appartements */}
            <NbAppartementsREF131
                name={[field.name, 'REF-131']}
                label="Nombre d'appartements :"
            />

            {/* Usage */}
            <UsageREF15A
                name={[field.name, 'REF-15-A']}
                label="Usage de la pompe à chaleur :"
            />

            {/* Puissance PAC */}
            <Form.Item
                name={[field.name, "Pinstallee"]}
                label="Puissance de la PAC installée (kW) : "
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <InputNumber
                    addonAfter="kW"
                    min='0'
                    controls={false}
                    style={{ width: '150px' }}
                    onChange={() => {
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                    }}
                />
            </Form.Item>

            {/* Alerte si Puissance PAC supérieure à 400 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pinstallee"]) > 400 ? (
                        <Alert
                            message="L'opération est éligible pour une puissance nominale inférieure ou égale à 400kW."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Temperature et Etas si Puissance PAC <= 400 kW */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pinstallee"]) <= 400 ? (
                        <>
                            {/*Temperature*/}
                            <Form.Item
                                name={[field.name, "TemperaturePAC"]}
                                label="Quel est le type de la PAC installée ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                                    }}>
                                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {/* ETAS variable continue */}
                                    <Form.Item
                                        label="Etas (%) :"
                                        name={[field.name, 'Etas']}
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                        tooltip={{
                                            title: "L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                    >
                                        <InputNumber
                                            min={0}
                                            addonAfter="%"
                                            controls={false}
                                            style={{ width: '100px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Etas"]) >= 111 && getFieldValue(['items', field.name, "Etas"]) < 120) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "Etas"]) >= 120) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "REF-140-E"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>

                                    {/* ETAS */}
                                    <EfficaciteEnergetique140E
                                        label=""
                                        name={[field.name, 'REF-140-E']}
                                        disabled={true}
                                        hidden
                                    />

                            {/* Alerte si basse température et ETAS inférieur à 126 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                                        <Alert
                                            message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Alerte si moyenne/haute température et ETAS inférieur à 111 */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                                        <Alert
                                            message="Pour une PAC moyenne/haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>




            {/* Puissance totale chaufferie */}
            <Form.Item style={{ margin: "0px" }}>
                <Space align='center'>
                    <Form.Item
                        name={[field.name, "Pchaufferie"]}
                        label="Puissance totale de la nouvelle chaufferie : "
                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                        tooltip={{
                            title: <>Puissance de la chaufferie après travaux. La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                            color: tooltip_color,
                        }}
                        //extra="Puissance de la chaufferie après travaux"
                        style={{ margin: "0px" }}
                    >
                        <InputNumber
                            addonAfter="kW"
                            style={{ width: '150px' }}
                            controls={false}
                            min='0'
                            onChange={() => {
                                if (form.getFieldValue(['items', field.name, "Pinstallee"]) >= (0.4 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                                    && form.getFieldValue(['items', field.name, "Pchaufferie"]) >= form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                                else if (form.getFieldValue(['items', field.name, "Pinstallee"]) < (0.4 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                                    && form.getFieldValue(['items', field.name, "Pchaufferie"]) > form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (form.getFieldValue(['items', field.name, "Pinstallee"]) / form.getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                                else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                            }}
                        />
                    </Form.Item>

                    {/* R */}
                    <RREF142
                        label="Facteur R :"
                        name={[field.name, 'REF-142']}
                        disabled={true}
                        hidden
                    />
                </Space>
            </Form.Item>
            {/* Alerte si Pchaufferie < Pinstallée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                        <Alert
                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
        </>
    );
};
