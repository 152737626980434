import { FormListFieldData, Typography, Divider, Button, Flex } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import TypeInstallationREF81H from '../refs/TypeInstallationREF81H';
import SecteurREF22AG from '../refs/SecteurREF22AG';
import SurfaceREF10 from '../refs/SurfaceREF10';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Batth1262S = ({ field }: { field: FormListFieldData }) => {

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'


    /* Infos FOST */
    const code_fost = "BAT-TH-126"
    const libelle_fost = "Ventilation mécanique double flux avec échangeur à débit d’air constant ou modulé"
    const denomination_fost = "Mise en place d’une ventilation mécanique double flux, avec échangeur, à débit d’air constant ou modulée"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-126%20v%20A32-2%20%C3%A0%20compter%20du%2001-04-2020.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            <TypeInstallationREF81H
                name={[field.name, "REF-81-H"]}
                label="Type de ventilation :"
            />

            <SecteurREF22AG
                name={[field.name, "REF-22-AG"]}
                label="Secteur d'application :"
            />

            <SurfaceREF10
                name={[field.name, "REF-10"]}
                label="Surface ventilée :"
                tooltip="On entend par surface ventilée, la surface totale du bâtiment couverte par le 
                         système de ventilation mécanique double flux avec échangeur, à débit d’air constant ou modulée."
            />

        </>
    );
};
