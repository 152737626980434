import React from 'react';

import { PatternFormat } from 'react-number-format';

import './styles.scss';

const PHONE_NUMBER_FORMAT = '## ###';

const ZipCodeFormat = ({
    value,
    classes,
    onChange,
    onBlur,
    autoFocus,
    ...props
}: {
    value?: string;
    classes?: string;
    onChange?: (value: string) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        onChange && onChange(inputValue);
    };
    return (
        <PatternFormat
            {...props}
            format={PHONE_NUMBER_FORMAT}
            value={value || ''}
            mask=" "
            className={`${classes || ''} antd-copy-default-input`}
            onChange={handleChange}
            onBlur={onBlur}
            autoFocus={autoFocus}

        />
    );
};

export default React.memo(ZipCodeFormat);
