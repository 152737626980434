import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
function TypeInstallationREF81T({ label, name, tooltip }: { label: string, name: NamePath, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner le type d'installation"
      },]}
    tooltip={{
      title: tooltip,
      icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
      color: '#0086CA',
    }}
  >
    <Select
      placeholder="Sélectionnez le type d'installation"
      style={{ width: '320px' }}
    >
      <Option value="0">Simple flux modulée proportionnelle</Option>
      <Option value="1">Simple flux modulée à détection de présence</Option>
      <Option value="2">Simple flux à débit d’air constant</Option>
    </Select>
  </Form.Item >
}
export default TypeInstallationREF81T;