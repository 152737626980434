import { Form, Alert, FormListFieldData, Radio, Typography, Divider, Collapse, Button, Card, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import ModeFonctionnementREF36A from '@/features/new-project/operations/forms/components/common/ModeFonctionnementREF36A';

const { Paragraph } = Typography

const { Title } = Typography

export const Indut1041 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Lien vers le PDF de la FOST */
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-104.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Paragraph>L'opération est réalisée sur une chaudière :
        <Typography style={{ paddingLeft: '16px' }}>- de production de<Typography.Text strong style={{ color: primary_color }}> vapeur</Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}>- de puissance thermique nominale<Typography.Text strong style={{ color: primary_color }}> inférieure à 20 MW</Typography.Text></Typography>
            <Typography style={{  paddingLeft: '16px' }}>- alimentée<Typography.Text strong style={{ color: primary_color }}> au gaz naturel ou au GPL</Typography.Text></Typography>
        </Paragraph>
        <Typography>La mise en place d’un économiseur sur une chaudière de secours n’est pas éligible à l’opération.</Typography>

    </>
    const facture = <>
        <Paragraph>La preuve de la réalisation de l'opération mentionne la<Typography.Text strong style={{ color: primary_color }}> mise en place d'un économiseur de chaleur.</Typography.Text></Paragraph>
        <Typography>À défaut, la preuve de réalisation de l’opération mentionne la mise en place d'un équipement avec ses marque et
                référence et elle est complétée par un document issu du fabricant indiquant que l’équipement de marque et référence
                installé est un économiseur de chaleur.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>IND-UT-104 : Économiseur sur les effluents gazeux d’une chaudière de production de vapeur</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’un économiseur sur les effluents gazeux
                    d’une chaudière de production de vapeur de puissance thermique nominale inférieure à 20 MW et alimentée au gaz naturel ou au GPL.
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Batiment industriel  */}
            <Form.Item
                name={[field.name, "BatimentIndustrie"]}
                label="L'opération est-elle réalisée dans le secteur de l'industrie ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                        form.setFieldValue(['items', field.name, "ChaudiereProductionVapeur"], undefined)
                        form.setFieldValue(['items', field.name, "ChaudiereGazNatOuGpl"], undefined)
                        form.setFieldValue(['items', field.name, "EffluentsGazeux"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Bat industrie non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentIndustrie"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée dans le secteur de l'industrie."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Industrie OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentIndustrie"]) === "Oui" ? (
                        <>
                            {/* Chaudière de production de vapeur */}
                            <Form.Item
                                name={[field.name, "ChaudiereProductionVapeur"]}
                                label="L'opération est-elle réalisée sur une chaudière de production de vapeur ?"
                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                        form.setFieldValue(['items', field.name, "ChaudiereGazNatOuGpl"], undefined)
                                        form.setFieldValue(['items', field.name, "EffluentsGazeux"], undefined)
                                    }
                                }>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si Chaudière Vapeur non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "ChaudiereProductionVapeur"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si elle est réalisée sur une chaudière de production de vapeur."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Suite formulaire si Chaudiere Vapeur OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "ChaudiereProductionVapeur"]) === "Oui" ? (
                                        <>
                                            {/* Chaudière alimentée au gaz naturel ou GPL */}
                                            <Form.Item
                                                name={[field.name, "ChaudiereGazNatOuGpl"]}
                                                label="L'opération est-elle réalisée sur une chaudière alimentée au gaz naturel ou au GPL ?"
                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {
                                                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                                        form.setFieldValue(['items', field.name, "EffluentsGazeux"], undefined)
                                                    }
                                                }>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte si Chaudière GazNat ou GPL non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "ChaudiereGazNatOuGpl"]) === "Non" ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si elle est réalisée sur une chaudière alimentée au gaz naturel ou au GPL."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* Suite formulaire si Chaudiere GazNat ou GPL OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "ChaudiereGazNatOuGpl"]) === "Oui" ? (
                                                        <>
                                                            {/* Effluents Gazeux */}
                                                            <Form.Item
                                                                name={[field.name, "EffluentsGazeux"]}
                                                                label="L'économiseur de chaleur est-il installé sur les effluents gazeux de la chaudière ?"
                                                                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                                            >
                                                                <Radio.Group buttonStyle='solid' onChange={
                                                                    () => {
                                                                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                                                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                                                                    }
                                                                }>
                                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {/* Alerte si Effluents Gazeux non */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "EffluentsGazeux"]) === "Non" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si l'économiseur de chaleur est installé sur les effluents gazeux de la chaudière."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Suite formulaire si Effluents Gazeux OK */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "EffluentsGazeux"]) === "Oui" ? (
                                                                        <>
                                                                            {/* Puissance chaudière */}
                                                                            <PuissanceREF26
                                                                                name={[field.name, "REF-26"]}
                                                                                label="Puissance utile nominale de la chaudière (kW) :"
                                                                                unite="kW"
                                                                                tooltip="La puissance utile nominale à retenir est celle figurant sur la plaque signalétique de la chaudière ou à défaut celle
                                                                                    indiquée sur un document issu du fabricant de la chaudière. Elle est définie par l’article R.224-20 du code de
                                                                                    l’environnement comme « la puissance thermique maximale fixée et garantie par le constructeur comme pouvant
                                                                                    être délivrée au fluide caloporteur en marche continue »."
                                                                            />
                                                                            {/* Mode de fonctionnement */}
                                                                            <ModeFonctionnementREF36A
                                                                                name={[field.name, "REF-36-A"]}
                                                                                label="Mode de fonctionnement du site industriel :"
                                                                            />
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item >

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
