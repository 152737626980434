import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import SecteurREF22AA from '@/features/new-project/operations/forms/components/common/SecteurREF22AA';
import PuissanceChaudiereREF24E from '@/features/new-project/operations/forms/components/common/PuissanceChaudiereREF24E';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

const { Title } = Typography

export const Batth1582 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-TH-158"
    const libelle_fost = "Pompe à chaleur réversible de type air/air"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) réversible de type air/air de puissances calorifique et frigorifique nominales inférieures ou égales à 1 MW"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-158%20vA42-2%20%C3%A0%20compter%20du%2031-01-2022.pdf"

    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Pour les PAC de type air/air de puissance calorifique nominale
            <Typography.Text strong style={{ color: primary_color }}> inférieure ou égale à 12 kW, </Typography.Text>
            les coefficients de performance selon le règlement (UE) 206/2012 de la Commission du 6 mars 2012 portant
            application de la directive 2009/125/CE du Parlement européen et du Conseil en ce qui concerne les exigences
            d'écoconception applicables aux climatiseurs et aux ventilateurs de confort sont supérieurs ou égaux à :
            <Typography style={{ paddingLeft: '16px' }}>- <Typography.Text strong style={{ color: primary_color }}>4,2 </Typography.Text>pour le
                <Typography.Text strong style={{ color: primary_color }}> coefficient de performance saisonnier (SCOP) </Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}>- <Typography.Text strong style={{ color: primary_color }}>6 </Typography.Text>pour
                <Typography.Text strong style={{ color: primary_color }}> l’efficacité énergétique saisonnière (SEER)</Typography.Text></Typography>
        </Paragraph>
        <Paragraph>Pour les PAC de type air/air de puissance calorifique nominale
            <Typography.Text strong style={{ color: primary_color }}> supérieure à 12 kW, </Typography.Text>
            les efficacités énergétiques saisonnières (Etas) selon le règlement (UE) 2016/2281 de la Commission du 30 novembre 2016 mettant
            en oeuvre la directive 2009/125/CE du Parlement européen et du Conseil établissant un cadre pour la fixation d'exigences en
            matière d'écoconception applicables aux produits liés à l'énergie, en ce qui concerne les exigences d'écoconception applicables
            aux appareils de chauffage à air, aux appareils de refroidissement, aux refroidisseurs industriels haute température et aux
            ventilo-convecteurs sont supérieures ou égales à :
            <Typography style={{ paddingLeft: '16px' }}>- Pour une PAC (hors PAC en toiture) :
                <Typography style={{ paddingLeft: '32px' }}>- <Typography.Text strong style={{ color: primary_color }}>145 % </Typography.Text>pour le
                    <Typography.Text strong style={{ color: primary_color }}> chauffage des locaux </Typography.Text></Typography>
                <Typography style={{ paddingLeft: '32px' }}>- <Typography.Text strong style={{ color: primary_color }}>250% </Typography.Text>pour le
                    <   Typography.Text strong style={{ color: primary_color }}> refroidissement des locaux</Typography.Text></Typography>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- Pour une PAC en toiture (rooftop) intégrant le chauffage, le refroidissement, la ventilation,
                le rafraîchissement par surventilation nocturne et la filtration :
                <Typography style={{ paddingLeft: '32px' }}>- <Typography.Text strong style={{ color: primary_color }}>130 % </Typography.Text>pour le
                    <Typography.Text strong style={{ color: primary_color }}> chauffage des locaux </Typography.Text></Typography>
                <Typography style={{ paddingLeft: '32px' }}>- <Typography.Text strong style={{ color: primary_color }}>150% </Typography.Text>pour le
                    <Typography.Text strong style={{ color: primary_color }}> refroidissement des locaux</Typography.Text></Typography>
            </Typography>
        </Paragraph>
    </>
    const facture = <>
        <Paragraph>La facture mentionne :
            <Typography>- la<Typography.Text strong style={{ color: primary_color }}> mise en place d’une pompe à chaleur réversible de type air/air</Typography.Text></Typography>
            <Typography>- les <Typography.Text strong style={{ color: primary_color }}>puissances frigorifique et calorifique nominales </Typography.Text>de la pompe à chaleur</Typography>
            <Typography>- pour une PAC de type air/air de puissance calorifique nominale inférieure ou égale à 12 kW, les
                <Typography.Text strong style={{ color: primary_color }}> coefficients de performance SCOP et SEER </Typography.Text>de l’équipement</Typography>
            <Typography>- pour une PAC de type air/air de puissance calorifique nominale supérieure à 12 kW, le<Typography.Text strong style={{ color: primary_color }}> type de PAC
                (PAC en toiture, ou « rooftop » ; autre PAC), les efficacités énergétiques saisonnières (Etas) de l’équipement
                pour le chauffage et le refroidissement des locaux</Typography.Text></Typography>
            <Typography></Typography>
            <Typography></Typography>
        </Paragraph>
        <Paragraph>
            A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence
            et elle est complétée par un document issu du fabricant ou d’un organisme établi dans l'Espace économique européen et accrédité
            selon la norme NF EN 45011 par le Comité français d'accréditation (COFRAC) ou tout autre organisme d'accréditation signataire
            de l'accord européen multilatéral pertinent pris dans le cadre de European co-operation for Accreditation (EA), coordination
            européenne des organismes d'accréditation.
        </Paragraph>
        <Typography>
            Ce document indique que l’équipement de marque et référence mis en place est une PAC réversible de type air/air. Il précise les
            puissances calorifique et frigorifique nominales de la PAC ainsi que les performances énergétiques de l’équipement installé :
            SCOP et SEER pour une PAC de puissance calorifique nominale inférieure ou égale à 12 kW ; Etas pour le chauffage des locaux et Etas
            pour le refroidissement des locaux, pour une PAC de puissance calorifique nominale supérieure à 12 kW. Dans ce dernier cas, il indique
            également le type de PAC (PAC en toiture, ou « rooftop » ; autre PAC).
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>

            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>{denomination_fost}</Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire plus de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                        form.setFieldValue(['items', field.name, "REF-22-AA"], undefined)
                        form.setFieldValue(['items', field.name, "REF-24-E"], undefined)
                        form.setFieldValue(['items', field.name, "PACTypeAirAir"], undefined)
                        form.setFieldValue(['items', field.name, "SCOP"], undefined)
                        form.setFieldValue(['items', field.name, "SEER"], undefined)
                        form.setFieldValue(['items', field.name, "ETASChaud"], undefined)
                        form.setFieldValue(['items', field.name, "ETASFroid"], undefined)
                        form.setFieldValue(['items', field.name, "PuissanceCaloInstallee"], undefined)
                        form.setFieldValue(['items', field.name, "PuissanceFrigoInstallee"], undefined)

                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            {/* PAC réversible Air/Air */}
                            <Form.Item shouldUpdate
                                name={[field.name, "PACTypeAirAir"]}
                                label="La PAC installée est-elle réversible de type air/air ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-22-AA"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-24-E"], undefined)
                                        form.setFieldValue(['items', field.name, "SCOP"], undefined)
                                        form.setFieldValue(['items', field.name, "SEER"], undefined)
                                        form.setFieldValue(['items', field.name, "ETASChaud"], undefined)
                                        form.setFieldValue(['items', field.name, "ETASFroid"], undefined)
                                        form.setFieldValue(['items', field.name, "PuissanceCaloInstallee"], undefined)
                                        form.setFieldValue(['items', field.name, "PuissanceFrigoInstallee"], undefined)
                                    }
                                }>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si PACTypeAirAir non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "PACTypeAirAir"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si la PAC installée est réversible de type air/air."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                            {/* Suite formulaire si PACTyeAirAir OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "PACTypeAirAir"]) === "Oui" ? (
                                        <>
                                            {/*Secteur*/}
                                            <SecteurREF22AA
                                                label="Secteur :"
                                                name={[field.name, 'REF-22-AA']}
                                            />

                                            {/* Puissance Chaudière */}
                                            <PuissanceChaudiereREF24E
                                                label="Puissance de la PAC :"
                                                name={[field.name, 'REF-24-E']}
                                                onChange={
                                                    () => {
                                                        form.setFieldValue(['items', field.name, "SCOP"], undefined)
                                                        form.setFieldValue(['items', field.name, "SEER"], undefined)
                                                        form.setFieldValue(['items', field.name, "ETASChaud"], undefined)
                                                        form.setFieldValue(['items', field.name, "ETASFroid"], undefined)
                                                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                                    }
                                                }
                                            />
                                            {/* SCOP et SEER si PAC <= 12 kW */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "REF-24-E"]) === "0" ? (
                                                        <>
                                                            <Form.Item
                                                                label="SCOP :"
                                                                name={[field.name, 'SCOP']}
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                                tooltip={{
                                                                    title: "Le coefficient de performance saisonnier est mesuré selon le règlement (EU) 206/2012 de la commission du 6 mars 2012.",
                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                    color: tooltip_color,
                                                                }}
                                                            >
                                                                <InputNumber min={0} decimalSeparator=',' />
                                                            </Form.Item>

                                                            {/* Alerte si SCOP  < 4,2  */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "SCOP"]) < 4.2 ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si le SCOP est supérieur ou égal à 4,2."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            <Form.Item
                                                                label="SEER :"
                                                                name={[field.name, 'SEER']}
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                            >
                                                                <InputNumber min={0} decimalSeparator=',' />
                                                            </Form.Item>

                                                            {/* Alerte si SEER  < 6  */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "SEER"]) < 6 ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si le SEER est supérieur ou égal à 6."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* ETAS chaud et froid si PAC > 12 kW */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "REF-24-E"]) === "1" || getFieldValue(['items', field.name, "REF-24-E"]) === "2" ? (
                                                        <>
                                                            <Form.Item
                                                                label="Efficacité énergétique saisonnière (ETAS) en chauffage :"
                                                                name={[field.name, 'ETASChaud']}
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                                tooltip={{
                                                                    title: <>L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (UE) 2016/2281 de la Commission du 30
                                                                        novembre 2016 mettant en oeuvre la directive 2009/125/CE du Parlement européen et du Conseil établissant un cadre pour la
                                                                        fixation d'exigences en matière d'écoconception applicables aux produits liés à l'énergie, en ce qui concerne les exigences
                                                                        d'écoconception applicables aux appareils de chauffage à air, aux appareils de refroidissement, aux refroidisseurs industriels
                                                                        haute température et aux ventilo-convecteurs</>,
                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                    color: tooltip_color,
                                                                }}
                                                            >
                                                                <InputNumber addonAfter="%" min={0} decimalSeparator=',' />
                                                            </Form.Item>
                                                            {/* Alerte si ETAS chaud < 138% et PAC > 12 kW */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "ETASChaud"]) < 138 && getFieldValue(['items', field.name, "REF-24-E"]) === "1" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si l'ETAS en chauffage est supérieur ou égal à 138%."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Alerte si ETAS chaud < 126% et PAC en toiture */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "ETASChaud"]) < 126 && getFieldValue(['items', field.name, "REF-24-E"]) === "2" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si l'ETAS en chauffage est supérieur ou égal à 126%."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Efficacité énergétique saisonnière (ETAS) en refroidissement :"
                                                                name={[field.name, 'ETASFroid']}
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                                tooltip={{
                                                                    title: <>L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (UE) 2016/2281 de la Commission du 30
                                                                        novembre 2016 mettant en oeuvre la directive 2009/125/CE du Parlement européen et du Conseil établissant un cadre pour la
                                                                        fixation d'exigences en matière d'écoconception applicables aux produits liés à l'énergie, en ce qui concerne les exigences
                                                                        d'écoconception applicables aux appareils de chauffage à air, aux appareils de refroidissement, aux refroidisseurs industriels
                                                                        haute température et aux ventilo-convecteurs</>,
                                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                                    color: tooltip_color,
                                                                }}
                                                            >
                                                                <InputNumber addonAfter="%" min={0} decimalSeparator=',' />
                                                            </Form.Item>
                                                            {/* Alerte si ETAS froid < 198% et PAC > 12 kW */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "ETASFroid"]) < 198 && getFieldValue(['items', field.name, "REF-24-E"]) === "1" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si l'ETAS en refroidissement est supérieur ou égal à 198%."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                            {/* Alerte si ETAS froid < 144% et PAC en toiture */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "ETASFroid"]) < 144 && getFieldValue(['items', field.name, "REF-24-E"]) === "2" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si l'ETAS en refroidissement est supérieur ou égal à 144%."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Surface */}
                                            <SurfaceREF10
                                                label="Surface totale chauffée par la PAC installée (m²) :"
                                                name={[field.name, 'REF-10']}
                                                tooltip='Surface totale chauffée par la PAC installée (m²)'
                                            />
                                            {/* Puissance calo PAC */}
                                            <Form.Item
                                                label="Puissance calorifique nominale installée (kW) :"
                                                name={[field.name, 'PuissanceCaloInstallee']}
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <InputNumber addonAfter="kW" min={0} />
                                            </Form.Item>
                                            {/* Alerte Puissance calo > 1MW */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "PuissanceCaloInstallee"]) > "1000" ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si la PAC installée est de puissance nominale calorifique inférieure ou égale à 1 MW."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                            {/* Puissance frigo PAC */}
                                            <Form.Item
                                                label="Puissance frigorifique nominale installée (kW) :"
                                                name={[field.name, 'PuissanceFrigoInstallee']}
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <InputNumber addonAfter="kW" min={0} />
                                            </Form.Item>
                                            {/* Alerte Puissance frigo > 1MW */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "PuissanceFrigoInstallee"]) > 1000 ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si la PAC installée est de puissance nominale frigorifique inférieure ou égale à 1 MW."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>

    );
};
