import BaseAPI from '@/apis/base';

class SimulatorService {
    public axios;
    constructor() {
        this.axios = BaseAPI;
    }

    async getAffiliation(tag: string) {
        return await this.axios
            .get(`/affiliations/retrieve?tag=${encodeURIComponent(tag)}`)
            .then((response) => response.data);
    }
}

const simulatorService = new SimulatorService();

export default simulatorService;
