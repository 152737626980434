import { Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function TypeCondenseurREF60C({label, name, tooltip}: {label: string, name : NamePath, tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[]}) {
  return <Form.Item
            name={name}
            label={label}
            rules={[
                {
                required: true,
                message: "Veuillez renseigner le type de condenseur"
                },
            ]}
            tooltip={{
                title:tooltip,
                icon: <InfoCircleOutlined style={{ color:'#002766' }}/>,
                color: '#0086CA',
                }}
            >
            <Select
                placeholder="Sélectionnez le type de condenseur"
                allowClear
                style={{ width: '300px' }}
            >
                <Option value="0">Condensation par rapport à l’atmosphère</Option>
                <Option value="1">Condensation à eau seul</Option>
            </Select>
        </Form.Item>
}