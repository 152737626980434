import { useEffect } from 'react';

import { Spinner } from '@/components';
import { useGuardRoute } from '@/hooks/auth/useGuardRoute';
import { BaseLayout } from '@layouts/index';
import { Outlet } from 'react-router-dom';

export const PublicOutlet = () => {
    const { isLoading, guardRoute } = useGuardRoute();

    useEffect(() => {
        guardRoute();
    }, [isLoading, guardRoute]);

    if (isLoading) return <Spinner />;

    return (
        <BaseLayout>
            <Outlet />
        </BaseLayout>
    );
};
