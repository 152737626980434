import React from 'react';

import { NumericInput } from '@/components';
import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Checkbox, Form, Input, Row, Space } from 'antd';

export const ProcessUtilities = () => {
    return (
        <Form.Item
            className="new-project__under-form-item"
            name={constant.PROCESS_UTILITIES_AND_COLD_TYPES}
        >
            <Checkbox.Group>
                <Space direction="vertical">
                    <Row align="bottom">
                        <Checkbox value={constant.PROCESS_UTILITIES_COLD}>
                            <Text>
                                Froid : Puissance des compresseurs (en cumulé) :
                            </Text>
                        </Checkbox>
                        <Form.Item
                            className="form-item--same-line"
                            name={
                                constant.PROCESS_UTILITIES_COLD_COMPRESSOR_POWER
                            }
                        >
                            <NumericInput
                                maxLength={7}
                                placeholder="0"
                                classes="input-sameline-answer"
                            />
                        </Form.Item>
                        <Text>kW</Text>
                    </Row>
                    <Row align="bottom">
                        <Checkbox
                            value={constant.PROCESS_UTILITIES_PRESSURIZED_AIR}
                        >
                            <Text>
                                Air comprimé : Puissance des compresseurs (en
                                cumulé) :
                            </Text>
                        </Checkbox>
                        <Form.Item
                            className="form-item--same-line"
                            name={
                                constant.PROCESS_UTILITIES_PRESSURIZED_AIR_COMPRESSOR_POWER
                            }
                        >
                            <NumericInput
                                maxLength={7}
                                placeholder="0"
                                classes="input-sameline-answer"
                            />
                        </Form.Item>
                        <Text>kW</Text>
                    </Row>
                    <Row align="bottom">
                        <Checkbox value={constant.PROCESS_UTILITIES_ENGINE}>
                            <Text>Moteur : Puissance :</Text>
                        </Checkbox>
                        <Form.Item
                            className="form-item--same-line"
                            name={constant.PROCESS_UTILITIES_ENGINE_POWER}
                        >
                            <NumericInput
                                maxLength={7}
                                placeholder="0"
                                classes="input-sameline-answer"
                            />
                        </Form.Item>
                        <Text>kW</Text>
                    </Row>
                    <Row align="bottom">
                        <Checkbox value={constant.PROCESS_UTILITIES_OTHERS}>
                            <Text>Autre</Text>
                        </Checkbox>
                        <Form.Item
                            className="form-item--same-line"
                            name={constant.PROCESS_UTILITIES_OTHERS_NAMES}
                        >
                            <Input className="input-text-sameline" />
                        </Form.Item>
                    </Row>
                </Space>
            </Checkbox.Group>
        </Form.Item>
    );
};
