import { useState } from 'react';

import * as constant from '@/features/new-project/constants';
import { WorkDetailsDescription } from '@/features/new-project/operations/WorkDetailsDescription';
import { Text, Title } from '@/utils';
import { Checkbox, Form, Space } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import {
    Calorifugeage,
    Heating,
    HeatingRecovery,
    Isolation,
    OthersWorks,
    ProcessUtilities,
    Regulation,
} from './operations-types';

export const WorkDetails = () => {
    const [worksType, setWorksType] = useState<CheckboxValueType[]>();

    const onChangeWorkType = (checkedValues: CheckboxValueType[]) => {
        setWorksType(checkedValues);
    };

    return (
        <>
            <Title level={4}>3. Détails des travaux</Title>
            <Form.Item name={constant.WROKS_TYPE}>
                <Checkbox.Group onChange={onChangeWorkType}>
                    <Space direction="vertical" size="small">
                        <>
                            <Checkbox value="heating">
                                <Text strong>Chauffage</Text>
                            </Checkbox>
                            {worksType?.includes(constant.HEATING) && (
                                <Heating />
                            )}
                        </>

                        <>
                            <Checkbox value="isolation">
                                <Text strong>Isolation</Text>
                            </Checkbox>
                            {worksType?.includes(constant.ISOLATION) && (
                                <Isolation />
                            )}
                        </>
                        <>
                            <Checkbox value="regulation">
                                <Text strong>Régulation</Text>
                            </Checkbox>
                            {worksType?.includes(constant.REGULATION) && (
                                <Regulation />
                            )}
                        </>
                        <>
                            <Checkbox value="calorifugeage">
                                <Text strong>Calorifugeage</Text>
                            </Checkbox>
                            {worksType?.includes(constant.CALORIFUGEAGE) && (
                                <Calorifugeage />
                            )}
                        </>
                        <>
                            <Checkbox value="processUtilitiesAndCold">
                                <Text strong>Process, utilités et froid</Text>
                            </Checkbox>
                            {worksType?.includes(
                                constant.PROCESS_UTILITIES_AND_COLD
                            ) && <ProcessUtilities />}
                        </>
                        <>
                            <Checkbox value="heatingRecovery">
                                <Text strong>Récupération de chaleur</Text>
                            </Checkbox>
                            {worksType?.includes(constant.HEATING_RECOVERY) && (
                                <HeatingRecovery />
                            )}
                        </>
                        <>
                            <Checkbox value="othersWorkTypes">
                                <Text strong>Autre</Text>
                            </Checkbox>
                            {worksType?.includes(
                                constant.OTHERS_WORK_TYPES
                            ) && <OthersWorks />}
                        </>
                    </Space>
                </Checkbox.Group>
            </Form.Item>

            <WorkDetailsDescription />
        </>
    );
};
