import { useState } from 'react';

import { Title } from '@/utils';
import { InboxOutlined } from '@ant-design/icons';
import { Form, Upload, message } from 'antd';

import type { RcFile } from 'antd/es/upload/interface';

import { checkFileSize, checkTotalFilesSize } from '../utils';

const UPLOAD_FILES = 'uploadFiles';

export const UploadFiles = () => {
    const [fileUpload, setFileList] = useState<RcFile[]>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }

        const filteredFileList = e?.fileList.filter(
            (file: RcFile) => file.size <= 3 * 1024 * 1024
        );

        return filteredFileList;
    };
    const getFileSizes = () => {
        return fileUpload.map((file) => file.size);
    };
    const isFilesSizeExceeded = (fileSize: number) => {
        const fileSizes = getFileSizes();
        fileSizes.push(fileSize);
        return checkTotalFilesSize(fileSizes);
    };
    const beforeFileUpload = (file: RcFile) => {
        const isAccepted = checkFileSize(file.size);
        if (isAccepted) {
            messageApi.error(
                `${file.name} dépasse la taille maximale de 3 Mo.`
            );
            return false;
        }
        if(isFilesSizeExceeded(file.size)) {
          messageApi.error(
            `le totale des fichirs dépasse la taille maximale de 10 Mo.`
        );
        return false;
        }
        setFileList((prevFileList) => [...prevFileList, file]);
        return false;
    };

    const handleRemove = (file: RcFile) => {
        setFileList((prevFileList) =>
            prevFileList.filter((item) => item.uid !== file.uid)
        );
    };
    return (
        <>
            <Title level={4}>
                Chargement du devis ou tout autre pièce utile au chiffrage
            </Title>
            <Form.Item
                className="new-project__form-item"
                name={UPLOAD_FILES}
                getValueFromEvent={normFile}
                valuePropName="fileList"
            >
                <Upload.Dragger
                    beforeUpload={beforeFileUpload}
                    name="bannerUpload"
                    showUploadList={{
                        showRemoveIcon: true,
                        showPreviewIcon: true,
                    }}
                    multiple={true}
                    accept=".pdf,.xlsx"
                    className="new-project-upload"
                    fileList={fileUpload}
                    onRemove={(file) => handleRemove(file as RcFile)}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Cliquez ou faites glisser le fichier vers cette zone
                        pour le télécharger
                    </p>
                    <p className="ant-upload-hint">{`PDF < 3Mo`}</p>
                </Upload.Dragger>
            </Form.Item>
            {contextHolder}
        </>
    );
};
