import React from 'react';

// import { ZipCodeFormat } from '@/components';
import { Text, Title } from '@/utils';
import { dateFormat } from '@/utils/constants';
import {
    Col,
    DatePicker,
    Divider,
    Flex,
    Form,
    Input,
    Radio,
    Row,
    Space,
} from 'antd';

import { WorkAdress } from '../commun/WorkAdress';
import * as constant from '../constants';
import { IsSimulatorProps } from '../types';
// import { getClimaticZoneByZipCode } from '../utils';
import { ApplicantProfile } from './work-descriptions/ApplicantProfile';
import { PrincipalUseOfBuilding } from './work-descriptions/PrincipalUseOfBuilding';
import { SecondUseOfBuilding } from './work-descriptions/SecondUseOfBuilding';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const GeneralDescription: React.FC<IsSimulatorProps> = ({
    isSimulator,
}) => {
    // const form = Form.useFormInstance();
    return (
        <>
            <Title level={4}>1. Description générale du projet</Title>
            <Row>
                <Form.Item
                    name={constant.PROJECT_NAME}
                    label={
                        <Text className="field-description" strong>
                            Quel nom souhaitez-vous donner à votre projet
                        </Text>
                    }
                    rules={constant.projectNameValidation}
                >
                    <Input />
                </Form.Item>
            </Row>
            <Row>
                <Space direction="vertical" size="small">
                    <ApplicantProfile />
                </Space>
            </Row>
            {isSimulator ? null : (
                <>
                    <Row>
                        <Space direction="vertical" size="small">
                            <PrincipalUseOfBuilding />
                        </Space>
                    </Row>
                    <Row>
                        <Space direction="vertical" size="small">
                            <SecondUseOfBuilding />
                        </Space>
                    </Row>
                </>
            )}
            <Row>
                <Col>
                    <Form.Item
                        label={
                            <Text className="field-description" strong>
                                Estimation date début travaux / Estimation date
                                fin travaux
                            </Text>
                        }
                        rules={[{ required: !isSimulator }]}
                        name={constant.ESTIMATE_WORK_DATE}
                    >
                        <RangePicker
                            format={dateFormat}
                            className="middle-input-width"
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Text className="field-description" strong>
                                Estimation date signature devis
                            </Text>
                        }
                        rules={[{ required: true }]}
                        name={constant.ESTIMATE_SIGNATURE_DATE}
                        className="middle-input-width"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={dateFormat}
                            placeholder="sélectionner une date"
                            className="middle-input-width"
                        />
                    </Form.Item>
                    {/* <Form.Item
                        label={
                            <Text className="field-description" strong>
                                Code postal site concerné par le projet
                            </Text>
                        }
                        rules={[{ required: true }]}
                        name={constant.WORK_ZIPCODE}
                        className="middle-input-width"
                    >
                        <ZipCodeFormat
                            classes="ant-input antd-copy-input"
                            onBlur={(event) => {
                                const { value } = event.target;
                                form.setFieldsValue({
                                    climatic_zone:
                                        getClimaticZoneByZipCode(value),
                                });
                                form.setFieldsValue({ workZipCode: value });
                            }}
                            onChange={(value) => {
                                form.setFieldsValue({
                                    climatic_zone:
                                        getClimaticZoneByZipCode(value),
                                });
                                form.setFieldsValue({ workZipCode: value });
                            }}
                        />
                    </Form.Item> */}
                    <Divider />
                    <Title level={4}>2. Adresse des travaux</Title>
                    <WorkAdress />

                    {isSimulator ? null : (
                        <Form.Item
                            label={
                                <Text className="field-description" strong>
                                    Les bâtiments concernés ont-ils tous plus de
                                    2 ans ? Pour être éligible aux CEE, les
                                    bâtiments résidentiels et tertiaires doivent
                                    avoir plus de 2 ans.
                                </Text>
                            }
                            name={constant.BULDING_EXISTING}
                            rules={[{ required: true }]}
                        >
                            <Radio.Group>
                                <Space direction="vertical" size="small">
                                    <Radio
                                        id={constant.YES}
                                        value={constant.YES}
                                    >
                                        Oui
                                    </Radio>
                                    <Radio id={constant.NO} value={constant.NO}>
                                        Non
                                    </Radio>
                                    <Radio
                                        id={constant.I_DONT_KNOW}
                                        value={constant.I_DONT_KNOW}
                                    >
                                        Je ne sais pas
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    )}
                </Col>
                <Col></Col>
            </Row>
            <Form.Item
                style={{ width: '100%' }}
                label={
                    <Flex dir="column">
                        <Text className="field-description" strong>
                            Décrivez-nous votre projet en quelques lignes
                        </Text>
                    </Flex>
                }
                name={constant.PROJECT_DESCRIPTION}
                rules={[{ required: false }]}
            >
                <>
                    <Text>
                        Cette section vous permet de nous fournir l’ensemble des
                        éléments qui vous semblent pertinents concernant votre
                        projet. Si une ou plusieurs des opérations nécessaires à
                        votre projet sont manquantes dans le simulateur,
                        ci-dessous, vous pouvez nous en fournir le détail ici.
                    </Text>
                    <TextArea
                        style={{
                            height: 150,
                            resize: 'none',
                        }}
                        placeholder="Isolation des toits d'un entrepôt de 45 000 m&sup2; et changement des huisseries sur tout le site"
                    />
                </>
            </Form.Item>
        </>
    );
};
