//TODO: amélioration après la demande de changement les numéros des téléphones aussi
import React, { useState } from 'react';

import { Container } from '@/layouts';
import { RootState } from '@/store/store';
import { NOT_SPECIFIED, Text, Title } from '@/utils';
import { emptyStringFormatter } from '@/utils';
import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Row, Space } from 'antd';
import { useSelector } from 'react-redux';

import { ChangePasswordModal } from './modals/ChangePassword';
import { ChangePhoneNumbersModal } from './modals/ChangePhoneNumbers';
import './style.scss';
import { TPartner } from './types';

const Profile: React.FC = () => {
    const [isModalPasswordOpen, setIsModalPasswordOpen] =
        useState<boolean>(false);
    const [isModalPhonesOpen, setIsModalPhonesOpen] = useState<boolean>(false);
    const [isToChangeMobileNumber, setIsToChangeMobileNumber] =
        useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const { heliosUser: user } = useSelector(
        (state: RootState) => state.heliosUserReducer
    );
    const partner: TPartner | undefined = user?.partner;

    const showModal = (
        isToChangePassword: boolean = true,
        value: string = '',
        isToChangeMobileNumber: boolean = true
    ) => {
        if (isToChangePassword) {
            setIsModalPasswordOpen(true);
        } else {
            setIsModalPhonesOpen(true);
            setPhoneNumber(value);
            setIsToChangeMobileNumber(isToChangeMobileNumber);
        }
    };

    const handleOnCancel = (isToChangePassword: boolean = true) => {
        if (isToChangePassword) {
            setIsModalPasswordOpen(false);
        } else {
            setIsModalPhonesOpen(false);
        }
    };

    return (
        <Container>
            <Card className="profile__card">
                <Space direction="vertical" size="large">
                    <Row align="middle">
                        <Col>
                            <Avatar
                                style={{
                                    backgroundColor: '#72B8EC',
                                    marginRight: '8px',
                                }}
                                size={'large'}
                            >
                                <Text strong>{`${user?.firstName?.charAt(
                                    0
                                )}${user?.lastName?.charAt(0)}`}</Text>
                            </Avatar>
                        </Col>
                        <Col className="user-menu">
                            <Space direction="vertical" size={2}>
                                <Text style={{ lineHeight: 'inherit' }} strong>
                                    {`${user?.firstName} ${user?.lastName}`}
                                </Text>
                                <Text style={{ lineHeight: 'inherit' }}>
                                    {partner?.companyName}
                                    {user?.function
                                        ? ` - ${emptyStringFormatter(
                                              user?.function
                                          )}`
                                        : ` - ${NOT_SPECIFIED}`}
                                </Text>
                            </Space>
                        </Col>
                    </Row>
                    <Space direction="vertical" size="small">
                        <Title level={5} style={{ marginTop: 0 }}></Title>
                        <Text>
                            <Text strong>Ligne mobile : </Text>
                            <Text>{`${
                                user ? user?.mobileNumber : NOT_SPECIFIED
                            }`}</Text>
                            <EditOutlined
                                style={{ marginLeft: '5px' }}
                                onClick={() =>
                                    showModal(false, user?.mobileNumber)
                                }
                            />
                        </Text>
                        <Text>
                            <Text strong>Ligne fixe : </Text>
                            <Text>
                                {`${user ? user?.phoneNumber : NOT_SPECIFIED}`}
                            </Text>
                            <EditOutlined
                                style={{ marginLeft: '5px' }}
                                onClick={() =>
                                    showModal(false, user?.phoneNumber, false)
                                }
                            />
                        </Text>
                        <Text>
                            <Text strong>Adresse email : </Text>
                            {`${user ? user?.email : NOT_SPECIFIED}`}
                        </Text>
                        <Button
                            type="link"
                            className="change-pwd-btn"
                            onClick={() => showModal()}
                        >
                            <Text style={{ textDecoration: 'underline' }}>
                                Changer mon mot de passe
                            </Text>
                        </Button>
                    </Space>
                    <hr color="#cecece" />
                    <Space direction="vertical">
                        <Text strong>{partner?.companyName}</Text>
                        <Space>
                            <Text strong>SIRET:</Text>
                            <Text>{partner?.siret}</Text>
                        </Space>
                        <Space>
                            <Text strong>Adresse:</Text>
                            <Text>{`${partner?.streetNumber} ${partner?.streetName} ${partner?.zipCode} ${partner?.city}`}</Text>
                        </Space>
                    </Space>
                </Space>
                <ChangePasswordModal
                    isOpen={isModalPasswordOpen}
                    onCancel={handleOnCancel}
                />
                <ChangePhoneNumbersModal
                    isOpen={isModalPhonesOpen}
                    onCancel={() => handleOnCancel(false)}
                    oldNumber={phoneNumber && phoneNumber}
                    isToChangeMobNumber={isToChangeMobileNumber}
                />
            </Card>
        </Container>
    );
};

export default Profile;
