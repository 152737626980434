import { Form, InputNumber } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function NbAppartementsREF131({ label, name, style }: { label: string, name: NamePath, style?: React.CSSProperties }) {
    if (typeof style !== 'undefined') {
        return <Form.Item
            name={name}
            label={label}
            style={style}
            rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
                { type: 'integer', message: "Veuillez renseigner un nombre entier." },
            ]}
        >
            <InputNumber min={0} decimalSeparator=',' />
        </Form.Item>
    }
    else
        return <Form.Item
            name={name}
            label={label}
            rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
                { type: 'integer', message: "Veuillez renseigner un nombre entier." },
            ]}
        >
            <InputNumber min={0} decimalSeparator=',' />
        </Form.Item>
}


