import React, { useCallback, useEffect, useState } from 'react';

import { Text } from '@/utils';
import {
    CheckOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

import { conditions } from './data';
import {
    ConditionType,
    PasswordValidationProps,
    ValidationStateType,
} from './types';
import { checkPasswordValidation } from './utils';

export const PasswordValidation: React.FC<PasswordValidationProps> = ({
    value,
}) => {
    const [validation, setValidation] = useState<ValidationStateType>({
        hasTenCaracters: false,
        hasAtLeastOneDegit: false,
        hasAtLeastOneCapitalLetter: false,
        hasAtLeastOneLowerCaseLetter: false,
        hasAtLeastOneSpecialCaracter: false,
    });
    const onChangePassword = useCallback(() => {
        const validation = checkPasswordValidation(value);

        setValidation(validation);
    }, [value]);

    useEffect(() => {
        onChangePassword();
    }, [value, onChangePassword]);

    const ConditionValidation = ({
        currenState,
        description,
    }: {
        currenState: boolean;
        description: string;
    }) => {
        return (
            <>
                {currenState ? (
                    <CheckOutlined
                        style={{
                            color: '#52c41a',
                            marginRight: '5px',
                        }}
                    />
                ) : (
                    <CloseOutlined
                        style={{
                            color: '#ff4d4f',
                            marginRight: '5px',
                        }}
                    />
                )}
                <Text>{description}</Text>
            </>
        );
    };
    return (
        <Space direction="vertical" size="small">
            {conditions.map((condition: ConditionType) => {
                return (
                    <Space key={condition.id.toString()}>
                        <ConditionValidation
                            currenState={validation[condition.currenStateName]}
                            description={condition.description}
                        />
                        {condition.tooltipTitle &&
                            condition.tooltipTitle !== '' && (
                                <Tooltip
                                    placement="top"
                                    title={condition.tooltipTitle}
                                    trigger={['focus', 'click', 'hover']}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            )}
                    </Space>
                );
            })}
        </Space>
    );
};
