import { Form, Alert, FormListFieldData, Typography, InputNumber, Radio, Divider, Flex, Button, Card, Collapse } from 'antd';
import { useState } from 'react';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import ApplicationMoteurREF40G from '@/features/new-project/operations/forms/components/common/ApplicationMoteurREF40G';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';


export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};

const { Paragraph } = Typography
const { Title } = Typography


export const Indut1022 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "IND-UT-102"
    const libelle_fost = "Système de variation électronique de vitesse sur un moteur asynchrone"
    const denomination_fost = <>Mise en place d’un système de variation électronique de vitesse (VEV) sur un moteur asynchrone
        existant dépourvu de ce système, ou neuf de puissance nominale inférieure ou égale à 3 MW</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-102%20v%20A19-2.pdf"

    /* Textes explicatifs */
    const eligibilite = <>
        <Typography>Est exclu de l'opération standardisée tout moteur
            <Typography.Text strong style={{ color: primary_color }}> IE2 </Typography.Text>
            défini par le règlement (CE) n°640/2009 de la Commission du 22 juillet 2009 modifié par le
            règlement (UE) n°4/2014 de la Commission du 6 janvier 2014, acheté :
            <Typography style={{ paddingLeft: '16px' }}>- entre le
                <Typography.Text strong style={{ color: primary_color }}> 1er janvier 2015 et le 31 décembre 2016 </Typography.Text>
                et de puissance nominale comprise
                <Typography.Text strong style={{ color: primary_color }}> entre 7,5 kW inclus et 375 kW inclus</Typography.Text>
            </Typography>
            <Typography style={{ paddingLeft: '16px' }}>- à partir du
                <Typography.Text strong style={{ color: primary_color }}> 1er janvier 2017 </Typography.Text>
                et de puissance nominale comprise
                <Typography.Text strong style={{ color: primary_color }}> entre 0,75 kW inclus et 375 kW inclus</Typography.Text></Typography>
        </Typography>
    </>
    const facture = <>
        <Paragraph>La facture mentionne la
            <Typography.Text strong style={{ color: primary_color }}> mise en place d’un système de
                variation électronique de vitesse.</Typography.Text>
        </Paragraph>
        <Typography>
            À défaut, la preuve de réalisation de l’opération mentionne l'installation d'un équipement avec ses marque et
            référence et elle est complétée par un document issu du fabricant indiquant que l'équipement de marque et référence
            installé est un système de variation électronique de vitesse.
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* VEV installé sur moteur asynchrone */}
            <Form.Item
                name={[field.name, "VEVSurAsynchrone"]}
                label="Le système de VEV est-il installé sur un moteur asynchrone ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => form.setFieldValue(['items', field.name, "MoteurNeufOuDepourvu"], undefined)}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si VEV non installée sur moteur asynchrone */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "VEVSurAsynchrone"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si le système de VEV est installé sur un moteur asynchrone."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Système installé sur moteur neuf ou dépourvu de VEV */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "VEVSurAsynchrone"]) === "Oui" ? (
                        <Form.Item
                            name={[field.name, "MoteurNeufOuDepourvu"]}
                            label="Le système est-il installé sur un moteur neuf, ou sur un moteur existant dépourvu de VEV ?"
                            rules={[
                                { required: true, message: "Veuillez renseigner ce champ" },
                            ]}
                        >
                            <Radio.Group buttonStyle='solid' onChange={
                                () => {
                                    form.setFieldValue(['items', field.name, "MoteurClasseIE22015"], undefined)
                                    form.setFieldValue(['items', field.name, "MoteurClasseIE22017"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-40-G"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                }
                            }>
                                <Radio.Button value="Oui">Oui</Radio.Button>
                                <Radio.Button value="Non">Non</Radio.Button>
                            </Radio.Group>
                        </Form.Item>


                    ) : null
                }
            </Form.Item>
            {/* Alerte si VEV installée sur moteur non dépourvu */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "MoteurNeufOuDepourvu"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si le système de VEV est installé sur un moteur neuf, ou existant et dépourvu de système de VEV."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Moteur de classe IE2*/}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "MoteurNeufOuDepourvu"]) === "Oui" ? (
                        <>
                            {/* Moteur de classe IE2 2015-2016*/}
                            <Form.Item
                                name={[field.name, "MoteurClasseIE22015"]}
                                label="Le moteur est défini de classe IE2 par le réglement CE n°640/2009, acheté entre le 01/01/2015 et le 31/12/2016 et de puissance nominale comprise entre 7,5 kW inclus et 375 kW inclus :"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={() => {
                                    form.setFieldValue(['items', field.name, "REF-40-G"], undefined)
                                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                    form.setFieldValue(['items', field.name, "MoteurClasseIE22017"], undefined)
                                }}>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si oui */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "MoteurClasseIE22015"]) === "Oui" ? (
                                        <>
                                            <Alert
                                                message="Le moteur n'est pas éligible."
                                                type="error"
                                                showIcon
                                            />
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "MoteurClasseIE22015"]) === "Non" ? (
                                        <>
                                            {/* Moteur de classe IE2 2017 */}
                                            <Form.Item
                                                name={[field.name, "MoteurClasseIE22017"]}
                                                label="Le moteur est défini de classe IE2 par le réglement CE n°640/2009, acheté à partir du 01/01/2017 et de puissance nominale comprise entre 0,75 kW inclus et 375 kW inclus :"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={() => {
                                                    form.setFieldValue(['items', field.name, "REF-40-G"], undefined)
                                                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                                                }}>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte si oui */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "MoteurClasseIE22017"]) === "Oui" ? (
                                                        <>
                                                            <Alert
                                                                message="Le moteur n'est pas éligible."
                                                                type="error"
                                                                showIcon
                                                            />
                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "MoteurClasseIE22015"]) === "Non" && getFieldValue(['items', field.name, "MoteurClasseIE22017"]) === "Non" ? (
                        <>
                            {/* Application moteur */}
                            <ApplicationMoteurREF40G
                                label="Application du moteur électrique sur lequel est installé le système de VEV"
                                name={[field.name, 'REF-40-G']}
                            />
                            {/* Puissance */}
                            <Form.Item
                                name={[field.name, 'REF-26']}
                                label="Puissance totale (kW)"
                                tooltip={{
                                    title: "La puissance totale à prendre en compte est égale à la somme des puissances totales des moteurs équipés de VEV.",
                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                    color: tooltip_color,
                                }}
                                rules={[
                                    { required: true, message: "Veuillez renseigner la puissance totale" },
                                ]}
                            >
                                <InputNumber addonAfter='kW' min='0' decimalSeparator=',' />
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />

        </>
    );
};
