import { Form, Alert, FormListFieldData, InputNumber, Typography, Divider, Flex, Button, } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import TypeLogementREF3 from '../refs/TypeLogementREF3';
import SurfaceHabitableREF14F from '../refs/SurfaceHabitableREF14F';
import CopREF12J from '../refs/CopREF12J';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';

/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1293S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAR-TH-129"
    const libelle_fost = "Pompe à chaleur de type air/air"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) de type air/air"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-129%20mod%20A27-3%20%C3%A0%20compter%20du%2001-04-2018.pdf"

    const formItemMarginBottom = 10

    const formItemStyle: React.CSSProperties = {
        marginBottom: formItemMarginBottom,
    };

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Type de logement */}
            <TypeLogementREF3
                label="Type de logement :"
                name={[field.name, 'REF-3']}
                style={formItemStyle}
            />

            {/* Surface chauffée */}
            <Form.Item
                name={[field.name, "SurfaceChauffee"]}
                label="Surface chauffée par la PAC (m²) : "
                style={formItemStyle}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <InputNumber
                    addonAfter="m²"
                    min='0'
                    controls={false}
                    style={{ width: '150px' }}
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 35 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 60 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 35) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 60) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "2" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "3" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "4" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) <= 130 && form.getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "5" }]) }
                        else if (form.getFieldValue(['items', field.name, "SurfaceChauffee"]) > 130) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "6" }]) }
                        else {
                            form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                        }
                    }}
                />
            </Form.Item>

            <SurfaceHabitableREF14F
                label=""
                name={[field.name, 'REF-14-F']}
                disabled={true}
                style={formItemStyle}
                hidden
            />

            {/* SCOP variable continue */}
            <Form.Item
                label="SCOP :"
                name={[field.name, 'Scop']}
                style={formItemStyle}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: "Le coefficient de performance saisonnier est déterminé selon le règlement n°206/2012 de la commission du 6 mars 2012.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber
                    min={0}
                    controls={false}
                    decimalSeparator=','
                    style={{ width: '100px' }}
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Scop"]) >= 3.9 && form.getFieldValue(['items', field.name, "Scop"]) < 4.3) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Scop"]) >= 4.3) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Scop"]) < 3.9) { form.setFields([{ name: ["items", field.name, "REF-12-J"], value: undefined }]) }

                    }}
                />
            </Form.Item>

            {/* SCOP */}
            <CopREF12J
                label=""
                name={[field.name, 'REF-12-J']}
                disabled={true}
                hidden
                style={formItemStyle}
            />

            {/* Alerte si SCOP < 3.9 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Scop"]) < 3.9 ? (
                        <Alert
                            message="Cette opération n'est éligible que si le SCOP est supérieur ou égal à 3,9."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>


            {/* Puissance PAC */}
            <Form.Item
                name={[field.name, "Pinstallee"]}
                label="Puissance nominale de la PAC (kW) :"
                style={formItemStyle}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: "La puissance nominale est déterminée selon le règlement n°206/2012 de la commission du 6 mars 2012.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber
                    addonAfter="kW"
                    min='0'
                    controls={false}
                    style={{ width: '150px' }}
                />
            </Form.Item>
            {/* Alerte Pinstallee > 12 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pinstallee"]) > 12 ? (
                        <Alert
                            message="Cette opération n'est éligible que si la puissance nominale est inférieure ou égale à 12 kW."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

        </>
    );
};
