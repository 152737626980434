import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Button, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import TypeLogementREF3 from '../refs/TypeLogementREF3';
import SurfaceHabitableREF14F from '../refs/SurfaceHabitableREF14F';
import SurfaceHabitableREF14E from '../refs/SurfaceHabitableREF14E';
import EfficaciteEnergetiqueREF140G from '../refs/EfficaciteEnergetiqueIREF140G';
import UsageREF15A from '../refs/UsageREF15A';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import TypeCoupDePouceIREF159Z from '../refs/TypeCoupDePouceIREF159Z';
import MenageModesteREF175 from '../refs/MenageModesteREF175';


const { Title, Paragraph } = Typography

export const Barth1711S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "BAR-TH-171"
    const libelle_fost = "Pompe à chaleur de type air/eau"
    const denomination_fost = "Mise en place d’une pompe à chaleur (PAC) de type air/eau"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-171%20vA55-1%20%C3%A0%20compter%20du%2001-01-2024_0.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Classe du régulateur */}
            <Form.Item
                name={[field.name, "ClasseRegulateur"]}
                label="Classe du régulateur :"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <>Pour être éligible aux CEE, le régulateur doit relever de l'une des classes IV, V, VI, VII ou VIII
                        telles que définies au paragraphe 6.1 de la communication de la Commission 2014/C 207/02.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <Radio.Group buttonStyle='solid'>
                    <Radio.Button value="IV">IV</Radio.Button>
                    <Radio.Button value="V">V</Radio.Button>
                    <Radio.Button value="VI">VI</Radio.Button>
                    <Radio.Button value="VII">VII</Radio.Button>
                    <Radio.Button value="VIII">VIII</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Usage */}
            <UsageREF15A
                label="Usage de la pompe à chaleur :"
                name={[field.name, 'REF-15-A']}
            />

            {/*Temperature*/}
            <Form.Item
                name={[field.name, "TemperaturePAC"]}
                label="Quel est l'application de la PAC installée ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: <><Paragraph style={{ color: 'white' }}>Une application à basse température est une application dans laquelle un dispositif de chauffage des locaux
                        par pompe à chaleur fournit sa puissance calorifique déclarée pour une température de sortie de l’échangeur thermique intérieur de 35 °C.</Paragraph>
                        <Paragraph style={{ color: 'white' }}>Une application à moyenne ou haute température est une application dans laquelle un dispositif de chauffage des locaux par pompe à chaleur
                            fournit sa puissance calorifique déclarée pour une température de sortie de l’échangeur thermique intérieur d’au moins 55 °C.</Paragraph></>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                        form.setFieldValue(['items', field.name, "REF-140-G"], undefined)
                        form.setFieldValue(['items', field.name, "Etas"], undefined)
                    }}>
                    <Radio.Button value="BasseTemperature">Basse température</Radio.Button>
                    <Radio.Button value="MoyenneHauteTemperature">Moyenne ou haute température</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Type de logement */}
            <TypeLogementREF3
                label="Type de logement :"
                name={[field.name, 'REF-3']}
            />

            {/* Surface si Appartement */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "1" ? (
                        <>
                            {/* Surface chauffée */}

                            <Form.Item
                                name={[field.name, "SurfaceChauffee"]}
                                label="Surface chauffée par la PAC (m²) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="m²"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 35 && getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "0" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 60 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 35) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "1" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 60) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "2" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "3" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "4" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) <= 130 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "5" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) > 130) { form.setFields([{ name: ["items", field.name, "REF-14-F"], value: "6" }]) }
                                        else {
                                            form.setFields([{ name: ["items", field.name, "REF-14-F"], value: undefined }])
                                        }
                                    }}
                                />
                            </Form.Item>

                            <SurfaceHabitableREF14F
                                label=""
                                name={[field.name, 'REF-14-F']}
                                disabled={true}
                                hidden
                            />
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Surface si Maison individuelle */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-3"]) === "0" ? (
                        <>
                            {/* Surface chauffée */}
                            <Form.Item
                                name={[field.name, "SurfaceChauffee"]}
                                label="Surface chauffée par la PAC (m²) : "
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <InputNumber
                                    addonAfter="m²"
                                    min='0'
                                    controls={false}
                                    style={{ width: '150px' }}
                                    onChange={() => {
                                        if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 70 && getFieldValue(['items', field.name, "SurfaceChauffee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "0" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 90 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 70) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "1" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 110 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 90) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "2" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) < 130 && getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 110) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "3" }]) }
                                        else if (getFieldValue(['items', field.name, "SurfaceChauffee"]) >= 130) { form.setFields([{ name: ["items", field.name, "REF-14-E"], value: "4" }]) }
                                        else {
                                            form.setFields([{ name: ["items", field.name, "REF-14-E"], value: undefined }])
                                        }
                                    }}
                                />
                            </Form.Item>

                            <SurfaceHabitableREF14E
                                label=""
                                name={[field.name, 'REF-14-E']}
                                disabled={true}
                                hidden
                            />
                        </>
                    ) : null
                }
            </Form.Item>


            {/* ETAS variable continue */}
            <Form.Item
                label="Efficacité énergétique saisonnière de la PAC :"
                name={[field.name, 'Etas']}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
                tooltip={{
                    title: "L’efficacité énergétique saisonnière (ηs) est déterminée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 déterminée et selon l’application de la PAC installée.",
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber
                    min={0}
                    controls={false}
                    style={{ width: '150px' }}
                    addonAfter='%'
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Etas"]) >= 111 && form.getFieldValue(['items', field.name, "Etas"]) < 140) { form.setFields([{ name: ["items", field.name, "IREF-140-G"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 140 && form.getFieldValue(['items', field.name, "Etas"]) < 170) { form.setFields([{ name: ["items", field.name, "IREF-140-G"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 170 && form.getFieldValue(['items', field.name, "Etas"]) < 200) { form.setFields([{ name: ["items", field.name, "IREF-140-G"], value: "2" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) >= 200) { form.setFields([{ name: ["items", field.name, "IREF-140-G"], value: "3" }]) }
                        else if (form.getFieldValue(['items', field.name, "Etas"]) < 111) { form.setFields([{ name: ["items", field.name, "IREF-140-G"], value: undefined }]) }

                    }}
                />
            </Form.Item>


            {/* ETAS */}
            <EfficaciteEnergetiqueREF140G
                label=""
                name={[field.name, 'IREF-140-G']}
                disabled={true}
                hidden
            />

            {/* Alerte si ETAS < 126 et basse température */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Etas"]) < 126 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "BasseTemperature" ? (
                        <Alert
                            message="Pour une PAC basse température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 126%."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Alerte si ETAS < 111 et moyenne/haute température */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Etas"]) < 111 && getFieldValue(['items', field.name, "TemperaturePAC"]) === "MoyenneHauteTemperature" ? (
                        <Alert
                            message="Pour une PAC moyenne ou haute température, l'efficacité énergétique saisonnière doit être supérieure ou égale à 111%."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Coup de pouce */}
            <Form.Item
                name={[field.name, "EligibleCoupDePouce"]}
                label="La PAC installée vient-elle en remplacement d'une chaudière au gaz, au fioul ou au charbon ?"
                colon={false}
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "IREF-159-Z"], undefined)
                    form.setFieldValue(['items', field.name, "REF-175"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {/* Energie remplacée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "EligibleCoupDePouce"]) === "Oui" ? (
                        <>
                            <Typography style={{ marginBottom: 10 }}><Typography.Text strong>L'opération est éligible au bonus Coup de Pouce, renseignez les informations suivantes pour connaître le montant bonifié :</Typography.Text></Typography>
                            <TypeCoupDePouceIREF159Z
                                name={[field.name, "IREF-159-Z"]}
                                label="Énergie remplacée"
                            />
                            <MenageModesteREF175
                                name={[field.name, "REF-175"]}
                                label="Ménage modeste :"
                            />
                        </>
                    ) : null
                }
            </Form.Item>
        </>
    );
};
