import React, { useEffect, useState } from 'react';

import { Message } from '@/components';
import { PasswordValidation } from '@/components';
import { validation } from '@/features/auth';
import { useChangePassword } from '@/hooks';
import {
    CheckCircleOutlined,
    EyeInvisibleOutlined,
    EyeTwoTone,
} from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';

import type { TChangeInformationsModalProps, TChangePassword } from '../types';

export const ChangePasswordModal: React.FC<TChangeInformationsModalProps> = ({
    isOpen,
    onCancel,
}) => {
    const [passwordsFields, setPasswordsFields] = useState<TChangePassword>({
        oldPwd: '',
        newPwd: '',
        confirmNewPwd: '',
    });
    const [isChangeError, setIsError] = useState<boolean>(false);
    const [isSuccessChange, setIsSuccessChange] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordsFields({
            ...passwordsFields,
            [e.target.name]: e.target.value,
        });
    };

    const { isPending, isRejected, changePassword } = useChangePassword(
        passwordsFields.oldPwd,
        passwordsFields.newPwd
    );

    const handleOk = () => {
        changePassword();
        if (!isRejected) {
            setIsSuccessChange(true);
            setIsError(false);
        } else {
            setIsSuccessChange(false);
            setIsError(true);
        }
    };

    const handleCancel = () => {
        onCancel();
    };

    const onCloseModal = () => {
        setIsSuccessChange(false);
        setIsError(false);
        setPasswordsFields({
            oldPwd: '',
            newPwd: '',
            confirmNewPwd: '',
        });
        onCancel();
    };

    useEffect(() => {}, [isPending, isSuccessChange, isChangeError]);

    return (
        <Modal
            title="Changer votre mot de passe"
            open={isOpen}
            onOk={isChangeError || isSuccessChange ? onCloseModal : handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: isPending }}
            cancelButtonProps={{
                disabled: isPending,
                style: {
                    display: `${
                        isChangeError || isSuccessChange
                            ? 'none'
                            : 'inline-block'
                    }`,
                },
            }}
            okText={`${isChangeError || isSuccessChange ? 'Ok' : 'Valider'}`}
            cancelText="Annuler"
        >
            {isChangeError ? (
                <Message
                    message="Un problème s'est produit, veuillez réessayer plus tard."
                    type="danger"
                    icon={<CheckCircleOutlined />}
                />
            ) : isSuccessChange ? (
                <Message
                    message="Votre mot de passe a bien été changé."
                    type="success"
                    icon={<CheckCircleOutlined />}
                />
            ) : (
                <Form
                    name="compelete-new-password"
                    style={{ width: '100%' }}
                    autoComplete="off"
                    className="connexion__form"
                    disabled={isPending}
                >
                    <Form.Item
                        name="oldPwdItem"
                        rules={validation.passwordValidation}
                    >
                        <Input.Password
                            name="oldPwd"
                            placeholder="Ancien mot de passe"
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                            onChange={handleChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="newPwdItem"
                        rules={validation.passwordValidation}
                    >
                        <Input.Password
                            name="newPwd"
                            placeholder="Nouveau mot de passe"
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                            onChange={handleChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmNewPwdItem"
                        rules={[
                            validation.confirmPasswordValidation,
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('newPwd') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'Le mot de passe et la confirmation ne sont pas identiques'
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            name="confirmNewPwd"
                            placeholder="Confirmation du nouveau mot de passe"
                            iconRender={(visible) =>
                                visible ? (
                                    <EyeTwoTone />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                            onChange={handleChange}
                        />
                    </Form.Item>

                    <Form.Item>
                        <PasswordValidation value={passwordsFields.newPwd} />
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};
