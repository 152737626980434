import * as constant from '@/features/new-project/constants';
import { TNewProjectForm } from '@/store/slices/new-project/types';
import { getObjectFromArray } from '@/utils';

export const createOthersWorkAnswers = (item: any, values: TNewProjectForm) => {
    const newItem: typeof item = {};
    newItem.name = item.name;
    newItem.label = item.label;
    newItem.children = [];

    const othersWorkTypes = values[constant.OTHERS_WORK_TYPES];
    const { children } = item;

    if (othersWorkTypes && othersWorkTypes.length > 0) {
        for (let index = 0; index < othersWorkTypes.length; index++) {
            const element = othersWorkTypes[index];
            const answer = getObjectFromArray(children, 'name', element);

            if (answer.name === constant.OTHERS_TYPES) {
                answer.label = answer.label.replace(
                    constant.STR_VALUE,
                    values[constant.OTHERS_WORK_TYPES_NAMES]
                );
            }

            newItem.children.push(answer);
        }
    }

    return newItem;
};
