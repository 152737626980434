import { Form, Radio } from 'antd';
import { NamePath } from 'antd/es/form/interface';

export default function CopREF12J({ label, name, disabled, style, hidden }: { label: string, name: NamePath, disabled: boolean, style?: React.CSSProperties, hidden?: boolean  }) {
    return <Form.Item
        name={name}
        label={label}
        style={style}
        hidden={hidden}
        rules={
            [{
                required: true,
                message: "Veuillez renseigner le SCOP"
            },]}>
        <Radio.Group
            buttonStyle='solid'
            disabled={disabled}
        >
            <Radio.Button value="0">3,9 ≤ SCOP &lt; 4,3</Radio.Button>
            <Radio.Button value="1">4,3 ≤ SCOP</Radio.Button>
        </Radio.Group>
    </Form.Item >
}
