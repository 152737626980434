import { Form, FormListFieldData, Typography, Divider, Flex, Button, InputNumber } from 'antd';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';
import SecteurREF22AD from '../refs/SecteurREF22AD';

const { Title, Paragraph } = Typography

export const Resch1033S = ({ field }: { field: FormListFieldData }) => {

    // Couleurs
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'


    /* Infos FOST */
    const code_fost = "RES-CH-103"
    const libelle_fost = "Réhabilitation d’un poste de livraison de chaleur d’un bâtiment tertiaire"
    const denomination_fost = <>Réhabilitation d’un poste de livraison de chaleur par le remplacement de la
        totalité des éléments constitutifs du primaire du poste de livraison</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/RES-CH-103%20vA36-3%20%C3%A0%20compter%20du%2012-10-2020.pdf"



    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Secteur */}
            <SecteurREF22AD
                name={[field.name, "REF-22-AD"]}
                label="Secteur d'actvité :"
            />

            {/* Surface */}
            <Form.Item
                name={[field.name, "REF-10"]}
                label="Surface totale chauffée (m²) :"
                rules={[
                    { required: true, message: "Veuillez renseigner la surface" },
                ]}
                tooltip={{
                    title: <><Paragraph style={{ color: 'white' }}>La surface prise en compte est la surface chauffée du ou des
                        bâtiments tertiaires raccordés au poste de livraison réhabilité.</Paragraph>
                        <Typography style={{ color: 'white' }}>Si plusieurs postes de livraisons alimentent un même bâtiment alors :</Typography>
                        <Typography style={{ color: 'white' }}>- soit la totalité des postes est réhabilitée pour être éligible pour la surface totale chauffée ;</Typography>
                        <Typography style={{ color: 'white' }}>- soit, chaque poste réhabilité peut être éligible à une partie de la surface du bâtiment, en
                            appliquant un prorata en fonction de la puissance de chaque poste de livraison par rapport à la puissance totale raccordée.</Typography>
                    </>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
            >
                <InputNumber addonAfter="m²" min={0} decimalSeparator=',' />
            </Form.Item>

        </>
    );
};
