import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
export default function TypeCanalisationREF33C({ label, name, tooltip }: { label: string, name: NamePath ,tooltip: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner ce champ"
      },]}
    tooltip={{
      title: tooltip,
      icon: <InfoCircleOutlined style={{ color: '#002766' }} />,
      color: '#0086CA',
    }}>
    <Select
      placeholder="Sélectionnez le type de canalisations"
      allowClear
      style={{ width: '150px' }}
    >
      <Option value="0">Canalisations respectant la norme NF EN 12828</Option>
      <Option value="1">Canalisations respectant la norme NF EN 253</Option>
    </Select>
  </Form.Item >
}