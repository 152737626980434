import { EstimateVolume } from "@/hooks/new-project/project-request-types";

export function formatKey(key: string): string {
  const formatted = key
      .replace(/(\d)/, ' $1')
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/^./, function (str) {
          return str.toUpperCase();
      });

  return formatted
      .split(' ')
      .map((word) => (word.length === 1 ? word : ' ' + word))
      .join('');
}

export interface VolumeData {
  classique?: number;
  precariousness?: number;
}

export interface VolumeSums {
  sumClassique: number;
  sumPrecariousness: number;
}

export interface EstimateSums {
  sumTotalMax: number | null;
  sumTotalMedium: number | null;
  sumTotalMin: number | null;
}

export function sumVolumes(data: EstimateVolume[] = []): VolumeSums {
  let sumClassique = 0;
  let sumPrecariousness = 0;

  if (!data || !Array.isArray(data)) {
      return {
          sumClassique,
          sumPrecariousness,
      };
  }

  data.forEach((item) => {
      sumClassique += item.classique || 0;
      sumPrecariousness += item.precariousness || 0;
  });

  return {
      sumClassique,
      sumPrecariousness,
  };
}


export function sumEstimations(data: EstimateVolume[] = []): EstimateSums {
  let sumTotalMax = 0;
  let sumTotalMin = 0;
  let sumTotalMedium = 0;

  if (!data || !Array.isArray(data)) {
      return {
        sumTotalMax,
          sumTotalMin,
          sumTotalMedium,
      };
  }

  data.forEach((item) => {
      sumTotalMax += item.totalprimemax|| 0;
      sumTotalMin += item.totalprimemin || 0;
      sumTotalMedium += item.totalprimemedium|| 0;
  });

  return {
      sumTotalMax,
      sumTotalMin,
      sumTotalMedium
  };
}

export function formatIfNumber(value: string | number): string | number {
  const number = Number(value);

  if (isNaN(number)) {
      return value;
  }

  return number
      .toLocaleString('en-US', { useGrouping: true })
      .replace(/,/g, ' ');
}
