import React from 'react';

import { numberFormatter } from '@/utils';
import { Flex, Typography } from 'antd';

import { PriceRangeType } from './utils/utils';

interface PriceRangeProps {
    minPrice?: number;
    maxPrice?: number;
    mediumPrice?: number;
    isPublic?: boolean;
    priceRangeType?: PriceRangeType;
}

const PrimeCeeRange: React.FC<PriceRangeProps> = ({
    minPrice,
    maxPrice,
    mediumPrice,
    isPublic = false,
    priceRangeType = PriceRangeType.PRIVATE_DETAIL,
}) => {
    const primaryColor = '#002766';
    const secondaryColor = '#000000';
    const subtitleColor = '#5E5E5E';
    const resultColor = '#0086CA';

    const titleColor = isPublic ? primaryColor : secondaryColor;

    const getDescription = (priceRangeType: PriceRangeType): string => {
        const description = (() => {
            switch (priceRangeType) {
                case PriceRangeType.PRIVATE_DETAIL:
                    return 'Estimation prime bénéficiaire';
                case PriceRangeType.PRIVATE_RESUME:
                    return 'Estimation prime:';
                case PriceRangeType.PRIVATE_TOTAL:
                    return 'Estimation prime bénéficiaire';
                case PriceRangeType.PUBLIC_DETAIL:
                    return 'Prime CEE estimée :';
                case PriceRangeType.PUBLIC_TOTAL:
                    return 'Prime CEE totale estimée :';
                default:
                    return '';
            }
        })();

        return description;
    };

    const noPricesAvailable = [minPrice, maxPrice, mediumPrice].every(
        (price) => !price || price === 0
    );

    return (
        <Flex style={{ width: '100%' }} justify="space-between" align="center">
            <Typography style={{ marginRight: '20px' }}>
                <Typography.Text strong underline style={{ color: titleColor }}>
                    {getDescription(priceRangeType)}
                </Typography.Text>
            </Typography>
            <Typography>
                {/* Check if all prices are zero, then display a message */}
                {noPricesAvailable ? (
                    <Typography.Text strong>Non disponible</Typography.Text>
                ) : mediumPrice && mediumPrice !== 0 ? (
                    <Typography.Text strong style={{ color: resultColor }}>
                        {numberFormatter(mediumPrice, 0)}
                    </Typography.Text>
                ) : (
                    <>
                        <Typography.Text
                            strong
                            style={{
                                color: subtitleColor,
                            }}
                        >
                            Entre{' '}
                        </Typography.Text>
                        <Typography.Text
                            strong
                            style={{
                                color: resultColor,
                            }}
                        >
                            {numberFormatter(minPrice || 0, 0)}
                        </Typography.Text>
                        <Typography.Text
                            strong
                            style={{
                                color: subtitleColor,
                            }}
                        >
                            {' '}
                            et{' '}
                        </Typography.Text>
                        <Typography.Text
                            strong
                            style={{
                                color: resultColor,
                            }}
                        >
                            {numberFormatter(maxPrice || 0, 0)}
                        </Typography.Text>
                    </>
                )}

                {!noPricesAvailable && (
                    <Typography.Text strong style={{ color: subtitleColor }}>
                        {' '}
                        {isPublic ? '€' : '€HT'}
                    </Typography.Text>
                )}
            </Typography>
        </Flex>
    );
};

export default PrimeCeeRange;
