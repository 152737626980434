import React, { useEffect } from 'react';

import { ESTIMATE_SIGNATURE_DATE } from '@/features/new-project/constants';
import { Text } from '@/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Flex, Form, FormListFieldData, Space } from 'antd';

import { useWatch } from 'antd/es/form/Form';


import * as FormFiche from '..';

export type FormType = {
    [key: string]: React.ComponentType<any>;
};

export const CreateForm = ({
    name,
    estimationQuataionDate,
    field,
    ...props
}: {
    name: string | undefined;
    estimationQuataionDate: string;
    field: FormListFieldData;
}): JSX.Element | null => {
    const formComponent: FormType = FormFiche;

    const form = Form.useFormInstance();
    const quotationDateFiled = useWatch(ESTIMATE_SIGNATURE_DATE);
    useEffect(() => {}, [form, quotationDateFiled]);

    if (name && typeof name === 'string') {

        const FormComponent = formComponent[name];
        if (FormComponent) {
            return React.createElement(FormComponent, {
                estimationQuataionDate,
                field,
                ...props,
            });
        }

        return (
            <Flex align="center" justify="center">
                <Space size="small" align="start">
                    <InfoCircleOutlined
                        style={{ fontSize: '14px', color: '#FAAD14' }}
                    />
                    <Text strong>
                        L’estimation live n’est actuellement pas disponible pour
                        la période concernée. Veuillez-nous en excuser. “Vous
                        pouvez toutefois utiliser notre formulaire générique et
                        être recontacté dans les 48h"
                    </Text>
                </Space>
            </Flex>
        );
    }

    return null;
};
