import React from 'react';

import { Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Désolé, la page que vous cherchez n'existe pas."
            extra={
                <Link to="/" type="primary">
                    Revenir à la page d’accueil
                </Link>
            }
        />
    );
};

export default NotFound;
