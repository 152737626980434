import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { RequestedDocument } from '@/hooks/new-project/project-request-types';

const { Title, Text, Link } = Typography;

interface Props {
  data: RequestedDocument[] | null;
}


const ProjectRequestDocuments: React.FC<Props> = ({ data }) => {
  return (
    <Card
      title={
        <Title level={4}>
          <b>Pièces jointes</b>
        </Title>
      }
    >
      <Row gutter={[16, 16]}>
        {data &&
          data.map((document, idx) => {
            const name = document.name.split('/')[2]
              ? document.name.split('/')[2]
              : document.name;
            return (
              <Col span={24} key={idx}>
                <Link
                  href={document.path}
                  target="_blank"
                  style={{ fontWeight: 600, fontSize: '16px', cursor: 'pointer' }}
                >
                  <Text>{name}</Text>
                </Link>
              </Col>
            );
          })}
      </Row>
    </Card>
  );
};

export default ProjectRequestDocuments;
