import { ValidationStateType } from './types';

export const checkPasswordValidation = (
    password: string
): ValidationStateType => {
    const hasTenCaracters = password.length >= 10;
    const hasAtLeastOneDegit = /[0-9]/.test(password);
    const hasAtLeastOneCapitalLetter = /[A-Z]/.test(password);
    const hasAtLeastOneLowerCaseLetter = /[a-z]/.test(password);
    const hasAtLeastOneSpecialCaracter =
        /^(?![\s])[^\w\s]|(?![\s])[^\w\s](?![\s])|[\s](?![\s])/.test(password);

    return {
        hasTenCaracters,
        hasAtLeastOneDegit,
        hasAtLeastOneCapitalLetter,
        hasAtLeastOneLowerCaseLetter,
        hasAtLeastOneSpecialCaracter,
    };
};
