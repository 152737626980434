import { Form, Alert, FormListFieldData, Radio, InputNumber, Typography, Divider, Space, Collapse, Button, Card, Row, Col, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { InfoCircleOutlined, ArrowRightOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceChaudiereREF24B from '@/features/new-project/operations/forms/components/common/PuissanceChaudiereREF24B';
import RREF142 from '@/features/new-project/operations/forms/components/common/RREF142';
import NbAppartementsREF131 from '@/features/new-project/operations/forms/components/common/NbAppartementsREF131';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

/* A retirer avant intégration, sert à tester les valeurs renvoyées */
export const transformObject = (input: any): any[] => {
    return Object.entries(input).map(([key, value]) => ({
        referenceName: key,
        referenceValue:
            value === undefined
                ? undefined
                : typeof value === 'string'
                    ? value.replaceAll(' ', '')
                    : value,
        isNa: value === '' || value === undefined ? true : false,
    }));
};
/* fin de la partie à supprimer */


const { Title } = Typography

export const Barth1071 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Lien vers le PDF de la FOST */
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAR-TH-107.pdf"

    /* Textes explicatifs */
    const eligibilité = <>
        <Row gutter={8}>
            <Col span={6}>
                <Card size='small' title="Pour une puissance nominale ≤ 70 kW :">
                    <Typography>L’efficacité énergétique saisonnière (Etas) selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 est
                        <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à 90%.</Typography.Text></Typography>
                </Card>
            </Col>
            <Col span={10}>
                <Card size='small' title="Pour une puissance nominale &gt; 70 kW et ≤ 400 kW :">
                    <Typography>- L’efficacité utile à 100 % de la puissance thermique nominale selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 est
                        <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à 87%.</Typography.Text></Typography>
                    <Typography>- L’efficacité utile à 30 % de la puissance thermique nominale selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013 est
                        <Typography.Text strong style={{ color: primary_color }}> supérieure ou égale à 95,5%.</Typography.Text></Typography>
                </Card>
            </Col>
            <Col span={8}>
                <Card size='small' title="Pour une puissance nominale &gt; 400 kW :">
                    <Typography>Le rendement PCI à pleine charge et le rendement PCI à 30% de charge selon l’arrêté du 3 mai 2007 relatif aux caractéristiques thermiques et à
                        la performance énergétique des bâtiments existants sont <Typography.Text strong style={{ color: primary_color }}>supérieurs ou égaux à 92%.</Typography.Text>
                    </Typography>
                </Card>
            </Col>
        </Row>

    </>
    const facture = <>
        <Row gutter={8}>
            <Col span={7}>
                <Card size='small' title="Pour une puissance nominale ≤ 70 kW :">
                    <Typography>La preuve de réalisation de l'opération mentionne :</Typography>
                    <Typography>- l'installation d'une chaudière</Typography>
                    <Typography>- la puissance nominale de la chaudière installée</Typography>
                    <Typography>- et l'efficacité énergétique saisonnière (Etas) de la chaudière installée</Typography>
                </Card>
            </Col>
            <Col span={10}>
                <Card size='small' title="Pour une puissance nominale &gt; 70 kW et ≤ 400 kW :">
                    <Typography>La preuve de réalisation de l'opération mentionne :</Typography>
                    <Typography>- l'installation d'une chaudière</Typography>
                    <Typography>- la puissance nominale de la chaudière installée</Typography>
                    <Typography>- l'efficacité utile de la chaudière à 100% de la puissance thermique nominale</Typography>
                    <Typography>- et l'efficacité utile de la chaudière à 30% de la puissance thermique nominale</Typography>
                </Card>
            </Col>
            <Col span={7}>
                <Card size='small' title="Pour une puissance nominale &gt; 400 kW :">
                    <Typography>La preuve de réalisation de l'opération mentionne :</Typography>
                    <Typography>- l'installation d'une chaudière</Typography>
                    <Typography>- la puissance nominale de la chaudière installée</Typography>
                    <Typography>- le rendement PCI à pleine charge</Typography>
                    <Typography>- et le rendement PCI à 30% de charge</Typography>
                </Card>
            </Col>
        </Row>
    </>
    const incompatibilites = <>
        <Paragraph>Lorsque :
            <Typography style={{ paddingLeft: '16px' }}>- la chaufferie après rénovation comporte des équipements de type <Typography.Text strong style={{ color: primary_color }}>pompe à chaleur collective gaz à absorption de type air/eau ou eau/eau</Typography.Text></Typography>
            <Typography style={{ paddingLeft: '16px' }}>et</Typography>
            <Typography style={{ paddingLeft: '16px' }}>- la puissance thermique de la (ou des) PAC installée(s) est <Typography.Text strong style={{ color: primary_color }}>supérieure ou égale à 40 % </Typography.Text>de la puissance de la nouvelle chaufferie</Typography>
        </Paragraph>
        <Typography>Alors <Typography.Text strong style={{ color: primary_color }}>aucun certificat n'est délivré </Typography.Text>pour la fiche BAR-TH-107.</Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilité}</>,
        2: <>{facture}</>,
        3: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>BAR-TH-107 : Chaudière collective haute performance énergétique</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>Mise en place d’une chaudière collective haute performance énergétique
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Appartements plus de 2 ans  */}
            <Form.Item
                name={[field.name, "Appartements2Ans"]}
                label="L'opération est réalisée sur des appartements existants depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "Pinstallee"], undefined)
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-24-B"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                        form.setFieldValue(['items', field.name, "REF-131"], undefined)
                        form.setFieldValue(['items', field.name, "Eligibilite70"], undefined)
                        form.setFieldValue(['items', field.name, "Eligibilite70-400_100"], undefined)
                        form.setFieldValue(['items', field.name, "Eligibilite70-400_30"], undefined)
                        form.setFieldValue(['items', field.name, "Eligibilite400_30"], undefined)
                        form.setFieldValue(['items', field.name, "Eligibilite400_100"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si Apparts + de 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur des appartements existants depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Appartements OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Appartements2Ans"]) === "Oui" ? (
                        <>
                            {/* Nb Appartements */}
                            <NbAppartementsREF131
                                label="Nombre d'appartements :"
                                name={[field.name, 'REF-131']}
                            />

                            {/* Puissance Chaudière */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align="end">
                                    <Form.Item
                                        name={[field.name, "Pinstallee"]}
                                        label="Puissance de la chaudière (kW) : "
                                        rules={[
                                            { required: true, message: "Veuillez renseigner ce champ" },
                                        ]}
                                    >
                                        <InputNumber
                                            addonAfter="kW"
                                            decimalSeparator=','
                                            min='0'
                                            controls={false}
                                            style={{ width: '150px' }}
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && getFieldValue(['items', field.name, "Pinstallee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "0" }]) }
                                                else if (getFieldValue(['items', field.name, "Pinstallee"]) > 400) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "1" }]) }
                                                else { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: undefined }]) }
                                                form.setFieldValue(['items', field.name, "Eligibilite70"], undefined)
                                                form.setFieldValue(['items', field.name, "Eligibilite70-400_100"], undefined)
                                                form.setFieldValue(['items', field.name, "Eligibilite70-400_30"], undefined)
                                                form.setFieldValue(['items', field.name, "Eligibilite400_30"], undefined)
                                                form.setFieldValue(['items', field.name, "Eligibilite400_100"], undefined)
                                                form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                                                form.setFieldValue(['items', field.name, "REF-142"], undefined)
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    <PuissanceChaudiereREF24B
                                        label=""
                                        name={[field.name, 'REF-24-B']}
                                        disabled={true}
                                        onChange={
                                            () => {
                                            }
                                        }
                                    />
                                </Space>
                            </Form.Item>
                            {/* Eligibilité P <= 70*/}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) <= 70 && getFieldValue(['items', field.name, "Pinstallee"]) > 0 ? (
                                        <>
                                            {/*Eligibilité ETAS*/}
                                            <Form.Item
                                                name={[field.name, "Eligibilite70"]}
                                                label="L'efficacité énergétique saisonnière (Etas) est-elle supérieure ou égale à 90% ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                                tooltip={{
                                                    title: "L’efficacité énergétique saisonnière (Etas) est calculée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                    color: tooltip_color,
                                                }}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si ETAS inférieur à 90*/}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Eligibilite70"]) === "Non" ? (
                                                        <Alert
                                                            message="Pour une chaudière de puissance inférieure ou égale à 70 kW, l'efficacité énergétique saisonnière doit être supérieure ou égale à 90%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Eligibilité 70 < P <= 400*/}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && getFieldValue(['items', field.name, "Pinstallee"]) > 70 ? (
                                        <>
                                            {/*Eligibilité Efficacité 100%*/}
                                            <Form.Item
                                                name={[field.name, "Eligibilite70-400_100"]}
                                                label="L'efficacité utile à 100% est-elle supérieure ou égale à 87% ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                                tooltip={{
                                                    title: "L’efficacité utile est déterminée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                    color: tooltip_color,
                                                }}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si Efficacité100 inférieure à 87*/}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Eligibilite70-400_100"]) === "Non" ? (
                                                        <Alert
                                                            message="Pour une chaudière de puissance supérieure à 70 kW et inférieure ou égale à 400 kW, l'efficacité utile à 100% de la puissance nominale doit être supérieure ou égale à 87%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/*Eligibilité Efficacité 30%*/}
                                            <Form.Item
                                                name={[field.name, "Eligibilite70-400_30"]}
                                                label="L'efficacité utile à 30% est-elle supérieure ou égale à 95,5% ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                                tooltip={{
                                                    title: "L’efficacité utile est déterminée selon le règlement (EU) n° 813/2013 de la commission du 2 août 2013.",
                                                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                                    color: tooltip_color,
                                                }}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si Efficacité30 inférieure à 95,5*/}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Eligibilite70-400_30"]) === "Non" ? (
                                                        <Alert
                                                            message="Pour une chaudière de puissance supérieure à 70 kW et inférieure ou égale à 400 kW, l'efficacité utile à 30% de la puissance nominale doit être supérieure ou égale à 95,5%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>

                            {/* Eligibilité P < 400*/}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pinstallee"]) > 400 ? (
                                        <>
                                            {/*Eligibilité Rendement PCI 100%*/}
                                            <Form.Item
                                                name={[field.name, "Eligibilite400_100"]}
                                                label="Le rendement PCI à pleine charge est-il supérieur ou égal à 92% ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si Rendement PCI inférieur à 92*/}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Eligibilite400_100"]) === "Non" ? (
                                                        <Alert
                                                            message="Pour une chaudière de puissance supérieure à 400 kW et inférieure ou égale à 400 kW, le rendement PCI à pleine charge doit être supérieur ou égal à 92%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/*Eligibilité Rendement PCI 30%*/}
                                            <Form.Item
                                                name={[field.name, "Eligibilite400_30"]}
                                                label="Le rendement PCI à 30% de charge est-il supérieur ou égal à 92% ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' >
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>

                                            {/* Alerte si Efficacité30 inférieure à 95,5*/}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "Eligibilite400_30"]) === "Non" ? (
                                                        <Alert
                                                            message="Pour une chaudière de puissance supérieure à 400 kW et inférieure ou égale à 400 kW, le rendement PCI à 30% de charge doit être supérieur ou égal à 92%."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>


                            {/* Puissance totale chaufferie */}
                            <Form.Item style={{ margin: "0px" }}>
                                <Space align='end'>
                                    <Form.Item
                                        name={[field.name, "Pchaufferie"]}
                                        label="Puissance totale de la nouvelle chaufferie : "
                                        rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                                        tooltip={{
                                            title: <>La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                                            icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                                            color: tooltip_color,
                                        }}
                                        extra="Puissance de la chaufferie après travaux"
                                        style={{ margin: "0px" }}
                                    >
                                        <InputNumber
                                            addonAfter="kW"
                                            style={{ width: '150px' }}
                                            controls={false}
                                            min='0'
                                            onChange={() => {
                                                if (getFieldValue(['items', field.name, "Pinstallee"]) >= (1 / 3 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) >= getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                                                else if (getFieldValue(['items', field.name, "Pinstallee"]) < (1 / 3 * getFieldValue(['items', field.name, "Pchaufferie"]))
                                                    && getFieldValue(['items', field.name, "Pchaufferie"]) > getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (getFieldValue(['items', field.name, "Pinstallee"]) / getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                                                else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <ArrowRightOutlined style={{ color: border_color, fontSize: '1.8em' }} />
                                    </Form.Item>
                                    {/* R */}
                                    <RREF142
                                        label="Facteur R :"
                                        name={[field.name, 'REF-142']}
                                        disabled={true}
                                    />
                                </Space>
                            </Form.Item>
                            {/* Alerte si Pchaufferie < Pinstallée */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                                        <Alert
                                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>
                        </>) : null
                }
            </Form.Item>

            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
