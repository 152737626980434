import React from 'react';

import { PatternFormat } from 'react-number-format';

const PHONE_NUMBER_FORMAT = '## ## ## ## ##';

const PhoneNumberInput = ({
    value,
    classes,
    onChange,
    ...props
}: {
    value?: string;
    classes?: string;
    onChange?: (value: string) => void;
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        onChange && onChange(inputValue);
    };
    return (
        <PatternFormat
            format={PHONE_NUMBER_FORMAT}
            value={value || ''}
            mask=" "
            className={classes}
            onChange={handleChange}
            {...props}
        />
    );
};

export default React.memo(PhoneNumberInput);
