import { FormListFieldData, Typography, Divider, Button, Flex } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22AD from '../refs/SecteurREF22AD';
import SurfaceREF10 from '../refs/SurfaceREF10';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';


const { Title } = Typography

export const Batth1093S = ({ field }: { field: FormListFieldData }) => {

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-109"
    const libelle_fost = "Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative"
    const denomination_fost = "Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative sur un circuit de chauffage collectif à combustible existant."
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-109%20vA54-3%20%C3%A0%20compter%20du%2001-01-2024.pdf"

    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            <SecteurREF22AD
                name={[field.name, "REF-22-AD"]}
                label="Secteur d'activité :"
            />

            <SurfaceREF10
                name={[field.name, "REF-10"]}
                label="Surface chauffée (m²) :"
                tooltip="La surface chauffée à prendre en compte est celle gérée par le système de régulation par optimiseur de relance mis en place comprenant une fonction auto-adaptative."
            />

        </>
    );
};
