import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { InfoCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
export default function NatureFluideREF121C({ label, name}: { label: string, name: NamePath | JSX.Element[] | React.ReactNode | React.ReactNode[] }) {
  return <Form.Item
    name={name}
    label={label}
    rules={
      [{
        required: true,
        message: "Veuillez renseigner ce champ"
      },]}
	  >
    <Select
      placeholder="Sélectionnez la nature du fluide"
      allowClear
      style={{ width: '150px' }}
    >
      <Option value="0">Eau chaude</Option>
      <Option value="1">Eau surchauffée</Option>
	  <Option value="2">Vapeur</Option>
    </Select>
  </Form.Item >
}
