import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import SecteurREF22AD from '@/features/new-project/operations/forms/components/common/SecteurREF22AD';
import SurfaceREF10 from '@/features/new-project/operations/forms/components/common/SurfaceREF10';
import { ZoneClimatique } from '@/features/new-project/operations/forms/components/common/ZoneClimatique';

const { Paragraph } = Typography

const { Title } = Typography

export const Batth1093 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "BAT-TH-109"
    const libelle_fost = "Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative"
    const denomination_fost = "Mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative sur un circuit de chauffage collectif à combustible existant."
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-109%20vA54-3%20%C3%A0%20compter%20du%2001-01-2024.pdf"


    /* Textes explicatifs */
    const eligibilite = <>
        <Paragraph>Le dispositif est équipé d’une
            <Typography.Text strong style={{ color: primary_color }}> fonction basée sur l’apprentissage adaptatif de l’arrêt et du démarrage optimisé du système de chauffage</Typography.Text>
            , au sens de la norme NF EN 12098-1 « Performance énergétique des bâtiments - Régulation pour les systèmes de chauffage - Partie 1 : Equipement de régulation pour
            les systèmes de chauffage à eau chaude », qui recalcule les paramètres utilisés pour déterminer l'heure d'arrêt et de mise en marche, en se basant sur la température
            ambiante mesurée, l’inertie du bâtiment, les paramètres d’occupation ou la météorologie locale.
            <Typography style={{ paddingLeft: '16px' }}></Typography>
        </Paragraph>
        <Typography>Le dispositif intègre une fonction <Typography.Text strong style={{ color: primary_color }}>« descente de température (réduit en inoccupation) » et une fonction « commutateur été/hiver » </Typography.Text>
            au sens de la norme NF EN 12098-1.
        </Typography>

    </>
    const facture = <>
        <Paragraph>La facture mentionne
            <Typography.Text strong style={{ color: primary_color }}> la mise en place d’un optimiseur de relance comprenant une fonction auto-adaptative </Typography.Text>
            au sens de la norme NF EN 12098-1.
        </Paragraph>
        <Typography>A défaut, la preuve de réalisation de l’opération mentionne la mise en place d’un équipement avec ses marque et référence et elle est
            accompagnée d’un document issu du fabricant indiquant que l’équipement de marque et référence mis en place est un optimiseur de relance comprenant
            une fonction auto-adaptative au sens de la norme NF EN 12098-1.
        </Typography>
    </>
    const incompatibilites = <>
        <Typography>La présente opération n’est pas cumulable avec les opérations relevant des fiches :
            <Typography><Typography.Text strong style={{ color: primary_color, paddingLeft: '16px' }}>- BAT-TH-108 « Système de régulation par programmation d’intermittence »</Typography.Text>
            </Typography>
            <Typography><Typography.Text strong style={{ color: primary_color, paddingLeft: '16px' }}>- BAT-TH-116 « Système de gestion technique du bâtiment pour le chauffage et l’eau chaude sanitaire »</Typography.Text>
            </Typography>
        </Typography>
    </>

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
        { key: '3', label: 'Incompatibilités' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
        3: <>{incompatibilites}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>

            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Code Postal */}
            <ZoneClimatique field={field} />

            {/* Bâtiment tertiaire plus de 2 ans  */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2Ans"]}
                label="L'opération est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "CircuitChauffage2Ans"], undefined)
                        form.setFieldValue(['items', field.name, "CommutateurEteHiver"], undefined)
                        form.setFieldValue(['items', field.name, "DescenteDeTemperature"], undefined)
                        form.setFieldValue(['items', field.name, "REF-22-AD"], undefined)
                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                    }
                }>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2Ans"]) === "Oui" ? (
                        <>
                            {/* Circuit Chauffage plus de 2 ans  */}
                            <Form.Item
                                name={[field.name, "CircuitChauffage2Ans"]}
                                label="L’équipement de régulation est-il installé sur un circuit de chauffage collectif à combustible existant depuis plus de 2 ans ?"
                                rules={[
                                    { required: true, message: "Veuillez renseigner ce champ" },
                                ]}
                            >
                                <Radio.Group buttonStyle='solid' onChange={
                                    () => {
                                        form.setFieldValue(['items', field.name, "CommutateurEteHiver"], undefined)
                                        form.setFieldValue(['items', field.name, "DescenteDeTemperature"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-22-AD"], undefined)
                                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                    }
                                }>
                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                    <Radio.Button value="Non">Non</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {/* Alerte si circuit chauffage non */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "CircuitChauffage2Ans"]) === "Non" ? (
                                        <Alert
                                            message="Cette opération n'est éligible que si l’équipement de régulation est installé sur un circuit de chauffage collectif à combustible existant depuis plus de 2 ans."
                                            type="error"
                                            showIcon
                                        />
                                    ) : null
                                }
                            </Form.Item>

                            {/* Suite formulaire si Circuit Chauffage OK */}
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) =>
                                    getFieldValue(['items', field.name, "CircuitChauffage2Ans"]) === "Oui" ? (
                                        <>
                                            {/* Fonction Commutateur été/hiver  */}
                                            <Form.Item
                                                name={[field.name, "CommutateurEteHiver"]}
                                                label="Le dispositif intègre-t-il une fonction « commutateur été/hiver » au sens de la norme NF EN 12098-1 ?"
                                                rules={[
                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                ]}
                                            >
                                                <Radio.Group buttonStyle='solid' onChange={
                                                    () => {
                                                        form.setFieldValue(['items', field.name, "DescenteDeTemperature"], undefined)
                                                        form.setFieldValue(['items', field.name, "REF-22-AD"], undefined)
                                                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                                    }
                                                }>
                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                            {/* Alerte si Fonction Commutateur été/hiver non */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "CommutateurEteHiver"]) === "Non" ? (
                                                        <Alert
                                                            message="Cette opération n'est éligible que si le dispositif intègre une fonction « commutateur été/hiver » au sens de la norme NF EN 12098-1."
                                                            type="error"
                                                            showIcon
                                                        />
                                                    ) : null
                                                }
                                            </Form.Item>

                                            {/* Suite formulaire si CommutateurEteHiver OK */}
                                            <Form.Item noStyle shouldUpdate>
                                                {({ getFieldValue }) =>
                                                    getFieldValue(['items', field.name, "CommutateurEteHiver"]) === "Oui" ? (
                                                        <>
                                                            {/* Fonction Descente de température  */}
                                                            <Form.Item
                                                                name={[field.name, "DescenteDeTemperature"]}
                                                                label="Le dispositif intègre-t-il une fonction « descente de température (réduit en inoccupation) » au sens de la norme NF EN 12098-1 ?"
                                                                rules={[
                                                                    { required: true, message: "Veuillez renseigner ce champ" },
                                                                ]}
                                                            >
                                                                <Radio.Group buttonStyle='solid' onChange={
                                                                    () => {
                                                                        form.setFieldValue(['items', field.name, "REF-22-AD"], undefined)
                                                                        form.setFieldValue(['items', field.name, "REF-10"], undefined)
                                                                    }
                                                                }>
                                                                    <Radio.Button value="Oui">Oui</Radio.Button>
                                                                    <Radio.Button value="Non">Non</Radio.Button>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {/* Alerte si Fonction Descente de température non */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DescenteDeTemperature"]) === "Non" ? (
                                                                        <Alert
                                                                            message="Cette opération n'est éligible que si le dispositif intègre une fonction « descente de température (réduit en inoccupation) » au sens de la norme NF EN 12098-1."
                                                                            type="error"
                                                                            showIcon
                                                                        />
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                            {/* Suite formulaire si Descente de température OK */}
                                                            <Form.Item noStyle shouldUpdate>
                                                                {({ getFieldValue }) =>
                                                                    getFieldValue(['items', field.name, "DescenteDeTemperature"]) === "Oui" ? (
                                                                        <>
                                                                            <SecteurREF22AD
                                                                                name={[field.name, "REF-22-AD"]}
                                                                                label="Secteur d'activité :"
                                                                            />

                                                                            <SurfaceREF10
                                                                                name={[field.name, "REF-10"]}
                                                                                label="Surface chauffée (m²) :"
                                                                                tooltip="La surface chauffée à prendre en compte est celle gérée par le système de régulation par optimiseur de relance mis en place comprenant une fonction auto-adaptative."
                                                                            />

                                                                        </>
                                                                    ) : null
                                                                }
                                                            </Form.Item>

                                                        </>
                                                    ) : null
                                                }
                                            </Form.Item>
                                        </>
                                    ) : null
                                }
                            </Form.Item>
                        </>
                    ) : null
                }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button> : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card
                            tabList={tabListNoTitle}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            size='small'
                        >
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>

    );
};
