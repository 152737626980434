import { Form, FormListFieldData, Typography, Flex, Divider, Button } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FilePdfOutlined } from '@ant-design/icons';
import ModeFonctionnementREF36A from '../refs/ModeFonctionnementREF36A';
import TemperatureSourceREF67B from '../refs/TemperatureSourceREF67B';
import TypeInstallationREF81G from '../refs/TypeInstallationREF81G';
import PuissanceREF26 from '../refs/PuissanceREF26';


const { Title } = Typography

export const Indut1181S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'

    /* Infos FOST */
    const code_fost = "IND-UT-118"
    const libelle_fost = "Brûleur avec dispositif de récupération de chaleur sur four industriel"
    const denomination_fost = <>Mise en place d’un brûleur auto-récupérateur ou d’un brûleur régénératif (autorégénératif
        ou paire de brûleurs régénératifs) ou d’un récupérateur de chaleur sur les fumées pour préchauffer l’air comburant sur un four industriel</>
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/IND-UT-118.pdf"


    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="top">
                <Typography.Text strong style={{ color: subtitle_color, fontSize: '1.1em', marginTop: '0px' }}>
                    {denomination_fost}
                </Typography.Text>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, marginTop: '12px', marginBottom: '12px' }}></Divider>

            {/* Mode de fonctionnment */}
            <ModeFonctionnementREF36A
                name={[field.name, "REF-36-A"]}
                label="Mode de fonctionnement du site :"
            />

            {/* Température de sortie du four */}
            <TemperatureSourceREF67B
                name={[field.name, "REF-67-B"]}
                label="Température des fumées à la sortie du four (°C) :"
            />

            {/* Nature de l'opération */}
            <TypeInstallationREF81G
                name={[field.name, "REF-81-G"]}
                label="Nature de l'opération :"
                onChange={
                    () => {
                        form.setFieldValue(['items', field.name, "REF-26"], undefined)
                        form.setFieldValue(['items', field.name, "REF-36-A"], undefined)
                    }
                }
            />

            {/* Puissance si Nature opération = 0 ou 1 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-81-G"]) === "0" || getFieldValue(['items', field.name, "REF-81-G"]) === "1" ? (
                        <>
                            {/* Puissance */}
                            <PuissanceREF26
                                name={[field.name, "REF-26"]}
                                label="Somme des puissances thermiques nominales des nouveaux brûleurs (kW) :"
                                unite='kW'
                            />
                        </>
                    ) : null
                }
            </Form.Item >

            {/* Puissance si Nature opération = 2 */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "REF-81-G"]) === "2" ? (
                        <>
                            {/* Puissance */}
                            <PuissanceREF26
                                name={[field.name, "REF-26"]}
                                label="Somme des puissances thermiques nominales des brûleurs existants transformés (kW) :"
                                unite='kW'
                            />
                        </>
                    ) : null
                }
            </Form.Item >
        </>
    );
};
