import { ApiCalculateResponse, VolumeType } from '@features/new-project/types';

export function convertirClesEnMinuscules(
    objet: ApiCalculateResponse
): VolumeType {
    const nouveauObjet: VolumeType = {} as VolumeType;

    Object.entries(objet).forEach(([key, valeur]) => {
        if (key !== 'ficheId' && key !== 'ficheIndex') {
            nouveauObjet[key.toLowerCase()] = valeur;
        }
    });
    nouveauObjet.ficheId = objet.ficheId;
    nouveauObjet.ficheIndex = objet.ficheIndex;

    return nouveauObjet;
}
