import React from 'react';

import { useScreenDetector } from '@/hooks/responsive/useResponsive';
import { Input, Space, Typography } from 'antd';

const KeywordSearchInput: React.FC<{
  searchQuery: string;
  isPublic?: boolean;
  handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ searchQuery, isPublic = false, handleSearchQueryChange }) => {
  const primary_color = '#002766';

  const fontSize = isPublic ? '11px' : '';

  const { isMobile } = useScreenDetector();
  return (
    <Space
      direction={isMobile ? 'vertical' : 'horizontal'}
      style={{
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingInline: '16px',
      }}
    >
      <Typography>
        <Typography.Text
          strong
          style={{
            flex: '1',
            color: primary_color,
            fontSize: fontSize,
          }}
        >
          Recherche par mots-clés:
        </Typography.Text>
      </Typography>
      <Input
        size="large"
        style={{
          flex: '1',
          width: '296px',
        }}
        value={searchQuery}
        onChange={handleSearchQueryChange}
      />
    </Space>
  );
};

export default KeywordSearchInput;
