import React, { useState } from 'react';

import { NumericInput } from '@/components';
import * as constant from '@/features/new-project/constants';
import { Text } from '@/utils';
import { Checkbox, Form, Row, Space } from 'antd';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const Heating = () => {
    const [heatingType, setHeatingType] = useState<CheckboxValueType[]>();
    const [tertiaryWorkBoilerPower, setTertiaryWorkBoilerPower] =
        useState<string>('');
    const [heatingWorkBuildingArea, setHeatingWorkBuildingArea] =
        useState<string>('');
    const onChange = (checkedValues: CheckboxValueType[]) => {
        setHeatingType(checkedValues);
    };

    return (
        <div className="new-project__under-form-item">
            <Form.Item style={{ marginBottom: 0 }} name={constant.HEATING_WORK}>
                <Checkbox.Group onChange={onChange}>
                    <Space direction="vertical" size="small">
                        <div>
                            <Checkbox value="tertiary">
                                <Text>Tertiaire</Text>
                            </Checkbox>
                            {heatingType?.includes(constant.TERTIARY) && (
                                <div className="work-type-answer">
                                    <Text>
                                        <ul style={{ listStyle: 'square' }}>
                                            <Space
                                                direction="vertical"
                                                size="small"
                                            >
                                                <li style={{ paddingLeft: 0 }}>
                                                    <Row align="bottom">
                                                        <Text>
                                                            Surface du bâtiment
                                                            :
                                                        </Text>
                                                        <Form.Item
                                                            className="form-item--same-line"
                                                            name={
                                                                constant.TERTIARY_WORK_BUILDING_AREA
                                                            }
                                                        >
                                                            <NumericInput
                                                                maxLength={7}
                                                                classes="input-sameline-answer"
                                                                value={
                                                                    heatingWorkBuildingArea
                                                                }
                                                                onChange={(
                                                                    heatingWorkBuildingArea
                                                                ) =>
                                                                    setHeatingWorkBuildingArea(
                                                                        heatingWorkBuildingArea
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <Text>
                                                            m<sup>2</sup>
                                                        </Text>
                                                    </Row>
                                                </li>
                                                <li style={{ paddingLeft: 0 }}>
                                                    <Row align="bottom">
                                                        <Text>
                                                            ET/OU puissance de
                                                            la chaudière/PAC à
                                                            installer :
                                                        </Text>
                                                        <Form.Item
                                                            className="form-item--same-line"
                                                            name={
                                                                constant.TERTIARY_WORK_BOILER_POWER
                                                            }
                                                        >
                                                            <NumericInput
                                                                maxLength={7}
                                                                classes="input-sameline-answer"
                                                                value={
                                                                    tertiaryWorkBoilerPower
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    setTertiaryWorkBoilerPower(
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <Text>kW</Text>
                                                    </Row>
                                                </li>
                                            </Space>
                                        </ul>
                                    </Text>
                                </div>
                            )}
                        </div>
                        <div>
                            <Checkbox value="residentialy">
                                <Text>Résidentiel</Text>
                            </Checkbox>
                            {heatingType?.includes(constant.RESIDENTIALY) && (
                                <div className="work-type-answer">
                                    <Text>
                                        <ul style={{ listStyle: 'square' }}>
                                            <Space
                                                direction="vertical"
                                                size="small"
                                            >
                                                <li style={{ paddingLeft: 0 }}>
                                                    <Row align="bottom">
                                                        <Text>
                                                            Nombre de logements
                                                            :
                                                        </Text>
                                                        <Form.Item
                                                            className="form-item--same-line"
                                                            name={
                                                                constant.RESIDENTIALY_NUMBER_OF_HOUSINGS
                                                            }
                                                        >
                                                            <NumericInput
                                                                maxLength={7}
                                                                classes="input-sameline-answer"
                                                            />
                                                        </Form.Item>
                                                    </Row>
                                                </li>
                                                <li style={{ paddingLeft: 0 }}>
                                                    <Row align="bottom">
                                                        <Text>
                                                            ET/OU puissance de
                                                            la chaudière/PAC à
                                                            installer :
                                                        </Text>
                                                        <Form.Item
                                                            className="form-item--same-line"
                                                            name={
                                                                constant.RESIDENTIALY_WORK_BOILER_POWER
                                                            }
                                                        >
                                                            <NumericInput
                                                                maxLength={7}
                                                                placeholder="0"
                                                                classes="input-sameline-answer"
                                                            />
                                                        </Form.Item>
                                                        <Text>kW</Text>
                                                    </Row>
                                                </li>
                                            </Space>
                                        </ul>
                                    </Text>
                                </div>
                            )}
                        </div>
                    </Space>
                </Checkbox.Group>
            </Form.Item>
        </div>
    );
};
