import { Form, Alert, FormListFieldData, InputNumber, Typography, Divider, Button, Flex } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { InfoCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import PuissanceChaudiereREF24B from '../refs/PuissanceChaudiereREF24B';
import RREF142 from '../refs/RREF142';
import SecteurREF22AD from '../refs/SecteurREF22AD';
import UsageREF15A from '../refs/UsageREF15A';
import { ZoneClimatiqueS } from '../refs/ZoneClimatiqueS';

const { Paragraph, Title } = Typography

export const Batth1022S = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()
    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-TH-102"
    const libelle_fost = "Chaudière collective à haute performance énergétique"
    const denomination_fost = "Mise en place d’une chaudière à haute performance énergétique pour un système de chauffage central à combustible"
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-102%20v%20A28-2%20%C3%A0%20compter%20du%2001-01-2019.pdf"




    return (
        <>
            <Title level={4} style={{ color: primary_color, margin: 0 }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}></Divider>

            {/* Code Postal */}
            <ZoneClimatiqueS field={field} />

            {/* Surface */}
            <Form.Item
                name={[field.name, "REF-10"]}
                label="Surface totale chauffée du bâtiment (m²) :"
                rules={[{ required: true, message: "Veuillez renseigner la surface" },]}
            >
                <InputNumber addonAfter="m²" controls={false} decimalSeparator=',' min={0} />
            </Form.Item>

            {/* Usage */}
            <UsageREF15A
                name={[field.name, "REF-15-A"]}
                label="Usage : "
            />

            {/* Secteur */}
            <SecteurREF22AD
                name={[field.name, "REF-22-AD"]}
                label="Secteur : "
            />


            {/* Puissance Chaudière */}

            <Form.Item
                name={[field.name, "Pinstallee"]}
                label="Puissance de la chaudière (kW) : "
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <InputNumber
                    addonAfter="kW"
                    decimalSeparator=','
                    min='0'
                    controls={false}
                    style={{ width: '150px' }}
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Pinstallee"]) <= 400 && form.getFieldValue(['items', field.name, "Pinstallee"]) > 0) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "0" }]) }
                        else if (form.getFieldValue(['items', field.name, "Pinstallee"]) > 400) { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: "1" }]) }
                        else { form.setFields([{ name: ["items", field.name, "REF-24-B"], value: undefined }]) }
                        form.setFieldValue(['items', field.name, "Pchaufferie"], undefined)
                        form.setFieldValue(['items', field.name, "REF-142"], undefined)
                    }}
                />
            </Form.Item>
            <PuissanceChaudiereREF24B
                label=""
                name={[field.name, 'REF-24-B']}
                disabled={true}
                hidden
                onChange={
                    () => {
                    }
                }
            />


            {/* Puissance totale chaufferie */}
            <Form.Item
                name={[field.name, "Pchaufferie"]}
                label="Puissance totale de la nouvelle chaufferie : "
                rules={[{ required: true, message: "Veuillez renseigner ce champ" },]}
                tooltip={{
                    title: <>Puissance de la chaufferie après travaux. La puissance de la nouvelle chaufferie ne comptabilise pas les équipements de secours.</>,
                    icon: <InfoCircleOutlined style={{ color: primary_color }} />,
                    color: tooltip_color,
                }}
                style={{ margin: "0px" }}
            >
                <InputNumber
                    addonAfter="kW"
                    style={{ width: '150px' }}
                    controls={false}
                    min='0'
                    onChange={() => {
                        if (form.getFieldValue(['items', field.name, "Pinstallee"]) >= (1 / 3 * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                            && form.getFieldValue(['items', field.name, "Pchaufferie"]) >= form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: "1" }]) }
                        else if (form.getFieldValue(['items', field.name, "Pinstallee"]) < (1 / 3  * form.getFieldValue(['items', field.name, "Pchaufferie"]))
                            && form.getFieldValue(['items', field.name, "Pchaufferie"]) > form.getFieldValue(['items', field.name, "Pinstallee"])) { form.setFields([{ name: ["items", field.name, "REF-142"], value: (form.getFieldValue(['items', field.name, "Pinstallee"]) / form.getFieldValue(['items', field.name, "Pchaufferie"])) }]) }
                        else { form.setFields([{ name: ["items", field.name, "REF-142"], value: undefined }]) }

                    }}
                />
            </Form.Item>

            {/* R */}
            <RREF142
                label="Facteur R :"
                name={[field.name, 'REF-142']}
                disabled={true}
                hidden
            />

            {/* Alerte si Pchaufferie < Pinstallée */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "Pchaufferie"]) < getFieldValue(['items', field.name, "Pinstallee"]) ? (
                        <Alert
                            message="La puissance de la nouvelle chaufferie est au moins égale à la puissance installée."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>


        </>
    );
};
