import React, { useCallback, useEffect, useState } from 'react';

import { AuthenticationCard } from '@/components';
import { useSendVerificationCode } from '@/hooks/coginto/useSendVerificationCode';
import { setUsername } from '@/store/slices';
import { Text } from '@/utils/constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { validation } from '@features/auth/index';
import { Button, Form, Input, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import '../styles.scss';

const ForgetPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, seEmail] = useState<string>('');

    const { isPending, changePassword, isRejected, isResolved } =
        useSendVerificationCode();

    useEffect(() => {
        if (isResolved) {
            dispatch(setUsername(email));
            navigate('/reset-password');
        }
    }, [isResolved, dispatch, navigate, email]);

    const handleVerificationPassowrd = useCallback(() => {
        changePassword(email);
    }, [changePassword, email]);
    return (
        <AuthenticationCard title="Mot de passe oublié">
            <Space
                direction="vertical"
                size="small"
                style={{ display: 'flex' }}
            >
                {isRejected && (
                    <Text type="danger" style={{ marginBottom: '5px' }}>
                        l'email entré ne correspond à aucun compte
                    </Text>
                )}

                <Text type="secondary">
                    <ExclamationCircleOutlined /> Un code de vérification sera
                    envoyé à votre adresse email
                </Text>
                <Form
                    name="basic"
                    style={{ width: '100%' }}
                    autoComplete="off"
                    className="form__authentication"
                    onFinish={handleVerificationPassowrd}
                >
                    <Form.Item
                        name="username"
                        rules={validation.emailValidation}
                    >
                        <Input
                            name="username"
                            placeholder="Veuillez saisir votre email"
                            onChange={(e) => seEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Row justify="end" style={{ marginBottom: '15px' }}>
                        <Link to="/">Retour à la connexion</Link>
                    </Row>
                    <Row justify="center">
                        <Form.Item className="form__btn">
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isPending}
                            >
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Space>
        </AuthenticationCard>
    );
};

export default ForgetPassword;
